export const mediterraneanDietAdherence = {
    title: "Mediterranean Diet Adherence",
    about: "",
    instructions: "",
    questions: [
        {
            questionNumber: "1",
            question: "Do you use olive oil as main culinary fat?",
            answers: ["YES", "NO"],
            type: "radio",
        },
        {
            questionNumber: "2",
            question: "How much olive oil do you consume in a given day (including oil used for frying, salads, out-of-house meals, etc…)?",
            answers: ["None", "1 tbsp", "2 tbsp", "3 tbsp", "≥4 tbsp"],
            type: "radio",
        },
        {
            questionNumber: "3",
            question:
                "How many vegetable servings do you consume per day? (1 serving: 200 g; consider side dishes as ½ a serving; consider 1 serving of raw vegetable as 2 servings)",
            answers: ["<1 serving", "1 serving", "≥2 servings"],
            type: "radio",
        },
        {
            questionNumber: "4",
            question: "How many fruit units (including natural fruit juices) do you consume per day?",
            answers: ["<1 unit", "1 unit", "2 units", "≥3 units"],
            type: "radio",
        },
        {
            questionNumber: "5",
            question:
                "How many servings of red meat, hamburger, or meat products (ham, sausage, etc..) do you consume per day? (1 serving: 100-150g)",
            answers: ["<1 serving", "1 serving", "2 serving", "≥3 servings"],
            type: "radio",
        },
        {
            questionNumber: "6",
            question: "How many servings of butter, margarine, or cream do you consume per day? (1 serving: 12g)",
            answers: ["None", "<1 serving", "1 serving", "2 serving", "≥3 servings"],
            type: "radio",
        },
        {
            questionNumber: "7",
            question: "How many sweet or carbonated beverages do you drink per day?",
            answers: ["None", "<1", "1", "2", "≥3"],
            type: "radio",
        },
        {
            questionNumber: "8",
            question: "How much wine do you drink per week?",
            answers: ["None", "1 glass", "2 glasses", "3 glasses", "4 glasses", "5 glasses", "6 glasses", "≥7 glasses"],
            type: "radio",
        },
        {
            questionNumber: "9",
            question: "How many servings of legumes do you consume per week? (1 serving: 150g)",
            answers: ["None", "1", "2", "≥3"],
            type: "radio",
        },
        {
            questionNumber: "10",
            question: "How many servings of fish or shellfish to you consume per week? (1 serving: 100-150g of fish or 4-5 units or 200g shellfish)",
            answers: ["None", "1", "2", "≥3"],
            type: "radio",
        },
        {
            questionNumber: "11",
            question: "How many times per week to you consume sweets or pastries (not homemade), such as cakes, cookies, biscuits, or custard?",
            answers: ["None", "1", "2", "≥3"],
            type: "radio",
        },
        {
            questionNumber: "12",
            question: "How many servings of nuts (including peanuts) do you consume per week? (1 serving: 30 g)",
            answers: ["None", "1", "2", "≥3"],
            type: "radio",
        },
        {
            questionNumber: "13",
            question: "Do you preferentially consume chicken, turkey, or rabbit meat instead of veal, pork, hamburger, or sausage?",
            answers: ["YES", "NO"],
            type: "radio",
        },
        {
            questionNumber: "14",
            question:
                "How many times per week do you consume vegetables, pasta, rice, or other dishes seasoned with sofrito (sauce made with tomato and onion, leek, or garlic simmered with olive oil)?",
            answers: ["None", "1", "≥2"],
            type: "radio",
        },
    ],
};
export const dietHistory = {
    title: "Diet History",
    about: "",
    instructions: "",
    questions: [
        {
            questionNumber: "1",
            question: "Over the past month, how often did you drink tomato juice?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "1a",
            question: "Each time you drank tomato juice, how much did you usually drink?",
            answers: ["Less than ½ cup (4 ounces)", "½ to 1¼ cups (4 to 10 ounces)", "More than 1¼ cups (10 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "2",
            question: "How many vegetable servings do you consume per day? (1 serving: 200 g [consider side dishes as half a serving])",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "2a",
            question: "Each time you drank other vegetable juice, how much did you usually drink?",
            answers: ["Less than ¾ cup (6 ounces)", "¾ to 1¼ cups (6 to 10 ounces)", "More than 1¼ cups (10 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "3",
            question: "Over the past month, how often did you drink 100% orange juice or grapefruit juice?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "3a",
            question: "Each time you drank 100% orange juice or grapefruit juice, how much did you usually drink?",
            answers: ["Less than ¾ cup (6 ounces)", "¾ to 1¼ cups (6 to 10 ounces)", "More than 1¼ cups (10 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "3b",
            question: "How often was the orange juice or grapefruit juice you drank calcium-fortified?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "4",
            question:
                "Over the past month, how often did you drink other 100% fruit juice or 100% fruit juice mixtures (such as apple, grape, pineapple, or others)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "4a",
            question: "Each time you drank other 100% fruit juice or 100% fruit juice mixtures, how much did you usually drink?",
            answers: ["Less than ¾ cup (6 ounces)", "¾ to 1½ cups (6 to 12 ounces)", "More than 1½ cups (12 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "5",
            question: "How often did you drink other fruit drinks (such as Fruité, Fruitopia, Five Alive, Sunny D, or Kool-Aid, diet or regular)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "5a",
            question: "Each time you drank other fruit drinks, how much did you usually drink?",
            answers: ["Less than ¾ cup (6 ounces)", "1 to 2 cups (8 to 16 ounces)", "More than 2 cups (16 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "5b",
            question: "How often were your other fruit drinks diet or low calorie?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "6",
            question:
                "How often did you drink milk or milk substitutes as a beverage (NOT in coffee, NOT in cereal)? (Please do not include chocolate milk, hot chocolate, and milk in milkshakes or meal replacement beverages.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "6a",
            question: "Each time you drank milk or milk substitutes as a beverage, how much did you usually drink?",
            answers: ["Less than ¾ cup (6 ounces)", "¾ to 1½ cups (6 to 12 ounces)", "More than 1½ cups (12 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "6b",
            question: "What kind of milk or milk substitutes did you usually drink?",
            answers: ["Whole milk 2% fat milk", "1% fat milk", "Skim, nonfat, or 0.5% fat milk", "Soy milk", "Rice milk", "Almond milk", "Other"],
            type: "radio",
        },
        {
            questionNumber: "7",
            question: "How often did you drink chocolate milk or hot chocolate?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "7a",
            question: "Each time you drank chocolate milk or hot chocolate, how much did you usually drink?",
            answers: ["Less than ¾ cup (6 ounces)", "1 to 2 cups (8 to 16 ounces)", "More than 2 cups (16 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "7b",
            question: "How often was the chocolate milk or hot chocolate you drank reduced-fat or fat-free?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "8",
            question: "How often did you drink milkshakes?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "8a",
            question: "Each time you drank milkshakes, how much did you usually drink?",
            answers: ["Less than ¾ cup (6 ounces)", "1 to 2 cups (8 to 16 ounces)", "More than 2 cups (16 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "9",
            question:
                "How often did you drink meal replacement or high-protein beverages (such as Boost, Breakfast Essential, Ensure, Slimfast or others)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "9a",
            question: "Each time you drank meal replacement or high-protein beverages, how much did you usually drink?",
            answers: ["Less than ¾ cup (6 ounces)", "¾ to 1½ cups (6 to 12 ounces)", "More than 1½ cups (12 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "10",
            question: "How often did you drink soft drinks or pop?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "10a",
            question: "Each time you drank soft drinks or pop, how much did you usually drink?",
            answers: [
                "Less than 12 ounces or less than 1 regular size can or bottle (355 ml)",
                "12 to 16 ounces or 1 regular size can or bottle (355 ml)",
                "More than 16 ounces or more than 1 regular size can or bottle (355 ml)",
            ],
            type: "radio",
        },
        {
            questionNumber: "10b",
            question: "How often were your soft drinks or pop diet or calorie-free?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "10c",
            question: "How often were your soft drinks or pop caffeine-free?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "11",
            question: "How often did you drink sports drinks (such as PowerAde or Gatorade)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "11a",
            question: "Each time you drank sports drinks, how much did you usually drink?",
            answers: ["Less than 1½ cups (12 ounces)", "1½ to 3 cups (12 to 24 ounces)", "More than 3 cups (24 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "12",
            question: "How often did you drink energy drinks (such as Red Bull, Rock Star, Full Throttle, or Monster)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "12a",
            question: "Each time you drank energy drinks, how much did you usually drink?",
            answers: ["Less than 1 cup (8 ounces)", "1 to 2 cups (8 to 16 ounces)", "More than 2 cups (16 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "13",
            question: "How often did you drink beer?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "13a",
            question: "Each time you drank beer, how much did you usually drink?",
            answers: [
                "Less than 1 regular size can or bottle (341 ml)",
                "1 to 3 regular size cans or bottles",
                "More than 3 regular size cans or bottles",
            ],
            type: "radio",
        },
        {
            questionNumber: "14",
            question: "How often did you drink beer?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "14a",
            question: "Each time you drank water, how much did you usually drink?",
            answers: ["Less than 1 cup (8 ounces)", "1 to 4 cups (8 to 32 ounces)", "More than 4 cups (32 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "14b",
            question: "How often was the water you drank tap water?",
            answers: [
                "Less than 1 regular size can or bottle (341 ml)",
                "1 to 3 regular size cans or bottles",
                "More than 3 regular size cans or bottles",
            ],
            type: "radio",
        },
        {
            questionNumber: "14c",
            question: "Each time you drank beer, how much did you usually drink?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "14d",
            question: "How often was the water you drank bottled, sweetened water (with low or no-calorie sweetener, including carbonated water)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "15",
            question: "How often did you drink wine?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "15a",
            question: "Each time you drank wine, how much did you usually drink?",
            answers: ["Less than 1 glass (5 ounces)", "1 to 2 glasses (5 to 12 ounces)", "More than 2 glasses (12 ounces)"],
            type: "radio",
        },
        {
            questionNumber: "15b",
            question: "How often was the wine you drank red wine?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "16",
            question: "How often did you drink liquor or mixed drinks?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2-3 times per day",
                "4-5 times per day",
                "6 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "16a",
            question: "Each time you drank liquor or mixed drinks, how much did you usually drink?",
            answers: ["Less than 1 shot of liquor", "1 to 3 shots of liquor", "More than 3 shots of liquor"],
            type: "radio",
        },
        {
            questionNumber: "17",
            question: "How often did you eat oatmeal, Cream of Wheat, Red River, or other cooked cereal?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "17a",
            question: "Each time you ate oatmeal, Cream of Wheat, Red River, or other cooked cereal, how much did you usually eat?",
            answers: ["Less than ¾ cup", "¾ to 1¼ cups", "More than 1¼ cups"],
            type: "radio",
        },
        {
            questionNumber: "17b",
            question: "Each time you ate oatmeal, Cream of Wheat, Red River, or other cooked cereal, how much did you usually eat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "17c",
            question: "Was milk added to your oatmeal, Cream of Wheat, Red River, or other cooked cereal?",
            answers: ["YES", "NO"],
            type: "radio",
            skipTrigger: "NO",
            skipTo: "18",
        },
        {
            questionNumber: "17d",
            question: "What kind of milk was usually added?",
            answers: ["Whole milk 2% fat milk", "1% fat milk", "Skim, nonfat, or 0.5% fat milk", "Soy milk", "Rice milk", "Almond milk", "Other"],
            type: "radio",
        },
        {
            questionNumber: "17e",
            question: "Each time milk was added to your oatmeal, Cream of Wheat, Red River, or other cooked cereal, how much was usually added?",
            answers: ["Less than ½ cup", "½ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "18",
            question: "How often did you eat cold cereal?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "18a",
            question: "Each time you ate cold cereal, how much did you usually eat?",
            answers: ["Less than 1 cup", "1 to 2½ cups", "More than 2½ cups"],
            type: "radio",
        },
        {
            questionNumber: "18b",
            question: "How often was the cold cereal you ate Vector or PC Force Active?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "18c",
            question: "How often was the cold cereal you ate All Bran, Fibre 1, Fibre First, 100% Bran, or All-Bran Buds?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "18d",
            question:
                "How often was the cold cereal you ate some other bran or fibre cereal (such as Cheerios (regular), Shredded Wheat, Raisin Bran, Bran Flakes, Mini-Wheats, Shreddies, Honey Bunches of Oats, Oatmeal Crisp or others)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "18e",
            question:
                "How often was the cold cereal you ate any other type of cold cereal (such as Corn Flakes, Rice Krispies, Frosted Flakes, Special K, Froot Loops, Cap'n Crunch, Honey Nut Cheerios, Honeycomb, or others)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "18f",
            question: "Was milk added to your cold cereal?",
            answers: ["YES", "NO"],
            type: "radio",
            skipTrigger: "NO",
            skipTo: "19",
        },
        {
            questionNumber: "18g",
            question: "What kind of milk was usually added?",
            answers: ["Whole milk 2% fat milk", "1% fat milk", "Skim, nonfat, or 0.5% fat milk", "Soy milk", "Rice milk", "Almond milk", "Other"],
            type: "radio",
        },
        {
            questionNumber: "18h",
            question: "Each time milk was added to your cold cereal, how much was usually added?",
            answers: ["Less than ½ cup", "½ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "19",
            question: "How often did you eat applesauce?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "19a",
            question: "Each time you ate applesauce, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to ¾ cup", "More than ¾ cup"],
            type: "radio",
        },
        {
            questionNumber: "20",
            question: "How often did you eat apples?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "20a",
            question: "Each time you ate apples, how many did you usually eat?",
            answers: ["Less than 1 apple", "1 apple", "More than 1 apple"],
            type: "radio",
        },
        {
            questionNumber: "21",
            question: "How often did you eat pears (fresh, canned, or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "21a",
            question: "Each time you ate pears, how many did you usually eat?",
            answers: ["Less than 1 pear", "1 pear", "More than 1 pear"],
            type: "radio",
        },
        {
            questionNumber: "22",
            question: "How often did you eat bananas?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "22a",
            question: "Each time you ate bananas, how many did you usually eat?",
            answers: ["Less than 1 banana", "1 banana", "More than 1 banana"],
            type: "radio",
        },
        {
            questionNumber: "23",
            question: "How often did you eat dried fruit (such as prunes or raisins)? (Please do not include dried apricots.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "23a",
            question: "Each time you ate dried fruit, how much did you usually eat?",
            answers: ["Less than 2 tablespoons", "2 to 5 tablespoons", "More than 5 tablespoons"],
            type: "radio",
        },
        {
            questionNumber: "24",
            question: "How often did you eat peaches, nectarines, or plums (fresh, canned or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "24a",
            question: "Each time you ate peaches, nectarines, or plums, how much did you usually eat?",
            answers: ["Less than 1 fruit or less than ½ cup", "1 to 2 fruits or ½ to ¾ cup", "More than 2 fruits or more than ¾ cup"],
            type: "radio",
        },
        {
            questionNumber: "25",
            question: "How often did you eat grapes?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "25a",
            question: "Each time you ate grapes, how much did you usually eat?",
            answers: ["Less than ½ cup or less than 10 grapes", "½ to 1 cup or 10 to 30 grapes", "More than 1 cup or more than 30 grapes"],
            type: "radio",
        },
        {
            questionNumber: "26",
            question: "How often did you eat cantaloupe?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "26a",
            question: "Each time you ate cantaloupe, how much did you usually eat?",
            answers: ["Less than ¼ melon or less than ½ cup", "¼ melon or ½ to 1 cup", "More than ¼ melon or more than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "27",
            question: "How often did you eat melon, other than cantaloupe (such as watermelon or honeydew)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "27a",
            question: "Each time you ate melon other than cantaloupe, how much did you usually eat?",
            answers: ["Less than 1 cup or 2 small wedges", "1 to 3 cups or 2 medium wedges", "More than 3 cups or 2 large wedges"],
            type: "radio",
        },
        {
            questionNumber: "28",
            question: "How often did you eat strawberries (fresh or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "28a",
            question: "Each time you ate strawberries, how much did you usually eat?",
            answers: ["Less than ¼ cup or less than 4 berries", "¼ to ¾ cup or 4 to 10 berries", "More than ¾ cup or more than 10 berries"],
            type: "radio",
        },
        {
            questionNumber: "29",
            question: "How often did you eat blueberries, raspberries, saskatoon berries or blackberries (fresh or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "29a",
            question: "Each time you ate blueberries, raspberries, saskatoon berries or blackberries, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to ¾ cup", "More than ¾ cup"],
            type: "radio",
        },
        {
            questionNumber: "30",
            question: "How often did you eat oranges, tangelos, mandarins, or clementines?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "30a",
            question: "Each time you ate oranges, tangelos, mandarins, or clementines, how many did you usually eat?",
            answers: ["Less than 1 fruit", "1 fruit", "More than 1 fruit"],
            type: "radio",
        },
        {
            questionNumber: "31",
            question: "How often did you eat grapefruit?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "31a",
            question: "Each time you ate grapefruit, how much did you usually eat?",
            answers: ["Less than ½ grapefruit", "½ to 1 grapefruit", "More than 1 grapefruit"],
            type: "radio",
        },
        {
            questionNumber: "32",
            question: "How often did you eat pineapple?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "32a",
            question: "Each time you ate pineapple, how much did you usually eat?",
            answers: [
                "Less than ¼ cup or less than 1 medium slice",
                "¼ to ¾ cup or 1 to 2 medium slices",
                "More than ¾ cup or more than 2 medium slices",
            ],
            type: "radio",
        },
        {
            questionNumber: "33",
            question: "How often did you eat other kinds of fruit?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "33a",
            question: "Each time you ate other kinds of fruit, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to ¾ cup", "More than ¾ cup"],
            type: "radio",
        },
        {
            questionNumber: "34",
            question: "How often did you eat COOKED greens (such as spinach, turnip greens, collard, mustard greens, chard, or kale)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "34a",
            question: "Each time you ate COOKED greens, how much did you usually eat?",
            answers: ["Less than ½ cup", "½ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "35",
            question: "How often did you eat RAW greens (such as spinach, chard, or kale)? (We will ask about lettuce later.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "35a",
            question: "Each time you ate RAW greens, how much did you usually eat?",
            answers: ["Less than 1½ cups", "1½ to 3 cups", "More than 3 cups"],
            type: "radio",
        },
        {
            questionNumber: "36",
            question: "How often did you eat coleslaw?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "36a",
            question: "Each time you ate coleslaw, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to ¾ cup", "More than ¾ cup"],
            type: "radio",
        },
        {
            questionNumber: "37",
            question: "How often did you eat sauerkraut or cabbage (other than coleslaw)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "37a",
            question: "Each time you ate sauerkraut or cabbage, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "38",
            question: "How often did you eat carrots (fresh, canned, or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "38a",
            question: "Each time you ate carrots, how much did you usually eat?",
            answers: [
                "Less than ¼ cup or less than 2 baby carrots",
                "¼ to ¾ cup or 2 to 8 baby carrots",
                "More than ¾ cup or more than 8 baby carrots",
            ],
            type: "radio",
        },
        {
            questionNumber: "39",
            question: "How often did you eat string beans or green beans (fresh, canned, or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "39a",
            question: "Each time you ate string beans or green beans, how much did you usually eat?",
            answers: ["Less than ½ cup", "½ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "40",
            question: "How often did you eat peas (fresh, canned, or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "40a",
            question: "Each time you ate peas, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to ½ cup", "More than ½ cup"],
            type: "radio",
        },
        {
            questionNumber: "41",
            question: "How often did you eat corn (fresh, canned or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "41a",
            question: "Each time you ate corn, how much did you usually eat?",
            answers: ["Less than 1 ear or less than ½ cup ", "1 ear or ½ to 1 cup", "More than 1 ear or more than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "42",
            question: "How often did you eat broccoli (fresh or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "42a",
            question: "Each time you ate broccoli, how much did you usually eat?",
            answers: ["Less than 1 ear or less than ½ cup", "1 ear or ½ to 1 cup", "More than 1 ear or more than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "43",
            question: "How often did you eat cauliflower (fresh or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "43a",
            question: "Each time you ate cauliflower, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "44",
            question: "How often did you eat Brussels sprouts (fresh or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "44a",
            question: "Each time you ate Brussels sprouts, how much did you usually eat?",
            answers: [
                "Less than 4 Brussels sprouts or less than ½ cup",
                "4 to 7 Brussels sprouts or ½ to 1 cup",
                "More than 7 Brussels sprouts or more than 1 cup",
            ],
            type: "radio",
        },
        {
            questionNumber: "45",
            question: "How often did you eat asparagus (fresh or frozen)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "45a",
            question: "Each time you ate asparagus, how much did you usually eat?",
            answers: ["Less than 5 spears or less than ½ cup", "5 to 9 spears or ½ to ¾ cup", "More than 9 spears or more than ¾ cup"],
            type: "radio",
        },
        {
            questionNumber: "46",
            question: "How often did you eat winter squash (such as pumpkin, butternut, or acorn)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "46a",
            question: "Each time you ate winter squash, how much did you usually eat?",
            answers: ["Less than 1/3 cup", "1/3 to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "47",
            question: "How often did you eat mixed vegetables (such as vegetable stir fry, frozen or canned mixed vegetables)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "47a",
            question: "Each time you ate mixed vegetables, how much did you usually eat?",
            answers: ["Less than 1/3 cup", "1/3 to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "48",
            question: "How often did you eat onions?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "48a",
            question: "Each time you ate onions, how much did you usually eat?",
            answers: [
                "Less than 1 slice or less than 1 tablespoon",
                "1 to 5 slices or 1 to 4 tablespoons",
                "More than 5 slices or more than 4 tablespoons",
            ],
            type: "radio",
        },
        {
            questionNumber: "49",
            question:
                "Now think about all the cooked vegetables you ate in the past month and how they were prepared. How often were your vegetables COOKED WITH some sort of fat, including oil spray? (Please do not include potatoes.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "49a",
            question: "Which fats were usually added to your vegetables DURING COOKING? (Please do not include potatoes. Mark all that apply.)",
            answers: [
                "Margarine (including light)",
                "Butter (including light)",
                "Lard, fatback, or bacon fat",
                "Olive oil",
                "Corn oil",
                "Canola or rapeseed oil",
                "Oil spray, such as Pam or others",
                "Other kinds of oil",
                "None of the above",
            ],
            type: "checkbox",
        },
        {
            questionNumber: "50",
            question:
                "Now, thinking again about all the cooked vegetables you ate in the past month, how often was some sort of fat, sauce, or dressing added AFTER COOKING OR AT THE TABLE? (Please do not include potatoes.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "50a",
            question:
                "Which fats, sauces, or dressings were usually added AFTER COOKING OR AT THE TABLE? (Please do not include potatoes. Mark all that apply.)",
            answers: [
                "Margarine (including light)",
                "Butter (including light)",
                "Lard, fatback, or bacon fat",
                "Vegetable oil",
                "Salad dressing",
                "Cheese sauce",
                "White sauce",
                "Other",
            ],
            type: "radio",
        },
        {
            questionNumber: "50b",
            question:
                "If margarine, butter, vegetable oil, lard, fatback, or bacon fat was added to your cooked vegetables AFTER COOKING OR AT THE TABLE, how much did you usually add?",
            answers: ["Did not usually add these", "Less than 1 teaspoon", "1 to 3 teaspoons", "More than 3 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "50c",
            question:
                "If salad dressing, cheese sauce, or white sauce was added to your cooked vegetables AFTER COOKING OR AT THE TABLE, how much did you usually add?",
            answers: ["Did not usually add these", "Less than 1 teaspoon", "1 to 3 teaspoons", "More than 3 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "51",
            question: "How often did you eat sweet peppers (green, red, or yellow)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "51a",
            question: "Each time you ate sweet peppers, how much did you usually eat?",
            answers: ["Less than 1/8 pepper", "1/8 to ½ pepper", "More than ½ pepper"],
            type: "radio",
        },
        {
            questionNumber: "52",
            question: "How often did you eat fresh tomatoes (including those in salads)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "52a",
            question: "Each time you ate fresh tomatoes, how much did you usually eat?",
            answers: ["Less than ¼ tomato", "¼ to 1 tomato", "More than 1 tomato"],
            type: "radio",
        },
        {
            questionNumber: "53",
            question: "How often did you eat lettuce salads (with or without other vegetables)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "53a",
            question: "Each time you ate lettuce salads, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to 1½ cups", "More than 1½ cups"],
            type: "radio",
        },
        {
            questionNumber: "53b",
            question: "How often did the lettuce salads you ate include dark green lettuce?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "54",
            question: "How often did you eat salad dressing (including low-fat) on salads?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "54a",
            question: "Each time you ate salad dressing on salads, how much did you usually eat?",
            answers: ["Less than 2 tablespoons", "2 to 4 tablespoons", "More than 4 tablespoons"],
            type: "radio",
        },
        {
            questionNumber: "55",
            question: "How often did you eat sweet potatoes or yams?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "55a",
            question: "Each time you ate sweet potatoes or yams, how much did you usually eat?",
            answers: ["1 small potato or less than ½ cup", "1 medium potato or ½ to 1 cup", "1 large potato or more than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "56",
            question: "How often did you eat French fries, home fries, hash browned potatoes, or tater tots?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "56a",
            question: "Each time you ate French fries, home fries, hash browned potatoes, or tater tots how much did you usually eat?",
            answers: ["Less than 10 fries or less than ½ cup", "10 to 25 fries or ½ to 1 cup", "More than 25 fries or more than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "56b",
            question:
                "How often did the French fries, home fries, hash browned potatoes, or tater tots you ate include poutine (with gravy and cheese)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "57",
            question: "How often did you eat potato salad?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "57a",
            question: "Each time you ate potato salad, how much did you usually eat?",
            answers: ["Less than ½ cup", "½ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "58",
            question: "How often did you eat baked, boiled, or mashed potatoes?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "58a",
            question: "Each time you ate baked, boiled, or mashed potatoes, how much did you usually eat?",
            answers: ["1 small potato or less than ½ cup", "1 medium potato or ½ to 1 cup", "1 large potato or more than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "58b",
            question: "Each time you ate baked, boiled, or mashed potatoes, how much did you usually eat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
            skipTrigger: "Almost never or never",
            skipTo: "58d",
        },
        {
            questionNumber: "58c",
            question: "Each time sour cream was added to your potatoes, how much was usually added?",
            answers: ["Less than 1 tablespoon", "1 to 4 tablespoons", "More than 4 tablespoons"],
            type: "radio",
        },
        {
            questionNumber: "58d",
            question: "How often was margarine (including light) added to your potatoes, EITHER IN COOKING OR AT THE TABLE?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "58e",
            question: "How often was butter (including light) added to your potatoes, EITHER IN COOKING OR AT THE TABLE?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "58f",
            question: "Each time margarine or butter was added to your potatoes, how much was usually added?",
            answers: ["never added", "Less than 1 teaspoon", "1 to 3 teaspoons", "More than 3 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "58g",
            question: "How often was cheese or cheese sauce added to your potatoes, EITHER IN COOKING OR AT THE TABLE?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
            skipTrigger: "Almost never or never",
            skipTo: "59",
        },
        {
            questionNumber: "58h",
            question: "Each time cheese or cheese sauce was added to your potatoes, how much was usually added?",
            answers: ["Less than 1 tablespoon", "1 to 3 tablespoons", "More than 3 tablespoons"],
            type: "radio",
        },
        {
            questionNumber: "59",
            question: "How often did you eat salsa?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "59a",
            question: "Each time you ate salsa, how much did you usually eat?",
            answers: ["Less than 2 tablespoons", "2 tablespoons to ½ cup", "More than ½ cup"],
            type: "radio",
        },
        {
            questionNumber: "60",
            question: "How often did you eat salsa?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "60a",
            question: "Each time you ate salsa, how much did you usually eat?",
            answers: ["Less than 2 teaspoons", "2 to 6 teaspoons", "More than 6 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "61",
            question: "How often did you eat stuffing, dressing, or dumplings?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "61a",
            question: "Each time you ate stuffing, dressing, or dumplings, how much did you usually eat?",
            answers: ["Less than ½ cup", "½ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "62",
            question: "How often did you eat chili?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "62a",
            question: "Each time you ate chili, how much did you usually eat?",
            answers: ["Less than 1 cup", "1 to 1¾ cups", "More than 1¾ cups"],
            type: "radio",
        },
        {
            questionNumber: "63",
            question:
                "How often did you eat Mexican foods (such as tacos, tostados, burritos, tamales, fajitas, enchiladas, quesadillas, or chimichangas)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "63a",
            question: "Each time you ate Mexican foods, how much did you usually eat?",
            answers: ["Less than 1 taco, burrito, etc.", "1 to 2 tacos, burritos, etc.", "More than 2 tacos, burritos, etc."],
            type: "radio",
        },
        {
            questionNumber: "64",
            question: "How often did you eat baked beans? (Please include canned, ready-made, or homemade.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "64a",
            question: "Each time you ate baked beans, how much did you usually eat?",
            answers: ["Less than ½ cup", "½ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "65",
            question:
                "How often did you eat other cooked dried beans (such as pintos, kidney, black-eyed peas, lima, lentils, soybeans, or refried beans)? (Please do not include bean, pea or lentil soups, or chili.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "65a",
            question: "Each time you ate other beans, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to ¾ cup", "More than ¾ cup"],
            type: "radio",
        },
        {
            questionNumber: "65b",
            question: "How often were the other beans you ate refried beans, beans prepared with any type of fat, or with meat added?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "66",
            question: "How often did you eat other kinds of vegetables?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "66a",
            question: "Each time you ate other kinds of vegetables, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to ½ cup", "More than ½ cup"],
            type: "radio",
        },
        {
            questionNumber: "67",
            question: "How often did you eat rice or other cooked grains (such as bulgur, cracked wheat, or millet)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "67a",
            question: "Each time you ate rice or other cooked grains, how much did you usually eat?",
            answers: ["Less than ½ cup", "½ to 1½ cups", "More than 1½ cups"],
            type: "radio",
        },
        {
            questionNumber: "67b",
            question: "How often was butter, margarine, or oil added to your rice or other cooked grains IN COOKING OR AT THE TABLE?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "68",
            question: "How often did you eat pancakes, waffles, or French toast?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "68a",
            question: "Each time you ate pancakes, waffles, or French toast, how much did you usually eat?",
            answers: ["Less than 1 medium piece", "1 to 3 medium pieces", "More than 3 medium pieces"],
            type: "radio",
        },
        {
            questionNumber: "68b",
            question: "How often was margarine (including light) added to your pancakes, waffles, or French toast AFTER COOKING OR AT THE TABLE?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "68c",
            question: "How often was butter (including light) added to your pancakes, waffles, or French toast AFTER COOKING OR AT THE TABLE?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "68d",
            question: "Each time margarine or butter was added to your pancakes, waffles, or French toast, how much was usually added?",
            answers: ["never added", "Less than 1 teaspoon", "1 to 3 teaspoons", "More than 3 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "68e",
            question: "How often was syrup added to your pancakes, waffles, or French toast?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
            skipTrigger: "Almost never or never",
            skipTo: "69",
        },
        {
            questionNumber: "68f",
            question: "Each time syrup was added to your pancakes, waffles, or French toast, how much was usually added?",
            answers: ["Less than 1 tablespoon", "1 to 3 tablespoons", "More than 3 tablespoons"],
            type: "radio",
        },
        {
            questionNumber: "69",
            question:
                "How often did you eat lasagna, stuffed shells, stuffed manicotti, ravioli, or tortellini? (Please do not include spaghetti or other pasta.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "69a",
            question: "Each time you ate lasagna, stuffed shells, stuffed manicotti, ravioli, or tortellini, how much did you usually eat?",
            answers: ["Less than 1 cup", "1 to 2 cups", "More than 2 cups"],
            type: "radio",
        },
        {
            questionNumber: "70",
            question: "How often did you eat macaroni and cheese?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "70a",
            question: "Each time you ate macaroni and cheese, how much did you usually eat?",
            answers: ["Less than 1 cup", "1 to 1½ cups", "More than 1½ cups"],
            type: "radio",
        },
        {
            questionNumber: "71",
            question: "How often did you eat pasta salad or macaroni salad?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "71a",
            question: "Each time you ate pasta salad or macaroni salad, how much did you usually eat?",
            answers: ["Less than ½ cup", "½ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "72",
            question: "Other than the pastas listed in Questions 69, 70, and 71, how often did you eat pasta, spaghetti, or other noodles?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "72a",
            question: "Each time you ate pasta, spaghetti, or other noodles, how much did you usually eat?",
            answers: ["Less than 1 cup", "1 to 3 cups", "More than 3 cups"],
            type: "radio",
        },
        {
            questionNumber: "72b",
            question: "How often did you eat your pasta, spaghetti, or other noodles with tomato sauce or spaghetti sauce made WITH meat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "72c",
            question: "How often did you eat your pasta, spaghetti, or other noodles with tomato sauce or spaghetti sauce made WITHOUT meat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "72d",
            question: "How often did you eat your pasta, spaghetti, or other noodles with margarine, butter, oil, or cream sauce?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "73",
            question: "How often did you eat bagels or English muffins?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "73a",
            question: "How often were the bagels or English muffins you ate whole wheat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "73b",
            question: "Each time you ate bagels or English muffins, how many did you usually eat?",
            answers: ["Less than 1 bagel or English muffin", "1 bagel or English muffin", "More than 1 bagel or English muffin"],
            type: "radio",
        },
        {
            questionNumber: "73c",
            question: "How often was margarine (including light) added to your bagels or English muffins?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "73d",
            question: "How often was butter (including light) added to your bagels or English muffins?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "73e",
            question: "Each time margarine or butter was added to your bagels or English muffins, how much was usually added?",
            answers: ["never added", "Less than 1 teaspoon", "1 to 2 teaspoons", "More than 2 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "73f",
            question: "How often was cream cheese (including low-fat) spread on your bagels or English muffins?",
            answers: ["never added", "Less than 1 teaspoon", "1 to 2 teaspoons", "More than 2 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "73g",
            question: "Each time cream cheese was added to your bagels or English muffins, how much was usually added?",
            answers: ["Less than 1 tablespoon", "1 to 2 tablespoons", "More than 2 tablespoons"],
            type: "radio",
        },
        {
            questionNumber: "74",
            question:
                "How often did you eat breads, rolls or flatbreads (such as pita, roti and tortillas) AS PART OF SANDWICHES (including burger and hot dog rolls)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "74a",
            question: "Each time you ate breads, rolls or flatbreads AS PART OF SANDWICHES, how many did you usually eat?",
            answers: ["1 slice or ½ roll or flatbread", "2 slices or 1 roll or flatbread", "More than 2 slices or more than 1 roll or flatbread"],
            type: "radio",
        },
        {
            questionNumber: "74b",
            question: "How often were the breads, rolls or flatbreads that you used for your sandwiches white (including burger and hot dog rolls)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "74c",
            question:
                "How often was mayonnaise or mayonnaise- type dressing (including low-fat) added to the breads, rolls or flatbreads used for your sandwiches or wraps?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
            skipTrigger: "Almost never or never",
            skipTo: "74e",
        },
        {
            questionNumber: "74d",
            question:
                "Each time mayonnaise or mayonnaise-type dressing was added to the breads, rolls or flatbreads used for your sandwiches or wraps, how much was usually added?",
            answers: ["Less than 1 teaspoon", "1 to 3 teaspoons", "More than 3 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "74e",
            question: "How often was margarine (including light) added to the breads, rolls or flatbreads used for your sandwiches or wraps?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "74f",
            question: "How often was butter (including low-fat) added to the breads, rolls or flatbreads used for your sandwiches or wraps?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "74g",
            question:
                "Each time margarine or butter was added to the breads, rolls or flatbreads used for your sandwiches or wraps, how much was usually added?",
            answers: ["never added", "Less than 1 teaspoon", "1 to 2 teaspoons", "More than 2 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "75",
            question: "How often did you eat breads, dinner rolls or flatbreads, NOT AS PART OF SANDWICHES?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "75a",
            question: "Each time you ate breads, dinner rolls or flatbreads, NOT AS PART OF SANDWICHES, how much did you usually eat?",
            answers: [
                "1 slice or 1 dinner roll or ½ flatbread",
                "2 slices or 2 dinner rolls or 1 flatbread",
                "More than 2 slices or dinner rolls or more than",
            ],
            type: "radio",
        },
        {
            questionNumber: "75b",
            question: "How often were the breads, rolls or flatbreads you ate white?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "75b",
            question: "How often were the breads, rolls or flatbreads you ate white?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "75c",
            question: "How often was margarine (including light) added to your breads, rolls or flatbreads?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "75d",
            question: "How often was butter (including light) added to your breads, rolls or flatbreads?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "75e",
            question: "Each time margarine or butter was added to your breads, rolls or flatbreads, how much was usually added?",
            answers: ["never added", "Less than 1 teaspoon", "1 to 2 teaspoons", "More than 2 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "75f",
            question: "How often was cream cheese (including low-fat) added to your breads, rolls or flatbreads?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
            skipTrigger: "Almost never or never",
            skipTo: "76",
        },
        {
            questionNumber: "75g",
            question: "Each time cream cheese was added to your breads, rolls or flatbreads, how much was usually added?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "76",
            question: "How often did you eat jam, jelly, or honey on bagels, muffins, bread, rolls, or crackers?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "76a",
            question: "Each time you ate jam, jelly, or honey, how much did you usually eat?",
            answers: ["Less than 2 teaspoons", "2 to 4 teaspoons", "More than 4 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "77",
            question: "How often did you eat peanut butter or other nut butter?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "77a",
            question: "Each time you ate peanut butter or other nut butter, how much did you usually eat?",
            answers: ["Less than 1 tablespoon", "1 to 2 tablespoons", "More than 2 tablespoons"],
            type: "radio",
        },
        {
            questionNumber: "78",
            question: "How often did you eat roast beef or steak IN SANDWICHES?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "78a",
            question: "Each time you ate roast beef or steak IN SANDWICHES, how much did you usually eat?",
            answers: ["Less than 1 slice or less than 2 ounces", "1 to 2 slices or 2 to 4 ounces", "More than 2 slices or more than 4 ounces"],
            type: "radio",
        },
        {
            questionNumber: "79",
            question:
                "How often did you eat turkey or chicken COLD CUTS (such as loaf, luncheon meat, turkey ham, turkey salami, or turkey pastrami)? (We will ask about other turkey or chicken later.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "79a",
            question: "Each time you ate turkey or chicken COLD CUTS, how much did you usually eat?",
            answers: ["Less than 1 slice", "1 to 3 slices", "More than 3 slices"],
            type: "radio",
        },
        {
            questionNumber: "80",
            question: "How often did you eat luncheon or deli-style ham? (We will ask about other ham later.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "80a",
            question: "Each time you ate luncheon or deli-style ham, how much did you usually eat?",
            answers: ["Less than 1 slice", "1 to 3 slices", "More than 3 slices"],
            type: "radio",
        },
        {
            questionNumber: "80b",
            question: "How often was the luncheon or deli-style ham you ate light, low-fat, or fat-free?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "81",
            question:
                "How often did you eat other cold cuts or luncheon meats (such as bologna, salami, corned beef, pastrami, or others, including low-fat)? (Please do not include ham, turkey, or chicken cold cuts.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "81a",
            question: "Each time you ate other cold cuts or luncheon meats, how much did you usually",
            answers: ["Less than 1 slice", "1 to 3 slices", "More than 3 slices"],
            type: "radio",
        },
        {
            questionNumber: "81b",
            question:
                "How often were the other cold cuts or luncheon meats you ate light, low-fat, or fat-free? (Please do not include ham, turkey, or chicken cold cuts.)",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "82",
            question: "How often did you eat canned tuna (including in salads, sandwiches, or casseroles)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "82a",
            question: "Each time you ate canned tuna, how much did you usually eat?",
            answers: ["Less than ¼ cup or less than 2 ounces", "¼ to ½ cup or 2 to 3 ounces", "More than ½ cup or more than 3 ounces"],
            type: "radio",
        },
        {
            questionNumber: "82b",
            question: "How often was the canned tuna you ate water-packed?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "82c",
            question: "How often was the canned tuna you ate prepared with mayonnaise or other",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "83",
            question: "How often did you eat GROUND chicken or turkey? (We will ask about other chicken and turkey later.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "83a",
            question: "Each time you ate GROUND chicken or turkey, how much did you usually eat?",
            answers: ["Less than 2 ounces or less than ½ cup", "2 to 4 ounces or ½ to 1 cup", "More than 4 ounces or more than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "84",
            question: "How often did you eat beef hamburgers or cheeseburgers from a FAST FOOD or OTHER RESTAURANT?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "84a",
            question: "Each time you ate beef hamburgers or cheeseburgers from a FAST FOOD or OTHER RESTAURANT, what size did you usually eat?",
            answers: [
                "Small hamburger (such as a regular Burger King or McDonald's Hamburger)",
                "Medium (such as McDonald’s or Burger King Double Burger or Cheeseburger)",
                "Large (such as Burger King Whopper or Double Whopper or a McDonald's Double Quarter Pounder)",
            ],
            type: "radio",
        },
        {
            questionNumber: "84b",
            question: "Each time you ate beef hamburgers or cheeseburgers from a FAST FOOD or OTHER RESTAURANT, how much did you usually eat?",
            answers: ["Less than 1 burger", "1 burger", "More than 1 burger"],
            type: "radio",
        },
        {
            questionNumber: "84c",
            question: "How often did you have cheeseburgers rather than hamburgers from a FAST FOOD or OTHER RESTAURANT?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "85",
            question: "How often did you eat beef hamburgers or cheeseburgers that were NOT from a FAST FOOD or OTHER RESTAURANT?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "85a",
            question:
                "Each time you ate beef hamburgers or cheeseburgers that were NOT from a FAST FOOD or OTHER RESTAURANT, how much did you usually eat?",
            answers: ["Less than 1 patty or less than 2 ounces", "1 patty or 2 to 4 ounces", "More than 1 patty or more than 4 ounces"],
            type: "radio",
        },
        {
            questionNumber: "85b",
            question: "How often were these beef hamburgers or cheeseburgers made with lean or extra lean ground beef?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "86",
            question: "How often did you eat ground beef in mixtures (such as meatballs, casseroles, chili, or meatloaf)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "86a",
            question: "Each time you ate ground beef in mixtures, how much did you usually eat?",
            answers: ["Less than 3 ounces or less than ½ cup", "3 to 8 ounces or ½ to 1 cup", "More than 8 ounces or more than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "87",
            question: "How often did you eat hot dogs, wieners or frankfurters? (Please do not include sausages or vegetarian hot dogs.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "87a",
            question: "Each time you ate hot dogs, wieners, or frankfurters, how many did you usually eat?",
            answers: ["Less than 1 hot dog", "1 to 2 hot dogs", "More than 2 hot dogs"],
            type: "radio",
        },
        {
            questionNumber: "87b",
            question: "How often were the hot dogs, wieners, or frankfurters you ate light or low-fat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "88",
            question: "How often did you eat beef mixtures (such as beef stew, beef curry, beef pot pie, beef and noodles, or beef and vegetables)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "88a",
            question: "Each time you ate beef mixtures, how much did you usually eat?",
            answers: ["Less than ½ cup", "Less than ½ cup", "More than 1½ cups"],
            type: "radio",
        },
        {
            questionNumber: "89",
            question: "How often did you eat roast beef or pot roast? (Please do not include roast beef or pot roast in sandwiches.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "89a",
            question: "Each time you ate roast beef or pot roast, how much did you usually eat?",
            answers: ["Less than 2 ounces", "2 to 6 ounces", "More than 6 ounces"],
            type: "radio",
        },
        {
            questionNumber: "90",
            question: "How often did you eat steak (beef)? (Please do not include steak in sandwiches)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "90a",
            question: "Each time you ate steak (beef), how much did you usually eat?",
            answers: ["Less than 3 ounces", "3 to 7 ounces", "More than 7 ounces"],
            type: "radio",
        },
        {
            questionNumber: "90b",
            question: "How often was the steak you ate lean steak?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "91",
            question: "How often did you eat pork or beef spareribs?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "91a",
            question: "Each time you ate pork or beef spareribs, how much did you usually eat?",
            answers: ["Less than 4 ribs", "4 to 12 ribs", "More than 12 ribs"],
            type: "radio",
        },
        {
            questionNumber: "92",
            question: "How often did you eat roast turkey, turkey cutlets, or turkey nuggets (including in sandwiches)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "92a",
            question:
                "Each time you ate roast turkey, turkey cutlets, or turkey nuggets, how much did you usually eat? (Please note: 4 to 8 turkey nuggets = 3 ounces.)",
            answers: ["Less than 2 ounces", "2 to 5 ounces", "More than 5 ounces"],
            type: "radio",
        },
        {
            questionNumber: "93",
            question: "How often did you eat chicken mixtures (such as salads, sandwiches, casseroles, chicken curries, stews, or other mixtures)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "93a",
            question: "Each time you ate chicken mixtures, how much did you usually eat?",
            answers: ["Less than ½ cup", "½ to 1½ cups", "More than 1½ cups"],
            type: "radio",
        },
        {
            questionNumber: "94",
            question:
                "How often did you eat baked, broiled, roasted, stewed, or fried chicken (including nuggets)? (Please do not include chicken in mixtures.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "94a",
            question: "Each time you ate baked, broiled, roasted, stewed, or fried chicken (including nuggets), how much did you usually eat?",
            answers: [
                "Less than 2 drumsticks or wings 1 breast or thigh, or less than 4 nuggets, less than ",
                "2 drumsticks or wings, 1 breast or thigh, or 4 to 8 nuggets",
                "More than 2 drumsticks or wings, more than 1 breast or thigh, or more than 8 nuggets",
            ],
            type: "radio",
        },
        {
            questionNumber: "94b",
            question: "How often was the chicken you ate fried chicken (including deep fried) or chicken nuggets?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "94c",
            question: "How often was the chicken you ate WHITE meat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "94d",
            question: "How often did you eat chicken WITH skin?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "95",
            question: "How often did you eat baked ham or ham steak?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "95a",
            question: "Each time you ate baked ham or ham steak, how much did you usually eat?",
            answers: ["Less than 1 ounce", "1 to 3 ounces", "More than 3 ounces"],
            type: "radio",
        },
        {
            questionNumber: "96",
            question:
                "How often did you eat pork (including chops, roasts, and in mixed dishes)? (Please do not include ham, ham steak, or sausage.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "96a",
            question: "Each time you ate pork, how much did you usually eat?",
            answers: ["Less than 2 ounces or less than 1 chop", "2 to 5 ounces or 1 chop", "More than 5 ounces or more than 1 chop"],
            type: "radio",
        },
        {
            questionNumber: "97",
            question: "How often did you eat gravy on meat, chicken, potatoes (NOT including poutine), rice, etc.?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "97a",
            question: "Each time you ate gravy on meat, chicken, potatoes, rice, etc., how much did you usually eat?",
            answers: ["Less than 1/8 cup", "1/8 to ½ cup", "More than ½ cup"],
            type: "radio",
        },
        {
            questionNumber: "98",
            question: "How often did you eat liver (all kinds) or liverwurst?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "98a",
            question: "Each time you ate liver or liverwurst, how much did you usually eat?",
            answers: ["Less than ½ ounce", "½ to 3 ounces", "More than 3 ounces"],
            type: "radio",
        },
        {
            questionNumber: "99",
            question: "How often did you eat bacon (including low-fat)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "99a",
            question: "Each time you ate bacon, how much did you usually eat?",
            answers: ["Fewer than 2 slices", "2 to 4 slices", "More than 4 slices"],
            type: "radio",
        },
        {
            questionNumber: "100",
            question: "How often did you eat bacon (including low-fat)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "100a",
            question: "Each time you ate bacon, how much did you usually eat?",
            answers: ["Fewer than 2 slices", "2 to 4 slices", "More than 4 slices"],
            type: "radio",
        },
        {
            questionNumber: "100b",
            question: "How often was the sausage you ate light, low-fat, or lean?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "101",
            question: "How often did you eat fried shellfish (such as crab, lobster, scallops, or shrimp)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "101a",
            question: "Each time you ate fried shellfish, how much did you usually eat?",
            answers: ["Less than 1 ounce", "1 to 3 ounces", "More than 3 ounces"],
            type: "radio",
        },
        {
            questionNumber: "102",
            question: "How often did you eat shellfish (such as crab, lobster, scallops, or shrimp) that was NOT FRIED?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "102a",
            question: "Each time you ate shellfish that was NOT FRIED, how much did you usually eat?",
            answers: ["Less than 1 ounce", "1 to 4 ounces", "More than 4 ounces"],
            type: "radio",
        },
        {
            questionNumber: "103",
            question: "How often did you eat shellfish (such as crab, lobster, scallops, or shrimp) that was NOT FRIED?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "103a",
            question: "Each time you ate dark or oily fish, how much did you usually eat?",
            answers: ["Less than 2 ounces", "2 to 6 ounces", "More than 6 ounces"],
            type: "radio",
        },
        {
            questionNumber: "104",
            question: "How often did you eat ready-to-eat battered fish or fish sticks, including in fast food sandwiches (not including shellfish)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "104a",
            question: "Each time you ate ready-to-eat battered fish or fish sticks, how much did you usually eat?",
            answers: ["Less than 2 ounces or less than 1 fillet", "2 to 6 ounces or 1 fillet", "More than 6 ounces or more than 1 fillet"],
            type: "radio",
        },
        {
            questionNumber: "105",
            question:
                "How often did you eat white or lean fish like cod, sole, perch, or pike (do not include ready-to-eat battered fish or fish sticks)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "105a",
            question: "Each time you ate white or lean fish, how much did you usually eat?",
            answers: ["Less than 2 ounces or less than 1 fillet", "2 to 5 ounces or 1 fillet", "More than 5 ounces or more than 1 fillet"],
            type: "radio",
        },
        {
            questionNumber: "106",
            question:
                "106.	How often was oil, butter, margarine, or other fat used to FRY, SAUTE, BASTE, OR MARINATE any meat, poultry, or fish you ate? (Please do not include deep frying.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "106a",
            question: "Which of the following fats were regularly used to prepare your meat, poultry, or fish?(Mark all that apply.)",
            answers: [
                "Margarine (including light)",
                "Butter (including light)",
                "Lard, fatback, or bacon fat",
                "Olive oil",
                "Olive oil",
                "Canola or rapeseed oil",
                "Oil spray, such as Pam or others",
                "Other kinds of oils",
                "None of the above",
            ],
            type: "checkbox",
        },
        {
            questionNumber: "107",
            question:
                "Thinking about the MEAT you ate, how often was it cooked by broiling, grilling, barbecuing, or pan-frying? (Do not include poultry or fish).",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "107a",
            question:
                "Each time you ate meat that was cooked by broiling, grilling, barbecuing, or pan-frying, what was the outside appearance of the meat?",
            answers: ["Light brown", "Medium brown", "Heavily browned or blackened"],
            type: "radio",
        },
        {
            questionNumber: "107b",
            question:
                "Each time you ate meat that was cooked by broiling, grilling, barbecuing, or pan-frying, what was the inside appearance of the meat?",
            answers: ["Red (rare)", "Pink (medium)", "Brown (well-done)"],
            type: "radio",
        },
        {
            questionNumber: "108",
            question: "How often did you eat tofu?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "108a",
            question: "How often did you eat tofu?",
            answers: ["Less than ¼ cup or less than 2 ounces", "¼ to ¾ cup or 2 to 6 ounces", "More than ¾ cup or more than 6 ounces"],
            type: "radio",
        },
        {
            questionNumber: "109",
            question: "How often did you eat soy burgers or soy meat-substitutes?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "109a",
            question: "Each time you ate soy burgers or soy meat-substitutes, how much did you usually eat?",
            answers: ["Less than ¼ cup or less than 2 ounces", "¼ to 1 cup or 2 to 8 ounces", "¼ to 1 cup or 2 to 8 ounces"],
            type: "radio",
        },
        {
            questionNumber: "110",
            question: "How often did you eat soup?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "110a",
            question: "Each time you ate soup, how much did you usually eat?",
            answers: ["Less than 1 cup", "1 to 2 cups", "More than 2 cups"],
            type: "radio",
        },
        {
            questionNumber: "110b",
            question: "How often were the soups you ate bean, pea, or lentil soups?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "110c",
            question: "How often were the soups you ate cream soups (including chowders)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "110d",
            question: "How often were the soups you ate tomato or vegetable soups (NOT cream soups)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "110e",
            question: "How often were the soups you ate broth soups (including chicken) with or without noodles or rice?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "111",
            question: "How often did you eat pizza?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "111a",
            question: "Each time you ate pizza, how much did you usually eat?",
            answers: [
                "Less than 1 slice or less than 1 mini pizza",
                "1 to 3 slices or 1 to 2 mini pizzas",
                "More than 3 slices or more than 2 mini pizzas",
            ],
            type: "radio",
        },
        {
            questionNumber: "111b",
            question: "How often did you eat pizza with pepperoni, sausage, or other meat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "112",
            question: "How often did you eat crackers?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "112a",
            question: "Each time you ate crackers, how many did you usually eat?",
            answers: ["Fewer than 4 crackers", "4 to 8 crackers", "More than 8 crackers"],
            type: "radio",
        },
        {
            questionNumber: "113",
            question: "How often did you eat corn bread or corn muffins?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "113a",
            question: "Each time you ate corn bread or corn muffins, how much did you usually eat?",
            answers: ["Less than 1 piece or muffin", "1 to 2 pieces or muffins", "More than 2 pieces or muffins"],
            type: "radio",
        },
        {
            questionNumber: "114",
            question: "How often did you eat baking powder biscuits, including scones or tea biscuits?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "114a",
            question: "Each time you ate baking powder biscuits including scones or tea biscuits, how many did you usually eat?",
            answers: ["Fewer than 1 biscuit", "1 to 2 biscuits", "More than 2 biscuits"],
            type: "radio",
        },
        {
            questionNumber: "115",
            question: "How often did you eat potato chips (including low-fat, baked, or low-salt)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "115a",
            question: "Each time you ate potato chips, how much did you usually eat?",
            answers: ["Fewer than 10 chips or less than 1 cup", "10 to 25 chips or 1 to 2 cups", "More than 25 chips or more than 2 cups"],
            type: "radio",
        },
        {
            questionNumber: "116",
            question: "How often did you eat corn chips or tortilla chips (including low-fat, baked, or low-salt)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "116a",
            question: "Each time you ate corn chips or tortilla chips, how much did you usually eat?",
            answers: ["Fewer than 10 chips or less than 1 cup", "10 to 25 chips or 1 to 2 cups", "More than 25 chips or more than 2 cups"],
            type: "radio",
        },
        {
            questionNumber: "117",
            question: "How often did you eat popcorn (including low-fat)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "117a",
            question: "Each time you ate popcorn, how much did you usually eat?",
            answers: ["Less than 1½ cups, popped", "1½ to 5 cups, popped", "More than 5 cups, popped"],
            type: "radio",
        },
        {
            questionNumber: "118",
            question: "How often did you eat pretzels?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "118a",
            question: "Each time you ate pretzels, how many did you usually eat?",
            answers: ["Fewer than 7 average twists", "Fewer than 7 average twists", "More than 20 average twists"],
            type: "radio",
        },
        {
            questionNumber: "119",
            question: "How often did you eat peanuts, walnuts, almonds, or other nuts?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "119a",
            question: "Each time you ate peanuts, walnuts, almonds, or other nuts, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to ½ cup", "More than ½ cup"],
            type: "radio",
        },
        {
            questionNumber: "119b",
            question: "How often were the nuts you ate peanuts?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "120",
            question: "How often did you eat flaxseeds?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "120a",
            question: "How often did you eat flaxseeds?",
            answers: ["Less than 1 tablespoon", "1 to 2 tablespoons", "More than 2 tablespoons"],
            type: "radio",
        },
        {
            questionNumber: "121",
            question: "How often did you eat other seeds, like sunflower or pumpkin seeds?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "121a",
            question: "Each time you ate other seeds, how much did you usually eat?",
            answers: ["Less than 2 tablespoons", "2 tablespoons to ½ cup", "More than ½ cup"],
            type: "radio",
        },
        {
            questionNumber: "122",
            question: "How often did you eat energy or high-protein bars, (such as Power Bars, Vector, Clif, Luna, Isoflex or others)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "122a",
            question: "Each time you ate energy or high-protein bars, how much did you usually eat?",
            answers: ["Less than 1 bar", "1 bar", "More than 1 bar"],
            type: "radio",
        },
        {
            questionNumber: "123",
            question: "How often did you eat yogurt (NOT including frozen yogurt)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "123a",
            question: "Each time you ate yogurt, how much did you usually eat?",
            answers: ["Less than ½ cup or less than 1 container", "½ to ¾ cup or 1 container", "More than ¾ cup or more than 1 container"],
            type: "radio",
        },
        {
            questionNumber: "123b",
            question: "How often was the yogurt you ate low-fat or fat-free?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "124",
            question: "How often did you eat cottage cheese (including low-fat)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "124a",
            question: "Each time you ate cottage cheese, how much did you usually eat?",
            answers: ["Less than ¼ cup", "¼ to ¾ cup", "More than ¾ cup"],
            type: "radio",
        },
        {
            questionNumber: "125",
            question:
                "How often did you eat cheese (including low-fat; including on cheeseburgers or in sandwiches or subs, NOT including cream cheese)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "125a",
            question: "Each time you ate cheese, how much did you usually eat?",
            answers: ["Less than ½ ounce or less than 1 slice", "½ to 2 ounces or 1 to 2 slices", "More than 2 ounces or more than 2 slices"],
            type: "radio",
        },
        {
            questionNumber: "125b",
            question: "How often was the cheese you ate low-fat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "126",
            question: "How often did you eat frozen yogurt, sorbet, or ices (including low-fat or fat-free)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "126a",
            question: "Each time you ate frozen yogurt, sorbet, or ices, how much did you usually eat?",
            answers: ["Less than ½ cup or less than 1 scoop", "½ to 1 cup or 1 to 2 scoops", "More than 1 cup or more than 2 scoops"],
            type: "radio",
        },
        {
            questionNumber: "127",
            question: "How often did you eat ice cream or ice cream bars (including low-fat or fat-free)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "127a",
            question: "Each time you ate ice cream or ice cream bars, how much did you usually eat?",
            answers: ["Less than ½ cup or less than 1 scoop", "½ to 1 cup or 1 to 2 scoops", "More than 1 cup or more than 2 scoops"],
            type: "radio",
        },
        {
            questionNumber: "127b",
            question: "Each time you ate ice cream or ice cream bars, how much did you usually eat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "128",
            question: "How often did you eat cake (including low-fat or fat-free)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "128a",
            question: "Each time you ate cake, how much did you usually eat?",
            answers: ["Less than 1 medium piece", "1 medium piece", "More than 1 medium piece"],
            type: "radio",
        },
        {
            questionNumber: "129",
            question: "How often did you eat cookies or brownies (including low-fat or fat-free)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "129a",
            question: "Each time you ate cookies or brownies, how much did you usually eat?",
            answers: ["Less than 2 cookies or 1 small brownie", "2 to 4 cookies or 1 medium brownie", "More than 4 cookies or 1 large brownie"],
            type: "radio",
        },
        {
            questionNumber: "130",
            question: "How often did you eat doughnuts, sweet rolls, Danish, or Pop-Tarts?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "130a",
            question: "Each time you ate doughnuts, sweet rolls, Danish, or Pop-Tarts, how much did you usually eat?",
            answers: ["Less than 1 piece", "1 to 2 pieces", "More than 2 pieces"],
            type: "radio",
        },
        {
            questionNumber: "131",
            question:
                "How often did you eat sweet muffins or dessert breads (such as banana bread, blueberry muffins, or lemon loaf, including low-fat or fat-free)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "131a",
            question: "Each time you ate sweet muffins or dessert breads, how much did you usually eat?",
            answers: ["Less than 1 medium piece", "1 medium piece", "More than 1 medium piece"],
            type: "radio",
        },
        {
            questionNumber: "132",
            question: "How often did you eat fruit crisp, cobbler, or strudel?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "132a",
            question: "Each time you ate fruit crisp, cobbler, or strudel, how much did you usually eat?",
            answers: ["Less than ½ cup", "½ to 1 cup", "More than 1 cup"],
            type: "radio",
        },
        {
            questionNumber: "133",
            question: "How often did you eat pie?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "133a",
            question: "Each time you ate pie, how much did you usually eat?",
            answers: ["Less than 1/8 of a pie", "About 1/8 of a pie", "More than 1/8 of a pie"],
            type: "radio",
        },
        {
            questionNumber: "133b",
            question: "How often were the pies you ate fruit pie (such as apple, blueberry, others)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "133c",
            question: "Each time you ate pie, how much did you usually eat?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "133d",
            question: "How often were the pies you ate pumpkin or sweet potato pie?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "133e",
            question: "How often were the pies you ate pecan pie?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "134",
            question: "How often did you eat chocolate candy?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "134a",
            question: "How often did you eat chocolate candy?",
            answers: [
                "Less than 1 average bar or less than 1 ounce",
                "1 average bar or 1 to 2 ounces",
                "More than 1 average bar or more than 2 ounces",
            ],
            type: "radio",
        },
        {
            questionNumber: "135",
            question: "How often did you eat other candy?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "135a",
            question: "Each time you ate other candy, how much did you usually eat?",
            answers: ["Fewer than 2 pieces", "2 to 6 pieces", "More than 6 pieces"],
            type: "radio",
        },
        {
            questionNumber: "136",
            question:
                "How often did you eat eggs, egg whites, or egg substitutes (NOT counting eggs in baked goods or desserts)? (Please include eggs in salads, quiche, soufflés, and sandwiches.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "136a",
            question: "Each time you ate eggs, how many did you usually eat?",
            answers: ["Fewer than 2 pieces", "2 to 6 pieces", "More than 6 pieces"],
            type: "radio",
        },
        {
            questionNumber: "136b",
            question: "How often were the eggs you ate egg substitutes or egg whites only?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "136c",
            question: "How often were the eggs you ate regular whole eggs?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "136d",
            question: "How often were the eggs you ate cooked in oil, butter, or margarine?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "136e",
            question: "How often were the eggs you ate part of egg salad?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "137",
            question:
                "How many cups of coffee, caffeinated or decaffeinated, did you drink? (Please do not include coffee drinks such as Latte, Mocha, Cappuccino, or Frappuccino.)",
            answers: [
                "NONE",
                "Less than 1 cup in past month",
                "1-3 cups in past month",
                "1 cup per week",
                "2-4 cups per week",
                "5-6 cups per week",
                "1 cup per day",
                "2 or more times per day",
                "2-3 cups per day",
                "4-5 cups per day",
                "6 or more cups per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "137a",
            question: "How often was the coffee you drank decaffeinated?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "138",
            question:
                "How often did you drink coffee drinks, such as Latte, Mocha, Cappuccino, or Frappuccino (including caffeinated or decaffeinated, hot or cold)?",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "138a",
            question: "Each time you drank coffee drinks, how much did you usually drink?",
            answers: ["Less than 8 ounces (250 ml)", "8 to 16 ounces (250 to 500 ml)", "More than 16 ounces (more than 500 ml)"],
            type: "radio",
        },
        {
            questionNumber: "138b",
            question: "Each time you drank coffee drinks, how much did you usually drink?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "139",
            question: "How many glasses, cans, or bottles of COLD or ICED tea, caffeinated or decaffeinated, did you drink?",
            answers: [
                "NONE",
                "Less than 1 glass, can or bottle in past month",
                "1-3 glasses, cans or bottles in past month",
                "1 glass, can or bottle per week",
                "2-4 glasses, cans or bottles per week",
                "5-6 glasses, cans or bottles per week",
                "1 glass, can or bottle per day",
                "2 or more times per day",
                "2-3 glasses, cans or bottles per day",
                "4-5 glasses, cans or bottles per day",
                "6 or more glasses, cans or bottles per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "139a",
            question: "How many glasses, cans, or bottles of COLD or ICED tea, caffeinated or decaffeinated, did you drink?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "139b",
            question:
                "How often was the cold or iced tea you drank presweetened with sugar or artificial sweeteners (such as Splenda, Equal, Sweet'N Low, or others)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "139c",
            question: "What kind of sweetener was added to your presweetened cold or iced tea most of the time?",
            answers: ["Sugar or honey", "Artificial sweeteners (such as Splenda, Equal, Sweet'N Low, or others)"],
            type: "radio",
        },
        {
            questionNumber: "140",
            question: "How many cups of HOT tea, caffeinated or decaffeinated (including herbal), did you drink?",
            answers: [
                "NONE",
                "Less than 1 cup in past month",
                "1-3 cups in past month",
                "1 cup per week",
                "2-4 cups per week",
                "5-6 cups per week",
                "1 cup per day",
                "2 or more times per day",
                "2-3 cups per day",
                "4-5 cups per day",
                "6 or more cups per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "140a",
            question: "How often was the hot tea you drank herbal?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "140b",
            question: "How often was the hot tea you drank green tea?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "140c",
            question: "How often was the hot black tea and/or the green tea you drank decaffeinated?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "141",
            question: "Over the past month, did you add sugar, honey or other sweeteners to your tea or coffee (hot or iced)?",
            answers: ["NO", "YES"],
            type: "radio",
        },
        {
            questionNumber: "141a",
            question: "How often did you add sugar or honey to your coffee or tea (hot or iced)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "141b",
            question: "Each time sugar or honey was added to your coffee or tea, how much was usually added?",
            answers: ["Less than 1 teaspoon", "1 to 3 teaspoons", "More than 3 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "141c",
            question: "How often did you add artificial sweetener (such as Splenda, Equal, Sweet'N Low, or others) to your coffee or tea?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
            skipTrigger: "Almost never or never",
            skipTo: "142",
        },
        {
            questionNumber: "141d",
            question: "What kind of artificial sweetener did you usually use?",
            answers: [
                "Equal, NutraSweet, or aspartame",
                "Sweet’N Low or cyclamate",
                "Splenda or sucralose",
                "Hermesetas or saccharin",
                "Hermesetas or saccharin",
            ],
            type: "radio",
        },
        {
            questionNumber: "141e",
            question: "Each time artificial sweetener was added to your coffee or tea, how much was usually added?",
            answers: ["Less than 1 packet or less than 1 teaspoon", "1 packet or 1 teaspoon", "More than 1 packet or more than 1 teaspoon"],
            type: "radio",
        },
        {
            questionNumber: "142",
            question: "Over the past month, did you add whiteners (such as cream, milk, or non-dairy creamer) to your tea or coffee?",
            answers: ["NO", "YES"],
            type: "radio",
        },
        {
            questionNumber: "142a",
            question: "How often was non-dairy creamer added to your coffee or tea?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
            skipTrigger: "Almost never or never",
            skipTo: "142d",
        },
        {
            questionNumber: "142b",
            question: "Each time non-dairy creamer was added to your coffee or tea, how much was usually used?",
            answers: ["Less than 1 teaspoon", "1 to 3 teaspoons", "More than 3 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "142c",
            question: "What kind of non-dairy creamer did you usually use?",
            answers: ["Regular powdered", "Low-fat or fat-free powdered", "Regular liquid", "Low-fat or fat-free liquid"],
            type: "radio",
        },
        {
            questionNumber: "142d",
            question: "How often was cream or half and half added to your coffee or tea?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
            skipTrigger: "Almost never or never",
            skipTo: "142f",
        },
        {
            questionNumber: "142e",
            question: "Each time cream or half and half was added to your coffee or tea, how much was usually added?",
            answers: ["Less than 1 tablespoon", "1 to 2 tablespoons", "More than 2 tablespoons"],
            type: "radio",
        },
        {
            questionNumber: "142f",
            question: "How often was milk added to your coffee or tea?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "142g",
            question: "How often was milk added to your coffee or tea?",
            answers: ["Less than 1 tablespoon", "1 to 3 tablespoons", "More than 3 tablespoons"],
            type: "radio",
        },
        {
            questionNumber: "142h",
            question: "What kind of milk was usually added to your coffee or tea?",
            answers: [
                "Whole milk",
                "2% milk",
                "1% milk",
                "Skim, nonfat, or 0.5% fat milk",
                "Evaporated or condensed (canned) milk",
                "Soy milk",
                "Rice milk",
                "Almond milk",
                "Other",
            ],
            type: "radio",
        },
        {
            questionNumber: "143",
            question:
                "How often was sugar or honey added to foods you ate (such as on cereal, fruit, or yogurt)? (Please do not include sugar in coffee, tea, other beverages, or baked goods.)",
            answers: [
                "NEVER",
                "1 time in past month",
                "2-3 times in past month",
                "1-2 times per week",
                "3-4 times per week",
                "5-6 times per week",
                "1 time per day",
                "2 or more times per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "143a",
            question: "Each time sugar or honey was added to foods you ate, how much was usually added?",
            answers: ["Less than 1 teaspoon", "1 to 2 teaspoons", "More than 2 teaspoons"],
            type: "radio",
        },
        {
            questionNumber: "144",
            question: "Over the past month, did you eat margarine?",
            answers: ["NO", "YES"],
            type: "radio",
        },
        {
            questionNumber: "144a",
            question: "How often was the margarine you ate light or low-fat (stick or tub)?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "145",
            question: "Over the past month, did you eat butter?",
            answers: ["NO", "YES"],
            type: "radio",
        },
        {
            questionNumber: "145a",
            question: "Over the past month, did you eat butter?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "146",
            question: "Over the past month, did you eat butter?",
            answers: ["NO", "YES"],
            type: "radio",
        },
        {
            questionNumber: "146a",
            question: "How often was the mayonnaise you ate light, low-fat or fat-free?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "147",
            question: "Over the past month, did you eat butter?",
            answers: ["NO", "YES"],
            type: "radio",
        },
        {
            questionNumber: "147a",
            question: "How often was the mayonnaise you ate light, low-fat or fat-free?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "148",
            question: "Over the past month, did you eat cream cheese?",
            answers: ["NO", "YES"],
            type: "radio",
        },
        {
            questionNumber: "148a",
            question: "How often was the cream cheese you ate light, low-fat, or fat-free",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "149",
            question: "Did you eat salad dressing?",
            answers: ["NO", "YES"],
            type: "radio",
        },
        {
            questionNumber: "149a",
            question: "How often was the salad dressing you ate light, low-fat, or fat-free?",
            answers: ["Almost never or never", "About ¼ of the time", "About ½ of the time", "About ¾ of the time", "Almost always or always"],
            type: "radio",
        },
        {
            questionNumber: "150",
            question: "Over the past month, how many servings of vegetables (not including salad or potatoes) did you eat per week or per day?",
            answers: [
                "Less than 1 per week",
                "1-2 per week",
                "3-4 per week",
                "5-6 per week",
                "1 per day",
                "2 per day",
                "3 per day",
                "4 per day",
                "5 or more per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "151",
            question: "Over the past month, how many servings of fruit (not including juices) did you eat per week or per day?",
            answers: [
                "Less than 1 per week",
                "1-2 per week",
                "3-4 per week",
                "5-6 per week",
                "1 per day",
                "2 per day",
                "3 per day",
                "4 per day",
                "5 or more per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "152",
            question: "Over the past month, how many servings of fruit (not including juices) did you eat per week or per day?",
            answers: [
                "Avocado, guacamole",
                "Beef jerky",
                "Cheesecake",
                "Chocolate, fudge, or butterscotch toppings or syrups",
                "Chow mein noodles",
                "Croissants",
                "Dark chocolate",
                "Dried apricots",
                "Dried curcumin, turmeric",
                "Dried oregano, rosemary, thyme",
                "Egg rolls",
                "Fresh basil, cilantro, or parsley",
                "Game Meat (such as bison, wild goose, venison)",
                "Granola bars",
                "Hot peppers",
                "Jell-O, gelatin",
                "Mangoes",
                "Olives",
                "Pickles or pickled vegetables or fruit",
                "Plantains",
                "Pork neck bones, hock, head, feet",
                "Pudding or custard",
                "Sushi",
                "Veal, lamb",
                "Whipped cream, regular",
                "Whipped cream, substitute",
                "NONE",
            ],
            type: "checkbox",
        },
        {
            questionNumber: "153",
            question: "For ALL of the past month, have you followed any type of vegetarian diet?",
            answers: ["NO", "YES"],
            type: "radio",
        },
        {
            questionNumber: "153a",
            question: "Which of the following foods did you TOTALLY EXCLUDE from your diet? (Mark all that apply.)",
            answers: [
                "Meat (beef, pork, lamb, etc.)",
                "Poultry (chicken, turkey, duck, etc.)",
                "Fish and shellfish",
                "Eggs",
                "Dairy products (milk, cheese, etc.)",
            ],
            type: "checkbox",
        },
        {
            questionNumber: "154",
            question:
                "Over the past month, did you take any multivitamins, such as One-a-Day-, Centrum-, or Prenatal-type multivitamins (as pills, liquids, or packets)?",
            answers: ["NO", "YES"],
            type: "radio",
        },
        {
            questionNumber: "155",
            question: "How often did you take One-a-Day-, Centrum-, or Prenatal-type multivitamins?",
            answers: ["1-3 days in past month", "1-3 days per week", "4-6 days per week", "Every day"],
            type: "radio",
        },
        {
            questionNumber: "155a",
            question: "Did your multivitamin usually contain minerals (such as iron, zinc, etc.)?",
            answers: ["NO", "YES", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "155b",
            question: "Was your multivitamin usually a Prenatal-type?",
            answers: ["NO", "YES", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "155c",
            question: "Over the past month, did you take any vitamins, minerals, or herbal supplements other than your multivitamin?",
            answers: ["NO", "YES"],
            type: "radio",
            skipTrigger: "NO",
            skipTo: "165",
        },
        {
            questionNumber: "156",
            question: "How often did you take Antacids that contain Calcium such as Tums or Rolaids?",
            answers: ["NEVER", "1-3 days in past month", "1-3 days per week", "4-6 days per week", "Every day"],
            type: "radio",
        },
        {
            questionNumber: "156a",
            question:
                "When you took Antacids that contain Calcium such as Tums or Rolaids, about how many tablets or lozenges did you take in one day?",
            answers: ["Less than 1", "1", "2", "3", "4 or more", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "156b",
            question: "Was your Antacid usually extra strength?",
            answers: ["NO", "YES", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "157",
            question:
                "How often did you take Calcium (with or without Vitamin D) (NOT as part of a multivitamin in Question 155 or antacid in Question 156)?",
            answers: ["NEVER", "1-3 days in past month", "1-3 days per week", "4-6 days per week", "Every day"],
            type: "radio",
        },
        {
            questionNumber: "157a",
            question:
                "When you took Calcium, about how much elemental calcium did you take in one day? (If possible, please check the label for elemental calcium.)",
            answers: ["Less than 500 mg", "500-599 mg", "600-999 mg", "1,000 mg or more", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "157b",
            question: "Did your Calcium usually contain Vitamin D?",
            answers: ["NO", "YES", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "157c",
            question: "Did your Calcium usually contain Magnesium?",
            answers: ["NO", "YES", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "157d",
            question: "Did your Calcium usually contain Zinc?",
            answers: ["NO", "YES", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "158",
            question:
                "How often did you take Vitamin D on its own or as part of a calcium supplement (NOT as part of a multivitamin in Question 155)?",
            answers: ["NEVER", "1-3 days in past month", "1-3 days per week", "4-6 days per week", "Every day"],
            type: "radio",
        },
        {
            questionNumber: "158a",
            question: "When you took Vitamin D, about how much did you take in one day?",
            answers: ["Less than 400 IU", "400-799 IU", "800-999 IU", "1,000 IU or more", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "159",
            question: "How often did you take Iron (NOT as part of a multivitamin in Question 155)?",
            answers: ["NEVER", "1-3 days in past month", "1-3 days per week", "4-6 days per week", "Every day"],
            type: "radio",
        },
        {
            questionNumber: "160",
            question: "How often did you take Folic Acid (NOT as part of a multivitamin in Question 155)?",
            answers: ["NEVER", "1-3 days in past month", "1-3 days per week", "4-6 days per week", "Every day"],
            type: "radio",
        },
        {
            questionNumber: "160a",
            question: "When you took Folic Acid, about how much did you take in one day?",
            answers: ["Less than 0.4 mg ", "0.4-0.9 mg", "1.0 mg", "More than 1.0 mg", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "161",
            question: "How often did you take Vitamin C (NOT as part of a multivitamin in Question 155)?",
            answers: ["NEVER", "1-3 days in past month", "1-3 days per week", "4-6 days per week", "Every day"],
            type: "radio",
        },
        {
            questionNumber: "161a",
            question: "When you took Vitamin C, about how much did you take in one day?",
            answers: ["Less than 500 mg", "500-999 mg", "1,000-1,499 mg", "1,500-1,999 mg", "2,000 mg or more", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "162",
            question: "How often did you take Vitamin E (NOT as part of a multivitamin in Question 155)?",
            answers: ["NEVER", "1-3 days in past month", "1-3 days per week", "4-6 days per week", "Every day"],
            type: "radio",
        },
        {
            questionNumber: "162a",
            question: "When you took Vitamin E, about how much did you take in one day?",
            answers: ["Less than 400 IU", "400-799 IU", "800-999 IU", "1,000 IU or more", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "163",
            question:
                "Please mark any of the following single supplements you took more than once per week (NOT as part of a multivitamin in Question 155):",
            answers: ["B-6", "B-complex", "B-12", "Beta-carotene", "Magnesium", "Occu-vite/Eye health", "Potassium", "Selenium", "Vitamin A", "Zinc"],
            type: "checkbox",
        },
        {
            questionNumber: "164",
            question: "Please mark any of the following herbal, botanical, or other supplements you took more than once per week.",
            answers: [
                "Chondroitin",
                "Coenzyme Q-10",
                "Echinacea",
                "Energy supplements",
                "Fish oil/Omega-3's",
                "Flaxseed oil",
                "Garlic",
                "Ginger",
                "Ginkgo biloba",
                "Ginseng",
                "Glucosamine",
                "Peppermint",
                "Probiotics",
                "Red clover",
                "Saw palmetto",
                "Soy supplement",
                "Sports supplements",
                "St. John's wort",
                "Other",
            ],
            type: "checkbox",
        },
        {
            questionNumber: "165",
            question: "Is there anything else you ate in the past month? Please write name of food, frequency and amount (optional).",
            answers: [],
            type: "textfield",
            optional: true,
        },
    ],
};
export const eatingPattern = {
    title: "Eating Pattern Self Assessment",
    about: "",
    instructions: "",
    questions: [
        {
            questionNumber: "1",
            question: "How many servings of vegetables did you usually eat? (1 serving = ½ cup or ½ fist)",
            answers: ["< 1 per day", "1 per day", "2 per day", "3 per day", "4 per day", "5 per day", "6 or more per day"],
            weeklyValues: [6, 7, 14, 21, 28, 35, 999],
            type: "radio",
        },
        {
            questionNumber: "1a",
            question:
                "Of the vegetables that you ate, how many servings of raw leafy greens did you usually eat? (e.g. lettuce, spinach, cabbage, kale, mixed greens) (1 serving = 1 cup or 1 fist)",
            answers: ["< 1 per week", "1 per week", "2-3 per week", "4-6 per week", "1 per day", "2 or more per day"],
            weeklyValues: [0, 1, 2, 4, 7, 14],
            type: "radio",
        },
        {
            questionNumber: "1b",
            question:
                "Of the vegetables that you ate, how many servings of cruciferous vegetables did you eat? (e.g. broccoli, cauliflower, Brussel sprouts, radish, kale, cabbage, turnip, bok choy) ) (1 serving = ½ cup or ½ fist)",
            answers: ["< 1 per week", "1 per week", "2 per week", "3-4 per week", "5-6 per week", "1 or more per day"],
            weeklyValues: [0, 1, 2, 3, 5, 7],
            type: "radio",
        },
        {
            questionNumber: "2",
            question: "How many servings of fruit did you usually consume? (fresh, frozen, dried, 100% juice) (1 serving = 1 medium fruit or ½ cup or ½ fist)",
            answers: ["< 1 per day", "1 per day", "2 per day", "3 per day", "4 per day", "5 per day", "6 or more per day"],
            weeklyValues: [6, 7, 14, 21, 28, 35, 999],
            type: "radio",
        },
        {
            questionNumber: "2a",
            question: "Of the fruit that you ate, how many servings of berries did you eat? (fresh or frozen) (1 serving = ½ cup or ½ fist)",
            answers: ["< 1 per week", "1 per week", "2 per week", "3-4 per week", "5-6 per week", "1 or more per day"],
            weeklyValues: [0.5, 1, 2, 3, 5, 7],
            type: "radio",
        },
        {
            questionNumber: "3",
            question: "How many servings of unsalted nuts or all-natural nut butters (including peanuts) did you eat? (1 serving = ¼ cup nuts or 1 cupped hand or 2 tbsp nut butter)",
            answers: ["< 1 per week", "1 per week", "2-3 per week", "4-6 per week", "1 per day", "2 or more per day"],
            weeklyValues: [0.5, 1, 2, 4, 7, 14],
            type: "radio",
        },
        {
            questionNumber: "3a",
            question: "Of the nuts that you ate, how many servings of walnuts did you eat? (1 serving = ¼ cup nuts or 1 cupped hand or 2 tbsp nut butter)",
            answers: ["< 1 per week", "1 per week", "2-3 per week", "4-6 per week", "1 per day", "2 or more per day"],
            weeklyValues: [0.5, 1, 2, 4, 7, 14],
            type: "radio",
        },
        {
            questionNumber: "4",
            question: "How many servings of fish or seafood did you eat?  Don’t include battered or fried products. (1 serving = 3-4 oz or 1 palm)",
            answers: ["< 1 per week", "1 per week", "2 per week", "3-4 per week", "5-6 per week", "1 or more per day"],
            weeklyValues: [4, 5, 9, 18, 26, 999],
            type: "radio",
        },
        {
            questionNumber: "4a",
            question:
                "Of the fish and seafood that you ate, how many servings of fatty fish did you eat?  (e.g. salmon, tuna, trout, sardines, herring, mackerel, anchovies) ) (1 serving = 3-4 oz or 1 palm)",
            answers: ["< 1 per month", "1 per month", "2-3 per month", "1 per week", "2-3 per week", "4-6 per week", "1 or more per day"],
            weeklyValues: [0, 1, 2, 5, 8, 18, 28],
            type: "radio",
        },
        {
            questionNumber: "5",
            question:
                "How many servings of canned beans or cooked dried beans did you eat?  (e.g. chickpeas, lentils, kidney beans) ) (1 serving = ½ cup or ½ fist)",
            answers: ["< 1 per week", "1 per week", "2-3 per week", "4-6 per week", "1 per day", "2 or more per day"],
            type: "radio",
        },
        {
            questionNumber: "6",
            question:
                "Did you eat whole grain products instead of white breads or refined grains?  (e.g. 100% whole grain breads, brown pasta, brown rice, bulgar, barley, oatmeal)",
            answers: ["YES", "NO", "On most days"],
            type: "radio",
        },
        {
            questionNumber: "7",
            question:
                "Did you eat low-fat milk, yogurt, and cheese instead of higher fat dairy products?  (e.g. 1% or skim milk, 0-2% yogurt, < 23% block cheese, feta cheese)",
            answers: ["YES", "NO", "On most days"],
            type: "radio",
        },
        {
            questionNumber: "8",
            question:
                "Did you use extra virgin olive oil as your main culinary oil?  (Used for cooking, in salad dressing, added to bread or other foods.)",
            answers: ["YES", "NO"],
            type: "radio",
        },
        {
            questionNumber: "8a",
            question:
                "Over the past month, how many tablespoons of extra virgin olive oil did you usually consume (including oil used in cooking, salad dressings, added to bread or any other food)?",
            answers: [
                "Never",
                "Less than once per month",
                "1-3 tbsp. per month",
                "1 tbsp. per week",
                "2-4 tbsp. per week",
                "5-6 tbsp. per week",
                "1 tbsp. per day",
                "2-3 tbsp. per day",
                "4-5 tbsp. per day",
                "6+ tbsp. per day",
            ],
            type: "radio",
        },
        {
            questionNumber: "9",
            question: "How many servings of meat and poultry (excluding fish) did you eat? (1 serving = 3-4 oz or 1 palm)",
            answers: ["< 1 per week", "1-3 per week", "4-6 per week", "1 per day", "2 per day", "3 or more per day"],
            weeklyValues: [0.5, 3, 6, 7, 14, 999],
            type: "radio",
        },
        {
            questionNumber: "9a",
            question:
                "Of the meat that you ate, how many servings of red or processed meat did you eat?  (e.g. beef, hamburger, pork, liver, lamb, veal, duck, ham, cold cuts, pepperoni sticks, jerky, pâté) (1 serving = 3-4 oz or 1 palm)",
            answers: ["< 1 per week", "1 per week", "2-3 per week", "4-6 per week", "1 per day", "2 or more per day"],
            weeklyValues: [0.5, 1, 2, 4, 7, 14],
            type: "radio",
        },
        {
            questionNumber: "10",
            question:
                "In total, how many servings of butter, cream, hard-stick margarine, or high-fat dairy spreads did you eat?  (Includes full-fat sour cream and cream cheese) (1 serving = 1 tsp butter or 1 tbsp cream)",
            answers: ["< 1 per week", "1 per week", "2-3 per week", "4-6 per week", "1 per day", "2 or more per day"],
            type: "radio",
        },
        {
            questionNumber: "11",
            question:
                "How many servings of white bread did you eat?  (e.g. white bread, rolls, bagels, pita, or tortilla) (1 serving = 1 slice bread or ½ bagel)",
            answers: ["< 1 per week", "1 per week", "2-3 per week", "4-6 per week", "1 per day", "2 or more per day"],
            type: "radio",
        },
        {
            questionNumber: "12",
            question: "Have you eaten any of the following foods over the past month? (Serving sizes according to the Nutrition Facts table on the food label)",
            answers: [
                "Salty snacks and fried foods (e.g. potato chips, pretzels, fries, egg rolls)",
                "Sugary beverages (e.g. pop, sweetened fruit juice, lemonade, sports drinks)",
                "Candy, chocolates, bon bons",
                "Pre-packaged meals or sides (e.g. frozen dinners or appetizers, canned soup)",
                "Commercial (not homemade) baked goods",
                "Commercial (not homemade) dairy desserts",
                "None of the above",
            ],
            type: "checkbox",
        },
        {
            questionNumber: "12a",
            question:
                "Think about the foods where you answered “YES”. In total, how many servings of these foods did you usually eat?",
            answers: ["< 1 per week", "1-2 per week", "3 per week", "4 per week", "5-6 per week", "1 or more per day"],
            type: "radio",
        },
    ],
};
