import { RawDraftContentBlock, RawDraftContentState } from "draft-js";
import { inlineStyleRenderer } from "./renderers";
import { InlineStyle } from "./interfaces";

export const applyInlineStyle = (text: string, inlineStyles: Array<InlineStyle>) =>
    inlineStyles.reduce((acc: string, { offset, length, style, data }: InlineStyle) => {
        const toFormat = text.slice(offset, offset + length);
        const formatted = inlineStyleRenderer[style](data ?? toFormat);

        return acc.replace(toFormat, formatted);
    }, text);

export const tokenizeBlock = (block: RawDraftContentBlock, entityMap?: RawDraftContentState["entityMap"]) => {
    let { text } = block;
    // eslint-disable-next-line
    let { type, inlineStyleRanges, entityRanges } = block;

    let toEvaluate = [];

    if (entityRanges.length && entityMap)
        toEvaluate = [
            ...inlineStyleRanges,
            ...entityRanges.map(({ key, ...rest }) => ({ ...rest, style: entityMap[key].type, data: entityMap[key].data })),
        ];
    else toEvaluate = inlineStyleRanges;

    if (toEvaluate.length) text = applyInlineStyle(text, toEvaluate);

    return { text, type };
};
