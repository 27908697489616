import React from "react";

// Mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
    open: boolean;
    // eslint-disable-next-line
    setOpen(value: boolean): void;
}

const RewardsDialog: React.FC<Props> = ({ open, setOpen }) => {
    return (
        <Dialog
            className="rewards"
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Rewards & Badges</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sit amet pellentesque elit. Mauris at dui id nibh imperdiet
                    venenatis vel eu ex. Nulla eu ex blandit, vehicula magna sed, ornare mauris. In eleifend, lacus nec blandit scelerisque, neque
                    lacus laoreet ante, eget lobortis leo felis et ligula. Proin eget orci semper, vestibulum lectus et, viverra turpis. Aliquam
                    interdum tristique finibus. Morbi faucibus eleifend ante, eget laoreet lectus pharetra tristique. Sed luctus viverra sapien id
                    elementum. Nam rutrum nunc eget purus euismod, sed gravida magna tristique. Donec pulvinar neque vel dictum imperdiet. Proin at
                    auctor enim. Suspendisse dictum, eros ut tincidunt tristique, risus quam fringilla nulla, non pellentesque risus urna in urna. Sed
                    vitae magna in turpis varius molestie nec a risus. Praesent suscipit felis posuere lacus sodales pulvinar. Nulla sollicitudin
                    libero vel neque porta egestas. Donec et vulputate sem. Nunc accumsan ac metus id gravida. Mauris condimentum dui et.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button fullWidth className="cta__submit" onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RewardsDialog;
