import { ContentBlock, EditorState } from "draft-js";
import ImageRenderer from "./Image";
import ColorBoxRenderer from "./ColorBox";

/**
 * Since passing props to the component that renders a given block is a bit of
 * a hassle, a block rendered must have the signature of (ourProps) => () => jsx.
 * This allows us to pass props from here (look a image in rendered, i.e. imageRendered).
 *
 * imageRendered takes some props but returns a react component, i.e. an object and not
 * the rendered component. Component(object) != <Component /> (rendered component)
 */
const renderer = (editorState: EditorState) => (block: ContentBlock) => {
    const blockType = block.getType();

    if (blockType === "atomic") {
        const contentState = editorState.getCurrentContent();
        const entity = contentState.getEntity(block.getEntityAt(0));

        if (entity && entity.getType() === "IMAGE") {
            return {
                component: ImageRenderer,
                editable: false,
            };
        }
    }
    if (blockType === "color_box") {
        return {
            component: ColorBoxRenderer,
            editable: true,
        };
    }
};

export default renderer;
