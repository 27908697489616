import { FaTwitter } from "react-icons/fa";

import { Twitter } from "./interface";

const twitterConfig: Twitter = {
    title: "Twitter",
    Icon: FaTwitter,
};

export type { Twitter };
export default twitterConfig;
