import React from "react";
import moment, { Moment } from "moment";

// Mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Firebase
import { firestore } from "../../firebase/firebase";
import { updateDoc, doc, addDoc, collection } from "firebase/firestore";

// Data
import { exerciseFallsCalendar, fallsCalendar } from "../pages/portal/questionnaires/data/PhysicalExerciseActivityData";

// Custom components
import Title from "./Title";
import FormGenerator from "../pages/portal/questionnaires/FormGenerator";
import { Stat } from "../../interfaces/Stat";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
    open: boolean;
    // eslint-disable-next-line
    setOpen(value: boolean): void;
    selectedDate: Moment | null;
    // eslint-disable-next-line
    setEvents(value: any): void;
    events: any[];
}

const FallsDialog: React.FC<Props> = ({ open, setOpen, selectedDate, setEvents, events }) => {
    const { currentParticipant } = useFetchUser();

    const updateParticipant = async (events: any[]) => {
        if (currentParticipant && currentParticipant.id) {
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                physical: { ...currentParticipant.physical, falls: events },
            });
        }
    };

    const formatData = (data: any) => {
        const formattedData: any = {};

        data.forEach((d: any) => {
            Object.entries(d).map(e => {
                formattedData[e[0]] = e[1];
            });
        });

        return formattedData;
    };

    const getScore = (data: any, answers: number[]) => {
        let score: any = 0;
        Object.entries(formatData(data)).map(e => {
            const answerIndex = answers[parseInt((e[0] as string).replace("question", "")) + 1];

            if (e[0] === "question6") score += answerIndex - 1;
            return score;
        });

        return 0;
    };

    const handleComplete = async (data: any, answers: number[]) => {
        if (selectedDate) {
            if (currentParticipant && currentParticipant.id) {
                const localStat: Stat = {
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    isDeleted: false,
                    participantId: currentParticipant.id,
                    topic: "physical",
                    results: formatData(data),
                    score: getScore(data, answers),
                    answers,
                    questionnaireName: "exerciseFallsCalendar",
                };
                const createPayload = await addDoc(collection(firestore, "Stats"), localStat);
                await updateDoc(doc(firestore, "Stats", createPayload.id), { id: createPayload.id });

                const newEvent = {
                    date: selectedDate.toDate(),
                    value: data,
                };
                await updateParticipant([...events, newEvent]);

                // Participant log
                await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                    logs: [...currentParticipant.logs, { date: new Date(), action: `Filled a fall on ${moment(selectedDate).format("DD/MM/YYYY")}` }],
                });

                setEvents([...events, newEvent]);
            }
        }

        setOpen(false);
    };

    const getFontClass = () => {
        const fontObj = localStorage.getItem("font");
        if (fontObj) return fontObj;
        return "";
    };

    const getColorClass = () => {
        const themeObj = localStorage.getItem("theme");
        if (themeObj) return themeObj;
        return "";
    };

    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
            className={`fallsDialog ${getFontClass()} ${getColorClass()}`}
        >
            <DialogTitle>
                <Title>Falls calendar - {moment(selectedDate).format("DD/MM/YYYY")}</Title>
            </DialogTitle>
            <DialogContent>
                <FormGenerator
                    formData={currentParticipant && currentParticipant.state === "control" ? fallsCalendar : exerciseFallsCalendar}
                    onComplete={handleComplete}
                    setOpen={setOpen}
                />
            </DialogContent>
        </Dialog>
    );
};

export default FallsDialog;
