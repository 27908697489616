import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";

// Firebase
import { firestore } from "../../../../../firebase/firebase";
import { doc, updateDoc, addDoc, collection, getDocs, where, query, orderBy, onSnapshot } from "firebase/firestore";

// Mui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Utils
import { genDoc } from "../../../../../utils/firebase";
import { getBackgroundColor, getFontColor } from "../../../../../utils/general";
import { exportAOADocument } from "../../../../../utils/xlsx";

// Interfaces
import { Participant } from "../../../../../interfaces/Participant";
import { File } from "../../../../../interfaces/File";

// Hooks
import { useFetchUser } from "../../../../../hooks/useFetchUser";

// Custom components
import Title from "../../../../general/Title";
import FileLegend from "../../../../general/FileLegend";
import BiometricsTable from "../../../../general/SimpleTable";
import BiometricDialog from "../../../../general/BiometricDialog";

// Forms
import ComorbidityIssuesAndCompaints from "../../forms/ComorbidityIssuesAndCompaints";
import LogbookForOngoingMedications from "../../forms/LogbookForOngoingMedications";
import LogbookForMedicalNotes from "../../forms/LogbookForMedicalNotes";

interface Params {
    id: string;
}

const Health: React.FC = () => {
    const { id } = useParams<Params>();
    const hist = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { currentAdmin } = useFetchUser();

    // States
    const [allFiles, setAllFiles] = useState<File[]>([]);
    const [participantFiles, setParticipantFiles] = useState<File[]>([]);
    const [content, setContent] = useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [participant, setParticipant] = useState<Participant | null>(null);
    const [biometricDialogOpen, setBiometricDialogOpen] = useState(false);

    const columns = [
        {
            header: "Date",
            body: (x: any) => moment(x.date.seconds * 1000).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            header: "W",
            body: (x: any) => `${x.weight} kg`,
        },
        {
            header: "H",
            body: (x: any) => `${x.height} cm`,
        },
        {
            header: "W Cir.",
            body: (x: any) => `${x.waistCircumference} cm`,
        },
        {
            header: "Hip Cir.",
            body: (x: any) => {
                if ("hipCircumference" in x) {
                    return `${x.hipCircumference} cm`;
                }
                return "-";
            },
        },
        {
            header: "BP",
            body: (x: any) => `${x.bloodPressure} mmhg`,
        },
        {
            header: "HR",
            body: (x: any) => {
                if ("heartRate" in x) {
                    return `${x.heartRate} bpm`;
                }
                return "-";
            },
        },
        {
            header: "Chol.",
            body: (x: any) => `${x.cholesterol} mmol/L`,
        },
        {
            header: "Gluc.",
            body: (x: any) => `${x.glucose} mmol/L`,
        },
        {
            header: "BMI",
            body: (x: any) => {
                let localWeight = 0;
                let localHeight = 0;

                localWeight = parseFloat(x.weight as any);
                localHeight = parseFloat(x.height as any);

                return (localWeight / ((localHeight / 100) * (localHeight / 100))).toFixed(1);
            },
        },
    ];

    const handleSave = async () => {
        if (participant) {
            await updateDoc(doc(firestore, "Participants", id), {
                ...participant,
                health: {
                    ...participant.health,
                    content,
                    files,
                },
            });
        }

        const logPayload = await addDoc(collection(firestore, "Logs"), {
            createdAt: new Date(),
            updatedAt: new Date(),
            isDeleted: false,
            adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
            reason: `Updated participant ${
                participant && (participant as any).email ? (participant as any).email : (participant as any).id
            }'s health`,
        });

        await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });

        enqueueSnackbar("Participant updated", { variant: "success" });
    };

    const fetchAllFiles = async () => {
        const localQuery = query(
            collection(firestore, "Files"),
            where("topic", "==", "Health"),
            where("isDeleted", "==", false),
            orderBy("createdAt", "desc")
        );
        const payload = await getDocs(localQuery);
        const localFiles = payload.docs.map(genDoc<File>());

        setAllFiles(localFiles ?? []);
    };

    const fetchParticipantFiles = async () => {
        if (participant && participant.id) {
            const localQuery = query(
                collection(firestore, "Participants", participant.id, "Files"),
                where("topic", "==", "Health"),
                where("isDeleted", "==", false),
                orderBy("createdAt", "desc")
            );
            const payload = await getDocs(localQuery);
            const localFiles = payload.docs.map(genDoc<File>());

            setParticipantFiles(localFiles ?? []);
        }
    };

    const fetchParticipant = async () => {
        const docRef = doc(firestore, "Participants", id);

        return onSnapshot(docRef, doc => {
            const localParticipant = genDoc<Participant>()(doc);
            setParticipant(localParticipant);
        });
    };

    const changeBiometricDataDisplay = async () => {
        try {
            if (participant && participant.id) {
                await updateDoc(doc(firestore, "Participants", participant.id), {
                    hasBiometricData: !participant.hasBiometricData,
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleSubmitComorbidity = async (data: any) => {
        if (participant && participant.id) {
            await updateDoc(doc(firestore, "Participants", participant.id), {
                forms: {
                    ...participant.forms,
                    comorbidityIssuesAndComplaints: data,
                },
            });
            enqueueSnackbar("Updated Comorbidity Issues and Complaints", { variant: "success" });
        }
    };

    const handleSubmitOngoingMedications = async (data: any) => {
        if (participant && participant.id) {
            await updateDoc(doc(firestore, "Participants", participant.id), {
                forms: {
                    ...participant.forms,
                    logbookForOngoingMedications: data,
                },
            });
            enqueueSnackbar("Updated List of Ongoing Medications", { variant: "success" });
        }
    };

    const handleSubmitLogbookForMedicalNotes = async (data: any) => {
        if (participant && participant.id) {
            await updateDoc(doc(firestore, "Participants", participant.id), {
                forms: {
                    ...participant.forms,
                    logbookForMedicalNotes: data,
                },
            });
            enqueueSnackbar("Updated Medical Notes", { variant: "success" });
        }
    };

    const formatData = () => {
        const rows = [];

        rows.push(["Date", "Weight", "Blood Pressure", "Height", "Waist Circumference", "Cholesterol", "Glucose"]);

        if (participant) {
            participant.biometrics
                .sort((a: any, b: any) => (a.date.seconds > b.date.seconds ? -1 : 1))
                .forEach((x: any) => {
                    rows.push([
                        moment(x.date.seconds * 1000).format("YYYY-MM-DD HH:mm:ss"),
                        x.weight + " kg",
                        x.bloodPressure + " mmhg",
                        x.height + " cm",
                        x.waistCircumference + " cm",
                        x.cholesterol + " mmol/L",
                        x.glucose + " mmol/L",
                    ]);
                });
        }

        return rows;
    };

    const exportToXLSX = async () => {
        const fileName = `biometrics_${moment(new Date()).format("DD-MM-YYYY")}`;
        const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
        exportAOADocument(await formatData(), fileName, sheetName);
    };

    useEffect(() => {
        if (participant) {
            fetchParticipantFiles();
            setContent(participant.health.content);
            setFiles(participant.health.files);
        }
    }, [participant]);

    useEffect(() => {
        fetchParticipant();
    }, [id]);

    useEffect(() => {
        fetchAllFiles();
    }, []);

    return (
        <>
            <Grid item container>
                <Grid item xs={9}>
                    <Title>Interactive content</Title>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" style={{ float: "right" }} onClick={() => hist.push(`/dashboard/content/Health/Content/${id}`)}>
                        Add private content
                    </Button>
                </Grid>

                {allFiles
                    .filter(f => f.type === "Content")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: getBackgroundColor(true, true, data, content, files),
                                color: getFontColor(true, true, data, content, files),
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (content.some((c: any) => c === data.id)) {
                                    // Remove
                                    setContent((prev: any) => prev.filter((c: any) => c !== data.id));
                                } else {
                                    // Add
                                    setContent((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}

                {participantFiles
                    .filter(f => f.type === "Content")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: getBackgroundColor(false, true, data, content, files),
                                color: getFontColor(false, true, data, content, files),
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (content.some((f: any) => f === data.id)) {
                                    // Remove
                                    setContent((prev: any) => prev.filter((f: any) => f !== data.id));
                                } else {
                                    // Add
                                    setContent((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}
            </Grid>
            <br />
            <br />
            <Grid item container>
                <Grid item xs={10}>
                    <Title>Files</Title>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" style={{ float: "right" }} onClick={() => hist.push(`/dashboard/content/Health/File/${id}`)}>
                        Add private file
                    </Button>
                </Grid>
                {allFiles
                    .filter(f => f.type === "File")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: getBackgroundColor(true, false, data, content, files),
                                color: getFontColor(true, false, data, content, files),
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (files.some((f: any) => f === data.id)) {
                                    // Remove
                                    setFiles((prev: any) => prev.filter((f: any) => f !== data.id));
                                } else {
                                    // Add
                                    setFiles((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}

                {participantFiles
                    .filter(f => f.type === "File")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: getBackgroundColor(false, false, data, content, files),
                                color: getFontColor(false, false, data, content, files),
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (files.some((f: any) => f === data.id)) {
                                    // Remove
                                    setFiles((prev: any) => prev.filter((f: any) => f !== data.id));
                                } else {
                                    // Add
                                    setFiles((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}
            </Grid>

            <FileLegend />

            <Button variant="contained" style={{ marginTop: 40 }} onClick={handleSave}>
                Save
            </Button>
            <br />
            <br />
            <Title>Display biometrics</Title>
            {participant && (
                <Switch
                    checked={participant.hasBiometricData}
                    onChange={() => changeBiometricDataDisplay()}
                    name="checkedA"
                    inputProps={{
                        "aria-label": "secondary checkbox",
                    }}
                />
            )}

            <br />
            <br />

            <div>
                <Button
                    variant="contained"
                    onClick={() => {
                        setBiometricDialogOpen(true);
                    }}
                >
                    Update Biometric Data
                </Button>
            </div>

            <br />
            <br />

            {participant && participant.hasBiometricData && participant.biometrics.length !== 0 && (
                <>
                    <span style={{ paddingBottom: 10, color: "#8a8a8a", fontWeight: "normal", fontSize: "1.5rem" }}>Biometrics</span>

                    <Button variant="contained" style={{ float: "right" }} onClick={exportToXLSX}>
                        Export
                    </Button>
                    <BiometricsTable
                        data={participant.biometrics.sort((a: any, b: any) => (a.date.seconds > b.date.seconds ? -1 : 1))}
                        noMoreData={true}
                        rows={columns}
                        hidePagination
                    />
                </>
            )}

            {participant && participant.id && (
                <>
                    <ComorbidityIssuesAndCompaints participantId={participant.id} onComplete={handleSubmitComorbidity} />
                    <LogbookForOngoingMedications participantId={participant.id} onComplete={handleSubmitOngoingMedications} />
                    <LogbookForMedicalNotes participantId={participant.id} onComplete={handleSubmitLogbookForMedicalNotes} />
                </>
            )}

            {biometricDialogOpen && <BiometricDialog open={biometricDialogOpen} setOpen={setBiometricDialogOpen} participantId={participant?.id} />}
        </>
    );
};

export default Health;
