// Interfaces
import { Appointment } from "../interfaces/Appointment";

// Assets
import pdfIcon from "../assets/icons/pdfIcon.svg";
import powerpointIcon from "../assets/icons/powerpointIcon.svg";
import excelIcon from "../assets/icons/excelIcon.svg";
import wordIcon from "../assets/icons/wordIcon.svg";

export const removeUndefinedInObject = (data: any) => {
    const newObject: any = {};

    Object.entries(data).forEach(entry => {
        if (entry[1] !== undefined) newObject[entry[0]] = entry[1];
    });

    return newObject;
};

export const getBackgroundColor = (isGlobal: boolean, isContent: boolean, data: any, content: any[], files: any[]) => {
    let backgroundColor = "white";

    if (isContent && content) {
        if (isGlobal) backgroundColor = content.some((c: any) => c === data.id) ? "black" : "white";
        else backgroundColor = content.some((f: any) => f === data.id) ? "darkred" : "grey";
    } else if (!isContent && files) {
        if (isGlobal) backgroundColor = files.some((f: any) => f === data.id) ? "black" : "white";
        else backgroundColor = files.some((f: any) => f === data.id) ? "darkred" : "grey";
    }

    return backgroundColor;
};

export const getFontColor = (isGlobal: boolean, isContent: boolean, data: any, content: any[], files: any[]) => {
    let fontColor = "black";

    if (isContent && content) {
        if (isGlobal) fontColor = content.some((c: any) => c === data.id) ? "white" : "black";
        else fontColor = content.some((f: any) => f === data.id) ? "white" : "white";
    } else if (!isContent && files) {
        if (isGlobal) fontColor = files.some((f: any) => f === data.id) ? "white" : "black";
        else fontColor = files.some((f: any) => f === data.id) ? "white" : "white";
    }

    return fontColor;
};

export const getNextAppointment = (appointments: Appointment[]) => {
    const upcomingAppointments: Appointment[] = [];
    const now = new Date();

    // No appointments
    if (!appointments.length) return null;

    appointments.forEach(a => {
        if (a.start >= now) {
            upcomingAppointments.push(a);
        }
    });

    // No upcoming appointments
    if (!upcomingAppointments.length) return null;

    upcomingAppointments.sort((a, b) => a.start.getTime() - b.start.getTime());

    return upcomingAppointments[0];
};

export const getNextAppointmentDate = (appointments: Appointment[]) => {
    const nextAppointment = getNextAppointment(appointments);

    if (nextAppointment) return nextAppointment.start;
    else return null;
};

export const getAppointmentInProgress = (appointments: Appointment[]) => {
    let inProgress = false;
    appointments.sort((a, b) => a.start.getTime() - b.start.getTime());

    const start = new Date();
    const end = new Date();

    // 30 mins before
    start.setMinutes(start.getMinutes() - 30);
    // 2 hours after
    end.setHours(end.getHours() + 2);

    appointments.forEach(a => {
        if (a.start >= start && a.start <= end) {
            inProgress = true;
        }
    });

    return inProgress;
};

export const capitalizeFirstLetterOfEachWord = (s: string) => {
    return s.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};

export const fetchQuestionNumber = (question: string) => {
    const regExp = /[a-zA-Z]/g;
    let questionNumber = 0;
    let questionNumberWithoutQuestion = question.replace("question", "");

    // check if question number have letters
    if (regExp.test(questionNumberWithoutQuestion)) {
        questionNumberWithoutQuestion = questionNumberWithoutQuestion.substring(0, questionNumberWithoutQuestion.length - 1);
    }

    questionNumber = parseInt(questionNumberWithoutQuestion);

    return questionNumber;
};

export const getIcon = (extension: string) => {
    switch (extension) {
        case "media":
        case "pdf":
        default:
            return pdfIcon;
        case "powerpoint":
            return powerpointIcon;
        case "excel":
            return excelIcon;
        case "word":
            return wordIcon;
    }
};

export const camelcaseToWords = (string: string | undefined) => {
    if (string && typeof string === "string") {
        const result = string.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
};

export const objectsEqual = (o1: any, o2: any): any =>
    typeof o1 === "object" && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
