import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

// Mui
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// Assets
import breakfastIcon from "../../../../../../assets/icons/breakfastIcon.png";
import lunchIcon from "../../../../../../assets/icons/lunchIcon.png";
import dinnerIcon from "../../../../../../assets/icons/dinnerIcon.png";
import snackIcon from "../../../../../../assets/icons/snackIcon.png";

// Icons
import { MdExpandMore } from "react-icons/md";

// Utils
import { useFetchUser } from "../../../../../../hooks/useFetchUser";

// Interfaces
import { Participant } from "../../../../../../interfaces/Participant";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Firebase
import { firestore } from "../../../../../../firebase/firebase";
import { addDoc, collection, updateDoc, doc, getDoc } from "firebase/firestore";
import { genDoc } from "../../../../../../utils/firebase";

// yup validation
const userSchema = yup.object({
    // Typical Week
    typicalMondayBreakfast: yup.string(),
    typicalMondayLunch: yup.string(),
    typicalMondayDinner: yup.string(),
    typicalMondaySnack: yup.string(),
    typicalTuesdayBreakfast: yup.string(),
    typicalTuesdayLunch: yup.string(),
    typicalTuesdayDinner: yup.string(),
    typicalTuesdaySnack: yup.string(),
    typicalWednesdayBreakfast: yup.string(),
    typicalWednesdayLunch: yup.string(),
    typicalWednesdayDinner: yup.string(),
    typicalWednesdaySnack: yup.string(),
    typicalThursdayBreakfast: yup.string(),
    typicalThursdayLunch: yup.string(),
    typicalThursdayDinner: yup.string(),
    typicalThursdaySnack: yup.string(),
    typicalFridayBreakfast: yup.string(),
    typicalFridayLunch: yup.string(),
    typicalFridayDinner: yup.string(),
    typicalFridaySnack: yup.string(),
    typicalSaturdayBreakfast: yup.string(),
    typicalSaturdayLunch: yup.string(),
    typicalSaturdayDinner: yup.string(),
    typicalSaturdaySnack: yup.string(),
    typicalSundayBreakfast: yup.string(),
    typicalSundayLunch: yup.string(),
    typicalSundayDinner: yup.string(),
    typicalSundaySnack: yup.string(),

    // Recommendation
    recommendation: yup.string(),
});

interface Params {
    id: string;
}

const WeeklyDiet: React.FC = () => {
    const { setLoading, currentAdmin } = useFetchUser();
    const { id } = useParams<Params>();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [participant, setParticipant] = useState<Participant | null>(null);

    const defaultValues = {
        // Typical Week
        typicalMondayBreakfast: "",
        typicalMondayLunch: "",
        typicalMondayDinner: "",
        typicalMondaySnack: "",
        typicalTuesdayBreakfast: "",
        typicalTuesdayLunch: "",
        typicalTuesdayDinner: "",
        typicalTuesdaySnack: "",
        typicalWednesdayBreakfast: "",
        typicalWednesdayLunch: "",
        typicalWednesdayDinner: "",
        typicalWednesdaySnack: "",
        typicalThursdayBreakfast: "",
        typicalThursdayLunch: "",
        typicalThursdayDinner: "",
        typicalThursdaySnack: "",
        typicalFridayBreakfast: "",
        typicalFridayLunch: "",
        typicalFridayDinner: "",
        typicalFridaySnack: "",
        typicalSaturdayBreakfast: "",
        typicalSaturdayLunch: "",
        typicalSaturdayDinner: "",
        typicalSaturdaySnack: "",
        typicalSundayBreakfast: "",
        typicalSundayLunch: "",
        typicalSundayDinner: "",
        typicalSundaySnack: "",

        // Recommendation
        recommendation: "",
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({ resolver: yupResolver(userSchema), defaultValues });

    const fetchParticipant = async () => {
        try {
            const payload = await getDoc(doc(firestore, "Participants", id));
            setParticipant(genDoc<Participant>()(payload));
        } catch (e) {
            console.error(e);
        }
    };

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);

            await updateDoc(doc(firestore, "Participants", id), {
                diet: {
                    typicalWeek: {
                        monday: {
                            typicalMondayBreakfast: data.typicalMondayBreakfast,
                            typicalMondayLunch: data.typicalMondayLunch,
                            typicalMondayDinner: data.typicalMondayDinner,
                            typicalMondaySnack: data.typicalMondaySnack,
                        },
                        tuesday: {
                            typicalTuesdayBreakfast: data.typicalTuesdayBreakfast,
                            typicalTuesdayLunch: data.typicalTuesdayLunch,
                            typicalTuesdayDinner: data.typicalTuesdayDinner,
                            typicalTuesdaySnack: data.typicalTuesdaySnack,
                        },
                        wednesday: {
                            typicalWednesdayBreakfast: data.typicalWednesdayBreakfast,
                            typicalWednesdayLunch: data.typicalWednesdayLunch,
                            typicalWednesdayDinner: data.typicalWednesdayDinner,
                            typicalWednesdaySnack: data.typicalWednesdaySnack,
                        },
                        thursday: {
                            typicalThursdayBreakfast: data.typicalThursdayBreakfast,
                            typicalThursdayLunch: data.typicalThursdayLunch,
                            typicalThursdayDinner: data.typicalThursdayDinner,
                            typicalThursdaySnack: data.typicalThursdaySnack,
                        },
                        friday: {
                            typicalFridayBreakfast: data.typicalFridayBreakfast,
                            typicalFridayLunch: data.typicalFridayLunch,
                            typicalFridayDinner: data.typicalFridayDinner,
                            typicalFridaySnack: data.typicalFridaySnack,
                        },
                        saturday: {
                            typicalSaturdayBreakfast: data.typicalSaturdayBreakfast,
                            typicalSaturdayLunch: data.typicalSaturdayLunch,
                            typicalSaturdayDinner: data.typicalSaturdayDinner,
                            typicalSaturdaySnack: data.typicalSaturdaySnack,
                        },
                        sunday: {
                            typicalSundayBreakfast: data.typicalSundayBreakfast,
                            typicalSundayLunch: data.typicalSundayLunch,
                            typicalSundayDinner: data.typicalSundayDinner,
                            typicalSundaySnack: data.typicalSundaySnack,
                        },
                    },
                    recommendation: data.recommendation,
                },
            });

            const logPayload = await addDoc(collection(firestore, "Logs"), {
                createdAt: new Date(),
                updatedAt: new Date(),
                isDeleted: false,
                adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
                reason: `Updated participant ${
                    participant && (participant as any).email ? (participant as any).email : (participant as any).id
                }'s diet`,
            });

            await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });

            enqueueSnackbar("Participant updated", { variant: "success" });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.entries(errors).length > 0) {
            const name = Object.entries(errors)[0][0];
            const element = document.getElementsByName(name)[0];

            if (element) element.scrollIntoView({ block: "center" });
        }
    }, [errors]);

    useEffect(() => {
        if (participant) {
            if (participant.diet) {
                if (participant.diet.typicalWeek) {
                    if (participant.diet.typicalWeek.monday) {
                        setValue("typicalMondayBreakfast", participant.diet.typicalWeek.monday.typicalMondayBreakfast);
                        setValue("typicalMondayLunch", participant.diet.typicalWeek.monday.typicalMondayLunch);
                        setValue("typicalMondayDinner", participant.diet.typicalWeek.monday.typicalMondayDinner);
                        setValue("typicalMondaySnack", participant.diet.typicalWeek.monday.typicalMondaySnack);
                    }
                    if (participant.diet.typicalWeek.tuesday) {
                        setValue("typicalTuesdayBreakfast", participant.diet.typicalWeek.tuesday.typicalTuesdayBreakfast);
                        setValue("typicalTuesdayLunch", participant.diet.typicalWeek.tuesday.typicalTuesdayLunch);
                        setValue("typicalTuesdayDinner", participant.diet.typicalWeek.tuesday.typicalTuesdayDinner);
                        setValue("typicalTuesdaySnack", participant.diet.typicalWeek.tuesday.typicalTuesdaySnack);
                    }
                    if (participant.diet.typicalWeek.wednesday) {
                        setValue("typicalWednesdayBreakfast", participant.diet.typicalWeek.wednesday.typicalWednesdayBreakfast);
                        setValue("typicalWednesdayLunch", participant.diet.typicalWeek.wednesday.typicalWednesdayLunch);
                        setValue("typicalWednesdayDinner", participant.diet.typicalWeek.wednesday.typicalWednesdayDinner);
                        setValue("typicalWednesdaySnack", participant.diet.typicalWeek.wednesday.typicalWednesdaySnack);
                    }
                    if (participant.diet.typicalWeek.thursday) {
                        setValue("typicalThursdayBreakfast", participant.diet.typicalWeek.thursday.typicalThursdayBreakfast);
                        setValue("typicalThursdayLunch", participant.diet.typicalWeek.thursday.typicalThursdayLunch);
                        setValue("typicalThursdayDinner", participant.diet.typicalWeek.thursday.typicalThursdayDinner);
                        setValue("typicalThursdaySnack", participant.diet.typicalWeek.thursday.typicalThursdaySnack);
                    }
                    if (participant.diet.typicalWeek.friday) {
                        setValue("typicalFridayBreakfast", participant.diet.typicalWeek.friday.typicalFridayBreakfast);
                        setValue("typicalFridayLunch", participant.diet.typicalWeek.friday.typicalFridayLunch);
                        setValue("typicalFridayDinner", participant.diet.typicalWeek.friday.typicalFridayDinner);
                        setValue("typicalFridaySnack", participant.diet.typicalWeek.friday.typicalFridaySnack);
                    }
                    if (participant.diet.typicalWeek.saturday) {
                        setValue("typicalSaturdayBreakfast", participant.diet.typicalWeek.saturday.typicalSaturdayBreakfast);
                        setValue("typicalSaturdayLunch", participant.diet.typicalWeek.saturday.typicalSaturdayLunch);
                        setValue("typicalSaturdayDinner", participant.diet.typicalWeek.saturday.typicalSaturdayDinner);
                        setValue("typicalSaturdaySnack", participant.diet.typicalWeek.saturday.typicalSaturdaySnack);
                    }
                    if (participant.diet.typicalWeek.sunday) {
                        setValue("typicalSundayBreakfast", participant.diet.typicalWeek.sunday.typicalSundayBreakfast);
                        setValue("typicalSundayLunch", participant.diet.typicalWeek.sunday.typicalSundayLunch);
                        setValue("typicalSundayDinner", participant.diet.typicalWeek.sunday.typicalSundayDinner);
                        setValue("typicalSundaySnack", participant.diet.typicalWeek.sunday.typicalSundaySnack);
                    }
                } else if (participant.diet.recommendation) {
                    setValue("recommendation", participant.diet.recommendation);
                }
            }
        }
    }, [participant]);

    useEffect(() => {
        fetchParticipant();
    }, []);

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Accordion expanded={true}>
                <AccordionSummary expandIcon={<MdExpandMore />}>Typical Week</AccordionSummary>
                <AccordionDetails>
                    <h4>Monday</h4>
                    <img className="nofilterimg" src={breakfastIcon} alt="icon" style={{ maxWidth: 20 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalMondayBreakfast?.message}
                                error={!!errors.typicalMondayBreakfast?.message}
                                label="Breakfast"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalMondayBreakfast"
                        control={control}
                    />
                    <img className="nofilterimg" src={lunchIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalMondayLunch?.message}
                                error={!!errors.typicalMondayLunch?.message}
                                label="Lunch"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalMondayLunch"
                        control={control}
                    />
                    <img className="nofilterimg" src={dinnerIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalMondayDinner?.message}
                                error={!!errors.typicalMondayDinner?.message}
                                label="Dinner"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalMondayDinner"
                        control={control}
                    />
                    <img className="nofilterimg" src={snackIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalMondaySnack?.message}
                                error={!!errors.typicalMondaySnack?.message}
                                label="Snack"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalMondaySnack"
                        control={control}
                    />
                    <h4>Tuesday</h4>
                    <img className="nofilterimg" src={breakfastIcon} alt="icon" style={{ maxWidth: 20 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalTuesdayBreakfast?.message}
                                error={!!errors.typicalTuesdayBreakfast?.message}
                                label="Breakfast"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalTuesdayBreakfast"
                        control={control}
                    />
                    <img className="nofilterimg" src={lunchIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalTuesdayLunch?.message}
                                error={!!errors.typicalTuesdayLunch?.message}
                                label="Lunch"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalTuesdayLunch"
                        control={control}
                    />
                    <img className="nofilterimg" src={dinnerIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalTuesdayDinner?.message}
                                error={!!errors.typicalTuesdayDinner?.message}
                                label="Dinner"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalTuesdayDinner"
                        control={control}
                    />
                    <img className="nofilterimg" src={snackIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalTuesdaySnack?.message}
                                error={!!errors.typicalTuesdaySnack?.message}
                                label="Snack"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalTuesdaySnack"
                        control={control}
                    />
                    <h4>Wednesday</h4>
                    <img className="nofilterimg" src={breakfastIcon} alt="icon" style={{ maxWidth: 20 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalWednesdayBreakfast?.message}
                                error={!!errors.typicalWednesdayBreakfast?.message}
                                label="Breakfast"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalWednesdayBreakfast"
                        control={control}
                    />
                    <img className="nofilterimg" src={lunchIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalWednesdayLunch?.message}
                                error={!!errors.typicalWednesdayLunch?.message}
                                label="Lunch"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalWednesdayLunch"
                        control={control}
                    />
                    <img className="nofilterimg" src={dinnerIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalWednesdayDinner?.message}
                                error={!!errors.typicalWednesdayDinner?.message}
                                label="Dinner"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalWednesdayDinner"
                        control={control}
                    />
                    <img className="nofilterimg" src={snackIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalWednesdaySnack?.message}
                                error={!!errors.typicalWednesdaySnack?.message}
                                label="Snack"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalWednesdaySnack"
                        control={control}
                    />
                    <h4>Thursday</h4>
                    <img className="nofilterimg" src={breakfastIcon} alt="icon" style={{ maxWidth: 20 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalThursdayBreakfast?.message}
                                error={!!errors.typicalThursdayBreakfast?.message}
                                label="Breakfast"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalThursdayBreakfast"
                        control={control}
                    />
                    <img className="nofilterimg" src={lunchIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalThursdayLunch?.message}
                                error={!!errors.typicalThursdayLunch?.message}
                                label="Lunch"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalThursdayLunch"
                        control={control}
                    />
                    <img className="nofilterimg" src={dinnerIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalThursdayDinner?.message}
                                error={!!errors.typicalThursdayDinner?.message}
                                label="Dinner"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalThursdayDinner"
                        control={control}
                    />
                    <img className="nofilterimg" src={snackIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalThursdaySnack?.message}
                                error={!!errors.typicalThursdaySnack?.message}
                                label="Snack"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalThursdaySnack"
                        control={control}
                    />
                    <h4>Friday</h4>
                    <img className="nofilterimg" src={breakfastIcon} alt="icon" style={{ maxWidth: 20 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalFridayBreakfast?.message}
                                error={!!errors.typicalFridayBreakfast?.message}
                                label="Breakfast"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalFridayBreakfast"
                        control={control}
                    />
                    <img className="nofilterimg" src={lunchIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalFridayLunch?.message}
                                error={!!errors.typicalFridayLunch?.message}
                                label="Lunch"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalFridayLunch"
                        control={control}
                    />
                    <img className="nofilterimg" src={dinnerIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalFridayDinner?.message}
                                error={!!errors.typicalFridayDinner?.message}
                                label="Dinner"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalFridayDinner"
                        control={control}
                    />
                    <img className="nofilterimg" src={snackIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalFridaySnack?.message}
                                error={!!errors.typicalFridaySnack?.message}
                                label="Snack"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalFridaySnack"
                        control={control}
                    />
                    <h4>Saturday</h4>
                    <img className="nofilterimg" src={breakfastIcon} alt="icon" style={{ maxWidth: 20 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalSaturdayBreakfast?.message}
                                error={!!errors.typicalSaturdayBreakfast?.message}
                                label="Breakfast"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalSaturdayBreakfast"
                        control={control}
                    />
                    <img className="nofilterimg" src={lunchIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalSaturdayLunch?.message}
                                error={!!errors.typicalSaturdayLunch?.message}
                                label="Lunch"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalSaturdayLunch"
                        control={control}
                    />
                    <img className="nofilterimg" src={dinnerIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalSaturdayDinner?.message}
                                error={!!errors.typicalSaturdayDinner?.message}
                                label="Dinner"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalSaturdayDinner"
                        control={control}
                    />
                    <img className="nofilterimg" src={snackIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalSaturdaySnack?.message}
                                error={!!errors.typicalSaturdaySnack?.message}
                                label="Snack"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalSaturdaySnack"
                        control={control}
                    />
                    <h4>Sunday</h4>
                    <img className="nofilterimg" src={breakfastIcon} alt="icon" style={{ maxWidth: 20 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalSundayBreakfast?.message}
                                error={!!errors.typicalSundayBreakfast?.message}
                                label="Breakfast"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalSundayBreakfast"
                        control={control}
                    />
                    <img className="nofilterimg" src={lunchIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalSundayLunch?.message}
                                error={!!errors.typicalSundayLunch?.message}
                                label="Lunch"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalSundayLunch"
                        control={control}
                    />
                    <img className="nofilterimg" src={dinnerIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalSundayDinner?.message}
                                error={!!errors.typicalSundayDinner?.message}
                                label="Dinner"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalSundayDinner"
                        control={control}
                    />
                    <img className="nofilterimg" src={snackIcon} alt="icon" style={{ maxWidth: 20, marginTop: 10 }} />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                rows={4}
                                multiline
                                variant="standard"
                                helperText={errors.typicalSundaySnack?.message}
                                error={!!errors.typicalSundaySnack?.message}
                                label="Snack"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="typicalSundaySnack"
                        control={control}
                    />
                </AccordionDetails>
            </Accordion>

            <Button variant="contained" style={{ marginTop: 20 }} type="submit">
                Save
            </Button>
        </form>
    );
};

export default WeeklyDiet;
