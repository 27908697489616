import React, { useEffect, useState } from "react";
import moment from "moment";

// Interfaces
import { Message } from "../../interfaces/Message";
import { Participant } from "../../interfaces/Participant";
import { AdminUser } from "../../interfaces/AdminUser";

// Firebase
import { firestore } from "../../firebase/firebase";
import { addDoc, collection, getDocs, onSnapshot, orderBy, query, doc, updateDoc, getDoc } from "firebase/firestore";
import { genDoc } from "../../utils/firebase";

// Mui
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Icons
import { MdSend } from "react-icons/md";

interface Props {
    participantId?: string;
    adminId?: string;
    dashboardChat?: boolean;
}

const Chat: React.FC<Props> = ({ participantId = null, adminId = null, dashboardChat = false }) => {
    const { currentAdmin, currentParticipant } = useFetchUser();

    // States
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [admins, setAdmins] = useState<AdminUser[]>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [message, setMessage] = useState("");
    const [chat, setChat] = useState(false);

    const fetchParticipants = async () => {
        const payload = await getDocs(collection(firestore, "Participants"));
        const data = payload.docs.map(d => d.data()) as Participant[];
        setParticipants(data);
    };

    const fetchAdmins = async () => {
        const payload = await getDocs(collection(firestore, "Users"));
        const data = payload.docs.map(d => d.data()) as AdminUser[];
        setAdmins(data);
    };

    const fetchMessages = async () => {
        const recentMessagesQuery = query(collection(firestore, "Messages"), orderBy("createdAt", "desc"));

        onSnapshot(recentMessagesQuery, snapshot => {
            const data = snapshot.docs.map(d => d.data()) as Message[];
            data.sort((a, b) => {
                if (a.createdAt && b.createdAt) {
                    return (a.createdAt as any).seconds > (b.createdAt as any).seconds ? 1 : -1;
                }
                return 1;
            });

            setMessages(data);
        });
    };

    const handleChatContainer = () => {
        if (dashboardChat) setChat(!chat);
    };

    const handleSendMessage = async () => {
        const localMessage: Message = {
            createdAt: new Date(),
            updatedAt: new Date(),
            isDeleted: false,
            text: message,
        };

        if (participantId) localMessage.participantId = participantId;
        if (adminId) localMessage.adminId = adminId;

        const createPayload = await addDoc(collection(firestore, "Messages"), localMessage);
        await updateDoc(doc(firestore, "Messages", createPayload.id), { id: createPayload.id });

        if (participantId) {
            const participantPayload = await getDoc(doc(firestore, "Participants", participantId));

            const part = genDoc<Participant>()(participantPayload);

            if (participantId) {
                // Participant log
                await updateDoc(doc(firestore, "Participants", participantId), {
                    logs: [...part.logs, { date: new Date(), action: "Sent message in global chat" }],
                });
            }
        }

        setMessage("");
    };

    const isSameUser = (u: AdminUser | Participant | undefined) => {
        let sameUser = false;

        if (u) {
            if (u.id === adminId || u.id === participantId) sameUser = true;
        }

        return sameUser;
    };

    const getUser = (m: Message) => {
        if (m.participantId) {
            const part = participants.find(p => p.id === m.participantId);
            return part;
        } else {
            const admin = admins.find(a => a.id === m.adminId);
            return admin;
        }
    };

    const scrollToBottom = () => {
        const element = document.getElementById((messages.length - 1).toString());
        if (element) element.scrollIntoView({ block: "center" });
    };

    useEffect(() => {
        if (messages.length > 0) scrollToBottom();
    }, [messages]);

    useEffect(() => {
        fetchParticipants();
        fetchAdmins();
        fetchMessages();
        handleChatContainer();
    }, []);

    return (
        <div className={dashboardChat ? "chatDashboard__container" : "chat__container"}>
            <div className="chat__output">
                {messages && messages.length ? (
                    messages.map((m, i) => {
                        return (
                            <div
                                key={i}
                                id={i.toString()}
                                style={
                                    dashboardChat
                                        ? {
                                              display: "flex",
                                              justifyContent:
                                                  (currentAdmin && currentAdmin.id === m.adminId) ||
                                                  (currentParticipant && currentParticipant.id === m.participantId)
                                                      ? "flex-start"
                                                      : "flex-end",
                                          }
                                        : {
                                              display: "flex",
                                              justifyContent:
                                                  (currentAdmin && currentAdmin.id === m.adminId) ||
                                                  (currentParticipant && currentParticipant.id === m.participantId)
                                                      ? "flex-start"
                                                      : "flex-end",
                                          }
                                }
                            >
                                <div className="chat__messages">
                                    <Tooltip title={`Sent by ${getUser(m)?.email}`}>
                                        <h5
                                            className="chat__messages--user"
                                            style={
                                                (currentAdmin && currentAdmin.id === m.adminId) ||
                                                (currentParticipant && currentParticipant.id === m.participantId)
                                                    ? { marginRight: 10 }
                                                    : { marginLeft: 10, textAlign: "end" }
                                            }
                                        >
                                            {typeof getUser(m) !== "string" && isSameUser(getUser(m)) ? "You" : getUser(m)?.firstName}
                                        </h5>
                                    </Tooltip>
                                    <Tooltip title={`Sent on ${moment((m.createdAt as any).seconds * 1000).format("DD/MM/YYYY hh:mm:ss")}`}>
                                        <h6
                                            className="chat__messages--content"
                                            style={
                                                dashboardChat
                                                    ? (currentAdmin && currentAdmin.id === m.adminId) ||
                                                      (currentParticipant && currentParticipant.id === m.participantId)
                                                        ? {
                                                              marginLeft: 20,
                                                          }
                                                        : {
                                                              display: "flex",
                                                              placeContent: "flex-end",
                                                              alignSelf: "end",
                                                              marginRight: 20,
                                                              width: "min-content",
                                                          }
                                                    : (currentAdmin && currentAdmin.id === m.adminId) ||
                                                      (currentParticipant && currentParticipant.id === m.participantId)
                                                    ? {
                                                          backgroundColor: "#063336",
                                                          marginLeft: 20,
                                                      }
                                                    : {
                                                          display: "flex",
                                                          placeContent: "flex-end",
                                                          backgroundColor: "black",
                                                          alignSelf: "end",
                                                          marginRight: 20,
                                                      }
                                            }
                                        >
                                            {m.text}
                                        </h6>
                                    </Tooltip>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="centered__parent">
                        <h4>No messages</h4>
                    </div>
                )}
            </div>

            <TextField
                fullWidth
                variant="outlined"
                label="Type a message"
                placeholder="Type your message and then press enter"
                value={message}
                onChange={e => setMessage(e.target.value)}
                onKeyPress={ev => {
                    if (ev.key === "Enter") {
                        handleSendMessage();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                className="chat__submit"
                                onClick={() => {
                                    handleSendMessage();
                                }}
                            >
                                <MdSend />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

export default Chat;
