import { EditorState, RichUtils } from "draft-js";
import Option from "../../components/Option";

import { EditorConfigInline } from "../../config/defaultToolbar";
import { InlineStyle } from "../../config/inline";

// utils
import { getSelectionInlineStyle } from "../../utils/inline";

interface InlineProps {
    // eslint-disable-next-line
    onChange: (s: EditorState) => void;
    config: EditorConfigInline;
    editorState: EditorState;
}

const Inline = ({ onChange, config, editorState }: InlineProps) => {
    const isActive = (style: string) => {
        const selectionStyles = getSelectionInlineStyle(editorState);
        return selectionStyles ? selectionStyles.includes(style) : false;
    };

    const toggleInlineStyle = (style: string) => {
        const newState = RichUtils.toggleInlineStyle(editorState, style);

        onChange(newState);
    };

    return (
        <div>
            {config.map(({ style, title, Icon }: InlineStyle, index: number) => (
                <Option title={title} value={style.toUpperCase()} active={isActive(style.toUpperCase())} onClick={toggleInlineStyle} key={index}>
                    <Icon />
                </Option>
            ))}
        </div>
    );
};

export default Inline;
