import React, { useState } from "react";

// Mui
import Paper from "@mui/material/Paper";

// Settings
import settings from "../../../settings.json";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Components
import DesktopDrawer from "./DesktopDrawer";
import MobileDrawer from "./MobileDrawer";
import Loading from "../../general/Loading";

const DashboardLayout: React.FC<any> = ({ children }) => {
    const { loading } = useFetchUser();

    const [width, setWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return (
        <>
            <Loading loading={loading} />
            <div className="dashboardLayout__container">
                {width >= settings.page.maxScreenWidth ? <DesktopDrawer /> : <MobileDrawer />}
                <main className="dashboardLayout__content">
                    <div className="dashboardLayout__appBarSpacer" />
                    <div className="app__content">
                        <Paper>{children}</Paper>
                    </div>
                </main>
            </div>
        </>
    );
};

export default DashboardLayout;
