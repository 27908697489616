import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

// Mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

// Assets
import helpIcon from "../../../assets/icons/helpIcon.png";

// Icons
import { MdExpandMore, MdPhone } from "react-icons/md";
import { IoChevronBack } from "react-icons/io5";

// Custom component
import TopMenu from "../../general/TopMenu";
import ParticipantChat from "../../general/ParticipantChat";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Utils
import { help } from "../../../utils/email";

// Firebase
import { httpsCallable } from "firebase/functions";
import { firestore, functions } from "../../../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";

const Help: React.FC = () => {
    const { setLoading, currentParticipant } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();
    const hist = useHistory();

    // States
    const [question, setQuestion] = useState("");
    const [message, setMessage] = useState("");

    const resetFields = () => {
        setQuestion("");
        setMessage("");
    };

    const handleSubmitQuestion = async () => {
        try {
            if (currentParticipant) {
                setLoading(true);
                const sendEmail = httpsCallable(functions, "sendEmail");

                await sendEmail({
                    to: "Diana.Amaris@sjhc.london.on.ca",
                    from: currentParticipant.email,
                    subject: `${currentParticipant.email} - Synergic-2 Portal Help`,
                    text: `Synergic-2 Portal Help`,
                    html: help(question, message),
                });

                if (currentParticipant && currentParticipant.id) {
                    // Participant log
                    await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                        logs: [...currentParticipant.logs, { date: new Date(), action: "Sent help message to admins" }],
                    });
                }

                enqueueSnackbar("Email sent", { variant: "success" });
                resetFields();
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (currentParticipant && currentParticipant.id) {
            // Participant log
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...currentParticipant.logs, { date: new Date(), action: "Visited help page" }],
            });
        }
    }, []);

    return (
        <div className="help">
            <TopMenu title="Help" icon={helpIcon} backButton />

            <Grid container item spacing={8}>
                <Grid item xs={6} className="help__content">
                    {/* <h4 style={{ paddingBottom: 20 }}>Help</h4>
                    <p>If you have any questions, just write them in the chat and we will answer within the next 24 hours on business days.</p> */}

                    <h4 style={{ marginBottom: 40 }}>F.A.Q.</h4>
                    <Accordion className="help__accordion">
                        <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography className="help__faq__questions">
                                <b>What would happen with my progress if I lose internet in the middle of a session?</b>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        The data will be kept in memory on the device and sent to the server as soon as the internet connection is
                                        back.
                                    </li>
                                    <li>The session will resume at the last interrupted block.</li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="help__accordion" style={{ marginTop: 20 }}>
                        <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography className="help__faq__questions">
                                <b>I am having issues when working on my Neuropeak sessions.</b>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        Please write in the chat as much information as possible about the issue. When did the problem occur? Under
                                        what circumstances? What is the nature of the problem? Were you using a tablet, laptop/computer? Was the Wi-Fi
                                        stable?
                                    </li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className="help__accordion" style={{ marginTop: 20 }}>
                        <AccordionSummary
                            expandIcon={<MdPhone size={30} style={{ marginRight: 20, paddingTop: 17, paddingBottom: 17 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className="help__faq__questions">
                                <b>
                                    Toll-free number{" "}
                                    <a href="tel:888-772-7962" style={{ color: "#4ecbd3" }}>
                                        888-772-7962
                                    </a>{" "}
                                    or{" "}
                                    <a href="tel:888-772-7962" style={{ color: "#4ecbd3" }}>
                                        888-772-SYN2
                                    </a>
                                </b>
                            </Typography>
                        </AccordionSummary>
                    </Accordion>

                    <h4 style={{ marginTop: 40 }}>Email Support</h4>

                    <div style={{ borderRadius: 20, marginBottom: 30, marginTop: 30, backgroundColor: "#063336", padding: 10 }}>
                        <h4 style={{ marginTop: 20, marginBottom: 10 }}>Question</h4>
                        <TextField fullWidth value={question} onChange={e => setQuestion(e.target.value)} className="help__textbox" />

                        <Divider />

                        <h4 style={{ marginTop: 20, marginBottom: 10 }}>Message</h4>
                        <TextField
                            fullWidth
                            multiline
                            rows={7}
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            className="help__textbox"
                        />
                        <Button fullWidth className="cta__submit" variant="contained" onClick={() => handleSubmitQuestion()}>
                            Submit
                        </Button>
                    </div>

                    <Grid container>
                        <Grid item md={12} className="home__optionsContainer">
                            <Grid item xs={12} onClick={() => hist.push("/")} className="home__options__card">
                                <h4 className="home__options__title">Back to Home</h4>
                                <IoChevronBack className="home__options__image" />
                                <IoChevronBack className="home__options__background" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <h4 style={{ paddingBottom: 20 }}>Questions?</h4>
                    <p>If you have any questions, just write them in the chat and we will answer within the next 24 hours on business days.</p>
                    <div style={{ paddingBottom: 20 }} className="centered__parent">
                        {/* <img src={neuropeakLogo} alt="logo" /> */}
                    </div>

                    <div style={{ marginTop: 20 }}>
                        {currentParticipant && currentParticipant.id && <ParticipantChat participantId={currentParticipant.id} />}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Help;
