import React from "react";

// Mui
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";

// Component
import TableLoader from "./TableLoader";
import settings from "../../settings.json";

interface Props {
    data?: any[];
    loading?: boolean;
    rows?: any[];
    count?: number;
    noMoreData?: boolean;
    rowsPerPage?: number;
    page?: number;
    onDoubleClick?: any;
    onChangePage?: any;
    hidePagination?: boolean;
}

const SimpleTable: React.FC<Props> = ({
    data = [],
    loading = false,
    rows = [],
    count = data.length,
    noMoreData = false,
    rowsPerPage = settings.page.rowsPerPage,
    page = 0,
    onDoubleClick = null,
    onChangePage = null,
    hidePagination = false,
}) => {
    return (
        <>
            {!hidePagination && (
                <TablePagination
                    rowsPerPageOptions={[]}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`}
                    labelRowsPerPage={false}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                />
            )}

            <Table>
                <TableHead>
                    <TableRow>
                        {!!rows.length &&
                            rows.map((r, i) => (
                                <TableCell key={i} align="center">
                                    {r.header}
                                </TableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading || !count ? (
                        <TableLoader data={data} loading={loading} noMoreData={noMoreData} />
                    ) : (
                        data.map((row, i) => (
                            <TableRow
                                key={i}
                                onDoubleClick={() => {
                                    if (typeof onDoubleClick === "function") onDoubleClick(row);
                                }}
                                style={typeof onDoubleClick === "function" ? { cursor: "pointer" } : {}}
                                hover
                                className="onHover"
                            >
                                {!!rows.length &&
                                    rows.map(r => (
                                        <TableCell align="center" key={r.header}>
                                            {r.body(row)}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </>
    );
};

export default SimpleTable;
