import React from "react";
import { NavLink, useHistory } from "react-router-dom";

// Mui
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";

// Firebase
import { auth } from "../../../firebase/firebase";

// Icons
import { MdPeople, MdPowerSettingsNew, MdQueryBuilder, MdFilePresent, MdCalendarToday, MdChatBubbleOutline, MdQueryStats } from "react-icons/md";

// Utils
import settings from "../../../settings.json";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

type DrawerItemProps = { to: string; onClick?: any; icon?: React.FC; primaryText: string; color?: string };

const DrawerItem: React.FC<DrawerItemProps> = (props: DrawerItemProps) => {
    return (
        <NavLink to={props.to} onClick={() => props.onClick ?? null} className="drawer__nav__item">
            <ListItem button>
                <ListItemIcon>{props.icon ? <props.icon /> : null}</ListItemIcon>
                <ListItemText primary={props.primaryText} color={props.color ?? "primary"} />
            </ListItem>
        </NavLink>
    );
};

const DrawerContent = () => {
    const { currentAdmin } = useFetchUser();
    const hist = useHistory();

    return (
        <div className="drawer__nav">
            <List>
                <ListSubheader inset>Collections</ListSubheader>

                <DrawerItem to="/dashboard/participants" icon={MdPeople} primaryText="Participants" />
                <DrawerItem to="/dashboard/admins" icon={MdPeople} primaryText="Admins" />
                <DrawerItem to="/dashboard/stats" icon={MdQueryStats} primaryText="Stats" />
                {currentAdmin && currentAdmin.roles && currentAdmin.roles.length > 0 && currentAdmin.roles.includes(settings.app.highestRole) && (
                    <DrawerItem to="/dashboard/logs" icon={MdQueryBuilder} primaryText="Logs" />
                )}
            </List>
            <Divider />
            <List>
                <ListSubheader inset>Settings</ListSubheader>
                <DrawerItem to="/dashboard/globalChat" icon={MdChatBubbleOutline} primaryText="Global chat" />
                <DrawerItem to="/dashboard/appointments" icon={MdCalendarToday} primaryText="Appointments" />
                <DrawerItem to="/dashboard/contents" icon={MdFilePresent} primaryText="Content" />
                <div
                    className="drawer__nav__item"
                    onClick={async () => {
                        hist.push("/dashboard/login");
                        await auth.signOut();
                    }}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <MdPowerSettingsNew />
                        </ListItemIcon>
                        <ListItemText primary="Logout" color="primary" />
                    </ListItem>
                </div>
            </List>
        </div>
    );
};

export default DrawerContent;
