import { FaFacebook } from "react-icons/fa";

import { Facebook } from "./interface";

const facebookConfig: Facebook = {
    title: "Facebook",
    Icon: FaFacebook,
};

export type { Facebook };
export default facebookConfig;
