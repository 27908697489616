import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, Popover, ListItemIcon, ListItemText, MenuItem } from "@mui/material";

// Icons
import { MdExitToApp } from "react-icons/md";

// Firebase
import { auth } from "../../firebase/firebase";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Assets
import avatar from "../../assets/images/profile.jpg";

// Components
import UserDialog from "../pages/dashboard/users/UserDialog";

const UserMenu: React.FC = () => {
    const { currentAdmin } = useFetchUser();

    const [userMenu, setUserMenu] = useState<any>(null);
    const [openProfile, setOpenProfile] = useState<boolean>(false);

    return (
        <div>
            <Button onClick={e => setUserMenu(e.currentTarget)}>
                <Avatar src={avatar} />
            </Button>

            <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={() => setUserMenu(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                classes={{
                    paper: "py-8",
                }}
            >
                <MenuItem
                    component={Link}
                    to="/dashboard/login"
                    onClick={() => {
                        setUserMenu(null);
                        auth.signOut();
                    }}
                    role="button"
                >
                    <ListItemIcon className="min-w-40">
                        <MdExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </MenuItem>
            </Popover>
            {openProfile && (
                <UserDialog open={openProfile} setOpen={setOpenProfile} userId={currentAdmin && currentAdmin.id ? currentAdmin.id : ""} />
            )}
        </div>
    );
};

export default UserMenu;
