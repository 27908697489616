export const qualityOfLife = {
    title: "Quality of life",
    about: "",
    instructions: "Please answer the 36 questions of the Quality of life Survey completely, honestly, and without interruptions.",
    questions: [
        {
            questionNumber: "1",
            question: "In general, would you say your health is:",
            answers: ["Excellent", "Very Good", "Good", "Fair", "Poor"],
            type: "radio",
        },
        {
            questionNumber: "2",
            question: "Compared to one year ago, how would you rate your health in general now?",
            answers: [
                "Much better now than one year ago",
                "Somewhat better now than one year ago",
                "About the same",
                "Somewhat worse now than one year ago",
                "Much worse than one year ago",
            ],
            type: "radio",
        },
        {
            questionNumber: "3",
            question: "Vigorous activities, such as running, lifting heavy objects, participating in strenuous sports.",
            answers: ["Yes, Limited a lot", "Yes, Limited a Little", "No, Not Limited at all"],
            type: "radio",
            instructions:
                "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        },
        {
            questionNumber: "4",
            question: "Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf",
            answers: ["Yes, Limited a lot", "Yes, Limited a Little", "No, Not Limited at all"],
            type: "radio",
            instructions:
                "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        },
        {
            questionNumber: "5",
            question: "Lifting or carrying groceries",
            answers: ["Yes, Limited a lot", "Yes, Limited a Little", "No, Not Limited at all"],
            type: "radio",
            instructions:
                "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        },
        {
            questionNumber: "6",
            question: "Climbing several flights of stairs",
            answers: ["Yes, Limited a lot", "Yes, Limited a Little", "No, Not Limited at all"],
            type: "radio",
            instructions:
                "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        },
        {
            questionNumber: "7",
            question: "Climbing one flight of stairs",
            answers: ["Yes, Limited a lot", "Yes, Limited a Little", "No, Not Limited at all"],
            type: "radio",
            instructions:
                "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        },
        {
            questionNumber: "8",
            question: "Bending, kneeling, or stooping",
            answers: ["Yes, Limited a lot", "Yes, Limited a Little", "No, Not Limited at all"],
            type: "radio",
            instructions:
                "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        },
        {
            questionNumber: "9",
            question: "Walking more than a mile",
            answers: ["Yes, Limited a lot", "Yes, Limited a Little", "No, Not Limited at all"],
            type: "radio",
            instructions:
                "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        },
        {
            questionNumber: "10",
            question: "Walking several blocks",
            answers: ["Yes, Limited a lot", "Yes, Limited a Little", "No, Not Limited at all"],
            type: "radio",
            instructions:
                "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        },
        {
            questionNumber: "11",
            question: "Walking one block",
            answers: ["Yes, Limited a lot", "Yes, Limited a Little", "No, Not Limited at all"],
            type: "radio",
            instructions:
                "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        },
        {
            questionNumber: "12",
            question: "Bathing or dressing yourself",
            answers: ["Yes, Limited a lot", "Yes, Limited a Little", "No, Not Limited at all"],
            type: "radio",
            instructions:
                "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        },
        {
            questionNumber: "13",
            question: "Cut down the amount of time you spent on work or other activities",
            answers: ["Yes", "No"],
            type: "radio",
            instructions:
                "PHYSICAL HEALTH PROBLEMS: During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?",
        },
        {
            questionNumber: "14",
            question: "Accomplished less than you would like",
            answers: ["Yes", "No"],
            type: "radio",
            instructions:
                "PHYSICAL HEALTH PROBLEMS: During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?",
        },
        {
            questionNumber: "15",
            question: "Were limited in the kind of work or other activities",
            answers: ["Yes", "No"],
            type: "radio",
            instructions:
                "PHYSICAL HEALTH PROBLEMS: During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?",
        },
        {
            questionNumber: "16",
            question: "Had difficulty performing the work or other activities (for example, it took extra effort)",
            answers: ["Yes", "No"],
            type: "radio",
            instructions:
                "PHYSICAL HEALTH PROBLEMS: During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?",
        },
        {
            questionNumber: "17",
            question: "Cut down the amount of time you spent on work or other activities",
            answers: ["Yes", "No"],
            type: "radio",
            instructions:
                "EMOTIONAL HEALTH PROBLEMS: During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of any emotional problems (such as feeling depressed or anxious)?",
        },
        {
            questionNumber: "18",
            question: "Accomplished less than you would like",
            answers: ["Yes", "No"],
            type: "radio",
            instructions:
                "EMOTIONAL HEALTH PROBLEMS: During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of any emotional problems (such as feeling depressed or anxious)?",
        },
        {
            questionNumber: "19",
            question: "Didn't do work or other activities as carefully as usual",
            answers: ["Yes", "No"],
            type: "radio",
            instructions:
                "EMOTIONAL HEALTH PROBLEMS: During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of any emotional problems (such as feeling depressed or anxious)?",
        },
        {
            questionNumber: "20",
            question:
                "During the past 4 weeks, to what extent has your physical health or emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?",
            answers: ["Not at all", "Slightly", "Moderately", "Quite a bit", "Extremely"],
            type: "radio",
            instructions: "SOCIAL ACTIVITIES:",
        },
        {
            questionNumber: "21",
            question: "How much bodily pain have you had during the past 4 weeks?",
            answers: ["None", "Very Mild", "Mild", "Moderate", "Severe", "Very Severe"],
            type: "radio",
            instructions: "PAIN:",
        },
        {
            questionNumber: "22",
            question:
                "During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the home and housework)?",
            answers: ["Not at all", "A little bit", "Moderately", "Quite a bit", "Extremely"],
            type: "radio",
            instructions: "PAIN:",
        },
        {
            questionNumber: "23",
            question: "Did you feel full of pep?",
            answers: [
                "All of the time",
                "Most of the time",
                "A good Bit of the Time",
                "Some of the time",
                "A little bit of the time",
                "None of the Time",
            ],
            type: "radio",
            instructions:
                "ENERGY AND EMOTIONS: These questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling.",
        },
        {
            questionNumber: "24",
            question: "Have you been a very nervous person?",
            answers: [
                "All of the time",
                "Most of the time",
                "A good Bit of the Time",
                "Some of the time",
                "A little bit of the time",
                "None of the Time",
            ],
            type: "radio",
            instructions:
                "ENERGY AND EMOTIONS: These questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling.",
        },
        {
            questionNumber: "25",
            question: "Have you felt so down in the dumps that nothing could cheer you up?",
            answers: [
                "All of the time",
                "Most of the time",
                "A good Bit of the Time",
                "Some of the time",
                "A little bit of the time",
                "None of the Time",
            ],
            type: "radio",
            instructions:
                "ENERGY AND EMOTIONS: These questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling.",
        },
        {
            questionNumber: "26",
            question: "Have you felt calm and peaceful?",
            answers: [
                "All of the time",
                "Most of the time",
                "A good Bit of the Time",
                "Some of the time",
                "A little bit of the time",
                "None of the Time",
            ],
            type: "radio",
            instructions:
                "ENERGY AND EMOTIONS: These questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling.",
        },
        {
            questionNumber: "27",
            question: "Did you have a lot of energy?",
            answers: [
                "All of the time",
                "Most of the time",
                "A good Bit of the Time",
                "Some of the time",
                "A little bit of the time",
                "None of the Time",
            ],
            type: "radio",
            instructions:
                "ENERGY AND EMOTIONS: These questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling.",
        },
        {
            questionNumber: "28",
            question: "Have you felt downhearted and blue?",
            answers: [
                "All of the time",
                "Most of the time",
                "A good Bit of the Time",
                "Some of the time",
                "A little bit of the time",
                "None of the Time",
            ],
            type: "radio",
            instructions:
                "ENERGY AND EMOTIONS: These questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling.",
        },
        {
            questionNumber: "29",
            question: "Did you feel worn out?",
            answers: [
                "All of the time",
                "Most of the time",
                "A good Bit of the Time",
                "Some of the time",
                "A little bit of the time",
                "None of the Time",
            ],
            type: "radio",
            instructions:
                "ENERGY AND EMOTIONS: These questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling.",
        },
        {
            questionNumber: "30",
            question: "Have you been a happy person?",
            answers: [
                "All of the time",
                "Most of the time",
                "A good Bit of the Time",
                "Some of the time",
                "A little bit of the time",
                "None of the Time",
            ],
            type: "radio",
            instructions:
                "ENERGY AND EMOTIONS: These questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling.",
        },
        {
            questionNumber: "31",
            question: "Did you feel tired?",
            answers: [
                "All of the time",
                "Most of the time",
                "A good Bit of the Time",
                "Some of the time",
                "A little bit of the time",
                "None of the Time",
            ],
            type: "radio",
            instructions:
                "ENERGY AND EMOTIONS: These questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling.",
        },
        {
            questionNumber: "32",
            question:
                "During the past 4 weeks, how much of the time has your physical health or emotional problems interfered with your social activities (like visiting with friends, relatives, etc.)?",
            answers: ["All of the time", "Most of the time", "Some of the time", "A little bit of the time", "None of the Time"],
            type: "radio",
            instructions: "SOCIAL ACTIVITIES:",
        },
        {
            questionNumber: "33",
            question: "I seem to get sick a little easier than other people",
            answers: ["Definitely true", "Mostly true", "Don't know", "Mostly false", "Definitely false"],
            type: "radio",
            instructions: "GENERAL HEALTH: How true or false is each of the following statements for you?",
        },
        {
            questionNumber: "34",
            question: "I am as healthy as anybody I know",
            answers: ["Definitely true", "Mostly true", "Don't know", "Mostly false", "Definitely false"],
            type: "radio",
            instructions: "GENERAL HEALTH: How true or false is each of the following statements for you?",
        },
        {
            questionNumber: "35",
            question: "I expect my health to get worse",
            answers: ["Definitely true", "Mostly true", "Don't know", "Mostly false", "Definitely false"],
            type: "radio",
            instructions: "GENERAL HEALTH: How true or false is each of the following statements for you?",
        },
        {
            questionNumber: "36",
            question: "My health is excellent",
            answers: ["Definitely true", "Mostly true", "Don't know", "Mostly false", "Definitely false"],
            type: "radio",
            instructions: "GENERAL HEALTH: How true or false is each of the following statements for you?",
        },
    ],
};
export const healthUtilityIndex = {
    title: "Health Utility Index",
    about: "",
    instructions:
        "This questionnaire contains a set of questions that ask about various aspects of your health. When answering these questions please think about your health and your ability to do things on a day-to-day basis, during the past week. To define the past week period, please think about what the date was 7 days ago and recall the major events that you have experienced during this period. Please focus your answers on your abilities, disabilities and how you have felt during the past week. You may feel that some of these questions do not apply to you, but it is important that we ask the same questions of everyone. Also, a few questions are similar; please excuse the apparent overlap and answer each question independently. Please read each question and consider your answers carefully. For each question, please select one answer that best describes your level of ability or disability during the past week.",
    questions: [
        {
            questionNumber: "1",
            question: "Which one of the following best describes your ability, during the past week, to see well enough to read ordinary newsprint?",
            answers: [
                "Able to see well enough without glasses or contact lenses",
                "Able to see well enough with glasses or contact lenses",
                "Unable to see well enough even with glasses or contact lenses",
                "Unable to see at all",
            ],
            type: "radio",
        },
        {
            questionNumber: "2",
            question:
                "Which one of the following best describes your ability, during the past week, to see well enough to recognize a friend on the other side of the street?",
            answers: [
                "Able to see well enough without glasses or contact lenses",
                "Able to see well enough with glasses or contact lenses",
                "Unable to see well enough even with glasses or contact lenses",
                "Unable to see at all",
            ],
            type: "radio",
        },
        {
            questionNumber: "3",
            question:
                "Which one of the following best describes your ability, during the past week, to hear what was said in a group conversation with at least three other people?",
            answers: [
                "Able to hear what was said without a hearing aid",
                "Able to hear what was said with a hearing aid",
                "Unable to hear what was said even with a hearing aid",
                "Unable to hear what was said, but did not wear a hearing aid",
                "Unable to hear at all",
            ],
            type: "radio",
        },
        {
            questionNumber: "4",
            question:
                "Which one of the following best describes your ability, during the past week, to hear what was said in a conversation with one other person in a quiet room?",
            answers: [
                "Able to hear what was said without a hearing aid",
                "Able to hear what was said with a hearing aid",
                "Unable to hear what was said even with a hearing aid",
                "Unable to hear what was said, but did not wear a hearing aid",
                "Unable to hear at all",
            ],
            type: "radio",
        },
        {
            questionNumber: "5",
            question:
                "Which one of the following best describes your ability, during the past week, to be understood when speaking your own language with people who do not know you?",
            answers: ["Able to be understood completely", "Able to be understood partially", "Unable to be understood", "Unable to speak at all"],
            type: "radio",
        },
        {
            questionNumber: "6",
            question:
                "Which one of the following best describes your ability, during the past week, to be understood when speaking with people who know you well?",
            answers: ["Able to be understood completely", "Able to be understood partially", "Unable to be understood", "Unable to speak at all"],
            type: "radio",
        },
        {
            questionNumber: "7",
            question: "Which one of the following best describes how you have been feeling during the past week?",
            answers: [
                "Happy and interested in life",
                "Somewhat happy",
                "Somewhat unhappy",
                "Very unhappy",
                "So unhappy that life was not worthwhile",
            ],
            type: "radio",
        },
        {
            questionNumber: "8",
            question: "Which one of the following best describes the pain and discomfort you have experienced during the past week?",
            answers: [
                "Free of pain and discomfort",
                "Mild to moderate pain or discomfort that prevented to activities",
                "Moderate pain or discomfort that prevented some activities",
                "Moderate to severe pain or discomfort that prevented some activities",
                "Severe pain or discomfort that prevented most activities",
            ],
            type: "radio",
        },
        {
            questionNumber: "9",
            question:
                "Which one of the following best describes your ability, during the past week, to walk? Note: Walking equipment refers to mechanical supports such as braces, a cane, crutches or a walker.",
            answers: [
                "Able to walk around the neighborhood without difficulty, and without walking equipment",
                "Able to walk around the neighborhood with difficultly but did not require walking equipment or the help of another person",
                "Able to walk around the neighborhood with walking equipment, but without the help of another person",
                "Able to walk only short distances with walking equipment, and required a wheelchair to get around the neighborhood",
                "Unable to walk alone, even with walking equipment. Able to walk short distances with the help of another person, and required a wheelchair to get around the neighbourhood",
                "Unable to walk at all",
            ],
            type: "radio",
        },
        {
            questionNumber: "10",
            question:
                "Which one of the following best describes your ability, during the past week, to use your hands and fingers? Note: Special tools refers to hooks for buttoning clothes, gripping devices for opening jars or lifting small items, and other devices to compensate for limitations of hands or fingers.",
            answers: [
                "Full use of two hands and ten fingers",
                "Limitations in the use of hands or fingers, but did not require special tools or the help of another person",
                "Limitations in the use of hands or fingers, independent with use of special tools (did not require the help of another person)",
                "Limitations in the use of hands or fingers, required the help of another person for some tasks (not independent event with use of special tools)",
                "Limitations in the use of hands or fingers, required the help of another person for all tasks (not independent even with use of special tools)",
            ],
            type: "radio",
        },
        {
            questionNumber: "11",
            question: "Which one of the following best describes your ability, during the past week, to remember things?",
            answers: ["Able to remember most things", "Somewhat forgetful", "Very forgetful", "Unable to remember anything at all"],
            type: "radio",
        },
        {
            questionNumber: "12",
            question: "Which one of the following best describes your ability, during the past week, to think and solve day to day problems?",
            answers: [
                "Able to think clearly and solve day to day problems",
                "Had a little difficulty when trying to think and solve day to day problems",
                "Had some difficulty when trying to think and solve day to day problems",
                "Had great difficulty when trying to think and solve day to day problems",
                "Unable to think or solve day to day problems",
            ],
            type: "radio",
        },
        {
            questionNumber: "13",
            question: "Which one of the following best describes your ability, during the past week, to perform basic activities?",
            answers: [
                "Eat, bathe, dress and use the toilet normally",
                "Eat, bathe, dress or use the toilet independently with difficulty",
                "Required mechanical equipment to eat, bathe, dress or use the toilet independently",
                "Required the help of another person to eat, bathe, dress or use the toilet",
            ],
            type: "radio",
        },
        {
            questionNumber: "14",
            question: "Which one of the following best describes how you have been feeling during the past week?",
            answers: [
                "Generally happy and free from worry",
                "Occasionally fretful, angry, irritable, anxious or depressed",
                "Often fretful, angry, irritable, anxious or depressed",
                "Almost always fretful, angry, irritable, anxious or depressed",
                "Extremely fretful, angry, irritable, anxious or depressed; to the point of needing professional help",
            ],
            type: "radio",
        },
        {
            questionNumber: "15",
            question: "Which one of the following best describes the pain or discomfort you have experienced during the past week?",
            answers: [
                "Free of pain and discomfort",
                "Occasional pain or discomfort. Discomfort relived by non-prescription drugs or self-control activity without disruption of normal activities",
                "Frequent pain or discomfort. Discomfort relieved by oral medicines with occasional disruption of normal activities",
                "Frequent pain or discomfort; frequent disruption of normal activities. Discomfort required prescription narcotics for relief",
                "Severe pain or discomfort. Pain not relieved by drugs and constantly disrupted normal activities",
            ],
            type: "radio",
        },
        {
            questionNumber: "16",
            question: "Overall, how would you rate your health during the past week?",
            answers: ["Excellent.", "Very good.", "Good.", "Fair.", "Poor."],
            type: "radio",
        },
        {
            questionNumber: "17",
            question: "How did you complete the questionnaire? Please select the one answer that best describes your situation.",
            answers: [
                "By myself, without any help from anyone else.",
                "By myself, except someone else circled the answers on the questionnaire form for me.",
                "With the help of someone else.",
                "This questionnaire was completed by a family member, without help from the subject or patient.",
                "This questionnaire was completed by a nurse or other health professional, without help from the subject or patient.",
                "This questionnaire was completed by another person, without help from the subject or patient.",
            ],
            type: "radio",
            skipTrigger: "This questionnaire was completed by another person, without help from the subject or patient.",
            skipTo: "19",
            endTrigger: [
                "By myself, without any help from anyone else.",
                "By myself, except someone else circled the answers on the questionnaire form for me.",
                "With the help of someone else.",
                "This questionnaire was completed by a family member, without help from the subject or patient.",
            ],
        },
        {
            questionNumber: "18",
            question: "Please specify type of health professional:",
            answers: [],
            type: "textfield",
            endTrigger: null,
        },
        {
            questionNumber: "19",
            question: "Please specify relationship to subject or patient:",
            answers: [],
            type: "textfield",
        },
    ],
};
export const healthBehaviorsQuestionnaire = {
    title: "Health Behaviors Questionnaire",
    about: "",
    instructions: "During the past month how would you:",
    questions: [
        {
            questionNumber: "1",
            question: "Rate your current level of physical activity from 0 (not active at all) to 10 (extremely active)",
            answers: ["Not active at all", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Extremely active"],
            type: "slider",
        },
        {
            questionNumber: "2",
            question:
                "Rate your confidence that you can have a physically active lifestyle, 30 minutes of physical activity most days of the week, from 0 (not at all confident) to 10 (extremely confident).",
            answers: ["Not at all confident", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Extremely confident"],
            type: "slider",
        },
        {
            questionNumber: "3",
            question: "Rate your nutritional habits from 0 (unhealthy) to 10 (very healthy).",
            answers: ["Unhealthy", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Very Healthy"],
            type: "slider",
        },
        {
            questionNumber: "4",
            question: "How confident are you that you can follow a healthy diet from 0 (not at all confident) to 10 (extremely confident).",
            answers: ["Not at all confident", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Extremely confident"],
            type: "slider",
        },
        {
            questionNumber: "5",
            question: "Rate your support for maintaining healthy living from 0 (poor) to 10 (excellent).",
            answers: ["Poor", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Excellent"],
            type: "slider",
        },
        {
            questionNumber: "6",
            question: "Rate your current stress level from 0 (very high) to 10 (very low).",
            answers: ["Very high", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Very low"],
            type: "slider",
        },
        {
            questionNumber: "7",
            question:
                "How confident are you in your ability to manage your current stress level from 0 (not at all confident) to 10 (extremely confident).",
            answers: ["Not at all confident", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Extremely confident"],
            type: "slider",
        },
        {
            questionNumber: "8",
            question: "After a typical night’s sleep, how often do you feel well rested, from 0 (never) to 10 (always).",
            answers: ["Never", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Always"],
            type: "slider",
        },
        {
            questionNumber: "9",
            question: "Rate your overall spiritual well-being from 0 (poor) to 10 (excellent).",
            answers: ["Poor", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Excellent"],
            type: "slider",
        },
        {
            questionNumber: "10",
            question: "Rate your overall quality of life, from 0 (poor) to 10 (excellent).",
            answers: ["Poor", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Excellent"],
            type: "slider",
        },
        {
            questionNumber: "11",
            question: "Rate your overall health from 0 (poor) to 10 (excellent).",
            answers: ["Poor", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Excellent"],
            type: "slider",
        },
    ],
};
