import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// Mui core
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Custom components
import TabPanel from "../../../general/TabPanel";

// Tabs
import Profile from "./tabs/Profile";
import Tab2 from "./tabs/Tab2";
import Tab3 from "./tabs/Tab3";

// Interfaces
import { AdminUser } from "../../../../interfaces/AdminUser";

// Icons
import { MdHome } from "react-icons/md";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";

const tabs: any = [
    {
        label: "Profile",
        icon: <MdHome />,
        component: Profile,
    },
    {
        label: "Tab2",
        icon: <MdHome />,
        component: Tab2,
    },
    {
        label: "Tab3",
        icon: <MdHome />,
        component: Tab3,
    },
];

interface paramsType {
    id: string;
    tabIndex: string;
}

const User: React.FC = () => {
    const { id, tabIndex } = useParams<paramsType>();
    const hist = useHistory();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const mobileScreenSize = 500;
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

    // States
    const [user, setUser] = useState<AdminUser | null>(null);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        const unsub = onSnapshot(doc(firestore, "Users", id), doc => {
            const data = doc.data() as AdminUser;

            setUser(data);
        });
        return () => unsub();
    }, []);

    useEffect(() => {
        setTab(parseInt(tabIndex));
    }, [tabIndex]);

    return (
        <div>
            <div className="tab__background__color">
                <Tabs variant="fullWidth" value={tab} onChange={(e, v) => hist.push(`/dashboard/admin/${id}/${v}`)}>
                    {tabs.map((t: any) => (
                        <Tab key={t.label} icon={t.icon} label={screenWidth >= mobileScreenSize ? t.label : ""} />
                    ))}
                </Tabs>
            </div>
            {tabs.map((t: any, i: number) => (
                <TabPanel key={i} value={tab} index={i}>
                    <t.component user={user} />
                </TabPanel>
            ))}
        </div>
    );
};

export default User;
