import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";

// React-dates
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DayPicker, CalendarDay } from "react-dates";

// Mui
import Tooltip from "@mui/material/Tooltip";

// Custom component
import FallsDialog from "../general/FallsDialog";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

interface Props {
    date: string;
    selectedDate: Moment | null;
    // eslint-disable-next-line
    setSelectedDate: (date: Moment | null) => void;
    events: any[];
    // eslint-disable-next-line
    setEvents: (events: any[]) => void;
}

const FallsCalendar: React.FC<Props> = ({ date, selectedDate, setSelectedDate, events, setEvents }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { currentParticipant } = useFetchUser();

    // States
    const [currentMonth, setCurrentMonth] = useState(-1);
    const [currentYear, setCurrentYear] = useState(-1);
    const [fallsDialogOpen, setFallsDialogOpen] = useState(false);

    useEffect(() => {
        if (date) {
            setSelectedDate(moment(new Date(parseInt(date))));
        }
    }, [date]);

    useEffect(() => {
        if (selectedDate) setFallsDialogOpen(true);
    }, [selectedDate]);

    useEffect(() => {
        if (!open) setSelectedDate(null);
    }, [open]);

    useEffect(() => {
        if (currentParticipant) {
            setEvents(currentParticipant.physical.falls);
        }
    }, [currentParticipant]);

    useEffect(() => {
        setCurrentMonth(moment(new Date()).month());
        setCurrentYear(moment(new Date()).year());
    }, []);

    return (
        <div className="portalLayout__title pe__daypicker">
            <div className="centered__parent">
                <h4 style={{ marginBottom: 20 }}>
                    {currentParticipant && currentParticipant.state === "control" ? "Falls calendar" : "Exercise & Falls calendar"}
                </h4>
            </div>
            <DayPicker
                numberOfMonths={1}
                hideKeyboardShortcutsPanel
                onDayClick={(date: any) => {
                    const localDate: Date = date.toDate();
                    const today = new Date(new Date().getUTCFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999);
                    const requestedDate = new Date(localDate.getUTCFullYear(), localDate.getMonth(), localDate.getDate(), 0, 0, 0, 0);

                    if (requestedDate > today) {
                        return enqueueSnackbar("Can't set fall into the future", { variant: "error" });
                    }
                    setSelectedDate(date);
                }}
                onPrevMonthClick={date => {
                    setCurrentMonth(date.month());
                    setCurrentYear(date.year());
                }}
                onNextMonthClick={date => {
                    setCurrentMonth(date.month());
                    setCurrentYear(date.year());
                }}
                disableNext={moment(new Date()).month() === currentMonth && moment(new Date()).year() === currentYear}
                renderCalendarInfo={() => {
                    return <div style={{ color: "black", padding: 5, fontSize: 15 }}></div>;
                }}
                renderCalendarDay={props => {
                    const { day } = props;
                    let { modifiers } = props;

                    if (!modifiers) modifiers = new Set<string>();

                    if (day?.format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY")) {
                        modifiers.add("selected-span");
                    }

                    return <CalendarDay {...props} modifiers={modifiers} />;
                }}
                daySize={50}
                renderDayContents={day => {
                    const today = new Date();
                    today.setHours(0, 0, 0);
                    const isToday = moment(today).format("DD/MM/YYYY") === day.format("DD/MM/YYYY");

                    // Handle event per day
                    const isDailyTaskCompleted = events.some(event => {
                        const formattedDay = day.format("DD/MM/YYYY");
                        const formattedEvent = moment(event.date.seconds * 1000).format("DD/MM/YYYY");

                        if (formattedDay === formattedEvent) return true;
                        return false;
                    });

                    if (isToday) {
                        return (
                            <Tooltip title="Today">
                                <span style={{ color: "lightGray", fontWeight: "bold", fontSize: 25 }}>{day.format("D")}</span>
                            </Tooltip>
                        );
                    } else if (isDailyTaskCompleted) {
                        return (
                            <Tooltip
                                title={`${
                                    events.filter(e => {
                                        const checkedDate = new Date(e.date.seconds * 1000);
                                        const currentDate = day.toDate();

                                        return checkedDate.getTime() === currentDate.getTime();
                                    }).length
                                } Fall report${
                                    events.filter(e => {
                                        const checkedDate = new Date(e.date.seconds * 1000);
                                        const currentDate = day.toDate();

                                        return checkedDate.getTime() === currentDate.getTime();
                                    }).length > 1
                                        ? "s"
                                        : ""
                                } already completed`}
                            >
                                <span style={{ color: "lawngreen", fontWeight: "bold", fontSize: 25 }}>{day.format("D")}</span>
                            </Tooltip>
                        );
                    } else {
                        if (day > moment(today))
                            return (
                                <Tooltip title="Future date">
                                    <span style={{ color: "lightGray", fontSize: 25 }}>{day.format("D")}</span>
                                </Tooltip>
                            );
                        else
                            return (
                                <Tooltip title="No fall report">
                                    <span style={{ color: "red", fontWeight: "bold", fontSize: 25 }}>{day.format("D")}</span>
                                </Tooltip>
                            );
                    }
                }}
            />
            {fallsDialogOpen && (
                <FallsDialog open={fallsDialogOpen} setOpen={setFallsDialogOpen} selectedDate={selectedDate} setEvents={setEvents} events={events} />
            )}
        </div>
    );
};

export default FallsCalendar;
