import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

// React-dates
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DayPicker, CalendarDay } from "react-dates";

// Firebase
import { firestore } from "../../firebase/firebase";
import { updateDoc, doc, addDoc, collection, getDoc, serverTimestamp } from "firebase/firestore";
import { genDoc } from "../../utils/firebase";

// Mui
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

// Interfaces
import { Stat } from "../../interfaces/Stat";
import { Participant } from "../../interfaces/Participant";

// Custom components
import Title from "./Title";
import FormGenerator from "../pages/portal/questionnaires/FormGenerator";
import { fallsCalendar } from "../pages/portal/questionnaires/data/PhysicalExerciseActivityData";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
    open: boolean;
    // eslint-disable-next-line
    setOpen(value: boolean): void;
    selectedDate: Moment | null;
    // eslint-disable-next-line
    setEvents(value: any): void;
    events: any[];
}

const FallsDialog: React.FC<Props> = ({ open, setOpen, selectedDate, setEvents, events }) => {
    const { participantId } = useParams<any>();

    const updateParticipant = async (events: any[]) => {
        if (participantId) {
            const payload = await getDoc(doc(firestore, "Participants", participantId));
            const part = genDoc<Participant>()(payload);

            updateDoc(doc(firestore, "Participants", participantId), {
                physical: { ...part.physical, falls: events },
            });
        }
    };

    const formatData = (data: any) => {
        const formattedData: any = {};

        data.forEach((d: any) => {
            Object.entries(d).map(e => {
                formattedData[e[0]] = e[1];
            });
        });

        return formattedData;
    };

    const getScore = (data: any, answers: number[]) => {
        let score: any = 0;
        Object.entries(formatData(data)).map(e => {
            const answerIndex = answers[parseInt((e[0] as string).replace("question", "")) + 1];

            if (e[0] === "question6") score += answerIndex - 1;
            return score;
        });

        return 0;
    };

    const handleComplete = async (data: any, answers: number[]) => {
        if (selectedDate) {
            if (participantId) {
                const localStat: Stat | any = {
                    createdAt: serverTimestamp() as any,
                    updatedAt: serverTimestamp() as any,
                    isDeleted: false,
                    participantId: participantId,
                    topic: "physical",
                    results: formatData(data),
                    score: getScore(data, answers),
                    answers,
                    questionnaireName: "exerciseFallsCalendar",
                    creationDate: serverTimestamp() as any,
                };
                const createPayload = await addDoc(collection(firestore, "Stats"), localStat);
                await updateDoc(doc(firestore, "Stats", createPayload.id), { id: createPayload.id });

                const newEvent = {
                    date: selectedDate.toDate(),
                    value: data,
                };
                await updateParticipant([...events, newEvent]);

                setEvents([...events, newEvent]);
            }
        }

        setOpen(false);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
            className={`fallsDialog`}
        >
            <DialogTitle>
                <Title>Admin Falls Calendar - {moment(selectedDate).format("DD/MM/YYYY")}</Title>
            </DialogTitle>
            <DialogContent>
                <FormGenerator formData={fallsCalendar} onComplete={handleComplete} setOpen={setOpen} participantId={participantId} />
            </DialogContent>
        </Dialog>
    );
};

const AdminFallsCalendar: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { participantId } = useParams<any>();

    // States
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [events, setEvents] = useState<any[]>([]);
    const [currentMonth, setCurrentMonth] = useState(-1);
    const [currentYear, setCurrentYear] = useState(-1);
    const [fallsDialogOpen, setFallsDialogOpen] = useState(false);
    const [participant, setParticipant] = useState<Participant | undefined>(undefined);

    const fetchParticipant = async () => {
        try {
            const payload = await getDoc(doc(firestore, "Participants", participantId));
            setParticipant(genDoc<Participant>()(payload));
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (participant) {
            setEvents(participant.physical.falls);
        }
    }, [participant]);

    useEffect(() => {
        if (selectedDate) setFallsDialogOpen(true);
    }, [selectedDate]);

    useEffect(() => {
        if (participantId) fetchParticipant();
    }, [participantId]);

    return (
        <>
            <div className="portalLayout__title pe__daypicker">
                <div className="centered__parent">
                    <h4 style={{ marginBottom: 20, color: "black" }}>Admin Falls Calendar</h4>
                </div>
                <DayPicker
                    numberOfMonths={1}
                    hideKeyboardShortcutsPanel
                    onDayClick={(date: any) => {
                        const localDate: Date = date.toDate();
                        const today = new Date(new Date().getUTCFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999);
                        const requestedDate = new Date(localDate.getUTCFullYear(), localDate.getMonth(), localDate.getDate(), 0, 0, 0, 0);

                        if (requestedDate > today) {
                            return enqueueSnackbar("Can't set fall into the future", { variant: "error" });
                        }
                        setSelectedDate(date);
                    }}
                    onPrevMonthClick={date => {
                        setCurrentMonth(date.month());
                        setCurrentYear(date.year());
                    }}
                    onNextMonthClick={date => {
                        setCurrentMonth(date.month());
                        setCurrentYear(date.year());
                    }}
                    disableNext={moment(new Date()).month() === currentMonth && moment(new Date()).year() === currentYear}
                    renderCalendarInfo={() => {
                        return <div style={{ color: "black", padding: 5, fontSize: 15 }}></div>;
                    }}
                    renderCalendarDay={props => {
                        const { day } = props;
                        let { modifiers } = props;

                        if (!modifiers) modifiers = new Set<string>();

                        if (day?.format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY")) {
                            modifiers.add("selected-span");
                        }

                        return <CalendarDay {...props} modifiers={modifiers} />;
                    }}
                    daySize={50}
                    renderDayContents={day => {
                        const today = new Date();
                        today.setHours(0, 0, 0);
                        const isToday = moment(today).format("DD/MM/YYYY") === day.format("DD/MM/YYYY");

                        // Handle event per day
                        const isDailyTaskCompleted = events.some(event => {
                            const formattedDay = day.format("DD/MM/YYYY");
                            const formattedEvent = moment(event.date.seconds * 1000).format("DD/MM/YYYY");

                            if (formattedDay === formattedEvent) return true;
                            return false;
                        });

                        if (isToday) {
                            return (
                                <Tooltip title="Today">
                                    <span style={{ color: "lightGray", fontWeight: "bold", fontSize: 25 }}>{day.format("D")}</span>
                                </Tooltip>
                            );
                        } else if (isDailyTaskCompleted) {
                            return (
                                <Tooltip
                                    title={`${
                                        events.filter(e => {
                                            const checkedDate = new Date(e.date.seconds * 1000);
                                            const currentDate = day.toDate();

                                            return checkedDate.getTime() === currentDate.getTime();
                                        }).length
                                    } Fall report${
                                        events.filter(e => {
                                            const checkedDate = new Date(e.date.seconds * 1000);
                                            const currentDate = day.toDate();

                                            return checkedDate.getTime() === currentDate.getTime();
                                        }).length > 1
                                            ? "s"
                                            : ""
                                    } already completed`}
                                >
                                    <span style={{ color: "lawngreen", fontWeight: "bold", fontSize: 25 }}>{day.format("D")}</span>
                                </Tooltip>
                            );
                        } else {
                            if (day > moment(today))
                                return (
                                    <Tooltip title="Future date">
                                        <span style={{ color: "lightGray", fontSize: 25 }}>{day.format("D")}</span>
                                    </Tooltip>
                                );
                            else
                                return (
                                    <Tooltip title="No fall report">
                                        <span style={{ color: "red", fontWeight: "bold", fontSize: 25 }}>{day.format("D")}</span>
                                    </Tooltip>
                                );
                        }
                    }}
                />
                {fallsDialogOpen && (
                    <FallsDialog
                        open={fallsDialogOpen}
                        setOpen={setFallsDialogOpen}
                        selectedDate={selectedDate}
                        setEvents={setEvents}
                        events={events}
                    />
                )}
            </div>
        </>
    );
};

export default AdminFallsCalendar;
