import React, { useEffect } from "react";
import { useSnackbar } from "notistack";

// Mui core
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";

// Interfaces
import { Participant } from "../../../../../../interfaces/Participant";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Hooks
import { useFetchUser } from "../../../../../../hooks/useFetchUser";

// Firebase
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../../../firebase/firebase";

// yup validation
const requiredMessage = "Required field";

const userSchema = yup.object({
    physical: yup.number().required(requiredMessage),
    cognitive: yup.number().required(requiredMessage),
    diet: yup.number().required(requiredMessage),
    health: yup.number().required(requiredMessage),
    sleep: yup.number().required(requiredMessage),
});

interface Props {
    participant: Participant | null;
    primaryIndex: string;
    secondaryIndex: string;
}

const Progresses: React.FC<Props> = ({ participant = null }) => {
    const { currentAdmin, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    const defaultValues = {
        physical: 50,
        cognitive: 50,
        diet: 50,
        health: 50,
        sleep: 50,
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        getValues,
    } = useForm({ resolver: yupResolver(userSchema), defaultValues });

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);

            if (participant && participant.id) {
                await updateDoc(doc(firestore, "Participants", participant.id), {
                    ...participant,
                    progresses: { physical: data.physical, cognitive: data.cognitive, diet: data.diet, health: data.health, sleep: data.sleep },
                });

                const logPayload = await addDoc(collection(firestore, "Logs"), {
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    isDeleted: false,
                    adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
                    reason: `Updated participant ${data && (data as any).email ? (data as any).email : (data as any).id}`,
                });

                await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });

                enqueueSnackbar("Updated participant", { variant: "success" });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (participant) {
            setValue("physical", participant.progresses.physical);
            setValue("cognitive", participant.progresses.cognitive);
            setValue("diet", participant.progresses.diet);
            setValue("health", participant.progresses.health);
            setValue("sleep", participant.progresses.sleep);
        }
    }, [participant]);

    return (
        <div>
            {/* <Title>{participant ? participant?.firstName : "Participant"}</Title> */}
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    control={control}
                    name="physical"
                    render={({ field }) => (
                        <FormControl component="fieldset" error={!!errors.physical?.message} {...field} className="forms__fullWidth">
                            <FormLabel component="legend">Physical Exercise</FormLabel>
                            <div className="forms__slider">
                                <Slider
                                    defaultValue={50}
                                    step={1}
                                    max={100}
                                    valueLabelDisplay="auto"
                                    color={getValues("physical") < 50 ? "secondary" : "primary"}
                                    {...field}
                                />
                            </div>
                            <FormHelperText style={{ fontSize: 20 }}>{errors.physical?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
                <Controller
                    control={control}
                    name="cognitive"
                    render={({ field }) => (
                        <FormControl component="fieldset" error={!!errors.cognitive?.message} {...field} className="forms__fullWidth">
                            <FormLabel component="legend">Cognitive Training</FormLabel>
                            <div className="forms__slider">
                                <Slider
                                    defaultValue={50}
                                    step={1}
                                    max={100}
                                    valueLabelDisplay="auto"
                                    color={getValues("cognitive") < 50 ? "secondary" : "primary"}
                                    {...field}
                                />
                            </div>
                            <FormHelperText style={{ fontSize: 20 }}>{errors.cognitive?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
                <Controller
                    control={control}
                    name="diet"
                    render={({ field }) => (
                        <FormControl component="fieldset" error={!!errors.diet?.message} {...field} className="forms__fullWidth">
                            <FormLabel component="legend">Diet</FormLabel>
                            <div className="forms__slider">
                                <Slider
                                    defaultValue={50}
                                    step={1}
                                    max={100}
                                    valueLabelDisplay="auto"
                                    color={getValues("diet") < 50 ? "secondary" : "primary"}
                                    {...field}
                                />
                            </div>
                            <FormHelperText style={{ fontSize: 20 }}>{errors.diet?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
                <Controller
                    control={control}
                    name="health"
                    render={({ field }) => (
                        <FormControl component="fieldset" error={!!errors.health?.message} {...field} className="forms__fullWidth">
                            <FormLabel component="legend">Health</FormLabel>
                            <div className="forms__slider">
                                <Slider
                                    defaultValue={50}
                                    step={1}
                                    max={100}
                                    valueLabelDisplay="auto"
                                    color={getValues("health") < 50 ? "secondary" : "primary"}
                                    {...field}
                                />
                            </div>
                            <FormHelperText style={{ fontSize: 20 }}>{errors.health?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
                <Controller
                    control={control}
                    name="sleep"
                    render={({ field }) => (
                        <FormControl component="fieldset" error={!!errors.sleep?.message} {...field} className="forms__fullWidth">
                            <FormLabel component="legend">Sleep</FormLabel>
                            <div className="forms__slider">
                                <Slider defaultValue={50} step={1} max={100} valueLabelDisplay="auto" {...field} />
                            </div>
                            <FormHelperText style={{ fontSize: 20 }}>{errors.sleep?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
                <div style={{ display: "flex" }}>
                    <Button variant="contained" type="submit" color="primary" style={{ marginLeft: "auto", marginTop: 20 }}>
                        Update
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Progresses;
