import { useEffect, useState } from "react";
import moment, { Moment } from "moment";

// Interfaces
import { Stat } from "../../../../../../interfaces/Stat";
import { Participant } from "../../../../../../interfaces/Participant";

// Mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";

// Utils
import { convertObjectIntoDate } from "../../../../../../utils/date";

// Assets
import { ImFilePdf, ImFileExcel, ImCalendar } from "react-icons/im";

// Utils
import { createPdf, fetchQuestion } from "../../../../../../utils/pdf";
import { exportAOADocument } from "../../../../../../utils/xlsx";
import { objectsEqual } from "../../../../../../utils/general";

// Firebase
import { firestore } from "../../../../../../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";

const getFallDate = (participant: Participant, stat: Stat) => {
    return participant.physical.falls.find((fall: any) => {
        let localResults: any = [];
        let sortedFall: any = [];
        let isEqual = true;

        Object.entries(stat.results).forEach(x => {
            localResults.push({ [x[0]]: x[1] });
        });

        localResults = localResults.sort((a: any, b: any) => (Object.entries(a)[0] < Object.entries(b)[0] ? -1 : 1));
        sortedFall = fall.value.sort((a: any, b: any) => (Object.entries(a)[0] < Object.entries(b)[0] ? -1 : 1));

        if (localResults.length !== sortedFall.length) return undefined;

        for (let i = 0; i < localResults.length; i++) {
            if (!objectsEqual(localResults[i], sortedFall[i])) isEqual = false;
        }

        return isEqual;
    });
};

const formatData = (stat: Stat, participant: Participant) => {
    const data: any[] = [];

    if (stat.questionnaireName === "fallsCalendar" || stat.questionnaireName === "exerciseFallsCalendar") {
        data.push(["Completed Date", "Sleep Date", "Question", "Answer"]);
    } else {
        data.push(["Date", "Question", "Answer"]);
    }

    Object.entries(stat.results)
        .sort((a: any, b: any) => {
            const questionNumberA = a[0].replace("question", "");
            const questionNumberB = b[0].replace("question", "");
            // question number sort
            if (parseInt(a[0].replace("question", "")) > parseInt(b[0].replace("question", ""))) {
                const aLetter = (a[0] as string).replace(`question${questionNumberA}`, "");
                const bLetter = (b[0] as string).replace(`question${questionNumberB}`, "");
                // subquestion letter sort
                if (aLetter && bLetter) return aLetter > bLetter ? 1 : -1;
            }
            return parseInt(a[0].replace("question", "")) > parseInt(b[0].replace("question", "")) ? 1 : -1;
        })
        .filter(e => {
            // Filtering out empty fields
            if (stat.questionnaireName === "healthResourceUtilization") {
                const dataObject: any = {};
                dataObject[e[0]] = e[1];

                // Skip empty fields
                if ((!moment(e[1] as any).isValid() || (moment(e[1] as any).isValid() && typeof e[1] === "number")) && (e[1] || e[1] === 0)) {
                    return dataObject;
                }
                // Check if date is needed
                else if (moment(e[1] as any).isValid()) {
                    if (data[(e[0] as any).replace(e[0].charAt(9), "a")]) {
                        return dataObject;
                    }
                }
            }
            return e;
        })
        .map((e: any) => {
            // Question
            const questionText = `${fetchQuestion(stat.questionnaireName, e[0].replace("question", ""))}`;

            // Answer
            let answerText = e[1];

            if (Array.isArray(e[1])) {
                answerText = answerText.join(", ");
            }

            if (stat.createdAt) {
                if (stat.questionnaireName === "fallsCalendar" || stat.questionnaireName === "exerciseFallsCalendar") {
                    const fallDate = getFallDate(participant, stat);
                    if (fallDate) {
                        data.push([
                            moment(new Date(stat.createdAt)).format("DD-MM-YYYY"),
                            moment(new Date((fallDate.date as any).seconds * 1000)).format("DD-MM-YYYY"),
                            questionText,
                            answerText,
                        ]);
                    }
                } else {
                    data.push([moment(new Date(stat.createdAt)).format("DD-MM-YYYY"), questionText, answerText]);
                }
            }
        });

    return data;
};

const exportToXLSX = async (stat: Stat, participant: Participant) => {
    const fileName = `stats_${stat.questionnaireName}_${moment(new Date()).format("DD-MM-YYYY")}`;
    const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
    exportAOADocument(formatData(stat, participant), fileName, sheetName);
};

const downloadPdf = async (stat: Stat) => {
    // healthResourceUtilization
    delete stat.results.noVisitHospitalProfessional;
    delete stat.results.noVisitHospitalDepartment;
    delete stat.results.noTestsInvestigations;

    await createPdf(stat);
};

const downloadExcel = async (stat: Stat, participant: Participant) => {
    await exportToXLSX(stat, participant);
};

const StatsTableRows = (participant: Participant) => {
    //
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState<Moment | null>(moment(new Date()));
    const [stat, setStat] = useState<Stat | undefined>(undefined);

    const changeStatDate = async () => {
        try {
            if (stat && stat.id && date) {
                await updateDoc(doc(firestore, "Stats", stat.id), {
                    createdAt: date.toDate(),
                    creationDate: (stat as any).creationDate ?? stat.createdAt,
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (stat) setOpen(true);
    }, [stat]);

    return [
        {
            header: "Date",
            body: (s: Stat) => {
                const date = convertObjectIntoDate(s.createdAt);

                if (date) return moment(date).format("YYYY-MM-DD hh:mm:ss");

                return "Invalid Date";
            },
        },
        {
            header: "Questionnaire Name",
            body: (s: Stat) => s.questionnaireName,
        },
        {
            header: "Score",
            body: (s: Stat) => {
                if (typeof s.score === "number" && !isNaN(s.score)) return s.score;
                else if (typeof s.score === "object") {
                    if (s.questionnaireName === "eatingPattern") {
                        return `raw: ${s.score.rawScore}, brainHealthyEatingIndexScore: ${s.score.brainHealthyEatingIndexScore}`;
                    }
                    if (s.questionnaireName === "qualityOfLife") {
                        return (
                            <div>
                                <p style={{ color: "black" }}>Physical functioning: {Math.round(s.score.physicalFunctioning)}% </p>
                                <p style={{ color: "black" }}>
                                    Role limitations due to physical health: {Math.round(s.score.roleLimitationsDueToPhysicalHealth)}%
                                </p>
                                <p style={{ color: "black" }}>
                                    Role limitations due to emotional problems: {Math.round(s.score.roleLimitationsDueToEmotionalProblems)}%
                                </p>
                                <p style={{ color: "black" }}>Vitality, energy and fatigue: {Math.round(s.score.vitalityEnergyAndFatigue)}%</p>
                                <p style={{ color: "black" }}>General Mental Health: {Math.round(s.score.generalMentalHealth)}%</p>
                                <p style={{ color: "black" }}>Social functioning: {Math.round(s.score.socialFunctioning)}%</p>
                                <p style={{ color: "black" }}>Bodily Pain: {Math.round(s.score.bodilyPain)}%</p>
                                <p style={{ color: "black" }}>General Health Perceptions: {Math.round(s.score.generalHealthPerceptions)}%</p>
                                <p style={{ color: "black" }}>Health changes: {Math.round(s.score.healthChange)}%</p>
                                <p style={{ color: "black" }}>Total: {Math.round(s.score.total)}%</p>
                            </div>
                        );
                    }
                } else return 0;
            },
        },
        {
            header: "Topic",
            body: (s: Stat) => s.topic,
        },
        {
            body: (s: Stat) => {
                return (
                    <>
                        <ImFilePdf size={30} onClick={() => downloadPdf(s)} style={{ marginRight: 10 }} />
                        <ImFileExcel size={30} onClick={() => downloadExcel(s, participant)} style={{ marginRight: 10 }} />
                        <Tooltip title="Change date" placement="top">
                            <ImCalendar
                                size={30}
                                onClick={() => {
                                    console.log("id", s.id);
                                    setStat(s);
                                }}
                            />
                        </Tooltip>

                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Change stat's date
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    <div style={{ marginTop: 10 }}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                openTo="year"
                                                disableFuture
                                                views={["day"]}
                                                label="Custom date"
                                                inputFormat="YYYY/MM/DD"
                                                value={date}
                                                onChange={value => setDate(value)}
                                                renderInput={(params: any) => (
                                                    <TextField fullWidth variant="outlined" required style={{ marginBottom: 10 }} {...params} />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" onClick={() => setOpen(false)}>
                                    Close
                                </Button>
                                <Button variant="contained" onClick={changeStatDate} autoFocus>
                                    Change
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                );
            },
        },
    ];
};

export default StatsTableRows;
