import React, { useEffect, useState } from "react";
import _ from "lodash";

// Mui
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";

// yup validation
const requiredMessage = "Required field";

interface Props {
    onComplete: any;
}

const RECURRENCES = 7;

const HealthResourceUtilization: React.FC<Props> = ({ onComplete }) => {
    // States
    const [question1Disabled, setQuestion1Disabled] = useState("");
    const [question2Disabled, setQuestion2Disabled] = useState("");
    const [question3Disabled, setQuestion3Disabled] = useState("");

    const noTherapyObject: any = {};
    const noProcedureObject: any = {};

    // prevent scroll in number input
    document.addEventListener("wheel", function () {
        if (document && document.activeElement && document.activeElement && (document.activeElement as any).type === "number") {
            (document.activeElement as any).blur();
        }
    });

    const getSchema = () => {
        const schema: any = {};

        [...Array(RECURRENCES)].map((x, i) => {
            schema.noVisitHospitalProfessional = yup.string();
            if (!question1Disabled) {
                schema[`question1a${i + 1}`] = yup.string();
                schema[`question1b${i + 1}`] = yup.date();
                schema[`question1c${i + 1}`] = yup.string();
                schema[`question1d${i + 1}`] = yup.string();
                schema[`question1e${i + 1}`] = yup.string();
                schema[`question1f${i + 1}`] = yup.string();
                schema[`question1g${i + 1}`] = yup.string();
                if (!noTherapyObject[`noTherapy${i + 1}`]) schema[`question1h${i + 1}`] = yup.string();
                if (!noProcedureObject[`noProcedure${i + 1}`]) schema[`question1i${i + 1}`] = yup.string();
            }

            schema.noVisitHospitalDepartment = yup.string();
            if (!question2Disabled) {
                schema[`question2a${i + 1}`] = yup.string();
                schema[`question2b${i + 1}`] = yup.date();
                schema[`question2c${i + 1}`] = yup.string();
                schema[`question2d${i + 1}`] = yup.string();
                schema[`question2e${i + 1}`] = yup.string();
                schema[`question2f${i + 1}`] = yup.string();
                schema[`question2g${i + 1}`] = yup.string();
                schema[`question2h${i + 1}`] = yup.string();
                schema[`question2i${i + 1}`] = yup.string();
            }

            schema.noTestsInvestigations = yup.string();
            if (!question3Disabled) {
                schema[`question3a${i + 1}`] = yup.string();
                schema[`question3b${i + 1}`] = yup.date();
                schema[`question3c${i + 1}`] = yup.string();
                schema[`question3d${i + 1}`] = yup.string();
                schema[`question3e${i + 1}`] = yup.string();
                schema[`question3f${i + 1}`] = yup.string();
                schema[`question3g${i + 1}`] = yup.string();
            }
        });

        schema.question4a = yup.string().required(requiredMessage);
        schema.question4b = yup.string().required(requiredMessage);
        schema.question4c = yup.string().required(requiredMessage);

        schema.question5a1 = yup.string().required(requiredMessage);
        schema.question5a2 = yup.string().required(requiredMessage);
        schema.question5a3 = yup.string().required(requiredMessage);
        schema.question5b1 = yup.string().required(requiredMessage);
        schema.question5b2 = yup.string().required(requiredMessage);
        schema.question5b3 = yup.string().required(requiredMessage);
        schema.question5c1 = yup.string().required(requiredMessage);
        schema.question5c2 = yup.string().required(requiredMessage);
        schema.question5c3 = yup.string().required(requiredMessage);
        schema.question5d1 = yup.string().required(requiredMessage);
        schema.question5d2 = yup.string().required(requiredMessage);
        schema.question5d3 = yup.string().required(requiredMessage);
        schema.question5e1 = yup.string().required(requiredMessage);
        schema.question5e2 = yup.string().required(requiredMessage);
        schema.question5e3 = yup.string().required(requiredMessage);
        schema.question5f1 = yup.string().required(requiredMessage);
        schema.question5f2 = yup.string().required(requiredMessage);
        schema.question5f3 = yup.string().required(requiredMessage);
        schema.question5g1 = yup.string().required(requiredMessage);
        schema.question5g2 = yup.string().required(requiredMessage);
        schema.question5g3 = yup.string().required(requiredMessage);
        schema.question5h1 = yup.string().required(requiredMessage);
        schema.question5h2 = yup.string().required(requiredMessage);
        schema.question5h3 = yup.string().required(requiredMessage);

        return yup.object(schema);
    };

    const getDefaultValues = () => {
        const defaultValues: any = {};

        [...Array(RECURRENCES)].map((x, i) => {
            defaultValues.noVisitHospitalProfessional = "";
            if (!question1Disabled) {
                defaultValues[`question1a${i + 1}`] = "";
                defaultValues[`question1b${i + 1}`] = new Date();
                defaultValues[`question1c${i + 1}`] = "";
                defaultValues[`question1d${i + 1}`] = "";
                defaultValues[`question1e${i + 1}`] = "";
                defaultValues[`question1f${i + 1}`] = "";
                defaultValues[`question1g${i + 1}`] = "";
                if (!noTherapyObject[`noTherapy${i + 1}`]) defaultValues[`question1h${i + 1}`] = "";
                if (!noProcedureObject[`noProcedure${i + 1}`]) defaultValues[`question1i${i + 1}`] = "";
            }

            defaultValues.noVisitHospitalDepartment = "";
            if (!question2Disabled) {
                defaultValues[`question2a${i + 1}`] = "";
                defaultValues[`question2b${i + 1}`] = new Date();
                defaultValues[`question2c${i + 1}`] = "";
                defaultValues[`question2d${i + 1}`] = "";
                defaultValues[`question2e${i + 1}`] = "";
                defaultValues[`question2f${i + 1}`] = "";
                defaultValues[`question2g${i + 1}`] = "";
                defaultValues[`question2h${i + 1}`] = "";
                defaultValues[`question2i${i + 1}`] = "";
            }

            defaultValues.noTestsInvestigations = "";
            if (!question3Disabled) {
                defaultValues[`question3a${i + 1}`] = "";
                defaultValues[`question3b${i + 1}`] = new Date();
                defaultValues[`question3c${i + 1}`] = "";
                defaultValues[`question3d${i + 1}`] = "";
                defaultValues[`question3e${i + 1}`] = "";
                defaultValues[`question3f${i + 1}`] = "";
                defaultValues[`question3g${i + 1}`] = "";
            }
        });

        defaultValues.question4a = "";
        defaultValues.question4b = "";
        defaultValues.question4c = "";

        defaultValues.question5a1 = "";
        defaultValues.question5a2 = "";
        defaultValues.question5a3 = "";
        defaultValues.question5b1 = "";
        defaultValues.question5b2 = "";
        defaultValues.question5b3 = "";
        defaultValues.question5c1 = "";
        defaultValues.question5c2 = "";
        defaultValues.question5c3 = "";
        defaultValues.question5d1 = "";
        defaultValues.question5d2 = "";
        defaultValues.question5d3 = "";
        defaultValues.question5e1 = "";
        defaultValues.question5e2 = "";
        defaultValues.question5e3 = "";
        defaultValues.question5f1 = "";
        defaultValues.question5f2 = "";
        defaultValues.question5f3 = "";
        defaultValues.question5g1 = "";
        defaultValues.question5g2 = "";
        defaultValues.question5g3 = "";
        defaultValues.question5h1 = "";
        defaultValues.question5h2 = "";
        defaultValues.question5h3 = "";

        return defaultValues;
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        watch,
        setValue,
    } = useForm({ resolver: yupResolver(getSchema()), defaultValues: getDefaultValues() });

    noTherapyObject.noTherapy1 = watch("question1f1");
    noTherapyObject.noTherapy2 = watch("question1f2");
    noTherapyObject.noTherapy3 = watch("question1f3");
    noTherapyObject.noTherapy4 = watch("question1f4");
    noTherapyObject.noTherapy5 = watch("question1f5");
    noTherapyObject.noTherapy6 = watch("question1f6");
    noTherapyObject.noTherapy7 = watch("question1f7");

    noProcedureObject.noProcedure1 = watch("question1g1");
    noProcedureObject.noProcedure2 = watch("question1g2");
    noProcedureObject.noProcedure3 = watch("question1g3");
    noProcedureObject.noProcedure4 = watch("question1g4");
    noProcedureObject.noProcedure5 = watch("question1g5");
    noProcedureObject.noProcedure6 = watch("question1g6");
    noProcedureObject.noProcedure7 = watch("question1g7");

    const question5a1 = watch("question5a1");
    const question5b1 = watch("question5b1");
    const question5c1 = watch("question5c1");
    const question5d1 = watch("question5d1");
    const question5e1 = watch("question5e1");
    const question5f1 = watch("question5f1");
    const question5g1 = watch("question5g1");
    const question5h1 = watch("question5h1");

    const onSubmit = async (data: any) => {
        const dataArray: any[] = [];

        Object.entries(data).forEach(e => {
            const dataObject: any = {};

            // Remove question5 unwanted elements
            if (
                (question5a1 === "No" && (e[0] === "question5a2" || e[0] === "question5a3")) ||
                (question5b1 === "No" && (e[0] === "question5b2" || e[0] === "question5b3")) ||
                (question5c1 === "No" && (e[0] === "question5c2" || e[0] === "question5c3")) ||
                (question5d1 === "No" && (e[0] === "question5d2" || e[0] === "question5d3")) ||
                (question5e1 === "No" && (e[0] === "question5e2" || e[0] === "question5e3")) ||
                (question5f1 === "No" && (e[0] === "question5f2" || e[0] === "question5f3")) ||
                (question5g1 === "No" && (e[0] === "question5g2" || e[0] === "question5g3")) ||
                (question5h1 === "No" && (e[0] === "question5h2" || e[0] === "question5h3"))
            ) {
                // not needed
            } else {
                dataObject[e[0]] = e[1];
            }

            // Skip empty fields
            if ((!moment(e[1] as any).isValid() || (moment(e[1] as any).isValid() && typeof e[1] === "number")) && (e[1] || e[1] === 0)) {
                dataArray.push(dataObject);
            }
            // Check if date is needed
            else if (moment(e[1] as any).isValid()) {
                if (data[(e[0] as any).replace(e[0].charAt(9), "a")]) {
                    dataArray.push(dataObject);
                }
            }
        });

        onComplete(dataArray, []);
    };

    const getFontClass = () => {
        const fontObj = localStorage.getItem("font");
        if (fontObj) return fontObj;
        return "";
    };

    const getColorClass = () => {
        const themeObj = localStorage.getItem("theme");
        if (themeObj) return themeObj;
        return "";
    };

    useEffect(() => {
        if (!_.isEmpty(errors)) {
            const formattedErrors = Object.entries(errors).map(e => {
                return { field: e[0], message: e[1].message };
            });

            console.error("errors with the form", formattedErrors);
        }
    }, [errors]);

    return (
        <div className={`fg ${getFontClass()} ${getColorClass()}`}>
            <h2>Health Resource Utilization</h2>

            <h5 style={{ paddingBottom: 20, paddingTop: 20 }}>
                This questionnaire asks about your usage of the health care system in the LAST THREE MONTHS. We want to know what health care
                professionals you have seen and what costs, if any, you may have had, We know there are costs related to your health that are often
                hidden or not documented.
            </h5>

            <hr style={{ marginTop: 50, marginBottom: 30, height: 2, backgroundColor: "white" }} />

            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                    <p style={{ fontSize: 22 }}>1. Office visits to health professionals (or any type) in the LAST THREE MONTHS:</p>
                    <p style={{ textAlign: "justify", fontStyle: "italic" }}>
                        Some of the people you may have seen include your family physician, medical specialists, physiotherapists, chiropractors,
                        acupuncturists, nurse practitioners, dentists, etc. If you have visited any health professionals more than once, please tell
                        me about each visit separately. If your visit was related to a fall please check the fall related check box.
                    </p>
                </div>

                <div>
                    <Controller
                        control={control}
                        name="noVisitHospitalProfessional"
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Check here if no visits to any health professional in the LAST THREE MONTHS (skip to question 2)"
                                labelPlacement="start"
                                style={{ marginLeft: 0 }}
                                {...field}
                                onChange={e => {
                                    field.onChange(e);
                                    setQuestion1Disabled(getValues("noVisitHospitalProfessional"));
                                }}
                            />
                        )}
                    />
                </div>

                {/* Question #1 */}
                {[...Array(RECURRENCES)].map((x, i) => {
                    return (
                        <React.Fragment key={i}>
                            {i === 0 && <hr style={{ marginTop: 70, marginBottom: 30, border: "1px dashed white" }} />}
                            {!question1Disabled && (
                                <div>
                                    <p style={{ paddingTop: 30, fontWeight: "bold" }}>Health Professional Visit #{i + 1}</p>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                style={{ marginTop: 10 }}
                                                variant="outlined"
                                                helperText={errors[`question1a${i + 1}`]?.message as any}
                                                error={!!errors[`question1a${i + 1}`]?.message}
                                                label="A- Health Professional"
                                                fullWidth
                                                required
                                                className="question__labelHealth"
                                                {...field}
                                            />
                                        )}
                                        name={`question1a${i + 1}`}
                                        control={control}
                                    />

                                    <Controller
                                        name={`question1b${i + 1}`}
                                        control={control}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DateTimePicker
                                                    className="question__labelHealth"
                                                    label="B- Date of visit"
                                                    inputFormat="DD/MM/YY hh:mm A"
                                                    {...field}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            style={{ marginTop: 10, marginBottom: 10 }}
                                                            fullWidth
                                                            variant="outlined"
                                                            className="question__labelHealth"
                                                            required
                                                            error={errors && errors[`question1b${i + 1}`] && !!errors[`question1b${i + 1}`]?.message}
                                                            helperText={
                                                                errors && errors[`question1b${i + 1}`] && errors[`question1b${i + 1}`]?.message
                                                            }
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />

                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                helperText={(errors as any)[`question1c${i + 1}`]?.message}
                                                error={!!errors[`question1c${i + 1}`]?.message}
                                                label="C- Reason for visit"
                                                className="question__labelHealth"
                                                fullWidth
                                                required
                                                {...field}
                                            />
                                        )}
                                        name={`question1c${i + 1}`}
                                        control={control}
                                    />

                                    <div>
                                        <Controller
                                            control={control}
                                            name={`question1d${i + 1}`}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    style={{ margin: 0, marginTop: 10 }}
                                                    control={<Checkbox />}
                                                    label="D- Fall related"
                                                    labelPlacement="start"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <Controller
                                            control={control}
                                            name={`question1e${i + 1}`}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    style={{ margin: 0, marginTop: 10 }}
                                                    control={<Checkbox />}
                                                    label="E- Behavior/cognitive problems related"
                                                    labelPlacement="start"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <Controller
                                            control={control}
                                            name={`question1f${i + 1}`}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    style={{ margin: 0, marginTop: 10 }}
                                                    control={<Checkbox />}
                                                    label="F- No therapy"
                                                    labelPlacement="start"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <Controller
                                            control={control}
                                            name={`question1g${i + 1}`}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    style={{ margin: 0, marginTop: 10 }}
                                                    control={<Checkbox />}
                                                    label="G- No procedure"
                                                    labelPlacement="start"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>

                                    {!noProcedureObject[`noProcedure${i + 1}`] && (
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    style={{ marginTop: 10 }}
                                                    variant="outlined"
                                                    helperText={(errors as any)[`question1i${i + 1}`]?.message}
                                                    error={!!errors[`question1i${i + 1}`]?.message}
                                                    label="I- Most important procedure/service that was performed"
                                                    fullWidth
                                                    className="question__labelHealth"
                                                    required
                                                    {...field}
                                                />
                                            )}
                                            name={`question1i${i + 1}`}
                                            control={control}
                                        />
                                    )}

                                    {i !== [...Array(RECURRENCES)].length - 1 && (
                                        <hr style={{ marginTop: 70, marginBottom: 30, border: "1px dashed white" }} />
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}

                <hr style={{ marginTop: 70, marginBottom: 30, height: 2, backgroundColor: "white" }} />

                <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                    <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                        <p style={{ fontSize: 22 }}>2. Visits to hospitals in the LAST THREE MONTHS:</p>
                        <p style={{ textAlign: "justify", fontStyle: "italic" }}>
                            Please tell me about any visits, admissions, tests or procedures you had in a hospital department in the LAST THREE
                            MONTHS. Departments you may have visited include Radiology, Emergency, Day Surgery, Cardiology, Intensive Care Unit,
                            General Ward, etc.
                        </p>
                    </div>
                </div>

                <div>
                    <Controller
                        control={control}
                        name="noVisitHospitalDepartment"
                        render={({ field }) => (
                            <FormControlLabel
                                style={{ margin: 0 }}
                                control={<Checkbox />}
                                label="Check here if no visits to any hospital department in the LAST THREE MONTHS (skip to question 3)"
                                labelPlacement="start"
                                {...field}
                                onChange={e => {
                                    field.onChange(e);
                                    setQuestion2Disabled(getValues("noVisitHospitalDepartment"));
                                }}
                            />
                        )}
                    />
                </div>

                {/* Question #2 */}
                {[...Array(RECURRENCES)].map((x, i) => {
                    return (
                        <React.Fragment key={i}>
                            {!question2Disabled && (
                                <>
                                    <p style={{ paddingTop: 30, fontWeight: "bold" }}>Hospital Visit #{i + 1}</p>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                helperText={(errors as any)[`question2h${i + 1}`]?.message}
                                                error={!!errors[`question2h${i + 1}`]?.message}
                                                label="A- Type of hospital department"
                                                fullWidth
                                                className="question__labelHealth"
                                                required
                                                style={{ marginTop: 10 }}
                                                {...field}
                                            />
                                        )}
                                        name={`question2a${i + 1}`}
                                        control={control}
                                    />

                                    <Controller
                                        name={`question2b${i + 1}`}
                                        control={control}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DateTimePicker
                                                    label="B- Date of visit"
                                                    className="question__labelHealth"
                                                    inputFormat="DD/MM/YY hh:mm A"
                                                    {...field}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            required
                                                            className="question__labelHealth"
                                                            error={errors && errors[`question2b${i + 1}`] && !!errors[`question2b${i + 1}`]?.message}
                                                            helperText={
                                                                errors && errors[`question2b${i + 1}`] && errors[`question2b${i + 1}`]?.message
                                                            }
                                                            style={{ marginBottom: 10, marginTop: 10 }}
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />

                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                helperText={(errors as any)[`question2c${i + 1}`]?.message}
                                                error={!!errors[`question2c${i + 1}`]?.message}
                                                label="C- Reason for visit"
                                                fullWidth
                                                required
                                                className="question__labelHealth"
                                                {...field}
                                            />
                                        )}
                                        name={`question2c${i + 1}`}
                                        control={control}
                                    />

                                    <div>
                                        <Controller
                                            control={control}
                                            name={`question2d${i + 1}`}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    style={{ margin: 0, marginTop: 10 }}
                                                    control={<Checkbox />}
                                                    label="D- Fall related"
                                                    labelPlacement="start"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <Controller
                                            control={control}
                                            name={`question2e${i + 1}`}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    style={{ margin: 0 }}
                                                    control={<Checkbox />}
                                                    label="E- Behavior/cognitive problems related"
                                                    labelPlacement="start"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <Controller
                                            control={control}
                                            name={`question2f${i + 1}`}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    style={{ margin: 0 }}
                                                    control={<Checkbox />}
                                                    label="F- No Tests/Procedures"
                                                    labelPlacement="start"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>

                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                type="number"
                                                variant="outlined"
                                                helperText={(errors as any)[`question2g${i + 1}`]?.message}
                                                error={!!errors[`question2g${i + 1}`]?.message}
                                                label="G- Length of Stay"
                                                fullWidth
                                                className="question__labelHealth"
                                                required
                                                {...field}
                                                InputProps={{
                                                    endAdornment: <span style={{ color: "White" }}>(hrs/days)</span>,
                                                }}
                                                style={{ marginTop: 10 }}
                                            />
                                        )}
                                        name={`question2g${i + 1}`}
                                        control={control}
                                    />

                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                helperText={(errors as any)[`question2i${i + 1}`]?.message}
                                                error={!!errors[`question2i${i + 1}`]?.message}
                                                label="H- Most important tests/procedures performed"
                                                fullWidth
                                                className="question__labelHealth"
                                                required
                                                style={{ marginTop: 10 }}
                                                {...field}
                                            />
                                        )}
                                        name={`question2h${i + 1}`}
                                        control={control}
                                    />

                                    {i !== [...Array(RECURRENCES)].length - 1 && (
                                        <hr style={{ marginTop: 70, marginBottom: 30, border: "1px dashed white" }} />
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    );
                })}

                <hr style={{ marginTop: 70, marginBottom: 30, height: 2, backgroundColor: "white" }} />

                <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                    <p style={{ fontSize: 22 }}>3. Tests or investigations in the LAST THREE MONTHS:</p>
                    <p style={{ textAlign: "justify", fontStyle: "italic" }}>
                        Please tell me about any tests or investigations you had (e.g. x-rays, ultrasounds, stress tests, mammograms) in the LAST
                        THREE MONTHS.
                    </p>
                </div>

                <div>
                    <Controller
                        control={control}
                        name="noTestsInvestigations"
                        render={({ field }) => (
                            <FormControlLabel
                                style={{ margin: 0 }}
                                control={<Checkbox />}
                                label="Check here if no tests or investigations in the LAST THREE MONTHS"
                                labelPlacement="start"
                                {...field}
                                onChange={e => {
                                    field.onChange(e);
                                    setQuestion3Disabled(getValues("noTestsInvestigations"));
                                }}
                            />
                        )}
                    />
                </div>

                {/* Question #3 */}
                {[...Array(RECURRENCES)].map((x, i) => {
                    return (
                        <React.Fragment key={i}>
                            {!question3Disabled && (
                                <>
                                    <p style={{ paddingTop: 30, fontWeight: "bold" }}>Test / Investigation #{i + 1}</p>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                helperText={(errors as any)[`question3a${i + 1}`]?.message}
                                                error={!!errors[`question3a${i + 1}`]?.message}
                                                label="A- Type of test/investigation"
                                                fullWidth
                                                required
                                                style={{ marginTop: 10 }}
                                                className="question__labelHealth"
                                                {...field}
                                            />
                                        )}
                                        name={`question3a${i + 1}`}
                                        control={control}
                                    />

                                    <Controller
                                        name={`question3b${i + 1}`}
                                        control={control}
                                        render={({ field }) => (
                                            <LocalizationProvider dateAdapter={AdapterMoment} style={{ marginTop: 10 }}>
                                                <DateTimePicker
                                                    label="B- Date of test/investigation"
                                                    inputFormat="DD/MM/YY hh:mm A"
                                                    className="question__labelHealth"
                                                    {...field}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            required
                                                            className="question__labelHealth"
                                                            error={errors && errors[`question3b${i + 1}`] && !!errors[`question3b${i + 1}`]?.message}
                                                            helperText={
                                                                errors && errors[`question3b${i + 1}`] && errors[`question3b${i + 1}`]?.message
                                                            }
                                                            style={{ marginTop: 10 }}
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        name={`question3c${i + 1}`}
                                        render={({ field }) => (
                                            <FormControl
                                                component="fieldset"
                                                error={!!errors[`question3c${i + 1}`]?.message}
                                                style={{ marginTop: 10 }}
                                            >
                                                <FormLabel className="question__labelHealth" component="legend" style={{ fontSize: 12 }}>
                                                    C- Site of test/investigation
                                                </FormLabel>
                                                <RadioGroup row {...field}>
                                                    <FormControlLabel value="hospital" control={<Radio />} label="Hospital" />
                                                    <FormControlLabel value="physicianOffice" control={<Radio />} label="Physician's office" />
                                                    <FormControlLabel
                                                        value="lab"
                                                        control={<Radio />}
                                                        label="Laboratory or x-ray site (other than hospital)"
                                                    />
                                                    <FormControlLabel value="home" control={<Radio />} label="Home" />
                                                </RadioGroup>
                                                <FormHelperText>{(errors as any)[`question3c${i + 1}`]?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />

                                    <div>
                                        <Controller
                                            control={control}
                                            name={`question3d${i + 1}`}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    label="D- Fall related"
                                                    labelPlacement="start"
                                                    style={{ margin: 0 }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <Controller
                                            control={control}
                                            name={`question3e${i + 1}`}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    label="E- Behavior/cognitive problems related"
                                                    labelPlacement="start"
                                                    style={{ margin: 0 }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>

                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                type="number"
                                                variant="outlined"
                                                helperText={(errors as any)[`question3f${i + 1}`]?.message}
                                                error={!!errors[`question3f${i + 1}`]?.message}
                                                label="F- # of times"
                                                fullWidth
                                                className="question__labelHealth"
                                                required
                                                style={{ margin: 0, marginTop: 10 }}
                                                {...field}
                                            />
                                        )}
                                        name={`question3f${i + 1}`}
                                        control={control}
                                    />

                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                type="number"
                                                variant="outlined"
                                                helperText={(errors as any)[`question3g${i + 1}`]?.message}
                                                error={!!errors[`question3g${i + 1}`]?.message}
                                                label="G- Visit duration"
                                                fullWidth
                                                required
                                                className="question__labelHealth"
                                                InputProps={{
                                                    endAdornment: <span style={{ color: "White" }}>(hrs/days)</span>,
                                                }}
                                                style={{ margin: 0, marginTop: 10 }}
                                                {...field}
                                            />
                                        )}
                                        name={`question3g${i + 1}`}
                                        control={control}
                                    />
                                    {i !== [...Array(RECURRENCES)].length - 1 && (
                                        <hr style={{ marginTop: 70, marginBottom: 30, border: "1px dashed white" }} />
                                    )}
                                </>
                            )}
                        </React.Fragment>
                    );
                })}

                <hr style={{ marginTop: 70, marginBottom: 30, height: 2, backgroundColor: "white" }} />

                <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                    <p style={{ textAlign: "justify", fontSize: 22 }}>
                        4. These questions refer to how much assistance you receive from any non-paid help, such as other family members, friends or
                        volunteers. <b>Use the past 3 months to estimate</b>.
                    </p>
                </div>

                <p style={{ textAlign: "justify" }}>
                    A- How many hours a week did helper(s) spend with you (assisting with things such as getting the phone or the door, getting
                    around, or fetching things)?
                </p>

                <Controller
                    render={({ field }) => (
                        <TextField
                            type="number"
                            variant="outlined"
                            helperText={(errors as any).question4a?.message}
                            error={!!errors.question4a?.message}
                            label="How many hours (Enter '0' if none)"
                            fullWidth
                            required
                            className="question__labelHealth"
                            InputProps={{
                                endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                            }}
                            {...field}
                        />
                    )}
                    name="question4a"
                    control={control}
                />

                <div style={{ paddingTop: 20 }}>
                    <p style={{ textAlign: "justify" }}>
                        B- How many hours a week did helper(s) spend with you on basic tasks such as eating, dressing or personal care (bathing, using
                        toilet, brushing hair, etc.)
                    </p>
                </div>

                <Controller
                    render={({ field }) => (
                        <TextField
                            type="number"
                            variant="outlined"
                            helperText={(errors as any).question4b?.message}
                            error={!!errors.question4b?.message}
                            label="How many hours (Enter '0' if none)"
                            fullWidth
                            required
                            className="question__labelHealth"
                            InputProps={{
                                endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                            }}
                            {...field}
                        />
                    )}
                    name="question4b"
                    control={control}
                />

                <div style={{ paddingTop: 20 }}>
                    <p style={{ textAlign: "justify" }}>
                        C- How many hours a week did helper(s) spend helping you with activities such as shopping, chores, personal business,
                        transportation, or social activities?
                    </p>
                </div>

                <Controller
                    render={({ field }) => (
                        <TextField
                            type="number"
                            variant="outlined"
                            helperText={(errors as any).question4c?.message}
                            error={!!errors.question4c?.message}
                            label="How many hours (Enter '0' if none)"
                            fullWidth
                            required
                            className="question__labelHealth"
                            InputProps={{
                                endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                            }}
                            {...field}
                        />
                    )}
                    name="question4c"
                    control={control}
                />

                <hr style={{ marginTop: 70, marginBottom: 30, height: 2, backgroundColor: "white" }} />

                <div style={{ paddingTop: 20, marginBottom: 10 }}>
                    <p style={{ textAlign: "justify", fontSize: 22 }}>5. Do you receive non-paid help with the following activities:</p>
                    <p style={{ textAlign: "justify" }}>
                        <i>If you check Yes for any activity, record the number of hours and Who provides the help?</i>
                    </p>
                </div>

                <TableContainer style={{ border: "1px solid white", borderRadius: 10, marginTop: 20 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ backgroundColor: "#616161" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">TASK</TableCell>
                                <TableCell align="center">NEEDED HELP</TableCell>
                                <TableCell align="center">AVERAGE OF HOURS/WEEK</TableCell>
                                <TableCell align="center">HELPER</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">A- Shopping</TableCell>
                                <TableCell align="center">
                                    <Controller
                                        control={control}
                                        name="question5a1"
                                        render={({ field }) => (
                                            <FormControl
                                                component="fieldset"
                                                error={!!errors.question5a1?.message}
                                                required
                                                style={{ marginBottom: 20 }}
                                            >
                                                <RadioGroup
                                                    {...field}
                                                    onChange={e => {
                                                        field.onChange(e);
                                                        if (e.target.value === "No") {
                                                            setValue("question5a2", 0);
                                                            setValue("question5a3", "Other");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                                <FormHelperText>{errors.question5a1?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </TableCell>
                                {question5a1 === "Yes" ? (
                                    <>
                                        <TableCell align="center">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        helperText={(errors as any).question5a2?.message}
                                                        error={!!errors.question5a2?.message}
                                                        fullWidth
                                                        required
                                                        className="question__labelHealth"
                                                        InputProps={{
                                                            endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                                name="question5a2"
                                                control={control}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                control={control}
                                                name="question5a3"
                                                render={({ field }) => (
                                                    <FormControl
                                                        component="fieldset"
                                                        error={!!errors.question5a3?.message}
                                                        required
                                                        style={{ marginBottom: 20 }}
                                                    >
                                                        <RadioGroup {...field}>
                                                            <FormControlLabel value="Spouse" control={<Radio />} label="Spouse" />
                                                            <FormControlLabel value="Child" control={<Radio />} label="Child" />
                                                            <FormControlLabel value="Relative" control={<Radio />} label="Relative" />
                                                            <FormControlLabel value="Friend" control={<Radio />} label="Friend" />
                                                            <FormControlLabel value="Volunteer" control={<Radio />} label="Volunteer" />
                                                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                        </RadioGroup>
                                                        <FormHelperText>{errors.question5a3?.message}</FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </>
                                )}
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">B- Cleaning</TableCell>
                                <TableCell align="center">
                                    <Controller
                                        control={control}
                                        name="question5b1"
                                        render={({ field }) => (
                                            <FormControl
                                                component="fieldset"
                                                error={!!errors.question5b1?.message}
                                                required
                                                style={{ marginBottom: 20 }}
                                            >
                                                <RadioGroup
                                                    {...field}
                                                    onChange={e => {
                                                        field.onChange(e);
                                                        if (e.target.value === "No") {
                                                            setValue("question5b2", 0);
                                                            setValue("question5b3", "Other");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                                <FormHelperText>{errors.question5b1?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </TableCell>
                                {question5b1 === "Yes" ? (
                                    <>
                                        <TableCell align="center">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        helperText={(errors as any).question5b2?.message}
                                                        error={!!errors.question5b2?.message}
                                                        fullWidth
                                                        required
                                                        className="question__labelHealth"
                                                        InputProps={{
                                                            endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                                name="question5b2"
                                                control={control}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                control={control}
                                                name="question5b3"
                                                render={({ field }) => (
                                                    <FormControl
                                                        component="fieldset"
                                                        error={!!errors.question5b3?.message}
                                                        required
                                                        style={{ marginBottom: 20 }}
                                                    >
                                                        <RadioGroup {...field}>
                                                            <FormControlLabel value="Spouse" control={<Radio />} label="Spouse" />
                                                            <FormControlLabel value="Child" control={<Radio />} label="Child" />
                                                            <FormControlLabel value="Relative" control={<Radio />} label="Relative" />
                                                            <FormControlLabel value="Friend" control={<Radio />} label="Friend" />
                                                            <FormControlLabel value="Volunteer" control={<Radio />} label="Volunteer" />
                                                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                        </RadioGroup>
                                                        <FormHelperText>{errors.question5b3?.message}</FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </>
                                )}
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">C- Laundry</TableCell>
                                <TableCell align="center">
                                    <Controller
                                        control={control}
                                        name="question5c1"
                                        render={({ field }) => (
                                            <FormControl
                                                component="fieldset"
                                                error={!!errors.question5c1?.message}
                                                required
                                                style={{ marginBottom: 20 }}
                                            >
                                                <RadioGroup
                                                    {...field}
                                                    onChange={e => {
                                                        field.onChange(e);
                                                        if (e.target.value === "No") {
                                                            setValue("question5c2", 0);
                                                            setValue("question5c3", "Other");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                                <FormHelperText>{errors.question5c1?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </TableCell>
                                {question5c1 === "Yes" ? (
                                    <>
                                        <TableCell align="center">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        helperText={(errors as any).question5c2?.message}
                                                        error={!!errors.question5c2?.message}
                                                        fullWidth
                                                        required
                                                        className="question__labelHealth"
                                                        InputProps={{
                                                            endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                                name="question5c2"
                                                control={control}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                control={control}
                                                name="question5c3"
                                                render={({ field }) => (
                                                    <FormControl
                                                        component="fieldset"
                                                        error={!!errors.question5c3?.message}
                                                        required
                                                        style={{ marginBottom: 20 }}
                                                    >
                                                        <RadioGroup {...field}>
                                                            <FormControlLabel value="Spouse" control={<Radio />} label="Spouse" />
                                                            <FormControlLabel value="Child" control={<Radio />} label="Child" />
                                                            <FormControlLabel value="Relative" control={<Radio />} label="Relative" />
                                                            <FormControlLabel value="Friend" control={<Radio />} label="Friend" />
                                                            <FormControlLabel value="Volunteer" control={<Radio />} label="Volunteer" />
                                                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                        </RadioGroup>
                                                        <FormHelperText>{errors.question5c3?.message}</FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </>
                                )}
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">D- Meal Preparation</TableCell>
                                <TableCell align="center">
                                    <Controller
                                        control={control}
                                        name="question5d1"
                                        render={({ field }) => (
                                            <FormControl
                                                component="fieldset"
                                                error={!!errors.question5d1?.message}
                                                required
                                                style={{ marginBottom: 20 }}
                                            >
                                                <RadioGroup
                                                    {...field}
                                                    onChange={e => {
                                                        field.onChange(e);
                                                        if (e.target.value === "No") {
                                                            setValue("question5d2", 0);
                                                            setValue("question5d3", "Other");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                                <FormHelperText>{errors.question5d1?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </TableCell>
                                {question5d1 === "Yes" ? (
                                    <>
                                        <TableCell align="center">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        helperText={(errors as any).question5d2?.message}
                                                        error={!!errors.question5d2?.message}
                                                        fullWidth
                                                        required
                                                        className="question__labelHealth"
                                                        InputProps={{
                                                            endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                                name="question5d2"
                                                control={control}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                control={control}
                                                name="question5d3"
                                                render={({ field }) => (
                                                    <FormControl
                                                        component="fieldset"
                                                        error={!!errors.question5d3?.message}
                                                        required
                                                        style={{ marginBottom: 20 }}
                                                    >
                                                        <RadioGroup {...field}>
                                                            <FormControlLabel value="Spouse" control={<Radio />} label="Spouse" />
                                                            <FormControlLabel value="Child" control={<Radio />} label="Child" />
                                                            <FormControlLabel value="Relative" control={<Radio />} label="Relative" />
                                                            <FormControlLabel value="Friend" control={<Radio />} label="Friend" />
                                                            <FormControlLabel value="Volunteer" control={<Radio />} label="Volunteer" />
                                                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                        </RadioGroup>
                                                        <FormHelperText>{errors.question5d3?.message}</FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </>
                                )}
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">E- Transportation</TableCell>
                                <TableCell align="center">
                                    <Controller
                                        control={control}
                                        name="question5e1"
                                        render={({ field }) => (
                                            <FormControl
                                                component="fieldset"
                                                error={!!errors.question5e1?.message}
                                                required
                                                style={{ marginBottom: 20 }}
                                            >
                                                <RadioGroup
                                                    {...field}
                                                    onChange={e => {
                                                        field.onChange(e);
                                                        if (e.target.value === "No") {
                                                            setValue("question5e2", 0);
                                                            setValue("question5e3", "Other");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                                <FormHelperText>{errors.question5e1?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </TableCell>
                                {question5e1 === "Yes" ? (
                                    <>
                                        <TableCell align="center">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        helperText={(errors as any).question5e2?.message}
                                                        error={!!errors.question5e2?.message}
                                                        fullWidth
                                                        required
                                                        className="question__labelHealth"
                                                        InputProps={{
                                                            endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                                name="question5e2"
                                                control={control}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                control={control}
                                                name="question5e3"
                                                render={({ field }) => (
                                                    <FormControl
                                                        component="fieldset"
                                                        error={!!errors.question5e3?.message}
                                                        required
                                                        style={{ marginBottom: 20 }}
                                                    >
                                                        <RadioGroup {...field}>
                                                            <FormControlLabel value="Spouse" control={<Radio />} label="Spouse" />
                                                            <FormControlLabel value="Child" control={<Radio />} label="Child" />
                                                            <FormControlLabel value="Relative" control={<Radio />} label="Relative" />
                                                            <FormControlLabel value="Friend" control={<Radio />} label="Friend" />
                                                            <FormControlLabel value="Volunteer" control={<Radio />} label="Volunteer" />
                                                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                        </RadioGroup>
                                                        <FormHelperText>{errors.question5e3?.message}</FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </>
                                )}
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">F- Personal finances</TableCell>
                                <TableCell align="center">
                                    <Controller
                                        control={control}
                                        name="question5f1"
                                        render={({ field }) => (
                                            <FormControl
                                                component="fieldset"
                                                error={!!errors.question5f1?.message}
                                                required
                                                style={{ marginBottom: 20 }}
                                            >
                                                <RadioGroup
                                                    {...field}
                                                    onChange={e => {
                                                        field.onChange(e);
                                                        if (e.target.value === "No") {
                                                            setValue("question5f2", 0);
                                                            setValue("question5f3", "Other");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                                <FormHelperText>{errors.question5f1?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </TableCell>
                                {question5f1 === "Yes" ? (
                                    <>
                                        <TableCell align="center">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        helperText={(errors as any).question5f2?.message}
                                                        error={!!errors.question5f2?.message}
                                                        fullWidth
                                                        required
                                                        className="question__labelHealth"
                                                        InputProps={{
                                                            endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                                name="question5f2"
                                                control={control}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                control={control}
                                                name="question5f3"
                                                render={({ field }) => (
                                                    <FormControl
                                                        component="fieldset"
                                                        error={!!errors.question5f3?.message}
                                                        required
                                                        style={{ marginBottom: 20 }}
                                                    >
                                                        <RadioGroup {...field}>
                                                            <FormControlLabel value="Spouse" control={<Radio />} label="Spouse" />
                                                            <FormControlLabel value="Child" control={<Radio />} label="Child" />
                                                            <FormControlLabel value="Relative" control={<Radio />} label="Relative" />
                                                            <FormControlLabel value="Friend" control={<Radio />} label="Friend" />
                                                            <FormControlLabel value="Volunteer" control={<Radio />} label="Volunteer" />
                                                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                        </RadioGroup>
                                                        <FormHelperText>{errors.question5f3?.message}</FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </>
                                )}
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">G- Dressing</TableCell>
                                <TableCell align="center">
                                    <Controller
                                        control={control}
                                        name="question5g1"
                                        render={({ field }) => (
                                            <FormControl
                                                component="fieldset"
                                                error={!!errors.question5g1?.message}
                                                required
                                                style={{ marginBottom: 20 }}
                                            >
                                                <RadioGroup
                                                    {...field}
                                                    onChange={e => {
                                                        field.onChange(e);
                                                        if (e.target.value === "No") {
                                                            setValue("question5g2", 0);
                                                            setValue("question5g3", "Other");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                                <FormHelperText>{errors.question5g1?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </TableCell>
                                {question5g1 === "Yes" ? (
                                    <>
                                        <TableCell align="center">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        helperText={(errors as any).question5g2?.message}
                                                        error={!!errors.question5g2?.message}
                                                        fullWidth
                                                        required
                                                        className="question__labelHealth"
                                                        InputProps={{
                                                            endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                                name="question5g2"
                                                control={control}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                control={control}
                                                name="question5g3"
                                                render={({ field }) => (
                                                    <FormControl
                                                        component="fieldset"
                                                        error={!!errors.question5g3?.message}
                                                        required
                                                        style={{ marginBottom: 20 }}
                                                    >
                                                        <RadioGroup {...field}>
                                                            <FormControlLabel value="Spouse" control={<Radio />} label="Spouse" />
                                                            <FormControlLabel value="Child" control={<Radio />} label="Child" />
                                                            <FormControlLabel value="Relative" control={<Radio />} label="Relative" />
                                                            <FormControlLabel value="Friend" control={<Radio />} label="Friend" />
                                                            <FormControlLabel value="Volunteer" control={<Radio />} label="Volunteer" />
                                                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                        </RadioGroup>
                                                        <FormHelperText>{errors.question5g3?.message}</FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </>
                                )}
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">
                                    <div>H- Grooming</div> (toileting, bathing, etc.)
                                </TableCell>
                                <TableCell align="center">
                                    <Controller
                                        control={control}
                                        name="question5h1"
                                        render={({ field }) => (
                                            <FormControl
                                                component="fieldset"
                                                error={!!errors.question5h1?.message}
                                                required
                                                style={{ marginBottom: 20 }}
                                            >
                                                <RadioGroup
                                                    {...field}
                                                    onChange={e => {
                                                        field.onChange(e);
                                                        if (e.target.value === "No") {
                                                            setValue("question5h2", 0);
                                                            setValue("question5h3", "Other");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                                <FormHelperText>{errors.question5h1?.message}</FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </TableCell>
                                {question5h1 === "Yes" ? (
                                    <>
                                        <TableCell align="center">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        helperText={(errors as any).question5h2?.message}
                                                        error={!!errors.question5h2?.message}
                                                        fullWidth
                                                        required
                                                        className="question__labelHealth"
                                                        InputProps={{
                                                            endAdornment: <span style={{ color: "White" }}>(hours)</span>,
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                                name="question5h2"
                                                control={control}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                control={control}
                                                name="question5h3"
                                                render={({ field }) => (
                                                    <FormControl
                                                        component="fieldset"
                                                        error={!!errors.question5h3?.message}
                                                        required
                                                        style={{ marginBottom: 20 }}
                                                    >
                                                        <RadioGroup {...field}>
                                                            <FormControlLabel value="Spouse" control={<Radio />} label="Spouse" />
                                                            <FormControlLabel value="Child" control={<Radio />} label="Child" />
                                                            <FormControlLabel value="Relative" control={<Radio />} label="Relative" />
                                                            <FormControlLabel value="Friend" control={<Radio />} label="Friend" />
                                                            <FormControlLabel value="Volunteer" control={<Radio />} label="Volunteer" />
                                                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                        </RadioGroup>
                                                        <FormHelperText>{errors.question5h3?.message}</FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ display: "flex" }}>
                    <Button variant="contained" type="submit" color="primary" className="cta__submit" style={{ marginLeft: "auto", marginTop: 20 }}>
                        Finish
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default HealthResourceUtilization;
