export const getTopic = (questionnaireName: string) => {
    let topic = "";

    // Physical
    if (questionnaireName === "lawtonBrodyIADL") topic = "physical";
    if (questionnaireName === "fallsCalendar") topic = "physical";
    if (questionnaireName === "fallsAndBalance") topic = "physical";
    if (questionnaireName === "womenHealth") topic = "physical";
    if (questionnaireName === "healthStatus") topic = "physical";
    // Health
    if (questionnaireName === "qualityOfLife") topic = "heart";
    if (questionnaireName === "healthResourceUtilization") topic = "heart";
    if (questionnaireName === "healthBehaviorsQuestionnaire") topic = "heart";
    // Diet
    if (questionnaireName === "dietHistory") topic = "diet";
    if (questionnaireName === "eatingPattern") topic = "diet";
    if (questionnaireName === "mediterraneanDietAdherence") topic = "diet";
    // Sleep
    if (questionnaireName === "insomniaSeverityIndex") topic = "sleep";
    // Cognitive
    if (questionnaireName === "preCognitiveExpectancy") topic = "cognitive";
    if (questionnaireName === "postCognitiveExpectancy") topic = "cognitive";
    if (questionnaireName === "generalizedAnxietyDisorder") topic = "cognitive";
    if (questionnaireName === "geriatricDepressionScale") topic = "cognitive";
    if (questionnaireName === "bhproQuestionnaires") topic = "cognitive";
    if (questionnaireName === "visionHearing") topic = "cognitive";
    if (questionnaireName === "revisedHearingHandicapInventory") topic = "cognitive";
    if (questionnaireName === "bhproQuestionnaires") topic = "cognitive";
    return topic;
};
