import { BlockOption, BlockType } from "./interface";

const blockConfig: BlockType = {
    inDropdown: true,
    options: [
        { style: "unstyled", Icon: "Normal" },
        { style: "header-one", Icon: "H1" },
        { style: "header-two", Icon: "H2" },
        { style: "header-three", Icon: "H3" },
        { style: "header-four", Icon: "H4" },
        { style: "header-five", Icon: "H5" },
        { style: "header-six", Icon: "H6" },
        { style: "blockquote", Icon: "Blockquote" },
        { style: "code-block", Icon: "Code" },
        { style: "highlight", Icon: "Citation" },
    ],
    title: "Block",
};

export type { BlockType, BlockOption };
export default blockConfig;
