import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

// Mui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Utils
import { exportAOADocument } from "../../../../utils/xlsx";

// Interfaces
import { Stat } from "../../../../interfaces/Stat";
import { Participant } from "../../../../interfaces/Participant";

// Custom components
import ParticipantTable from "../../../general/SimpleTable";
import StatsTableRows from "./StatsTableRows";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Settings
import settings from "../../../../settings.json";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import {
    collection,
    query,
    orderBy,
    onSnapshot,
    startAfter,
    limit,
    endBefore,
    QueryDocumentSnapshot,
    DocumentData,
    getDocs,
    getCountFromServer,
} from "firebase/firestore";
import { genDoc } from "../../../../utils/firebase";

const COLLECTION = "Stats";

const Stats: React.FC = () => {
    const hist = useHistory();
    const { currentAdmin, loading, setLoading } = useFetchUser();

    // States
    const [stats, setStats] = useState<Stat[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState(0);
    const [firstVisible, setFirstVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
    const [participants, setParticipants] = useState<Participant[]>([]);

    const fetchAllParticipants = async () => {
        const payload = await getDocs(query(collection(firestore, "Participants"), orderBy("createdAt", "desc")));
        const data = payload.docs.map(d => d.data()) as Participant[];
        setParticipants(data);
    };

    const handleNextPage = async () => {
        try {
            if (stats.length !== 0) {
                setLoading(true);
                const localQuery = query(
                    collection(firestore, COLLECTION),
                    orderBy("createdAt", "desc"),
                    limit(settings.page.rowsPerPage),
                    startAfter(lastVisible)
                );

                return onSnapshot(localQuery, doc => {
                    const data = doc.docs.map(genDoc<Stat>()).filter((item: Stat) => {
                        if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                            return true;
                        }
                        return !item.isDeleted;
                    });

                    setFirstVisible(doc.docs[0]);
                    setLastVisible(doc.docs[doc.docs.length - 1]);
                    setStats(data);
                    setLoading(false);
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handlePreviousPage = async () => {
        try {
            setLoading(true);
            const localQuery = query(
                collection(firestore, COLLECTION),
                orderBy("createdAt", "desc"),
                limit(settings.page.rowsPerPage),
                endBefore(firstVisible)
            );

            return onSnapshot(localQuery, doc => {
                const data = doc.docs.map(genDoc<Stat>()).filter((item: Stat) => {
                    if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                        return true;
                    }
                    return !item.isDeleted;
                });

                setFirstVisible(doc.docs[0]);
                setLastVisible(doc.docs[doc.docs.length - 1]);
                setStats(data);
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchCount = async () => {
        try {
            const payload = await getCountFromServer(collection(firestore, "Stats"));
            setCount(payload.data().count);
        } catch (e) {
            console.error(e);
        }
    };

    const handlePageChange = (e: any, newPage: number) => {
        if (page < newPage) handleNextPage();
        else if (page > newPage) handlePreviousPage();

        setPage(newPage);
    };

    const fetchFirstStat = () => {
        try {
            setLoading(true);
            const localQuery = query(collection(firestore, COLLECTION), orderBy("createdAt", "desc"), limit(settings.page.rowsPerPage));

            return onSnapshot(localQuery, doc => {
                const data = doc.docs.map(genDoc<Stat>()).filter((item: Stat) => {
                    if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                        return true;
                    }
                    return !item.isDeleted;
                });

                setFirstVisible(doc.docs[0]);
                setLastVisible(doc.docs[doc.docs.length - 1]);
                setStats(data);
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchStats = async () => {
        const payload = await getDocs(collection(firestore, "Stats"));
        const data = payload.docs.map(d => d.data()) as Stat[];
        return data;
    };

    const extractQuestionnaireName = (questionnaireName: string) => {
        let questionName = "";
        switch (questionnaireName) {
            default:
            case "fallsCalendar":
                questionName = "Falls Calendar";
                break;
            case "lawtonBrodyIADL":
                questionName = "Lawton Brody IADL";
                break;
            case "fallsAndBalance":
                questionName = "Falls and Balance";
                break;
            case "insomniaSeverityIndex":
                questionName = "Insomnia Severity Index";
                break;
            case "mediterraneanDietAdherence":
                questionName = "Mediterranean Diet Adherence";
                break;
            case "dietHistory":
                questionName = "Diet History";
                break;
            case "eatingPattern":
                questionName = "Eating Pattern";
                break;
            case "healthResourceUtilization":
                questionName = "Health Resource Utilization";
                break;
            case "qualityOfLife":
                questionName = "Quality of Life";
                break;
            case "preCognitiveExpectancy":
                questionName = "Pre-Cognitive Expectancy";
                break;
            case "postCognitiveExpectancy":
                questionName = "Post-Cognitive Expectancy";
                break;
            case "generalizedAnxietyDisorder":
                questionName = "Generalized Anxiety Disorder";
                break;
            case "geriatricDepressionScale":
                questionName = "Geriatric Depression Scale";
                break;
        }

        return questionName;
    };

    const formatData = async () => {
        const headers = [];
        const stats = await fetchStats();

        // questionnaire count per topic
        const fallsCalendar = 7;
        const lawtonBrodyIADL = 8;
        const fallsAndBalance = 28;
        const insomniaSeverityIndex = 7;
        const mediterraneanDietAdherence = 14;
        const dietHistory = 436;
        const eatingPattern = 20;
        const qualityOfLife = 36;
        const preCognitiveExpectancy = 14;
        const postCognitiveExpectancy = 13;
        const generalizedAnxietyDisorder = 14;
        const geriatricDepressionScale = 30;

        // Headers
        headers.push("Date", "Participant", "Score", "Questionnaire", "Topic");

        for (let i = 1; i <= fallsCalendar; i++) headers.push(`Falls Calendar Question ${i}`);
        headers.push(`Falls Calendar Score`);
        for (let i = 1; i <= lawtonBrodyIADL; i++) headers.push(`Lawton Brody IADL Question ${i}`);
        headers.push(`Lawton Brody IADL Score`);
        for (let i = 1; i <= fallsAndBalance; i++) headers.push(`Falls and Balance Question ${i}`);
        headers.push(`Falls and Balance Score`);
        for (let i = 1; i <= insomniaSeverityIndex; i++) headers.push(`Insomnia Severity Index Question ${i}`);
        headers.push(`Insomnia Severity Index Score`);
        for (let i = 1; i <= mediterraneanDietAdherence; i++) headers.push(`Mediterranean Diet Adherence Question ${i}`);
        headers.push(`Mediterranean Diet Adherence Score`);
        for (let i = 1; i <= dietHistory; i++) headers.push(`Diet History Question ${i}`);
        headers.push(`Diet History Score`);
        for (let i = 1; i <= eatingPattern; i++) headers.push(`Eating Pattern Question ${i}`);
        headers.push(`Eating Pattern Score`);

        // healthResourceUtilization
        // healthResourceUtilization
        headers.push(
            "Health Resource Utilization Question 1a1",
            "Health Resource Utilization Question 1b1",
            "Health Resource Utilization Question 1c1",
            "Health Resource Utilization Question 1d1",
            "Health Resource Utilization Question 1e1",
            "Health Resource Utilization Question 1f1",
            "Health Resource Utilization Question 1g1",
            "Health Resource Utilization Question 1h1",
            "Health Resource Utilization Question 1i1",
            "Health Resource Utilization Question 2a1",
            "Health Resource Utilization Question 2b1",
            "Health Resource Utilization Question 2c1",
            "Health Resource Utilization Question 2d1",
            "Health Resource Utilization Question 2e1",
            "Health Resource Utilization Question 2f1",
            "Health Resource Utilization Question 2g1",
            "Health Resource Utilization Question 2h1",
            "Health Resource Utilization Question 2i1",
            "Health Resource Utilization Question 3a1",
            "Health Resource Utilization Question 3b1",
            "Health Resource Utilization Question 3c1",
            "Health Resource Utilization Question 3d1",
            "Health Resource Utilization Question 3e1",
            "Health Resource Utilization Question 3f1",
            "Health Resource Utilization Question 3g1",
            "Health Resource Utilization Question 1a2",
            "Health Resource Utilization Question 1b2",
            "Health Resource Utilization Question 1c2",
            "Health Resource Utilization Question 1d2",
            "Health Resource Utilization Question 1e2",
            "Health Resource Utilization Question 1f2",
            "Health Resource Utilization Question 1g2",
            "Health Resource Utilization Question 1h2",
            "Health Resource Utilization Question 1i2",
            "Health Resource Utilization Question 2a2",
            "Health Resource Utilization Question 2b2",
            "Health Resource Utilization Question 2c2",
            "Health Resource Utilization Question 2d2",
            "Health Resource Utilization Question 2e2",
            "Health Resource Utilization Question 2f2",
            "Health Resource Utilization Question 2g2",
            "Health Resource Utilization Question 2h2",
            "Health Resource Utilization Question 2i2",
            "Health Resource Utilization Question 3a2",
            "Health Resource Utilization Question 3b2",
            "Health Resource Utilization Question 3c2",
            "Health Resource Utilization Question 3d2",
            "Health Resource Utilization Question 3e2",
            "Health Resource Utilization Question 3f2",
            "Health Resource Utilization Question 3g2",
            "Health Resource Utilization Question 1a3",
            "Health Resource Utilization Question 1b3",
            "Health Resource Utilization Question 1c3",
            "Health Resource Utilization Question 1d3",
            "Health Resource Utilization Question 1e3",
            "Health Resource Utilization Question 1f3",
            "Health Resource Utilization Question 1g3",
            "Health Resource Utilization Question 1h3",
            "Health Resource Utilization Question 1i3",
            "Health Resource Utilization Question 2a3",
            "Health Resource Utilization Question 2b3",
            "Health Resource Utilization Question 2c3",
            "Health Resource Utilization Question 2d3",
            "Health Resource Utilization Question 2e3",
            "Health Resource Utilization Question 2f3",
            "Health Resource Utilization Question 2g3",
            "Health Resource Utilization Question 2h3",
            "Health Resource Utilization Question 2i3",
            "Health Resource Utilization Question 3a3",
            "Health Resource Utilization Question 3b3",
            "Health Resource Utilization Question 3c3",
            "Health Resource Utilization Question 3d3",
            "Health Resource Utilization Question 3e3",
            "Health Resource Utilization Question 3f3",
            "Health Resource Utilization Question 3g3",
            "Health Resource Utilization Question 1a4",
            "Health Resource Utilization Question 1b4",
            "Health Resource Utilization Question 1c4",
            "Health Resource Utilization Question 1d4",
            "Health Resource Utilization Question 1e4",
            "Health Resource Utilization Question 1f4",
            "Health Resource Utilization Question 1g4",
            "Health Resource Utilization Question 1h4",
            "Health Resource Utilization Question 1i4",
            "Health Resource Utilization Question 2a4",
            "Health Resource Utilization Question 2b4",
            "Health Resource Utilization Question 2c4",
            "Health Resource Utilization Question 2d4",
            "Health Resource Utilization Question 2e4",
            "Health Resource Utilization Question 2f4",
            "Health Resource Utilization Question 2g4",
            "Health Resource Utilization Question 2h4",
            "Health Resource Utilization Question 2i4",
            "Health Resource Utilization Question 3a4",
            "Health Resource Utilization Question 3b4",
            "Health Resource Utilization Question 3c4",
            "Health Resource Utilization Question 3d4",
            "Health Resource Utilization Question 3e4",
            "Health Resource Utilization Question 3f4",
            "Health Resource Utilization Question 3g4",
            "Health Resource Utilization Question 1a5",
            "Health Resource Utilization Question 1b5",
            "Health Resource Utilization Question 1c5",
            "Health Resource Utilization Question 1d5",
            "Health Resource Utilization Question 1e5",
            "Health Resource Utilization Question 1f5",
            "Health Resource Utilization Question 1g5",
            "Health Resource Utilization Question 1h5",
            "Health Resource Utilization Question 1i5",
            "Health Resource Utilization Question 2a5",
            "Health Resource Utilization Question 2b5",
            "Health Resource Utilization Question 2c5",
            "Health Resource Utilization Question 2d5",
            "Health Resource Utilization Question 2e5",
            "Health Resource Utilization Question 2f5",
            "Health Resource Utilization Question 2g5",
            "Health Resource Utilization Question 2h5",
            "Health Resource Utilization Question 2i5",
            "Health Resource Utilization Question 3a5",
            "Health Resource Utilization Question 3b5",
            "Health Resource Utilization Question 3c5",
            "Health Resource Utilization Question 3d5",
            "Health Resource Utilization Question 3e5",
            "Health Resource Utilization Question 3f5",
            "Health Resource Utilization Question 3g5",
            "Health Resource Utilization Question 1a6",
            "Health Resource Utilization Question 1b6",
            "Health Resource Utilization Question 1c6",
            "Health Resource Utilization Question 1d6",
            "Health Resource Utilization Question 1e6",
            "Health Resource Utilization Question 1f6",
            "Health Resource Utilization Question 1g6",
            "Health Resource Utilization Question 1h6",
            "Health Resource Utilization Question 1i6",
            "Health Resource Utilization Question 2a6",
            "Health Resource Utilization Question 2b6",
            "Health Resource Utilization Question 2c6",
            "Health Resource Utilization Question 2d6",
            "Health Resource Utilization Question 2e6",
            "Health Resource Utilization Question 2f6",
            "Health Resource Utilization Question 2g6",
            "Health Resource Utilization Question 2h6",
            "Health Resource Utilization Question 2i6",
            "Health Resource Utilization Question 3a6",
            "Health Resource Utilization Question 3b6",
            "Health Resource Utilization Question 3c6",
            "Health Resource Utilization Question 3d6",
            "Health Resource Utilization Question 3e6",
            "Health Resource Utilization Question 3f6",
            "Health Resource Utilization Question 3g6",
            "Health Resource Utilization Question 1a7",
            "Health Resource Utilization Question 1b7",
            "Health Resource Utilization Question 1c7",
            "Health Resource Utilization Question 1d7",
            "Health Resource Utilization Question 1e7",
            "Health Resource Utilization Question 1f7",
            "Health Resource Utilization Question 1g7",
            "Health Resource Utilization Question 1h7",
            "Health Resource Utilization Question 1i7",
            "Health Resource Utilization Question 2a7",
            "Health Resource Utilization Question 2b7",
            "Health Resource Utilization Question 2c7",
            "Health Resource Utilization Question 2d7",
            "Health Resource Utilization Question 2e7",
            "Health Resource Utilization Question 2f7",
            "Health Resource Utilization Question 2g7",
            "Health Resource Utilization Question 2h7",
            "Health Resource Utilization Question 2i7",
            "Health Resource Utilization Question 3a7",
            "Health Resource Utilization Question 3b7",
            "Health Resource Utilization Question 3c7",
            "Health Resource Utilization Question 3d7",
            "Health Resource Utilization Question 3e7",
            "Health Resource Utilization Question 3f7",
            "Health Resource Utilization Question 3g7",
            "Health Resource Utilization Question 4a",
            "Health Resource Utilization Question 4b",
            "Health Resource Utilization Question 4c",
            "Health Resource Utilization Question 5a1",
            "Health Resource Utilization Question 5a2",
            "Health Resource Utilization Question 5a3",
            "Health Resource Utilization Question 5b1",
            "Health Resource Utilization Question 5b2",
            "Health Resource Utilization Question 5b3",
            "Health Resource Utilization Question 5c1",
            "Health Resource Utilization Question 5c2",
            "Health Resource Utilization Question 5c3",
            "Health Resource Utilization Question 5d1",
            "Health Resource Utilization Question 5d2",
            "Health Resource Utilization Question 5d3",
            "Health Resource Utilization Question 5e1",
            "Health Resource Utilization Question 5e2",
            "Health Resource Utilization Question 5e3",
            "Health Resource Utilization Question 5f1",
            "Health Resource Utilization Question 5f2",
            "Health Resource Utilization Question 5f3",
            "Health Resource Utilization Question 5g1",
            "Health Resource Utilization Question 5g2",
            "Health Resource Utilization Question 5g3",
            "Health Resource Utilization Question 5h1",
            "Health Resource Utilization Question 5h2",
            "Health Resource Utilization Question 5h3"
        );
        headers.push(`Health Resource Utilization Score`);

        for (let i = 1; i <= qualityOfLife; i++) headers.push(`Quality of Life Question ${i}`);
        headers.push(`Quality of Life Score`);
        for (let i = 1; i <= preCognitiveExpectancy; i++) headers.push(`Pre-Cognitive Expectancy Question ${i}`);
        headers.push(`Pre-Cognitive Expectancy Score`);
        for (let i = 1; i <= postCognitiveExpectancy; i++) headers.push(`Post-Cognitive Expectancy Question ${i}`);
        headers.push(`Post-Cognitive Expectancy Score`);
        for (let i = 1; i <= generalizedAnxietyDisorder; i++) headers.push(`Generalized Anxiety Disorder Question ${i}`);
        headers.push(`Generalized Anxiety Disorder Score`);
        for (let i = 1; i <= geriatricDepressionScale; i++) headers.push(`Geriatric Depression Scale Question ${i}`);
        headers.push(`Geriatric Depression Scale Score`);

        let dataArray = [...Array(stats.length + 1)].map(() => Array(headers.length).fill("-"));

        dataArray[0] = headers;

        stats.forEach((s, i) => {
            if (s.createdAt) {
                const results = Object.entries(s.results);

                // Date
                dataArray[i + 1][0] = moment((s.createdAt as any).seconds * 1000).format("DD/MM/YYYY HH:mm:ss");
                // Id Participant
                dataArray[i + 1][1] = `${participants.find(p => p.id === s.participantId)?.firstName} (${s.participantId})`;
                // Score
                dataArray[i + 1][2] = s.score;
                // Questionnaire
                dataArray[i + 1][3] = s.questionnaireName;
                // Topic
                dataArray[i + 1][4] = s.topic;
                // Scores
                const scoreIndex = dataArray[0].findIndex((x: any) => x === `${extractQuestionnaireName(s.questionnaireName)} Score`);
                dataArray[i + 1][scoreIndex] = s.score;

                for (let j = 0; j < results.length; j++) {
                    let headerIndex = -1;

                    if (s.questionnaireName === "healthResourceUtilization" && results[j][0].includes("question")) {
                        headerIndex = dataArray[0].findIndex(
                            (x: any) => x === `${extractQuestionnaireName(s.questionnaireName)} Question ${results[j][0].replace("question", "")}`
                        );
                    } else if (results[j][0].includes("question")) {
                        const questionIndex = parseInt(results[j][0].replace("question", ""));

                        headerIndex = dataArray[0].findIndex(
                            (x: any) => x === `${extractQuestionnaireName(s.questionnaireName)} Question ${questionIndex}`
                        );
                    } else {
                        headerIndex = dataArray[0].findIndex(
                            (x: any) => x === `${extractQuestionnaireName(s.questionnaireName)} Question ${parseInt(results[j][0])}`
                        );
                    }

                    if (headerIndex !== -1 && dataArray[i + 1] && dataArray[i + 1][headerIndex] === "-") {
                        dataArray[i + 1][headerIndex] = results[j][1];
                    }
                }
            }
        });

        // Remove empty arrays from main array
        dataArray.forEach(d => {
            if (d && !d[0]) dataArray = dataArray.filter(x => x !== d);
        });

        return dataArray;
    };

    const exportToXLSX = async () => {
        const fileName = `stats_${moment(new Date()).format("DD-MM-YYYY")}`;
        const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
        exportAOADocument(await formatData(), fileName, sheetName);
    };

    useEffect(() => {
        setLoading(true);
        fetchAllParticipants();
        fetchCount();
        const unsub = fetchFirstStat();
        setLoading(false);

        return () => {
            typeof unsub === "function" && unsub();
        };
    }, []);

    return (
        <>
            <Grid item container>
                <Grid item xs={10}>
                    Stat
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" onClick={() => exportToXLSX()} style={{ float: "right", marginTop: 20 }}>
                        Export
                    </Button>
                </Grid>
            </Grid>

            <ParticipantTable
                loading={loading}
                data={stats}
                count={count}
                rows={StatsTableRows(participants)}
                noMoreData={stats.length <= count}
                rowsPerPage={settings.page.rowsPerPage}
                page={page}
                onChangePage={handlePageChange}
                onDoubleClick={(s: Stat) => hist.push(`/dashboard/stat/${s.id}`)}
            />
        </>
    );
};

export default Stats;
