import React from "react";
import moment from "moment";

// Mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";

// Utils
import { exportAOADocument } from "../../../../../../utils/xlsx";
import { fetchQuestionNumber } from "../../../../../../utils/general";
import { exerciseFallsCalendar  } from "../../../../portal/questionnaires/data/PhysicalExerciseActivityData";

const MAX_ENTRIES = 5;

type Props = {
    falls: any[];
};

const Falls: React.FC<Props> = ({ falls }) => {
    const fetchHeaders = (maxEntries: number | undefined = undefined) => {
        const headers: any[] = [];
        if (falls && falls.length) {
            let dates: any[] = [];

            if (maxEntries) {
                dates = falls
                    .sort((a: any, b: any) => {
                        if (a.date) return new Date(a.date.seconds * 1000).getTime() > new Date(b.date.seconds * 1000).getTime() ? -1 : 1;
                        return -1;
                    })
                    .slice(0, maxEntries)
                    .map(x => moment(x.date.seconds * 1000).format("DD/MM/YYYY"));
            } else {
                dates = falls
                    .sort((a: any, b: any) => {
                        if (a.date) return new Date(a.date.seconds * 1000).getTime() > new Date(b.date.seconds * 1000).getTime() ? -1 : 1;
                        return -1;
                    })
                    .map(x => moment(x.date.seconds * 1000).format("DD/MM/YYYY"));
            }
            headers.push(["Questions", ...dates]);
        }

        return headers;
    };

    const fetchRows = (maxEntries: number | undefined = undefined) => {
        const rows: any[] = [];

        Object.entries(exerciseFallsCalendar.questions).forEach((e: any) => {
            const row = [];
            row.push(`${e[1].questionNumber}. ${e[1].question}`);

            falls.forEach(x => {
                const value = x.value.find((y: any) => Object.keys(y)[0] === `question${e[1].questionNumber}`);

                if (value) {
                    if (Array.isArray(value[`question${e[1].questionNumber}`])) {
                        row.push(value[`question${e[1].questionNumber}`].join(", "));
                    } else row.push(value[`question${e[1].questionNumber}`]);
                } else row.push("-");
            });

            if (maxEntries) rows.push(row.slice(0, maxEntries + 1));
            else rows.push(row);
        });

        return rows.sort((a: any, b: any) => {
            if (fetchQuestionNumber(a[0]) > fetchQuestionNumber(b[0])) return 1;
            if (fetchQuestionNumber(a[0]) < fetchQuestionNumber(b[0])) return -1;
            if (isNaN(parseInt(a[0][a[0].indexOf(".") - 1])) && isNaN(parseInt(b[0][b[0].indexOf(".") - 1]))) {
                // same question number - compare the subquestions
                if (a[0][a[0].indexOf(".") - 1] > b[0][b[0].indexOf(".") - 1]) {
                    return 1;
                } else return -1;
            } else return 1;
        });
    };

    const formatData = () => {
        const data = [];

        data.push(fetchHeaders()[0]);
        fetchRows().forEach(r => data.push(r));

        return data;
    };

    const exportToXLSX = async () => {
        const fileName = `fall_summaries_${moment(new Date()).format("DD-MM-YYYY")}`;
        const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
        exportAOADocument(formatData(), fileName, sheetName);
    };

    return (
        <>
            <span style={{ paddingBottom: 10, color: "#8a8a8a", fontWeight: "normal", fontSize: "1.5rem" }}>Exercise & Falls calendar</span>
            <Button variant="contained" style={{ float: "right" }} onClick={exportToXLSX}>
                Export
            </Button>

            {falls.length ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            {fetchHeaders(MAX_ENTRIES).map(header => (
                                <TableRow key={header}>
                                    {header.map((element: any) => (
                                        <TableCell align="center" style={{ fontWeight: "bold" }}>
                                            {element}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>

                        {fetchRows(MAX_ENTRIES).length === 0 && (
                            <TableBody>
                                {Array(11).map(row => (
                                    <TableRow key={row[0]}>-</TableRow>
                                ))}
                            </TableBody>
                        )}

                        <TableBody>
                            {fetchRows(MAX_ENTRIES).map(row => (
                                <TableRow key={row[0]}>
                                    {row.map((element: any, i: number) => (
                                        <TableCell key={element} align={i === 0 ? "left" : "center"} style={i === 0 ? { fontWeight: "bold" } : {}}>
                                            {element}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div>No data</div>
            )}
        </>
    );
};

export default Falls;
