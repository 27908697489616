import React from "react";
import { useSnackbar } from "notistack";

// Mui core
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Settings
import settings from "../../../../settings.json";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import { emailRegex } from "../../../../utils/regex";

// Db
import { auth, functions } from "../../../../firebase/firebase";

// Firebase
import { sendPasswordResetEmail } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { firestore } from "../../../../firebase/firebase";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";

// Interfaces
import { AdminUser } from "../../../../interfaces/AdminUser";

// yup validation
const requiredMessage = "Required field";

const userSchema = yup.object({
    firstName: yup.string().required(requiredMessage),
    lastName: yup.string().required(requiredMessage),
    email: yup.string().matches(emailRegex, "Invalid email").required(requiredMessage),
    roles: yup.array(yup.string().oneOf(settings.app.dashboardRoles)).required(requiredMessage),
});

interface Props {
    open: boolean;
    setOpen: any;
    userId: string;
}

const UserDialog: React.FC<Props> = ({ open, setOpen }) => {
    const { currentAdmin, loading, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    const defaultValues = {
        firstName: "",
        lastName: "",
        email: "",
        roles: [],
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({ resolver: yupResolver(userSchema), defaultValues });

    const onSubmit = async (data: AdminUser) => {
        try {
            const user: AdminUser = { ...data };
            setLoading(true);
            const createAdminUser = httpsCallable(functions, "createAdminUser");
            await createAdminUser(user);

            await sendPasswordResetEmail(auth, user.email);

            const logPayload = await addDoc(collection(firestore, "Logs"), {
                createdAt: new Date(),
                updatedAt: new Date(),
                isDeleted: false,
                adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
                reason: `Created admin ${data && (data as any).email ? (data as any).email : (data as any).id}`,
            });

            await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });

            setLoading(false);
            enqueueSnackbar("Created admin", { variant: "success" });

            setOpen(false);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => !loading && setOpen(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="scroll-dialog-title">Admin creation</DialogTitle>
                <DialogContent>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.firstName?.message}
                                error={!!errors.firstName?.message}
                                label="Firstname"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="firstName"
                        control={control}
                    />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.lastName?.message}
                                error={!!errors.lastName?.message}
                                label="Lastname"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="lastName"
                        control={control}
                    />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.email?.message}
                                error={!!errors.email?.message}
                                label="Email"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="email"
                        control={control}
                    />
                    <Controller
                        name="roles"
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="roles-id">Roles</InputLabel>
                                <Select
                                    labelId="roles-id"
                                    multiple
                                    {...field}
                                    inputProps={{ readOnly: currentAdmin && currentAdmin.roles.includes(settings.app.highestRole) ? false : true }}
                                >
                                    {settings.app.dashboardRoles.map(r => (
                                        <MenuItem key={r} value={r}>
                                            {r}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                    <DialogActions>
                        <Button variant="contained" onClick={() => setOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit" color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default UserDialog;
