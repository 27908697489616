import React from "react";

// Custom components
import Title from "../../general/Title";
import Chat from "../../general/Chat";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

const GlobalChat: React.FC = () => {
    const { currentAdmin } = useFetchUser();

    return (
        <>
            <Title>Global Chat</Title>
            {currentAdmin && currentAdmin.id && <Chat adminId={currentAdmin.id} dashboardChat />}
        </>
    );
};

export default GlobalChat;
