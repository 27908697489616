import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { CalendarApi } from "@fullcalendar/react";

// Mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";

// Firebase
import { firestore } from "../../firebase/firebase";
import { getDocs, collection, where, query, addDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { genDoc } from "../../utils/firebase";

// Utils
import { differenceInMinutesBetween2Dates } from "../../utils/date";

// Interfaces
import { Appointment } from "../../interfaces/Appointment";
import { Participant } from "../../interfaces/Participant";
import { AdminUser } from "../../interfaces/AdminUser";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// yup validation
const requiredMessage = "Required field";

const userSchema = yup.object({
    start: yup.date().required(requiredMessage),
    end: yup.date().required(requiredMessage),
    title: yup.string().required(requiredMessage),
    duration: yup.number().min(1, "Should be at least 1 minute").required(requiredMessage),
    coordinatorId: yup.string().required(requiredMessage),
    participantId: yup.string().required(requiredMessage),
    linkUrl: yup.string().required(requiredMessage),
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
    open: boolean;
    selectedEventId: string;
    calendarApi: CalendarApi | null;
    appointments: Appointment[];
    selectedDate: Date | null | undefined;
    coordinatorId: string;
    // eslint-disable-next-line
    setOpen(value: boolean): void;
    // eslint-disable-next-line
    setAppointments(value: any[]): void;
    // eslint-disable-next-line
    setSelectedDate(value: Date | null | undefined): void;
}

const EventDialog: React.FC<Props> = ({
    open,
    selectedEventId = "",
    calendarApi,
    appointments,
    setOpen,
    selectedDate,
    setAppointments,
    coordinatorId,
    setSelectedDate,
}) => {
    const { setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [coordinators, setCoordinators] = useState<AdminUser[]>([]);
    const [inputValue, setInputValue] = React.useState("");
    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

    const defaultValues = {
        start: selectedDate ?? new Date(),
        end: selectedDate ?? new Date(),
        title: "",
        duration: 0,
        coordinatorId,
        participantId: "",
        linkUrl: "",
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({ resolver: yupResolver(userSchema), defaultValues });

    const getParticipantIdFromEmail = async (email: string) => {
        let participantId = "";
        const payload = await getDocs(query(collection(firestore, "Participants"), where("email", "==", email)));
        if (payload) {
            const data = payload.docs.map(d => d.data()) as Participant[];
            if (data && data.length && data[0].id) {
                participantId = data[0].id;
            }
        }

        return participantId;
    };

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);

            if (data.start && data.end) {
                const localEnd = new Date(data.start);
                localEnd.setMinutes(localEnd.getMinutes() + data.duration);

                // Modify
                if (selectedDate && selectedEventId) {
                    const localAppointment: Appointment = {
                        id: selectedEventId,
                        updatedAt: new Date(),
                        isDeleted: false,
                        title: data.title,
                        start: data.start,
                        end: localEnd,
                        participantId: await getParticipantIdFromEmail(data.participantId),
                        coordinatorId,
                        linkUrl: data.linkUrl,
                    };

                    const index = appointments.findIndex(a => a.id === selectedEventId);

                    if (index !== -1 && selectedEventId) {
                        appointments[index] = data;

                        if (moment.isMoment(localAppointment.start)) localAppointment.start = localAppointment.start.toDate();
                        if (moment.isMoment(localAppointment.end)) localAppointment.end = localAppointment.end.toDate();

                        await updateDoc(doc(firestore, "Appointments", selectedEventId), { ...localAppointment });
                        setAppointments(appointments);

                        enqueueSnackbar("Updated appointment", { variant: "success" });
                    }
                }
                // Create
                else {
                    const localAppointment: Appointment = {
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        isDeleted: false,
                        title: data.title,
                        start: data.start,
                        end: localEnd,
                        participantId: await getParticipantIdFromEmail(data.participantId),
                        coordinatorId,
                        linkUrl: data.linkUrl,
                    };

                    if (moment.isMoment(data.start)) data.start = data.start.toDate();
                    if (moment.isMoment(data.end)) data.end = data.end.toDate();

                    const payload = await addDoc(collection(firestore, "Appointments"), localAppointment);
                    await updateDoc(doc(firestore, "Appointments", payload.id), { id: payload.id });

                    data.id = payload.id;

                    setAppointments([...appointments, localAppointment]);

                    enqueueSnackbar("Created appointment", { variant: "success" });
                }
                resetFields();
            }
            setOpen(false);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchEvent = () => {
        if (selectedEventId && calendarApi) {
            const event = calendarApi.getEvents().find(e => selectedEventId === e.id);

            if (event) {
                if (event.start) setValue("start", event.start);
                if (event.end) setValue("end", event.end);
                setValue("title", event.title);
                if (event.start && event.end) {
                    setValue("duration", differenceInMinutesBetween2Dates(event.start, event.end));
                }

                setValue("coordinatorId", event.extendedProps.coordinatorId);
                setValue("participantId", event.extendedProps.participantId);
                setValue("linkUrl", event.extendedProps.linkUrl);
            }
        }
    };

    const fetchParticipants = async () => {
        const payload = await getDocs(collection(firestore, "Participants"));
        const localParticipants = payload.docs.map(genDoc<Participant>());

        setParticipants(localParticipants);
    };

    const fetchCoordinators = async () => {
        const payload = await getDocs(query(collection(firestore, "Users"), where("roles", "array-contains", "coordinator")));

        setCoordinators(payload.docs.map(genDoc<AdminUser>()));
    };

    const resetFields = () => {
        setValue("start", new Date());
        setValue("end", new Date());
        setValue("title", "");
        setValue("duration", 0);
        setValue("coordinatorId", coordinatorId);
        setValue("participantId", "");
        setValue("linkUrl", "");
        setSelectedDate(null);
    };

    const deleteEvent = async () => {
        setConfirmationDialogOpen(true);

        const appointmentToDelete = appointments.find(a => a.id === selectedEventId);

        setAppointments(appointments.filter(a => a.id !== selectedEventId));

        await deleteDoc(doc(firestore, "Appointments", selectedEventId));

        enqueueSnackbar(`Deleted appointment ${appointmentToDelete?.title}`, { variant: "success" });

        setOpen(false);
    };

    useEffect(() => {
        if (selectedEventId && calendarApi) fetchEvent();
    }, [selectedEventId]);

    useEffect(() => {
        fetchParticipants();
        fetchCoordinators();
    }, []);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
                resetFields();
                setOpen(false);
            }}
            aria-describedby="alert-dialog-slide-description"
        >
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: 0 }}>
                    <DialogTitle>{selectedDate && selectedEventId ? "Modify an event" : "Create an event"}</DialogTitle>
                </div>
                <DialogContent>
                    <div>
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            helperText={errors.title?.message}
                                            error={!!errors.title?.message}
                                            label="Title"
                                            fullWidth
                                            {...field}
                                        />
                                    )}
                                    name="title"
                                    control={control}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="start"
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DateTimePicker
                                                disablePast
                                                label="Start"
                                                inputFormat="DD/MM/YYYY HH:mm"
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        fullWidth
                                                        error={errors && errors.start && errors.start}
                                                        helperText={errors && errors.start && errors.start.message}
                                                        style={{ marginBottom: 10 }}
                                                        {...params}
                                                    />
                                                )}
                                                {...field}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            helperText={errors.duration?.message}
                                            error={!!errors.duration?.message}
                                            label="Duration (in minutes)"
                                            fullWidth
                                            {...field}
                                            inputProps={{ step: 15, min: 0 }}
                                        />
                                    )}
                                    name="duration"
                                    control={control}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="participantId"
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            options={participants.map(p => p.email)}
                                            getOptionLabel={option => {
                                                const participant = participants.find(x => x.id === option);
                                                if (participant) return participant.email;
                                                else return option;
                                            }}
                                            renderInput={params => {
                                                params.fullWidth = true;
                                                return <TextField {...params} label="Participant" />;
                                            }}
                                            {...field}
                                            onChange={(event: any, newValue: string | null) => {
                                                if (newValue) {
                                                    setValue("participantId", newValue);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="coordinatorId"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl fullWidth variant="outlined" error={!!errors.coordinatorId?.message}>
                                            <InputLabel id="roles-id">Coordinator</InputLabel>
                                            <Select labelId="roles-id" {...field} inputProps={{ readOnly: true }} disabled>
                                                {coordinators.map((c, i) => (
                                                    <MenuItem key={i} value={c.id}>
                                                        {c.firstName} ({c.email})
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {!!errors.coordinatorId?.message && <FormHelperText>{errors.coordinatorId?.message}</FormHelperText>}
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            helperText={errors.linkUrl?.message}
                                            error={!!errors.linkUrl?.message}
                                            label="Url"
                                            fullWidth
                                            {...field}
                                        />
                                    )}
                                    name="linkUrl"
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <Grid item container>
                    <Grid item xs={6}>
                        {selectedDate && selectedEventId && (
                            <div>
                                <Button onClick={() => setConfirmationDialogOpen(true)}>Delete event</Button>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <DialogActions>
                            <Button id="submitButton" type="submit">
                                {selectedDate && selectedEventId ? "Modify event" : "Add event"}
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </form>
            {confirmationDialogOpen && (
                <Dialog open={confirmationDialogOpen}>
                    <DialogTitle style={{ color: "black" }}>Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete this appointment?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmationDialogOpen(false)}>Cancel</Button>
                        <Button onClick={() => deleteEvent()}>Delete</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Dialog>
    );
};

export default EventDialog;
