// React
import { NavLink } from "react-router-dom";

// Mui
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";

// Firebase
import { auth } from "../../../firebase/firebase";

// Icons
import { MdHome, MdPeople, MdPowerSettingsNew } from "react-icons/md";

type DrawerItemProps = { to: string; onClick?: any; icon?: React.FC; primaryText: string; color?: string };

const DrawerItem: React.FC<DrawerItemProps> = (props: DrawerItemProps) => {
    return (
        <NavLink to={props.to} onClick={() => props.onClick ?? null} className="drawer__nav__item">
            <ListItem button>
                <ListItemIcon>{props.icon ? <props.icon /> : null}</ListItemIcon>
                <ListItemText primary={props.primaryText} color={props.color ?? "primary"} />
            </ListItem>
        </NavLink>
    );
};

const DrawerContent = () => {
    return (
        <div className="drawer__nav">
            <List>
                <ListSubheader inset>Groupe A</ListSubheader>
                <DrawerItem to="/accueil" icon={MdHome} primaryText="Accueil" />
                <DrawerItem to="/utilisateurs" icon={MdPeople} primaryText="Utilisateurs" />
            </List>
            <Divider />
            <List>
                <ListSubheader inset>Paramètres</ListSubheader>
                {/* {currentParticipant && currentParticipant.roles && currentParticipant.roles.length > 0 && currentParticipant.roles.includes(settings.app.highestRole) && (
                    <DrawerItem to="/journaux" icon={MdQueryBuilder} primaryText="Journaux" />
                )} */}
                <div
                    className="drawer__nav__item"
                    onClick={() => {
                        auth.signOut();
                    }}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <MdPowerSettingsNew />
                        </ListItemIcon>
                        <ListItemText primary="Déconnexion" color="primary" />
                    </ListItem>
                </div>
            </List>
        </div>
    );
};

export default DrawerContent;
