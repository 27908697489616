import { ContentBlock } from "draft-js";

import blockTypeConfig, { BlockType } from "./blockType";
import inlineConfig, { InlineStyle } from "./inline";
import textAlignConfig, { TextAlign } from "./textAlign";
import listConfig, { ListControl } from "./list";
import imageConfig, { Image } from "./image";
import twitterConfig, { Twitter } from "./twitter";
import facebookConfig, { Facebook } from "./facebook";
import colorBoxConfig, { ColorBox } from "./colorBox";
import linkConfig, { LinkControl } from "./link";

export interface EditorConfig {
    inline: InlineStyle[];
    block: BlockType;
    textAlign: TextAlign;
    list: ListControl;
    image: Image;
    twitter: Twitter;
    facebook: Facebook;
    colorBox: ColorBox;
    link: LinkControl;
}

const config: EditorConfig = {
    inline: inlineConfig,
    block: blockTypeConfig,
    textAlign: textAlignConfig,
    list: listConfig,
    image: imageConfig,
    twitter: twitterConfig,
    facebook: facebookConfig,
    colorBox: colorBoxConfig,
    link: linkConfig,
};

export const blockStyleFn = (block: ContentBlock) => {
    const type = block.getType();

    switch (type) {
        case "align_left":
            return "align-left";
        case "align_center":
            return "align-center";
        case "align_right":
            return "align-right";
        case "highlight":
            return "draftjs__block__highlight";
        default:
            return "";
    }
};

// first order config type
export type EditorConfigInline = typeof config.inline;
export type EditorConfigBlock = typeof config.block;
export type EditorConfigTextAlign = typeof config.textAlign;
export type EditorConfigList = typeof config.list;
export type EditorConfigImage = typeof config.image;
export type EditorConfigTwitter = typeof config.twitter;
export type EditorConfigFacebook = typeof config.facebook;
export type EditorConfigColorBox = typeof config.colorBox;
export type EditorConfigLink = typeof config.link;

// options config type
export type EditorBlockOptions = typeof config.block.options;
export type EditorTextAlignOptions = typeof config.textAlign.options;
export type EditorListOptions = typeof config.list.options;

export default config;
