import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

// Interfaces
import { AdminUser } from "../interfaces/AdminUser";
import { Participant } from "../interfaces/Participant";

// Firebase
import { auth, firestore } from "../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { genDoc } from "../utils/firebase";

const initState = {
    currentAdmin: null,
    currentParticipant: null,
    fetchingUser: true,
    loading: false,
    // eslint-disable-next-line
    setLoading: (value: any) => {
        //
    },
    yesterdayFallsCalendarCheck: false,
    // eslint-disable-next-line
    setYesterdayFallsCalendarCheck: (value: any) => {
        //
    },
};

const useFetchUserImpl = () => {
    // States
    const [loading, setLoading] = useState(false);
    const [currentAdmin, setCurrentAdmin] = useState<AdminUser | null>(null);
    const [currentParticipant, setCurrentParticipant] = useState<Participant | null>(null);
    const [fetchingUser, setFetchingUser] = useState<boolean>(true);
    const [yesterdayFallsCalendarCheck, setYesterdayFallsCalendarCheck] = useState(false);

    useEffect(() => {
        let unsubParticipant: any = null;
        let unsubAdmin: any = null;

        auth.onAuthStateChanged(async res => {
            if (res) {
                setFetchingUser(true);
                if (res.displayName === "Participants") {
                    unsubParticipant = onSnapshot(doc(firestore, "Participants", res.uid), doc => {
                        const data = genDoc<any>()(doc);

                        setCurrentParticipant(data);
                        setCurrentAdmin(null);
                        setFetchingUser(false);
                    });
                } else {
                    unsubAdmin = onSnapshot(doc(firestore, "Users", res.uid), doc => {
                        const data = genDoc<AdminUser>()(doc);

                        setCurrentAdmin(data);
                        setCurrentParticipant(null);
                        setFetchingUser(false);
                    });
                }
            } else {
                setCurrentAdmin(null);
                setCurrentParticipant(null);
                setFetchingUser(false);
            }
        });
        return () => {
            if (typeof unsubParticipant === "function") unsubParticipant();
            if (typeof unsubAdmin === "function") unsubAdmin();
        };
    }, []);

    return { currentAdmin, currentParticipant, fetchingUser, loading, setLoading, yesterdayFallsCalendarCheck, setYesterdayFallsCalendarCheck };
};

export const useFetchUser = singletonHook(initState, useFetchUserImpl);
