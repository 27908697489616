import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import { genDoc } from "../../../../utils/firebase";

// Custom components
import Title from "../../../general/Title";

// Interfaces
import { Stat as StatInterface } from "../../../../interfaces/Stat";

// Utils
import { fetchQuestion } from "../../../../utils/pdf";
import moment from "moment";

interface Params {
    id: string;
}

const Stat: React.FC = () => {
    const { id } = useParams<Params>();

    // States
    const [stat, setStat] = useState<StatInterface | null>(null);
    const [formattedElements, setFormattedElements] = useState<any>([]);

    const fetchStat = async () => {
        const payload = await getDoc(doc(firestore, "Stats", id));
        const localStat = genDoc<StatInterface>()(payload);
        setStat(localStat);
    };

    const formatStat = () => {
        if (stat) {
            const localElements: any[][] = [];
            Object.entries(stat.results)
                .filter(
                    (x: any) =>
                        x && x[0] !== "noTestsInvestigations" && x[0] !== "noVisitHospitalDepartment" && x[0] !== "noVisitHospitalProfessional"
                )
                .sort((a, b) => a[0].localeCompare(b[0], "en", { numeric: true }))
                .map((e: any) => {
                    // Question
                    const questionText = `${e[0].toUpperCase().replace("QUESTION", "Question ")}: ${fetchQuestion(
                        stat.questionnaireName,
                        e[0].replace("question", "")
                    )}`;

                    // Answer
                    const answerText = e[1];

                    localElements.push([questionText, answerText]);
                });

            setFormattedElements(localElements);
        }
    };

    const formatValue = (value: any) => {
        if (typeof value === "string") {
            if (!value) return "-";
            return value;
        } else if (Array.isArray(value)) {
            return value.join(", ");
        } else return moment(value.seconds * 1000).format("hh:mm:ss");
    };

    useEffect(() => {
        if (id) fetchStat();
    }, [id]);

    useEffect(() => {
        if (stat) formatStat();
    }, [stat]);

    return (
        <>
            <Title>Stat</Title>

            {formattedElements.length ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" style={{ fontWeight: "bold" }}>
                                    Question
                                </TableCell>
                                <TableCell align="center" style={{ fontWeight: "bold" }}>
                                    Answer
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formattedElements.map((row: any) => (
                                <TableRow key={row[0]}>
                                    {row.map((element: any, i: number) => (
                                        <TableCell
                                            key={element}
                                            align={typeof element === "string" && element.includes(":") ? "left" : "center"}
                                            style={i === 0 ? { fontWeight: "bold" } : {}}
                                        >
                                            {formatValue(element)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div>No data</div>
            )}
        </>
    );
};

export default Stat;
