import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// Custom components
import ComorbidityIssuesAndCompaints from "./forms/ComorbidityIssuesAndCompaints";
import LogbookForOngoingMedications from "./forms/LogbookForOngoingMedications";
import NeuropsychologicalTestScoresTable from "./forms/NeuropsychologicalTestScoresTable";
import SummaryReportTable from "./forms/SummaryReportTable";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import { genDoc } from "../../../utils/firebase";

// Interfaces
import { Participant } from "../../../interfaces/Participant";
import LogbookForMedicalNotes from "./forms/LogbookForMedicalNotes";

const Form = () => {
    const hist = useHistory();
    const { participantId, formName } = useParams<{ participantId: string; formName: string }>();

    // States
    const [participant, setParticipant] = useState<Participant | null>(null);

    const fetchParticipant = async () => {
        const payload = await getDoc(doc(firestore, "Participants", participantId));
        setParticipant(genDoc<Participant>()(payload));
    };

    const handleSubmit = async (data: any) => {
        if (participant && participantId) {
            if (formName === "comorbidityIssuesAndComplaints") {
                await updateDoc(doc(firestore, "Participants", participantId), {
                    forms: {
                        ...participant.forms,
                        comorbidityIssuesAndComplaints: data,
                    },
                });
                hist.push(`/dashboard/participant/${participantId}/bio/0`);
            }
            if (formName === "logbookForOngoingMedications") {
                await updateDoc(doc(firestore, "Participants", participantId), {
                    forms: {
                        ...participant.forms,
                        logbookForOngoingMedications: data,
                    },
                });
                hist.push(`/dashboard/participant/${participantId}/bio/0`);
            }
            if (formName === "neuropsychologicalTestScoresTable") {
                await updateDoc(doc(firestore, "Participants", participantId), {
                    forms: {
                        ...participant.forms,
                        neuropsychologicalTestScoresTable: data,
                    },
                });
                hist.push(`/dashboard/participant/${participantId}/bio/0`);
            }
            if (formName === "summaryReportTable") {
                await updateDoc(doc(firestore, "Participants", participantId), {
                    forms: {
                        ...participant.forms,
                        summaryReportTable: data,
                    },
                });
                hist.push(`/dashboard/participant/${participantId}/bio/0`);
            }
            if (formName === "logbookForMedicalNotes") {
                await updateDoc(doc(firestore, "Participants", participantId), {
                    forms: {
                        ...participant.forms,
                        logbookForMedicalNotes: data,
                    },
                });
            }
        }
    };

    useEffect(() => {
        fetchParticipant();
    }, [participantId]);

    return (
        <div>
            {formName === "comorbidityIssuesAndComplaints" && (
                <ComorbidityIssuesAndCompaints participantId={participantId} onComplete={handleSubmit} />
            )}
            {formName === "logbookForOngoingMedications" && <LogbookForOngoingMedications participantId={participantId} onComplete={handleSubmit} />}
            {formName === "neuropsychologicalTestScoresTable" && (
                <NeuropsychologicalTestScoresTable participantId={participantId} onComplete={handleSubmit} />
            )}
            {formName === "summaryReportTable" && <SummaryReportTable participantId={participantId} onComplete={handleSubmit} />}
            {formName === "logbookForMedicalNotes" && <LogbookForMedicalNotes participantId={participantId} onComplete={handleSubmit} />}
        </div>
    );
};

export default Form;
