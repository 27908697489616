import { EditorState, convertToRaw } from "draft-js";

export const saveAssets = async (editorState: EditorState, assetTypes: string[] = ["IMAGE"]) => {
    const contentState = editorState.getCurrentContent();
    const entities = contentState.getEntityMap().__getAll().toJS();
    // eslint-disable-next-line
    const assetEntities = Object.entries(entities).filter(([_, value]: any) => assetTypes.includes(value.type));

    const entitiesUploaded = await Promise.all(
        assetEntities.map(async ([key, entity]: any) => {
            const { src } = entity.data;

            if (!src) {
                console.error(`saveAssets: src is undefined. Skipping entity ${entity} with key: `, key);
                return [key, entity];
            }

            if (src.includes("firebase")) {
                return [key, entity];
            }

            return [key, entity];
        })
    );

    entitiesUploaded.forEach(([key, entity]: any) => {
        contentState.replaceEntityData(key, entity.data);
    });

    return EditorState.push(editorState, contentState, "change-block-data");
};

export const convertToRawJSON = (editorState: EditorState) => convertToRaw(editorState.getCurrentContent());

export const convertToRawString = (editorState: EditorState) => JSON.stringify(convertToRawJSON(editorState));
