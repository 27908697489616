import moment from "moment";

// Interfaces
import { Stat } from "../../../../interfaces/Stat";
import { Participant } from "../../../../interfaces/Participant";

// Utils
import { convertObjectIntoDate } from "../../../../utils/date";

const StatsTableRows = (participants: Participant[]) => {
    return [
        {
            header: "Date",
            body: (s: Stat) => {
                const date = convertObjectIntoDate(s.createdAt);

                if (date) return moment(date).format("YYYY-MM-DD");

                return "Invalid Date";
            },
        },
        {
            header: "Participant",
            body: (s: Stat) => {
                if (participants && participants.some(p => p.id === s.participantId)) {
                    return participants.find(p => p.id === s.participantId)?.email;
                }
                return "";
            },
        },
        {
            header: "Questionnaire Name",
            body: (s: Stat) => s.questionnaireName,
        },
        {
            header: "Score",
            body: (s: Stat) => {
                if (typeof s.score === "number" && !isNaN(s.score)) return s.score;
                else if (typeof s.score === "object") {
                    if (s.questionnaireName === "eatingPattern") {
                        return `raw: ${s.score.rawScore}, brainHealthyEatingIndexScore: ${s.score.brainHealthyEatingIndexScore}`;
                    }
                    if (s.questionnaireName === "qualityOfLife") {
                        return (
                            <div>
                                <p style={{ color: "black" }}>Physical functioning: {Math.round(s.score.physicalFunctioning)}% </p>
                                <p style={{ color: "black" }}>
                                    Role limitations due to physical health: {Math.round(s.score.roleLimitationsDueToPhysicalHealth)}%
                                </p>
                                <p style={{ color: "black" }}>
                                    Role limitations due to emotional problems: {Math.round(s.score.roleLimitationsDueToEmotionalProblems)}%
                                </p>
                                <p style={{ color: "black" }}>Vitality, energy and fatigue: {Math.round(s.score.vitalityEnergyAndFatigue)}%</p>
                                <p style={{ color: "black" }}>General Mental Health: {Math.round(s.score.generalMentalHealth)}%</p>
                                <p style={{ color: "black" }}>Social functioning: {Math.round(s.score.socialFunctioning)}%</p>
                                <p style={{ color: "black" }}>Bodily Pain: {Math.round(s.score.bodilyPain)}%</p>
                                <p style={{ color: "black" }}>General Health Perceptions: {Math.round(s.score.generalHealthPerceptions)}%</p>
                                <p style={{ color: "black" }}>Health changes: {Math.round(s.score.healthChange)}%</p>
                                <p style={{ color: "black" }}>Total: {Math.round(s.score.total)}%</p>
                            </div>
                        );
                    }
                } else return 0;
            },
        },
        {
            header: "Topic",
            body: (s: Stat) => s.topic,
        },
    ];
};

export default StatsTableRows;
