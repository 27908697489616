import React from "react";
import { useHistory } from "react-router-dom";

// Mui
import Grid from "@mui/material/Grid";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Icons
import { IoChevronBack } from "react-icons/io5";

// Custom components
import ParticipantChat from "../../general/ParticipantChat";
import NextMeeting from "../../general/NextMeeting";

// Firebase
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";

const Profile: React.FC = () => {
    const hist = useHistory();
    const { currentParticipant } = useFetchUser();

    React.useEffect(() => {
        if (currentParticipant && currentParticipant.id) {
            // Participant log
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...currentParticipant.logs, { date: new Date(), action: "Visited profile page" }],
            });
        }
    }, []);

    return (
        <Grid container spacing={8} className="profile">
            <Grid item md={6}>
                <h1>Profile</h1>

                <NextMeeting />

                <Grid item md={12} className="home__optionsContainer">
                    <Grid item xs={12} onClick={() => hist.push("/")} className="home__options__card">
                        <h4 className="home__options__title">Back to Home</h4>
                        <IoChevronBack className="home__options__image" />
                        <IoChevronBack className="home__options__background" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={6} className="home__section">
                <h1 className="home__title">Coordinator chat</h1>
                <div>
                    {currentParticipant && currentParticipant.id && <ParticipantChat participantId={currentParticipant.id} isFullscreen={true} />}
                </div>
            </Grid>
        </Grid>
    );
};

export default Profile;
