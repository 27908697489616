import React from "react";

// Mui
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

// Utils
import settings from "../../settings.json";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
}));

const Progress: React.FC = () => {
    const { currentParticipant } = useFetchUser();

    const getTopicBaseValue = (topic: "Physical Exercise" | "Cognitive Training" | "Diet" | "Health" | "Sleep") => {
        let value = 0;

        if (currentParticipant) {
            switch (topic) {
                default:
                case "Physical Exercise":
                    value = currentParticipant.progresses.physical;
                    break;
                case "Cognitive Training":
                    value = currentParticipant.progresses.cognitive;
                    break;
                case "Diet":
                    value = currentParticipant.progresses.diet;
                    break;
                case "Health":
                    value = currentParticipant.progresses.health;
                    break;
                case "Sleep":
                    value = currentParticipant.progresses.sleep;
                    break;
            }
        }

        return value;
    };

    const getTopicValue = (topic: "Physical Exercise" | "Cognitive Training" | "Diet" | "Health" | "Sleep", isPositive: boolean) => {
        let value = 0;

        if (currentParticipant) {
            switch (topic) {
                default:
                case "Physical Exercise":
                    if (isPositive && currentParticipant.progresses.physical > 50) value = 100 - (100 - currentParticipant.progresses.physical) * 2;
                    if (!isPositive && currentParticipant.progresses.physical < 50) value = 100 - currentParticipant.progresses.physical * 2;
                    break;
                case "Cognitive Training":
                    if (isPositive && currentParticipant.progresses.cognitive > 50) value = 100 - (100 - currentParticipant.progresses.cognitive) * 2;
                    if (!isPositive && currentParticipant.progresses.cognitive < 50) value = 100 - currentParticipant.progresses.cognitive * 2;
                    break;
                case "Diet":
                    if (isPositive && currentParticipant.progresses.diet > 50) value = 100 - (100 - currentParticipant.progresses.diet) * 2;
                    if (!isPositive && currentParticipant.progresses.diet < 50) value = 100 - currentParticipant.progresses.diet * 2;
                    break;
                case "Health":
                    if (isPositive && currentParticipant.progresses.health > 50) value = 100 - (100 - currentParticipant.progresses.health) * 2;
                    if (!isPositive && currentParticipant.progresses.health < 50) value = 100 - currentParticipant.progresses.health * 2;
                    break;
                case "Sleep":
                    if (isPositive && currentParticipant.progresses.sleep > 50) value = 100 - (100 - currentParticipant.progresses.sleep) * 2;
                    if (!isPositive && currentParticipant.progresses.sleep < 50) value = 100 - currentParticipant.progresses.sleep * 2;
                    break;
            }
        }

        return value;
    };

    return (
        <>
            <Grid item container spacing={2} className="progress">
                <Grid item className="progress__topics progress__topCorners" xs={4}>
                    <h6>Topics</h6>
                </Grid>
                <Grid item xs={4}>
                    <h6 className="progress__worse">Needs improvement</h6>
                </Grid>
                <Grid item xs={4}>
                    <h6 className="progress__better">On the right track</h6>
                </Grid>

                {settings.app.topics.map((t, i) => (
                    <React.Fragment key={i}>
                        <Grid item className="progress__topics" xs={4}>
                            <p>{t}</p>
                        </Grid>
                        <Grid item className="progress__bar" xs={8}>
                            <Tooltip title={getTopicBaseValue(t as any)} placement="top">
                                <Grid item container>
                                    <Grid item xs={6}>
                                        <BorderLinearProgress
                                            variant="determinate"
                                            value={getTopicValue(t as any, false)}
                                            color="secondary"
                                            style={{ transform: "rotate(180deg)" }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <BorderLinearProgress variant="determinate" value={getTopicValue(t as any, true)} />
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </>
    );
};

export default Progress;
