import React from "react";

// Assets
import playIcon from "../../assets/icons/playIcon.png";

interface Props {
    title: string;
    // eslint-disable-next-line
    onClick: (ev: any) => void;
}

const MediaCard: React.FC<Props> = ({ title, onClick }) => {
    return (
        <div onClick={onClick} className="mc">
            <div className="mc__childContainer centered__parent">
                <img className="mc__image nofilterimg" src={playIcon} alt={`${title}-image`} />
            </div>
            <div className="mc__titleContainer">
                <h5 className="mc__title">{title}</h5>
            </div>
        </div>
    );
};

export default MediaCard;
