import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Mui core
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Custom components
import TabPanel from "../../../../general/TabPanel";
import Data from "./dietTabs/Data";
import WeeklyDiet from "./dietTabs/WeeklyDiet";

// Icons
import { MdOutlineRestaurant, MdAttachFile } from "react-icons/md";

const Diet: React.FC<any> = ({ participant = null, index, tabName }) => {
    const hist = useHistory();

    // States
    const [tab, setTab] = useState(0);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const mobileScreenSize = 500;
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

    const tabs: any = [
        {
            label: "Data",
            icon: <MdAttachFile />,
            component: Data,
        },
        {
            label: "Weekly Diet",
            icon: <MdOutlineRestaurant />,
            component: WeeklyDiet,
        },
    ];

    useEffect(() => {
        setTab(parseInt(index));
    }, [index]);

    return (
        <div>
            <div>
                <div className="tab__background__color">
                    <Tabs
                        variant="fullWidth"
                        value={tab}
                        onChange={(e, v) => participant && participant.id && hist.push(`/dashboard/participant/${participant.id}/${tabName}/${v}`)}
                    >
                        {tabs.map((t: any) => (
                            <Tab key={t.label} icon={t.icon} label={screenWidth >= mobileScreenSize ? t.label : ""} />
                        ))}
                    </Tabs>
                </div>
                {tabs.map((t: any, i: number) => (
                    <TabPanel key={i} value={tab} index={i}>
                        <t.component participant={participant} />
                    </TabPanel>
                ))}
            </div>
        </div>
    );
};

export default Diet;
