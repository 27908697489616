import { FaBold, FaItalic, FaUnderline, FaStrikethrough, FaCode } from "react-icons/fa";
import { InlineStyle } from "./interface";

const inlineConfig: InlineStyle[] = [
    { style: "bold", title: "Gras", Icon: FaBold },
    { style: "italic", title: "Italic", Icon: FaItalic },
    { style: "underline", title: "Souligner", Icon: FaUnderline },
    { style: "strikethrough", title: "Barrer", Icon: FaStrikethrough },
    { style: "code", title: "Monospace", Icon: FaCode },
];

export type { InlineStyle };
export default inlineConfig;
