import { useState, useMemo, CSSProperties, MouseEvent, memo } from "react";
import { ContentBlock, ContentState } from "draft-js";

import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";

import { AiOutlinePicLeft, AiOutlinePicCenter, AiOutlinePicRight } from "react-icons/ai";
import { IconType } from "react-icons/lib";

interface RendererProps {
    contentState: ContentState;
    block: ContentBlock;
    [index: string]: any;
}

const popoverWrapperStyle: CSSProperties = { display: "flex", justifyContent: "space-evenly", alignItems: "center", minWidth: 150, minHeight: 40 };

const getAlignStyle = (selected: boolean) => {
    const s: CSSProperties = { fontSize: 20, cursor: "pointer" };

    if (selected) s.color = "grey";
    else s.color = "black";

    return s;
};

type Float = "left" | "right" | "none";

const alignIcons: { Icon: IconType; value: Float; title: string }[] = [
    { Icon: AiOutlinePicLeft, value: "left", title: "Aligner à gauche" },
    { Icon: AiOutlinePicCenter, value: "none", title: "Centrer" },
    { Icon: AiOutlinePicRight, value: "right", title: "Aligner à droite" },
];

const ImageRenderer = ({ contentState, block }: RendererProps) => {
    const entity = contentState.getEntity(block.getEntityAt(0));
    const { src, caption, width, height, alignment } = entity.getData();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [clicked, setClicked] = useState<boolean>(false);
    const [mouseIn, setMouseIn] = useState<boolean>(false);
    const [align, setAlign] = useState<Float>(alignment);
    const [tooltipTitle, setTooltipTitle] = useState<string>("");

    const imgStyle = useMemo(() => {
        const s: CSSProperties = { cursor: "pointer", margin: "auto" };

        const outlineStyle = "2px solid grey";
        if (clicked) s.outline = outlineStyle;
        if (mouseIn) s.outline = outlineStyle;

        return s;
    }, [clicked, mouseIn]);

    const figureStyle = useMemo(() => {
        const s: CSSProperties = { float: align, display: "flex", flexDirection: "column" };

        return s;
    }, [align]);

    const handleClick = (e: MouseEvent<HTMLImageElement>) => {
        setAnchorEl(e.currentTarget);
        setClicked(true);
    };

    const handleEnter = () => {
        setMouseIn(true);
    };

    const handleLeave = () => {
        setMouseIn(false);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setClicked(false);
    };

    const handleAlignChange = (value: Float) => {
        setAlign(value);
        contentState.replaceEntityData(block.getEntityAt(0), { src, caption, width, height, alignment: value });
    };

    return (
        <>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Tooltip title={tooltipTitle}>
                    <div style={popoverWrapperStyle}>
                        {alignIcons.map(({ Icon, value, title }, i: number) => (
                            <Icon
                                key={i}
                                style={getAlignStyle(align === value)}
                                onMouseEnter={() => {
                                    setTooltipTitle(title);
                                }}
                                onClick={() => {
                                    handleAlignChange(value);
                                }}
                            />
                        ))}
                    </div>
                </Tooltip>
            </Popover>
            <figure onMouseEnter={handleEnter} onMouseLeave={handleLeave} onClick={handleClick} style={figureStyle}>
                <img src={src} alt="" width={width} height={height} style={imgStyle} className="nofilterimg" />
                {!!caption && <figcaption style={{ textAlign: "center" }}>{caption}</figcaption>}
            </figure>
        </>
    );
};

const areEqual = (prevProps: any, nextProps: any) => {
    const pEntity = prevProps.contentState.getEntity(prevProps.block.getEntityAt(0));
    const nEntity = nextProps.contentState.getEntity(nextProps.block.getEntityAt(0));

    const pData = pEntity.getData();
    const nData = nEntity.getData();

    if (pData.src === nData.src && pData.height === nData.height && pData.width === nData.width && pData.alignment === nData.alignment) return true;

    return false;
};

export default memo(ImageRenderer, areEqual);
