import React from "react";
import moment from "moment";

// Mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";

// Utils
import { exportAOADocument } from "../../../../../../utils/xlsx";
import { fetchQuestionNumber } from "../../../../../../utils/general";

const MAX_ENTRIES = 5;

type Props = {
    events: any[];
};

const SleepEntries: React.FC<Props> = ({ events }) => {
    const fetchHeaders = (maxEntries: number | undefined = undefined) => {
        const headers: any[] = [];
        if (events && events.length) {
            let dates: any[] = [];

            if (maxEntries) {
                dates = events
                    .sort((a: any, b: any) => {
                        if (a.date) return new Date(a.date.seconds * 1000).getTime() > new Date(b.date.seconds * 1000).getTime() ? -1 : 1;
                        return -1;
                    })
                    .slice(0, maxEntries)
                    .map(x => moment(x.date.seconds * 1000).format("DD/MM/YYYY"));
            } else {
                dates = events
                    .sort((a: any, b: any) => {
                        if (a.date) return new Date(a.date.seconds * 1000).getTime() > new Date(b.date.seconds * 1000).getTime() ? -1 : 1;
                        return -1;
                    })
                    .map(x => moment(x.date.seconds * 1000).format("DD/MM/YYYY"));
            }
            headers.push(["Questions", ...dates]);
        }

        return headers;
    };

    const replaceVarNameByQuestionName = (varName: string) => {
        switch (varName) {
            case "question1":
                return "1. Yesterday, I took a nap for ______ minutes.";
            case "question2":
                return "2. I went to bed at _____.";
            case "question3":
                return "3. I was able to go to bed at my preferred time (as early or late as desired)";
            case "question4":
                return "4. I turned off the lights at _____.";
            case "question5":
                return "5. Once the lights went out, I fell asleep in about _________ minutes.";
            case "question6":
                return "6. During the night, I woke up for about _______ minutes.";
            case "question7":
                return "7. This morning I woke up at _____.";
            case "question8":
                return "8. I got up at ______.";
            case "question9":
                return "9. I woke up spontaneously (i.e. without an alarm clock or any external noise or light) yes/no.";
            case "question10":
                return "10. I find that my sleep has been:";
            default:
                return "";
        }

        // if (varName === "bedTime") return "1. What time did you get into bed?";
        // if (varName === "sleepTryTime") return "2. What time did you try to go to sleep?";
        // if (varName === "fallAsleepDuration") return "3. How long did it take you to fall asleep?";
        // if (varName === "wakeupCount") return "4. How many times did you wake up, not counting your final awakening?";
        // if (varName === "awakeningDuration") return "5. In total, how long did these awakenings last?";
        // if (varName === "finalAwakeningTime") return "6a. What time was your final awakening?";
        // if (varName === "finalAwakeningDuration") return "6b. After your final awakening, how long did you spend in your bed trying to sleep?";
        // if (varName === "wakeupEarlier") return "6c. Did you wake up earlier than you planned?";
        // if (varName === "wakeupEarlierCount") return "6d. If yes, how much earlier?";
        // if (varName === "getupTime") return "7. What time did you get out of bed for the day?";
        // if (varName === "totalSleepCount") return "8. In total, how long did you sleep?";
        // if (varName === "sleepQuality") return "9. How would you rate the quality of your sleep?";
        // if (varName === "restedIndex") return "10. How rested or refreshed did you feel when you woke-up for the day?";
        // if (varName === "napCount") return "11a. How many times did you nap or doze?";
        // if (varName === "napDuration") return "11b. In total, how long did you nap or doze?";
        // if (varName === "drinkCount") return "12a. How many drinks containing alcohol did you have?";
        // if (varName === "lastDrinkTime") return "12b. What time was your last drink?";
        // if (varName === "sodaCount") return "13a. How many caffeinated drinks (coffee, tea, soda, energy drinks) did you have?";
        // if (varName === "lastSodaTime") return "13b. What time was your last drink?";
        // if (varName === "takenMedication") return "14a. Did you take any over-the-counter or prescription medication(s) to help you sleep?";
        // if (varName === "medications") return "14b. Medication(s) taken";
        // if (varName === "dose") return "14c. Dose";
        // if (varName === "medicationTime") return "14d. Time taken";
        // if (varName === "comments") return "15. Comments (if applicable)";
    };

    const fetchRows = (maxEntries: number | undefined = undefined) => {
        const rows: any[] = [];

        if (events && events.length) {
            Object.entries(events[0].value)
                .sort((a: any, b: any) => {
                    if (a.date) return new Date(a.date.seconds * 1000).getTime() > new Date(b.date.seconds * 1000).getTime() ? -1 : 1;
                    return -1;
                })
                .forEach((e: any) => {
                    const row = [];

                    // Questions
                    row.push(replaceVarNameByQuestionName(e[0]));

                    events.forEach(event => {
                        const dailyData = event.value[e[0]];

                        if (typeof dailyData !== "undefined") {
                            if (dailyData.seconds) {
                                row.push(moment(dailyData.seconds * 1000).format("HH:mm:ss"));
                            } else {
                                if (Array.isArray(dailyData)) row.push(dailyData.join(", "));
                                else if (e[0] === "question10") {
                                    const question10Array = ["Very relaxing", "Restful", "Neutral", "Not very relaxing", "Not restful at all"];
                                    row.push(question10Array[dailyData - 1]);
                                } else row.push(dailyData);
                            }
                        } else row.push("n/a");
                    });

                    if (maxEntries) rows.push(row.slice(0, maxEntries + 1));
                    else rows.push(row);
                });
        }

        return rows.sort((a: any, b: any) => {
            if (fetchQuestionNumber(a[0]) > fetchQuestionNumber(b[0])) return 1;
            if (fetchQuestionNumber(a[0]) < fetchQuestionNumber(b[0])) return -1;
            if (isNaN(parseInt(a[0][a[0].indexOf(".") - 1])) && isNaN(parseInt(b[0][b[0].indexOf(".") - 1]))) {
                // same question number - compare the subquestions
                if (a[0][a[0].indexOf(".") - 1] > b[0][b[0].indexOf(".") - 1]) {
                    return 1;
                } else return -1;
            } else return 1;
        });
    };

    const formatData = () => {
        const data = [];

        data.push(fetchHeaders()[0]);
        fetchRows().forEach(r => data.push(r));

        return data;
    };

    const exportToXLSX = async () => {
        const fileName = `sleep_summaries_${moment(new Date()).format("DD-MM-YYYY")}`;
        const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
        exportAOADocument(formatData(), fileName, sheetName);
    };

    return (
        <>
            <span style={{ paddingBottom: 10, color: "#8a8a8a", fontWeight: "normal", fontSize: "1.5rem" }}>Completed sleep summaries</span>
            <Button variant="contained" style={{ float: "right" }} onClick={exportToXLSX}>
                Export
            </Button>

            {events.length ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            {fetchHeaders(MAX_ENTRIES).map(header => (
                                <TableRow key={header}>
                                    {header.map((element: any) => (
                                        <TableCell align="center" style={{ fontWeight: "bold" }}>
                                            {element}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {fetchRows(MAX_ENTRIES).map(row => (
                                <TableRow key={row[0]}>
                                    {row.map((element: any, i: number) => (
                                        <TableCell key={element} align={i === 0 ? "left" : "center"} style={i === 0 ? { fontWeight: "bold" } : {}}>
                                            {element}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div>No data</div>
            )}
        </>
    );
};

export default SleepEntries;
