import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Icons
import { MdOutlineLocalHospital, MdAttachFile } from "react-icons/md";

// Mui
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Custom components
import Data from "./sleepTabs/Data";
import SleepEntries from "./sleepTabs/SleepEntries";
import TabPanel from "../../../../general/TabPanel";

const Sleep: React.FC<any> = ({ participant, index, tabName }) => {
    const hist = useHistory();
    const mobileScreenSize = 500;
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

    // States
    const [tab, setTab] = useState(0);
    const [events, setEvents] = useState<any[]>([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const tabs: any = [
        {
            label: "Data",
            icon: <MdAttachFile />,
            component: Data,
        },
        {
            label: "Entries",
            icon: <MdOutlineLocalHospital />,
            component: SleepEntries,
        },
    ];

    // Fetching & sorting events
    useEffect(() => {
        if (participant && participant.sleep && participant.sleep.dates) {
            const localEvents = participant.sleep.dates;
            localEvents.sort((a: any, b: any) => (a.date as any).seconds - (b.date as any).seconds);
            setEvents(localEvents);
        }
    }, [participant]);

    useEffect(() => {
        setTab(parseInt(index));
    }, [index]);

    return (
        <div>
            <div>
                <div className="tab__background__color">
                    <Tabs
                        variant="fullWidth"
                        value={tab}
                        onChange={(e, v) => participant && participant.id && hist.push(`/dashboard/participant/${participant.id}/${tabName}/${v}`)}
                    >
                        {tabs.map((t: any) => (
                            <Tab key={t.label} icon={t.icon} label={screenWidth >= mobileScreenSize ? t.label : ""} />
                        ))}
                    </Tabs>
                </div>
                {tabs.map((t: any, i: number) => (
                    <TabPanel key={i} value={tab} index={i}>
                        <t.component participant={participant} events={events} />
                    </TabPanel>
                ))}
            </div>
        </div>
    );
};

export default Sleep;
