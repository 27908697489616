import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

// Mui core
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Autocomplete, FormControlLabel, Switch } from "@mui/material";

// Interfaces
import { Participant } from "../../../../../../interfaces/Participant";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Hooks
import { useFetchUser } from "../../../../../../hooks/useFetchUser";

// Settings
import settings from "../../../../../../settings.json";

// Utils
import { emailRegex } from "../../../../../../utils/regex";

// Firebase
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { auth, firestore, functions } from "../../../../../../firebase/firebase";
import { genDoc } from "../../../../../../utils/firebase";
import { AdminUser } from "../../../../../../interfaces/AdminUser";
import { sendPasswordResetEmail } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

// Forms
import SummaryReportTable from "../../../forms/SummaryReportTable";
import NeuropsychologicalTestScoresTable from "../../../forms/NeuropsychologicalTestScoresTable";

// yup validation
const requiredMessage = "Required field";

const userSchema = yup.object({
    firstName: yup.string().required(requiredMessage),
    participantId: yup.string().required(requiredMessage),
    email: yup.string().matches(emailRegex, "Invalid email").required(requiredMessage),
    sex: yup.string().oneOf(settings.app.sexes).required(requiredMessage),
    age: yup.number().required(),
    coordinatorId: yup.string().required(requiredMessage),
    state: yup.string().oneOf(settings.app.states).required(requiredMessage),
    physical: yup.boolean(),
    cognitive: yup.boolean(),
    health: yup.boolean(),
    sleep: yup.boolean(),
    diet: yup.boolean(),
});

interface Props {
    participant: Participant | null;
    primaryIndex: string;
    secondaryIndex: string;
}

const Info: React.FC<Props> = ({ participant = null }) => {
    const { currentAdmin, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [coordinators, setCoordinators] = useState<AdminUser[]>([]);
    const [inputValue, setInputValue] = React.useState("");
    const [changeEmail, setChangeEmail] = useState(false);
    const [newChangeEmail, setNewChangeEmail] = useState("");

    const defaultValues = {
        firstName: "",
        participantId: "",
        email: "",
        sex: "",
        age: 0,
        // dob: new Date(),
        coordinatorId: "",
        state: "",
        physical: true,
        cognitive: true,
        health: true,
        sleep: true,
        diet: true,
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch,
    } = useForm({ resolver: yupResolver(userSchema), defaultValues });

    const physical = watch("physical");
    const cognitive = watch("cognitive");
    const health = watch("health");
    const sleep = watch("sleep");
    const diet = watch("diet");
    const age = watch("age");

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);
            if (participant && participant.id) {
                await updateDoc(doc(firestore, "Participants", participant.id), {
                    ...participant,
                    firstName: data.firstName,
                    participantId: data.participantId,
                    sex: data.sex,
                    age: data.age,
                    // dob: new Date(data.dob),
                    coordinatorId: coordinators.find(x => x.email === data.coordinatorId)
                        ? coordinators.find(x => x.email === data.coordinatorId)?.id
                        : data.coordinatorId,
                    physical: {
                        ...participant.physical,
                        show: data.physical,
                    },
                    cognitive: {
                        ...participant.cognitive,
                        show: data.cognitive,
                    },
                    health: {
                        ...participant.health,
                        show: data.health,
                    },
                    sleep: {
                        ...participant.sleep,
                        show: data.sleep,
                    },
                    diet: {
                        ...participant.diet,
                        show: data.diet,
                    },
                    state: data.state,
                });

                const logPayload = await addDoc(collection(firestore, "Logs"), {
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    isDeleted: false,
                    adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
                    reason: `Updated participant ${data && (data as any).email ? (data as any).email : (data as any).id}`,
                });

                await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });

                enqueueSnackbar("Updated participant", { variant: "success" });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitSummary = async (data: any) => {
        if (participant && participant.id) {
            await updateDoc(doc(firestore, "Participants", participant.id), {
                forms: {
                    ...participant.forms,
                    summaryReportTable: data,
                },
            });
            enqueueSnackbar("Updated Summary Report Table", { variant: "success" });
        }
    };

    const handleSubmitNeuropsychological = async (data: any) => {
        if (participant && participant.id) {
            await updateDoc(doc(firestore, "Participants", participant.id), {
                forms: {
                    ...participant.forms,
                    neuropsychologicalTestScoresTable: data,
                },
            });
            enqueueSnackbar("Updated Neuropsychological Test Scores Table", { variant: "success" });
        }
    };

    const fetchCoordinators = async () => {
        const payload = await getDocs(query(collection(firestore, "Users"), where("roles", "array-contains", "coordinator")));
        setCoordinators(payload.docs.map(genDoc<AdminUser>()));
    };

    useEffect(() => {
        if (participant) {
            fetchCoordinators();
            // const dob = convertObjectIntoDate(participant.dob);
            setValue("firstName", participant.firstName);
            // if (dob) setValue("dob", dob as any);
            setValue("age", participant.age);
            setValue("email", participant.email);
            setValue("participantId", participant.participantId);
            setValue("sex", participant.sex);
            if (participant.coordinatorId) setValue("coordinatorId", participant.coordinatorId);
            setValue("state", participant.state);
            setValue("physical", participant.physical.show ?? true);
            setValue("cognitive", participant.cognitive.show ?? true);
            setValue("health", participant.health.show ?? true);
            setValue("sleep", participant.sleep.show ?? true);
        }
    }, [participant]);

    const handleEmailChange = async () => {
        const modifyAuthEmail = httpsCallable(functions, "modifyAuthEmail");

        const payload = await modifyAuthEmail({
            uid: participant && participant.id,
            email: newChangeEmail,
        });

        if (payload.data) {
            enqueueSnackbar("Email changed successfully", { variant: "success" });
        } else {
            enqueueSnackbar("Invalid email", { variant: "error" });
        }
    };

    // const calculateAge = (birthday: Date) => {
    //     console.log("birthday", birthday);

    //     // birthday is a date
    //     const now = new Date();
    //     now.setHours(0);
    //     now.setMinutes(0);
    //     now.setSeconds(0);
    //     now.setMilliseconds(0);

    //     const birthdayDate = birthday;
    //     birthdayDate.setHours(0);
    //     birthdayDate.setMinutes(0);
    //     birthdayDate.setSeconds(0);
    //     birthdayDate.setMilliseconds(0);

    //     const ageDifMs = Date.now() - birthday.getTime();
    //     const ageDate = new Date(ageDifMs); // miliseconds from epoch
    //     return Math.abs(ageDate.getUTCFullYear() - 1970);
    // };

    // const test = async () => {
    //     const participantsPayload = await getDocs(collection(firestore, "Participants"));
    //     const participants = participantsPayload.docs.map(genDoc<Participant>());

    //     for (const p of participants) {
    //         if (p.id) {
    //             if ((p as any).dob) {
    //                 console.log("---------------------------");
    //                 console.log("dob", moment((p as any).dob).format("YYYY-MM-DD HH:mm:ss"));
    //                 console.log("age", calculateAge((p as any).dob));
    //                 console.log("---------------------------");
    //                 await updateDoc(doc(firestore, "Participants", p.id), {
    //                     ...p,
    //                     age: calculateAge((p as any).dob),
    //                 });
    //             }
    //         }
    //     }
    // };

    return (
        <div className="info__container">
            {/* <Button variant="contained" onClick={test}>
                get age
            </Button> */}
            {/* <Title>{participant ? participant?.firstName : "Participant"}</Title> */}
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    render={({ field }) => (
                        <TextField
                            variant="standard"
                            helperText={errors.firstName?.message}
                            error={!!errors.firstName?.message}
                            label="Preferred Name"
                            fullWidth
                            required
                            {...field}
                        />
                    )}
                    name="firstName"
                    control={control}
                />
                <Controller
                    render={({ field }) => (
                        <TextField
                            variant="standard"
                            helperText={errors.participantId?.message}
                            error={!!errors.participantId?.message}
                            label="Participant id"
                            fullWidth
                            required
                            {...field}
                        />
                    )}
                    name="participantId"
                    control={control}
                />
                <Controller
                    render={({ field }) => (
                        <TextField
                            variant="standard"
                            helperText={errors.email?.message}
                            error={!!errors.email?.message}
                            label="Email"
                            fullWidth
                            required
                            disabled
                            {...field}
                        />
                    )}
                    name="email"
                    control={control}
                />
                <Controller
                    name="sex"
                    control={control}
                    render={({ field }) => (
                        <FormControl required fullWidth variant="standard">
                            <InputLabel id="roles-id">Sex</InputLabel>
                            <Select labelId="roles-id" {...field}>
                                {settings.app.sexes.map((s, i) => (
                                    <MenuItem key={i} value={s}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />
                <Controller
                    render={({ field }) => (
                        <TextField
                            variant="standard"
                            helperText={errors.age?.message}
                            error={!!errors.age?.message}
                            label="Age"
                            type="number"
                            fullWidth
                            required
                            {...field}
                            value={age}
                        />
                    )}
                    name="age"
                    control={control}
                />

                {/* <Controller
                    name="dob"
                    control={control}
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                openTo="year"
                                disableFuture
                                views={["year", "month", "day"]}
                                label="Date of Birth"
                                inputFormat="yyyy-MM-DD"
                                {...field}
                                renderInput={(params: any) => (
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        required
                                        error={errors && errors.dob && !!errors.dob?.message}
                                        helperText={errors && errors.dob && errors.dob?.message}
                                        style={{ marginBottom: 10 }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    )}
                /> */}

                <Controller
                    name="coordinatorId"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            options={coordinators.map(c => c.email)}
                            getOptionLabel={option => {
                                const coordinator = coordinators.find(x => x.id === option);
                                if (coordinator) return coordinator.email;
                                else return option;
                            }}
                            renderInput={params => {
                                params.fullWidth = true;
                                return (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Coordinator"
                                        error={errors && errors.coordinatorId && !!errors.coordinatorId?.message}
                                        helperText={errors && errors.coordinatorId && errors.coordinatorId?.message}
                                    />
                                );
                            }}
                            {...field}
                            onChange={(event: any, newValue: string | null) => {
                                if (newValue) {
                                    setValue("coordinatorId", newValue);
                                }
                            }}
                            style={{ color: "black" }}
                        />
                    )}
                />

                <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                        <FormControl required fullWidth variant="standard">
                            <InputLabel id="roles-id">State</InputLabel>
                            <Select labelId="roles-id" {...field}>
                                {settings.app.states.map((s, i) => (
                                    <MenuItem key={i} value={s}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />

                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10, marginBottom: 10 }}
                    onClick={async () => {
                        if (participant) {
                            await sendPasswordResetEmail(auth, participant.email);
                            enqueueSnackbar("Reset email sent", { variant: "success" });
                        }
                    }}
                >
                    Send password reset email
                </Button>
                <br />
                <Button variant="outlined" color="secondary" style={{ marginTop: 10, marginBottom: 10 }} onClick={() => setChangeEmail(!changeEmail)}>
                    {!changeEmail ? "Change participant email" : "Cancel email change"}
                </Button>

                {changeEmail && (
                    <div>
                        <TextField variant="standard" label="New Email" fullWidth required onChange={e => setNewChangeEmail(e.target.value)} />
                        <Button variant="contained" color="secondary" style={{ marginTop: 10, marginBottom: 10 }} onClick={handleEmailChange}>
                            Confirm participant email change
                        </Button>
                    </div>
                )}

                <h6 style={{ color: "rgba(0,0,0,0.6)" }}>Domains</h6>

                <div>
                    <Controller
                        name="physical"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                labelPlacement="end"
                                control={<Switch {...field} checked={physical} />}
                                label="Physical"
                                className="TEST"
                            />
                        )}
                    />
                </div>
                <div>
                    <Controller
                        name="cognitive"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                labelPlacement="end"
                                control={<Switch {...field} checked={cognitive} />}
                                label="Cognitive"
                                className="TEST"
                            />
                        )}
                    />
                </div>
                <div>
                    <Controller
                        name="health"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel labelPlacement="end" control={<Switch {...field} checked={health} />} label="Health" className="TEST" />
                        )}
                    />
                </div>
                <div>
                    <Controller
                        name="sleep"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel labelPlacement="end" control={<Switch {...field} checked={sleep} />} label="Sleep" className="TEST" />
                        )}
                    />
                </div>
                <div>
                    <Controller
                        name="diet"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel labelPlacement="end" control={<Switch {...field} checked={diet} />} label="Diet" className="TEST" />
                        )}
                    />
                </div>

                <div style={{ display: "flex" }}>
                    <Button variant="contained" type="submit" color="primary" style={{ marginLeft: "auto", marginTop: 20 }}>
                        Update
                    </Button>
                </div>
            </form>

            {participant && participant.id && (
                <>
                    <SummaryReportTable participantId={participant.id} onComplete={handleSubmitSummary} />
                    <NeuropsychologicalTestScoresTable participantId={participant.id} onComplete={handleSubmitNeuropsychological} />
                </>
            )}
        </div>
    );
};

export default Info;
