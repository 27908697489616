import { FaImage } from "react-icons/fa";

import { Image } from "./interface";

const imageConfig: Image = {
    title: "Image",
    Icon: FaImage,
    inputAccept: "image/*",
    previewImage: true,
    defaultSize: { width: "250px", height: "auto" },
};

export type { Image };
export default imageConfig;
