import * as React from "react";
import { useHistory } from "react-router-dom";

// Mui
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { FormControl, NativeSelect } from "@mui/material";

// Icons
import { MdMenu, MdLogout, MdKeyboardBackspace } from "react-icons/md";

// Firebase
import { auth, firestore } from "../../firebase/firebase";

// Custom components
import RewardsDialog from "./RewardsDialog";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Assets
import theme from "../../assets/icons/theme.png";
import { doc, updateDoc } from "firebase/firestore";

interface Props {
    title: string;
    icon: string;
    backButton?: boolean;
}

const TopMenu: React.FC<Props> = ({ title, icon, backButton = false }) => {
    const hist = useHistory();
    const { currentParticipant } = useFetchUser();
    // States
    const [open, setOpen] = React.useState(false);
    const [, setSize] = React.useState("");

    // toggle theme
    const changeTheme = () => {
        let theme = "";
        const divColor = (document.getElementById("color") as any).classList;
        if (divColor.contains("normal")) {
            divColor.replace("normal", "inverse");
            localStorage.setItem("theme", "inverse");
            theme = "inverse";
        } else {
            divColor.replace("inverse", "normal");
            localStorage.setItem("theme", "normal");
            theme = "normal";
        }

        if (currentParticipant && currentParticipant.id) {
            // Participant log
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...currentParticipant.logs, { date: new Date(), action: `Changed site theme to ${theme}` }],
            });
        }
    };

    // toggle font
    const changeFont = (value: string) => {
        if (currentParticipant && currentParticipant.id) {
            let fontSize = "";
            if (value === "font1") fontSize = "regular";
            if (value === "font2") fontSize = "big";
            if (value === "font3") fontSize = "bigger";

            // Participant log
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...currentParticipant.logs, { date: new Date(), action: `Changed site fonts to ${fontSize}` }],
            });
        }

        const divFont = (document.getElementById("font") as any).classList;
        if (value === "font1") {
            divFont.add("font1");
            divFont.remove("font2", "font3");
            localStorage.setItem("font", "font1");
        } else if (value === "font2") {
            divFont.add("font2");
            divFont.remove("font1", "font3");
            localStorage.setItem("font", "font2");
        } else if (value === "font3") {
            divFont.add("font3");
            divFont.remove("font1", "font2");
            localStorage.setItem("font", "font3");
        }
    };

    return (
        <div className="topMenu__container">
            <AppBar position="static" elevation={0} className="topMenu__appBar">
                <Container maxWidth="xl">
                    <Toolbar disableGutters className="topMenu__elements">
                        <Box className="topMenu__user">
                            <IconButton sx={{ p: 0 }} style={{ cursor: "default" }}>
                                <Avatar alt="icon" src={icon} className="nofilterimg" />
                            </IconButton>
                            <h3 className="topMenu__title">{title}</h3>
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "none" } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <MdMenu />
                            </IconButton>
                        </Box>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: "none", md: "none" } }}>
                            LOGO
                        </Typography>
                        <Box sx={{ display: "flex", gap: "2rem" }}>
                            {backButton && (
                                <Button className="topMenu__logout" onClick={() => hist.push("/")} sx={{ my: 2 }}>
                                    <div className="topMenu__logout__left">
                                        <h5>Back</h5>
                                    </div>
                                    <div className="topMenu__logout__right">
                                        <MdKeyboardBackspace />
                                    </div>
                                </Button>
                            )}

                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <FormControl sx={{ minWidth: "100px" }}>
                                    <Button onClick={changeTheme}>
                                        <img src={theme} alt="" className="nofilterimg" />
                                    </Button>
                                </FormControl>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }} className="topMenu__fontsize">
                                <h6 style={{ marginRight: 10 }}>Text Size:</h6>
                                <FormControl sx={{ minWidth: "100px" }}>
                                    <NativeSelect
                                        defaultValue={"font1"}
                                        onChange={e => {
                                            changeFont(e.target.value);
                                            setSize(e.target.value);
                                        }}
                                    >
                                        <option className="topMenu__font1" value="font1">
                                            Regular
                                        </option>
                                        <option className="topMenu__font2" value="font2">
                                            Big
                                        </option>
                                        <option className="topMenu__font3" value="font3">
                                            Bigger
                                        </option>
                                    </NativeSelect>
                                </FormControl>
                            </Box>

                            <Button className="topMenu__logout" onClick={() => auth.signOut()} sx={{ my: 2 }}>
                                <div className="topMenu__logout__left">
                                    <h5>Logout</h5>
                                </div>
                                <div className="topMenu__logout__right">
                                    <MdLogout />
                                </div>
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <RewardsDialog open={open} setOpen={setOpen} />
        </div>
    );
};

export default TopMenu;
