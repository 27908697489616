import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";

// Material UI Core
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

// Icons
import { MdAdd } from "react-icons/md";

// Interfaces
import { File } from "../../../../interfaces/File";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import { updateDoc, doc } from "firebase/firestore";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Settings
import settings from "../../../../settings.json";

const ContentTableRows = () => {
    const { currentAdmin } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();
    const hist = useHistory();

    const handleDelete = async (c: File) => {
        try {
            if (currentAdmin && currentAdmin.roles && currentAdmin.roles.includes(settings.app.highestRole)) {
                if (c.id)
                    await updateDoc(doc(firestore, "Files", c.id), {
                        isDeleted: !c.isDeleted,
                    });
            } else {
                enqueueSnackbar("You don't have the required role", { variant: "error" });
            }
        } catch (e) {
            console.error(e);
        }
    };

    return [
        {
            header: "Date added",
            body: (c: File) => moment(c.createdAt).format("YYYY-MM-DD"),
        },
        {
            header: "Title",
            body: (c: File) => c.title,
        },
        {
            header: "Topic",
            body: (c: File) => c.topic,
        },
        {
            header: "Type",
            body: (c: File) => c.type,
        },
        {
            header: "Extension/Url",
            body: (c: File) => (c.extension !== "media" ? c.extension : c.url),
        },
        {
            header: (
                <Button variant="contained" color="primary" onClick={() => hist.push("/dashboard/content")}>
                    <MdAdd />
                    Add
                </Button>
            ),
            body: (c: File) => (
                <Switch
                    checked={!c.isDeleted}
                    onChange={() => handleDelete(c)}
                    name="checkedA"
                    inputProps={{
                        "aria-label": "secondary checkbox",
                    }}
                />
            ),
        },
    ];
};

export default ContentTableRows;
