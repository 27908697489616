import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

// Firebase
import { firestore } from "../../../../../firebase/firebase";
import { doc, updateDoc, getDoc, addDoc, collection, getDocs, where, query, orderBy } from "firebase/firestore";

// Mui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

// Utils
import { genDoc } from "../../../../../utils/firebase";
import { getBackgroundColor, getFontColor } from "../../../../../utils/general";

// Interfaces
import { Participant } from "../../../../../interfaces/Participant";
import { File } from "../../../../../interfaces/File";

// Hooks
import { useFetchUser } from "../../../../../hooks/useFetchUser";

// Custom components
import Title from "../../../../general/Title";
import FileLegend from "../../../../general/FileLegend";

interface Params {
    id: string;
}

const CognitiveTraining: React.FC = () => {
    const { id } = useParams<Params>();
    const hist = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { currentAdmin } = useFetchUser();

    // States
    const [allFiles, setAllFiles] = useState<File[]>([]);
    const [participantFiles, setParticipantFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<string[]>([]);
    const [content, setContent] = useState<string[]>([]);
    const [participant, setParticipant] = useState<Participant | null>(null);
    const [appointmentIndex, setAppointmentIndex] = useState(0);
    const [projectId, setProjectId] = useState("");
    const [participantId, setParticipantId] = useState("");

    const handleSave = async () => {
        if (participant) {
            await updateDoc(doc(firestore, "Participants", id), {
                ...participant,
                cognitive: {
                    ...participant.cognitive,
                    files,
                    content,
                    appointmentIndex,
                    neuropeakCredentials: {
                        projectId,
                        participantId,
                    },
                },
            });
        }

        const logPayload = await addDoc(collection(firestore, "Logs"), {
            createdAt: new Date(),
            updatedAt: new Date(),
            isDeleted: false,
            adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
            reason: `Updated participant ${
                participant && (participant as any).email ? (participant as any).email : (participant as any).id
            }'s cognitive training`,
        });

        await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });

        enqueueSnackbar("Participant updated", { variant: "success" });
    };

    const fetchAllFiles = async () => {
        const localQuery = query(
            collection(firestore, "Files"),
            where("topic", "==", "Cognitive Training"),
            where("isDeleted", "==", false),
            orderBy("createdAt", "desc")
        );
        const payload = await getDocs(localQuery);
        const localFiles = payload.docs.map(genDoc<File>());

        setAllFiles(localFiles ?? []);
    };

    const fetchParticipantFiles = async () => {
        if (participant && participant.id) {
            const localQuery = query(
                collection(firestore, "Participants", participant.id, "Files"),
                where("topic", "==", "Cognitive Training"),
                where("isDeleted", "==", false),
                orderBy("createdAt", "desc")
            );
            const payload = await getDocs(localQuery);
            const localFiles = payload.docs.map(genDoc<File>());

            setParticipantFiles(localFiles ?? []);
        }
    };

    const fetchParticipant = async () => {
        const payload = await getDoc(doc(firestore, "Participants", id));
        const localParticipant = genDoc<Participant>()(payload);
        setParticipant(localParticipant);
    };

    useEffect(() => {
        if (participant && participant.id) {
            fetchParticipantFiles();
            setFiles(participant.cognitive.files);
            setContent(participant.cognitive.content);
            setAppointmentIndex(participant.cognitive.appointmentIndex);
            setProjectId(participant.cognitive.neuropeakCredentials.projectId);
            setParticipantId(participant.cognitive.neuropeakCredentials.participantId);
        }
    }, [participant]);

    useEffect(() => {
        fetchParticipant();
    }, [id]);

    useEffect(() => {
        fetchAllFiles();
    }, []);

    return (
        <div className="cognitive-admin">
            <Grid item container>
                <Grid item xs={9}>
                    <Title>Interactive content</Title>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={() => hist.push(`/dashboard/content/Cognitive Training/Content/${id}`)}
                    >
                        Add private content
                    </Button>
                </Grid>

                {allFiles
                    .filter(f => f.type === "Content")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: getBackgroundColor(true, true, data, content, files),
                                color: getFontColor(true, true, data, content, files),
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (content.some((c: any) => c === data.id)) {
                                    // Remove
                                    setContent((prev: any) => prev.filter((c: any) => c !== data.id));
                                } else {
                                    // Add
                                    setContent((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}
                {participantFiles
                    .filter(f => f.type === "Content")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: getBackgroundColor(false, true, data, content, files),
                                color: getFontColor(false, true, data, content, files),
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (content.some((f: any) => f === data.id)) {
                                    // Remove
                                    setContent((prev: any) => prev.filter((f: any) => f !== data.id));
                                } else {
                                    // Add
                                    setContent((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}
            </Grid>
            <Grid item container>
                <Grid item xs={10}>
                    <Title>Files</Title>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={() => hist.push(`/dashboard/content/Cognitive Training/File/${id}`)}
                    >
                        Add private file
                    </Button>
                </Grid>
                {allFiles
                    .filter(f => f.type === "File")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: files.some((f: any) => f === data.id) ? "black" : "white",
                                color: files.some((f: any) => f === data.id) ? "white" : "black",
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (files.some((f: any) => f === data.id)) {
                                    // Remove
                                    setFiles((prev: any) => prev.filter((f: any) => f !== data.id));
                                } else {
                                    // Add
                                    setFiles((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}

                {participantFiles
                    .filter(f => f.type === "File")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: getBackgroundColor(false, false, data, [], files),
                                color: getFontColor(false, false, data, [], files),
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (files.some((f: any) => f === data.id)) {
                                    // Remove
                                    setFiles((prev: any) => prev.filter((f: any) => f !== data.id));
                                } else {
                                    // Add
                                    setFiles((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}
            </Grid>
            <FileLegend />
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <Title>Appointments</Title>
            </div>
            <div style={{ marginBottom: 20 }}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={appointmentIndex}
                        onChange={e => setAppointmentIndex(parseInt(e.target.value))}
                    >
                        <FormControlLabel value={1} control={<Radio />} label="1" />
                        <FormControlLabel value={2} control={<Radio />} label="2" />
                        <FormControlLabel value={3} control={<Radio />} label="3" />
                        <FormControlLabel value={4} control={<Radio />} label="4" />
                        <FormControlLabel value={5} control={<Radio />} label="5" />
                        <FormControlLabel value={6} control={<Radio />} label="6" />
                        <FormControlLabel value={7} control={<Radio />} label="7" />
                        <FormControlLabel value={8} control={<Radio />} label="8" />
                        <FormControlLabel value={9} control={<Radio />} label="9" />
                        <FormControlLabel value={10} control={<Radio />} label="10" />
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={appointmentIndex}
                        onChange={e => setAppointmentIndex(parseInt(e.target.value))}
                    >
                        <FormControlLabel value={11} control={<Radio />} label="11" />
                        <FormControlLabel value={12} control={<Radio />} label="12" />
                        <FormControlLabel value={13} control={<Radio />} label="13" />
                        <FormControlLabel value={14} control={<Radio />} label="14" />
                        <FormControlLabel value={15} control={<Radio />} label="15" />
                        <FormControlLabel value={16} control={<Radio />} label="16" />
                        <FormControlLabel value={17} control={<Radio />} label="17" />
                        <FormControlLabel value={18} control={<Radio />} label="18" />
                        <FormControlLabel value={19} control={<Radio />} label="19" />
                        <FormControlLabel value={20} control={<Radio />} label="20" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <Title>Neuropeak Credentials</Title>
            </div>
            <div>
                <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Project id"
                    value={projectId}
                    onChange={(e: any) => setProjectId(e.target.value)}
                    style={{ marginRight: 10 }}
                />
                <TextField
                    variant="outlined"
                    autoComplete="off"
                    label="Participant id"
                    value={participantId}
                    onChange={(e: any) => setParticipantId(e.target.value)}
                />
            </div>
            <Button variant="contained" style={{ marginTop: 40 }} onClick={handleSave}>
                Save
            </Button>
        </div>
    );
};

export default CognitiveTraining;
