import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment, { Moment } from "moment";

// Mui
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";

// Assets
import cognitiveTrainingLogo from "../../../assets/images/logos/cognitiveTrainingLogo.png";
import physicalExerciseLogo from "../../../assets/images/logos/physicalExerciseLogo.png";
import healthLogo from "../../../assets/images/logos/healthLogo.png";
import dietLogo from "../../../assets/images/logos/dietLogo.png";
import sleepLogo from "../../../assets/images/logos/sleepLogo.png";
import helpLogo from "../../../assets/images/logos/helpLogo.png";
import bhp from "../../../assets/images/bhp.png";

// Custom component
import ParticipantChat from "../../general/ParticipantChat";
import Progress from "../../general/Progress";
import HomeCard from "../../general/HomeCard";
import TopMenu from "../../general/TopMenu";
import SurveyDialog from "./questionnaires/SurveyDialog";
import MyGoals from "../../general/MyGoals";
import BiometricDialog from "../../general/BiometricDialog";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Utils
import FallsCalendar from "../../general/FallsCalendar";
import NextMeeting from "../../general/NextMeeting";
import { parseGoalsContent } from "../../../utils/goals";

// Firebase
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { genDoc } from "../../../utils/firebase";

// Interfaces
import { Stat } from "../../../interfaces/Stat";

// Icons
import { RiFileList2Line } from "react-icons/ri";
import SleepCalendar from "../../general/SleepCalendar";
// import { getScore } from "../../../utils/survey";
// import {
//     generalizedAnxietyDisorder,
//     geriatricDepressionScale,
//     postCognitiveExpectancy,
//     preCognitiveExpectancy,
//     revisedHearingHandicapInventory,
//     visionHearing,
// } from "./questionnaires/data/CognitiveData";
// import { insomniaSeverityIndex } from "./questionnaires/data/SleepData";
// import { dietHistory, eatingPattern, mediterraneanDietAdherence } from "./questionnaires/data/DietData";
// import { exerciseFallsCalendar, fallsAndBalance, fallsCalendar, lawtonBrodyIADL } from "./questionnaires/data/PhysicalExerciseActivityData";
// import { qualityOfLife } from "./questionnaires/data/VascularHealth";
// import _ from "lodash";

const Home: React.FC = () => {
    const hist = useHistory();
    const { currentParticipant } = useFetchUser();
    const { date } = useParams<any>();

    // States
    const [open, setOpen] = useState(false);
    const [surveyDialogOpen, setSurveyDialogOpen] = useState(false);
    const [stats, setStats] = useState<Stat[]>([]);
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [events, setEvents] = useState<any[]>([]);
    const [, setWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // const cardMainTitle = useMemo(() => {
    //     if (currentParticipant) {
    //         switch (currentParticipant.state) {
    //             case "assessments":
    //             default:
    //                 return "Domains Targeted in SYNERGIC-2";
    //             case "intervention":
    //                 return "";
    //             case "control":
    //                 return "";
    //         }
    //     }
    //     return "My Personalized Interventions this week";
    // }, [currentParticipant]);

    // const physicalTitle = useMemo(() => {
    //     if (currentParticipant) {
    //         switch (currentParticipant.state) {
    //             case "assessments":
    //             default:
    //                 return "Physical Exercise / Sedentarism";
    //             case "intervention":
    //                 return "";
    //             case "control":
    //                 return "";
    //         }
    //     }
    //     return "Physical Exercise";
    // }, [currentParticipant]);

    // const healthTitle = useMemo(() => {
    //     if (currentParticipant) {
    //         switch (currentParticipant.state) {
    //             case "assessments":
    //             case "intervention":
    //             default:
    //                 return "Cardiometabolic Health";
    //             case "control":
    //                 return "";
    //         }
    //     }
    //     return "Health";
    // }, [currentParticipant]);

    const isQuestionnaireCompleted = (questionnaireName: string) => {
        return stats.some(
            x =>
                x.questionnaireName === questionnaireName &&
                x.participantId === currentParticipant?.id &&
                (!currentParticipant.resetDate ||
                    (currentParticipant.resetDate && x.createdAt && x.createdAt.getTime() >= currentParticipant.resetDate?.getTime()))
        );
    };

    const fetchStats = async () => {
        try {
            const payload = await getDocs(collection(firestore, "Stats"));
            setStats(payload.docs.map(genDoc<Stat>()));
        } catch (e) {
            console.error(e);
        }
    };

    React.useEffect(() => {
        if (currentParticipant && currentParticipant.id) {
            // Participant log
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...currentParticipant.logs, { date: new Date(), action: "Visited home page" }],
            });
        }
    }, []);

    useEffect(() => {
        fetchStats();
    }, []);

    // const fixScores = async () => {
    //     console.log("fix");

    //     try {
    //         const problematicStats = stats;

    //         console.log("count", problematicStats.length);

    //         let errorCount = 0;

    //         const today = new Date();
    //         today.setHours(0, 0, 0, 0);

    //         const getQuestionnaireByName = (questionnaireName: string): any => {
    //             switch (questionnaireName) {
    //                 default:
    //                     return "";
    //                 case "exerciseFallsCalendar":
    //                     return exerciseFallsCalendar;
    //                 case "qualityOfLife":
    //                     return qualityOfLife;
    //                 case "fallsCalendar":
    //                     return fallsCalendar;
    //                 case "fallsAndBalance":
    //                     return fallsAndBalance;
    //                 case "lawtonBrodyIADL":
    //                     return lawtonBrodyIADL;
    //                 case "dietHistory":
    //                     return dietHistory;
    //                 case "eatingPattern":
    //                     return eatingPattern;
    //                 case "mediterraneanDietAdherence":
    //                     return mediterraneanDietAdherence;
    //                 case "insomniaSeverityIndex":
    //                     return insomniaSeverityIndex;
    //                 case "preCognitiveExpectancy":
    //                     return preCognitiveExpectancy;
    //                 case "postCognitiveExpectancy":
    //                     return postCognitiveExpectancy;
    //                 case "generalizedAnxietyDisorder":
    //                     return generalizedAnxietyDisorder;
    //                 case "geriatricDepressionScale":
    //                     return geriatricDepressionScale;
    //                 case "revisedHearingHandicapInventory":
    //                     return revisedHearingHandicapInventory;
    //                 case "visionHearing":
    //                     return visionHearing;
    //             }
    //         };

    //         // Filter out scoreless questionnaires
    //         for (const problematicStat of problematicStats.filter(
    //             x =>
    //                 x.questionnaireName &&
    //                 x.questionnaireName !== "healthResourceUtilization" &&
    //                 x.questionnaireName !== "dietHistory" &&
    //                 x.questionnaireName !== "exerciseFallsCalendar" &&
    //                 x.questionnaireName !== "healthBehaviorsQuestionnaire" &&
    //                 x.questionnaireName !== "healthUtilityIndex" &&
    //                 x.questionnaireName !== "bhproQuestionnaires" &&
    //                 x.questionnaireName !== "comorbidityIssuesAndCompaints" &&
    //                 x.questionnaireName !== "womenHealth" &&
    //                 x.questionnaireName !== "visionHearing"
    //         )) {
    //             if (problematicStat.id) {
    //                 const data: any = {};
    //                 let newScore = problematicStat.score;

    //                 const questionnaire = getQuestionnaireByName(problematicStat.questionnaireName);

    //                 if (questionnaire.questions) {
    //                     const answers: any[] = [];

    //                     Object.entries(problematicStat.results)
    //                         .sort((a, b) => a[0].localeCompare(b[0], "en", { numeric: true }))
    //                         .map(e => {
    //                             const questionnaire = getQuestionnaireByName(problematicStat.questionnaireName);
    //                             const questionNumber = e[0].replace("question", "");

    //                             const questionIndex = questionnaire.questions.findIndex((x: any) => x.questionNumber === questionNumber);
    //                             if (questionIndex !== -1) {
    //                                 if (Array.isArray(e[1])) {
    //                                     const localAnswers: number[] = [];
    //                                     e[1].forEach(y => {
    //                                         const answerIndex = questionnaire.questions[questionIndex].answers.findIndex((x: any) => x === y);
    //                                         localAnswers.push(answerIndex + 1);
    //                                     });
    //                                     answers.push(-1);
    //                                 } else {
    //                                     const answerIndex = questionnaire.questions[questionIndex].answers.findIndex((x: any) => x === e[1]);
    //                                     answers.push(answerIndex + 1);
    //                                 }
    //                             }
    //                         });

    //                     newScore = getScore(
    //                         Object.entries(problematicStat.results)
    //                             .sort((a, b) => a[0].localeCompare(b[0], "en", { numeric: true }))
    //                             .map(e => {
    //                                 return { [e[0]]: e[1] };
    //                             }),
    //                         answers,
    //                         problematicStat.questionnaireName
    //                     );

    //                     data.answers = answers;
    //                     data.oldScore = problematicStat.score;
    //                     data.score = newScore;
    //                     data.scoreFixDate = moment(today).format("DD/MM/YYYY");
    //                 }

    //                 if (!problematicStat.answers.length || (problematicStat.score !== newScore && !_.isEqual(problematicStat.score, newScore))) {
    //                     if (problematicStat.questionnaireName === "eatingPattern") {
    //                         if (
    //                             problematicStat.score.rawScore === newScore.rawScore &&
    //                             problematicStat.score.brainHealthyEatingIndexScore === newScore.brainHealthyEatingIndexScore
    //                         ) {
    //                             // score is ok
    //                             continue;
    //                         }
    //                     }
    //                     if (problematicStat.questionnaireName === "qualityOfLife") {
    //                         if (
    //                             problematicStat.score.physicalFunctioning === newScore.physicalFunctioning &&
    //                             problematicStat.score.roleLimitationsDueToPhysicalHealth === newScore.roleLimitationsDueToPhysicalHealth &&
    //                             problematicStat.score.roleLimitationsDueToEmotionalProblems === newScore.roleLimitationsDueToEmotionalProblems &&
    //                             problematicStat.score.vitalityEnergyAndFatigue === newScore.vitalityEnergyAndFatigue &&
    //                             problematicStat.score.generalMentalHealth === newScore.generalMentalHealth &&
    //                             problematicStat.score.socialFunctioning === newScore.socialFunctioning &&
    //                             problematicStat.score.bodilyPain === newScore.bodilyPain &&
    //                             problematicStat.score.generalHealthPerceptions === newScore.generalHealthPerceptions &&
    //                             problematicStat.score.healthChange === newScore.healthChange &&
    //                             problematicStat.score.total === newScore.total
    //                         ) {
    //                             // score is ok
    //                             continue;
    //                         }
    //                     }
    //                     errorCount++;

    //                     console.log("-----------------------------------");
    //                     console.log("stat id", problematicStat.id);
    //                     console.log("questionnaireName", problematicStat.questionnaireName);
    //                     console.log("answers count", problematicStat.answers.length);
    //                     console.log("participantId", problematicStat.participantId);
    //                     console.log("old score", problematicStat.score);
    //                     console.log("new score", newScore);
    //                     console.log("data", data);
    //                     console.log("-----------------------------------");

    //                     // try {
    //                     //     await updateDoc(doc(firestore, "Stats", problematicStat.id), data);
    //                     // } catch (e) {
    //                     //     console.error(e);
    //                     // }
    //                 }
    //             }
    //         }
    //         console.log("errorCount", errorCount);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // };

    if (currentParticipant && stats.length) {
        if (currentParticipant.state === "assessments") {
            return (
                <>
                    <TopMenu title={`Welcome ${currentParticipant && currentParticipant.firstName}!`} icon={""} />
                    <div className="home assessments">
                        <Grid container>
                            <Grid item xs={12} md={4.5}>
                                <div className="centered__parent__vertically">
                                    <div>
                                        <div className="centered__parent">
                                            <h1 style={{ fontSize: 40 }}>Welcome to</h1>
                                        </div>
                                        <div className="centered__parent">
                                            <h1 style={{ fontSize: 50 }}>SYNERGIC-2</h1>
                                        </div>

                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "sleepDiary") &&
                                            currentParticipant.sleep.show && (
                                                <Grid xs={12} style={{ marginBottom: 40, marginTop: 40 }}>
                                                    <div className="centered__parent">
                                                        <h1 style={{ paddingBottom: 10, paddingTop: 20 }}>Sleep Calendar</h1>
                                                    </div>

                                                    <div className="portalLayout__title">
                                                        <div className="centered__parent">
                                                            <SleepCalendar participant={currentParticipant} />
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={7.5}>
                                <div>
                                    <div className="centered__parent">
                                        <h1 style={{ fontSize: 40, paddingBottom: 40 }}>Questionnaires available</h1>
                                    </div>

                                    {currentParticipant &&
                                        (!currentParticipant.questionnaires ||
                                            (currentParticipant.questionnaires && !currentParticipant.questionnaires.length)) && <p>None</p>}

                                    <Grid container item xs={12} style={{}}>
                                        {/* Physical */}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "lawtonBrodyIADL") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/lawtonBrodyIADL")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={physicalExerciseLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Lawton Brody IADL</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("lawtonBrodyIADL") ? "green" : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "fallsAndBalance") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/fallsAndBalance")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={physicalExerciseLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Falls And Balance</p>
                                                        </div>

                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("fallsAndBalance") ? "green" : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "womenHealth") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/womenHealth")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={physicalExerciseLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Women's Health</p>
                                                        </div>

                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("womenHealth") ? "green" : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {/* Sleep */}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "insomniaSeverityIndex") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/insomniaSeverityIndex")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={sleepLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Insomnia Severity Index</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("insomniaSeverityIndex")
                                                                    ? "green"
                                                                    : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {/* Diet */}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "mediterraneanDietAdherence") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/mediterraneanDietAdherence")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={dietLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Mediterranean Diet Adherence</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("mediterraneanDietAdherence")
                                                                    ? "green"
                                                                    : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "dietHistory") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/dietHistory")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={dietLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Diet History</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("dietHistory") ? "green" : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "eatingPattern") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/eatingPattern")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={dietLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Eating Pattern</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("eatingPattern") ? "green" : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}

                                        {/* Health */}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "healthResourceUtilization") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/healthResourceUtilization")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={healthLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Health Resource Utilization</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("healthResourceUtilization")
                                                                    ? "green"
                                                                    : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "qualityOfLife") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/qualityOfLife")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={healthLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Quality of life</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("qualityOfLife") ? "green" : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "healthUtilityIndex") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/healthUtilityIndex")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={healthLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Health Utility Index</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("healthUtilityIndex") ? "green" : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "healthBehaviorsQuestionnaire") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/healthBehaviorsQuestionnaire")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={healthLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Health Behaviors Questionnaire</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("healthBehaviorsQuestionnaire")
                                                                    ? "green"
                                                                    : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}

                                        {/* Cognitive */}

                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "preCognitiveExpectancy") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/preCognitiveExpectancy")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={cognitiveTrainingLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Pre-Cognitive Expectancy</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("preCognitiveExpectancy")
                                                                    ? "green"
                                                                    : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "postCognitiveExpectancy") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/postCognitiveExpectancy")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={cognitiveTrainingLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Post-Cognitive Expectancy</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("postCognitiveExpectancy")
                                                                    ? "green"
                                                                    : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "generalizedAnxietyDisorder") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/generalizedAnxietyDisorder")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={cognitiveTrainingLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Generalized Anxiety Disorder</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("generalizedAnxietyDisorder")
                                                                    ? "green"
                                                                    : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "geriatricDepressionScale") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/geriatricDepressionScale")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={cognitiveTrainingLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Geriatric Depression Scale</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("geriatricDepressionScale")
                                                                    ? "green"
                                                                    : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "revisedHearingHandicapInventory") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/revisedHearingHandicapInventory")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={cognitiveTrainingLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Revised Hearing Handicap Inventory</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("revisedHearingHandicapInventory")
                                                                    ? "green"
                                                                    : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "bhproQuestionnaires") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/bhproQuestionnaires")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={cognitiveTrainingLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>BHPro Questionnaires</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("bhproQuestionnaires") ? "green" : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        {currentParticipant &&
                                            currentParticipant.questionnaires &&
                                            currentParticipant.questionnaires.some(q => q.name === "visionHearing") && (
                                                <Grid item xs={6}>
                                                    <div
                                                        className="item"
                                                        onClick={() => hist.push("/survey/visionHearing")}
                                                        style={{ position: "relative" }}
                                                    >
                                                        <div className="logo-container">
                                                            <img src={cognitiveTrainingLogo} alt="icon" className="logo nofilterimg" />
                                                        </div>
                                                        <div className="centered__parent">
                                                            <p>Vision & Hearing</p>
                                                        </div>
                                                        <div
                                                            className="questionnaire-progression"
                                                            style={{
                                                                backgroundColor: isQuestionnaireCompleted("visionHearing") ? "green" : "orange",
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                    </Grid>
                                    <div className="centered__parent">
                                        <h1 style={{ paddingBottom: 10, paddingTop: 20 }}>Legend</h1>
                                    </div>
                                    <Grid container style={{}}>
                                        <Grid item xs={6}>
                                            <div className="item" style={{ position: "relative", cursor: "auto" }}>
                                                <div className="centered__parent">
                                                    <p>Completed</p>
                                                </div>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        borderRadius: 50,
                                                        width: 20,
                                                        height: 20,
                                                        right: 15,
                                                        top: 10,
                                                        backgroundColor: "green",
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="item" style={{ position: "relative", cursor: "auto" }}>
                                                <div className="centered__parent">
                                                    <p>Not Completed</p>
                                                </div>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        borderRadius: 50,
                                                        width: 20,
                                                        height: 20,
                                                        right: 15,
                                                        top: 10,
                                                        backgroundColor: "orange",
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {currentParticipant && currentParticipant.hasBiometricData ? (
                                        <div style={{ paddingTop: 20 }}>
                                            <div className="centered__parent">
                                                <h1 style={{ paddingBottom: 10, paddingTop: 20 }}>Biometric data</h1>
                                            </div>
                                            <div>
                                                <Button fullWidth className="cta__blue" variant="contained" onClick={() => setOpen(true)}>
                                                    Update my data <span className="ct__neuropeak__btnArrow">→</span>
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        {open && <BiometricDialog open={open} setOpen={setOpen} />}
                    </div>
                </>
            );
        }
        if (currentParticipant.state === "intervention") {
            return (
                <>
                    {/* <button onClick={fixScores}>fix</button> */}
                    <TopMenu title={`Welcome ${currentParticipant && currentParticipant.firstName}!`} icon={""} />
                    <div className="home intervention">
                        <div className="home__left">
                            <div className="home__section">
                                <h4 className="home__title">My Personalized Interventions this week</h4>
                                <Grid container spacing={2} item className="home__cards">
                                    {currentParticipant.physical.show && (
                                        <Grid item xs={4}>
                                            <HomeCard
                                                title="Physical Exercise"
                                                image={physicalExerciseLogo}
                                                onClick={() => hist.push("/physicalExercise")}
                                            />
                                        </Grid>
                                    )}
                                    {currentParticipant.cognitive.show && (
                                        <Grid item xs={4}>
                                            <HomeCard
                                                title="Cognitive Training"
                                                image={cognitiveTrainingLogo}
                                                onClick={() => hist.push("/cognitiveTraining")}
                                            />
                                        </Grid>
                                    )}

                                    {currentParticipant.diet.show && (
                                        <Grid item xs={4}>
                                            <HomeCard title="Diet" image={dietLogo} onClick={() => hist.push("/diet")} />
                                        </Grid>
                                    )}
                                    {currentParticipant.health.show && (
                                        <Grid item xs={4}>
                                            <HomeCard title="Cardiometabolic Health" image={healthLogo} onClick={() => hist.push("/health")} />
                                        </Grid>
                                    )}
                                    {currentParticipant.sleep.show && (
                                        <Grid item xs={4}>
                                            <HomeCard title="Sleep" image={sleepLogo} onClick={() => hist.push("/sleep")} />
                                        </Grid>
                                    )}
                                    <Grid item xs={4}>
                                        <div onClick={() => hist.push("/help")} className="home__helpContainerCard">
                                            <div className="home__titleContainer">
                                                <h5 className="home__title">Need help?</h5>
                                            </div>
                                            <img className="home__image nofilterimg" src={helpLogo} alt="NeedHelp-image" />
                                            <img src={helpLogo} className="home__logoBackground nofilterimg" />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="home__section home__chat">
                                <h4 className="home__title">General Chat</h4>
                                {currentParticipant && currentParticipant.id && <ParticipantChat participantId={currentParticipant.id} />}
                            </div>
                            <Grid item md={12}>
                                <Grid item xs={12} onClick={() => setSurveyDialogOpen(true)} className="home__options__card">
                                    <Button className="home__options__title">
                                        <h4>Complete survey</h4>
                                    </Button>
                                    <RiFileList2Line className="home__options__image" />
                                    <RiFileList2Line className="home__options__background" />
                                </Grid>
                            </Grid>
                        </div>
                        <div className="home__right">
                            <div className="home__section">
                                <h4 className="home__title">My latest progresses</h4>
                                <Progress />
                            </div>

                            {/* Wellness Vision */}
                            <h4 className="home__title">Wellness Vision</h4>
                            <div className="ct__neuropeak__content" style={{ marginLeft: 0 }}>
                                {!currentParticipant.wellnessVision && <p style={{ color: "white", fontFamily: "Roboto" }}>None</p>}
                                {parseGoalsContent(currentParticipant.wellnessVision).map((paragraph: any, index: number) => {
                                    if (paragraph.props.children?.length === 0) {
                                        return (
                                            <React.Fragment key={index}>
                                                <br />
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <p key={index} style={{ color: "white", fontFamily: "Roboto" }}>
                                            {paragraph}
                                        </p>
                                    );
                                })}
                            </div>

                            <div>
                                <MyGoals />
                            </div>

                            <div>
                                <NextMeeting />
                            </div>
                            {/* <div>
                                <Grid item xs={6} onClick={() => hist.push("/profile")} className="home__options__card">
                                    <h4 className="home__options__title">Profile</h4>
                                    <BsChatDots className="home__options__image" />
                                    <BsChatDots className="home__options__background" />
                                </Grid>
                            </div> */}
                            {/* <div>
                                <Grid item xs={6} className="home__options__card" onClick={() => setSurveyDialogOpen(true)}>
                                    <h4 className="home__options__title">Complete survey</h4>
                                    <RiFileList2Line className="home__options__image" />
                                    <RiFileList2Line className="home__options__background" />
                                </Grid>
                            </div> */}
                        </div>
                    </div>
                    {surveyDialogOpen && <SurveyDialog open={surveyDialogOpen} setOpen={setSurveyDialogOpen} topic="" />}
                </>
            );
        }
        if (currentParticipant.state === "control") {
            return (
                <>
                    <TopMenu title={`Welcome ${currentParticipant && currentParticipant.firstName}!`} icon={""} />

                    <div className="home control">
                        <div className="home__container--left">
                            <Grid xs={12} style={{ marginBottom: 40 }}>
                                <FallsCalendar
                                    date={date}
                                    events={events}
                                    setEvents={setEvents}
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                />
                            </Grid>

                            {currentParticipant &&
                                currentParticipant.questionnaires &&
                                currentParticipant.questionnaires.some(q => q.name === "sleepDiary") && (
                                    <Grid xs={12} style={{ marginBottom: 40 }}>
                                        <div className="centered__parent">
                                            <h4 style={{ marginBottom: 20 }}>Sleep Calendar</h4>
                                        </div>

                                        <div className="portalLayout__title">
                                            <div className="centered__parent">
                                                <SleepCalendar participant={currentParticipant} />
                                            </div>
                                        </div>
                                    </Grid>
                                )}

                            {/* <Grid xs={12}>
                                <div className="home__options__card" onClick={() => hist.push("/profile")}>
                                    <h4 className="home__options__title">Profile</h4>
                                    <BsChatDots className="home__options__image" />
                                    <BsChatDots className="home__options__background" />
                                </div>
                            </Grid> */}
                            <Grid item md={12}>
                                <Grid item xs={12} onClick={() => setSurveyDialogOpen(true)} className="home__options__card">
                                    <Button className="home__options__title">
                                        <h4>Complete survey</h4>
                                    </Button>
                                    <RiFileList2Line className="home__options__image" />
                                    <RiFileList2Line className="home__options__background" />
                                </Grid>
                            </Grid>
                        </div>
                        <div className="home__container--right">
                            <Grid xs={12}>
                                <h1 style={{ paddingBottom: 20 }}>Brain Health PRO</h1>
                                <h5 style={{ fontWeight: "normal" }}>
                                    You can make changes to reduce your dementia risk. Brain Health PRO will show you how.
                                </h5>
                                <a href="https://brainhealthpro.ca/" target="_blank">
                                    <img src={bhp} alt="" style={{ maxWidth: 500, margin: "auto", width: "100%" }} />
                                </a>
                            </Grid>

                            {/* <div onClick={() => hist.push("/help")} className="home__helpContainer">
                                <div className="home__help">
                                    <img className="home__image nofilterimg" src={helpLogo} alt="NeedHelp-image" />
                                    <img src={helpLogo} className="home__logoBackground nofilterimg" style={{ color: "#4ecbd3" }} />
                                    <div>
                                        <h5>
                                            Need help? <span style={{ float: "right" }}>1-800-SYNERGIC</span>
                                        </h5>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {surveyDialogOpen && <SurveyDialog open={surveyDialogOpen} setOpen={setSurveyDialogOpen} topic="" />}
                </>
            );
        }
    }
    return <>No state</>;
};

export default Home;
