import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";

// Custom components
import ParticipantDialog from "./ParticipantDialog";
import SearchBar from "../../../general/SearchBar";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Settings
import settings from "../../../../settings.json";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import {
    collection,
    query,
    orderBy,
    doc,
    onSnapshot,
    startAfter,
    limit,
    QueryDocumentSnapshot,
    DocumentData,
    getDocs,
    getCountFromServer,
    updateDoc,
} from "firebase/firestore";
import { genDoc } from "../../../../utils/firebase";

// Mui
import { Button, Grid, Switch } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";

const COLLECTION = "Participants";

const Participants: React.FC = () => {
    const hist = useHistory();
    const { currentAdmin, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [filteredItems, setFilteredItems] = useState<Participant[]>([]);
    const [count, setCount] = useState<number>(0);
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [open, setOpen] = useState(false);

    const fetchFirstItems = () => {
        try {
            const localQuery = query(collection(firestore, COLLECTION), orderBy("email", "asc"), limit(settings.page.rowsPerPage));
            return onSnapshot(localQuery, doc => {
                const data = doc.docs.map(genDoc<Participant>()).filter((item: Participant) => {
                    if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                        return true;
                    }
                    return !item.isDeleted;
                });
                setLastVisible(doc.docs[doc.docs.length - 1]);
                setParticipants(data);
                setFilteredItems(data);
            });
        } catch (e) {
            console.error(e);
        }
    };

    const fetchCount = async () => {
        try {
            const col = collection(firestore, "Participants");
            const snapshot = await getCountFromServer(col);

            setCount(snapshot.data().count);
        } catch (e) {
            console.error(e);
        }
    };

    const fetchMoreItems = async () => {
        try {
            if (participants.length !== 0) {
                const localQuery = query(
                    collection(firestore, COLLECTION),
                    orderBy("email", "asc"),
                    limit(settings.page.rowsPerPage),
                    startAfter(lastVisible)
                );

                const payload = await getDocs(localQuery);

                const data = payload.docs.map(genDoc<Participant>()).filter((item: Participant) => {
                    if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                        return true;
                    }
                    return !item.isDeleted;
                });
                setLastVisible(payload.docs[payload.docs.length - 1]);
                setParticipants([...participants, ...data]);
                setFilteredItems([...filteredItems, ...data]);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleSearch = (query: string) => {
        setSearchQuery(query);
    };

    const filterItems = async () => {
        const data = await getDocs(collection(firestore, COLLECTION));
        const localItems = data.docs.map(genDoc<Participant>());

        const lowercaseSearchQuery = searchQuery.toLowerCase().trim();

        const localFilteredUsages = localItems.filter(x => {
            if (!x.firstName || !x.participantId || !x.email || !x.createdAt) return false;

            return (
                x.email.toLowerCase().trim().includes(lowercaseSearchQuery) ||
                x.firstName.toLowerCase().trim().includes(lowercaseSearchQuery) ||
                x.participantId.toLowerCase().trim().includes(lowercaseSearchQuery) ||
                x.sex.toLowerCase().trim().includes(lowercaseSearchQuery) ||
                moment(x.createdAt).format("DD-MM-YYYY HH:mm:ss").includes(lowercaseSearchQuery)
            );
        });

        setCount(localFilteredUsages.length);
        setFilteredItems(localFilteredUsages.sort((a, b) => (a.createdAt && b.createdAt ? a.createdAt.getTime() - b.createdAt.getTime() : -1)));
    };

    const handleDelete = async (p: Participant) => {
        try {
            if (currentAdmin && currentAdmin.roles && currentAdmin.roles.includes(settings.app.highestRole)) {
                if (p.id)
                    await updateDoc(doc(firestore, COLLECTION, p.id), {
                        isDeleted: !p.isDeleted,
                    });
            } else {
                enqueueSnackbar("You don't have the required role", { variant: "error" });
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        setLoading(true);
        if (searchQuery) {
            filterItems();
        } else setFilteredItems(participants);
        setLoading(false);
    }, [searchQuery]);

    useEffect(() => {
        setLoading(true);
        fetchCount();
        const unsub = fetchFirstItems();
        setLoading(false);

        return () => {
            if (typeof unsub === "function") unsub();
        };
    }, []);

    return (
        <>
            <SearchBar onSearch={handleSearch}>
                <div className="searchbar__button">
                    <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                        Add
                    </Button>
                </div>
            </SearchBar>

            <div id="scrollableDiv" className="scrollable__container">
                <InfiniteScroll
                    dataLength={filteredItems.length}
                    next={fetchMoreItems}
                    hasMore={filteredItems.length < count}
                    loader={<h4 className="infinitescroll__container__message">Fetching next items...</h4>}
                    endMessage={!filteredItems.length && <h4 className="infinitescroll__container__message">No data</h4>}
                    className="infinitescroll__container"
                    scrollableTarget="scrollableDiv"
                >
                    {filteredItems.map(x => (
                        <div key={x.id} className="infinitescroll__container__card" onDoubleClick={() => hist.push(`participant/${x.id}/bio/0`)}>
                            <Grid item container>
                                <Grid item xs={12} sm={3}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">Firstname</div>
                                            </div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__text">{x.firstName}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">Participant Id</div>
                                            </div>
                                            <div className="centered__parent">
                                                <span className="infinitescroll__container__text">{x.participantId}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">Email</div>
                                            </div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__text">{x.email}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">Sex</div>
                                            </div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__text">{x.sex}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">Age</div>
                                            </div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__text">{x.age}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    <div className="centered__parent">
                                        <Switch
                                            checked={!x.isDeleted}
                                            onChange={() => handleDelete(x)}
                                            name="checkedA"
                                            inputProps={{
                                                "aria-label": "secondary checkbox",
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
            {open && <ParticipantDialog open={open} setOpen={setOpen} />}
        </>
    );
};

export default Participants;
