// ------------------- HELP EMAIL ------------------- //
export const help = (question: string, message: string) => `<html>
<body>
    <h3>Question</h3>
    <div>${question}<div/>
    <br/>
    <h3>Message</h3>
    <div>${message}<div/>
</body>
</html>`;

// ------------------- NEW PRIVATE CHAT NOTIFY EMAIL ------------------- //
export const newPrivateChatNotify = (firstName: string, email: string, message: string) => `<html>
<body>
    <h3>New private chat message</h3>
    <div>${firstName} (${email}) has sent you a new private message<div/>
    <br/>
    <h3>Message</h3>
    <div>${message}<div/>
</body>
</html>`;

// ------------------- NEW questionnaire EMAIL ------------------- //
export const questionnaireReminder = (firstName: string) => `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html
    xmlns="http://www.w3.org/1999/xhtml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    style="font-family: arial, 'helvetica neue', helvetica, sans-serif"
>
    <head>
        <meta charset="UTF-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="x-apple-disable-message-reformatting" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta content="telephone=no" name="format-detection" />
        <title>French</title>
        <!--[if (mso 16)]>
            <style type="text/css">
                a {
                    text-decoration: none;
                }
            </style>
        <![endif]-->
        <!--[if gte mso 9
            ]><style>
                sup {
                    font-size: 100% !important;
                }
            </style><!
        [endif]-->
        <!--[if gte mso 9]>
            <xml>
                <o:OfficeDocumentSettings>
                    <o:AllowPNG></o:AllowPNG>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
        <![endif]-->
        <!--[if !mso]><!-- -->
        <link href="https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i" rel="stylesheet" />
        <!--<![endif]-->
        <style type="text/css">
            #outlook a {
                padding: 0;
            }
            .es-button {
                mso-style-priority: 100 !important;
                text-decoration: none !important;
            }
            a[x-apple-data-detectors] {
                color: inherit !important;
                text-decoration: none !important;
                font-size: inherit !important;
                font-family: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
            }
            .es-desk-hidden {
                display: none;
                float: left;
                overflow: hidden;
                width: 0;
                max-height: 0;
                line-height: 0;
                mso-hide: all;
            }
            [data-ogsb] .es-button {
                border-width: 0 !important;
                padding: 10px 20px 10px 20px !important;
            }
            [data-ogsb] .es-button.es-button-1 {
                padding: 10px 20px !important;
            }
            @media only screen and (max-width: 600px) {
                p,
                ul li,
                ol li,
                a {
                    line-height: 150% !important;
                }
                h1,
                h2,
                h3,
                h1 a,
                h2 a,
                h3 a {
                    line-height: 120% !important;
                }
                h1 {
                    font-size: 30px !important;
                    text-align: left;
                }
                h2 {
                    font-size: 24px !important;
                    text-align: left;
                }
                h3 {
                    font-size: 20px !important;
                    text-align: left;
                }
                h1 a {
                    text-align: left;
                }
                .es-header-body h1 a,
                .es-content-body h1 a,
                .es-footer-body h1 a {
                    font-size: 30px !important;
                }
                h2 a {
                    text-align: left;
                }
                .es-header-body h2 a,
                .es-content-body h2 a,
                .es-footer-body h2 a {
                    font-size: 24px !important;
                }
                h3 a {
                    text-align: left;
                }
                .es-header-body h3 a,
                .es-content-body h3 a,
                .es-footer-body h3 a {
                    font-size: 20px !important;
                }
                .es-menu td a {
                    font-size: 14px !important;
                }
                .es-header-body p,
                .es-header-body ul li,
                .es-header-body ol li,
                .es-header-body a {
                    font-size: 14px !important;
                }
                .es-content-body p,
                .es-content-body ul li,
                .es-content-body ol li,
                .es-content-body a {
                    font-size: 14px !important;
                }
                .es-footer-body p,
                .es-footer-body ul li,
                .es-footer-body ol li,
                .es-footer-body a {
                    font-size: 16px !important;
                }
                .es-infoblock p,
                .es-infoblock ul li,
                .es-infoblock ol li,
                .es-infoblock a {
                    font-size: 12px !important;
                }
                *[class="gmail-fix"] {
                    display: none !important;
                }
                .es-m-txt-c,
                .es-m-txt-c h1,
                .es-m-txt-c h2,
                .es-m-txt-c h3 {
                    text-align: center !important;
                }
                .es-m-txt-r,
                .es-m-txt-r h1,
                .es-m-txt-r h2,
                .es-m-txt-r h3 {
                    text-align: right !important;
                }
                .es-m-txt-l,
                .es-m-txt-l h1,
                .es-m-txt-l h2,
                .es-m-txt-l h3 {
                    text-align: left !important;
                }
                .es-m-txt-r img,
                .es-m-txt-c img,
                .es-m-txt-l img {
                    display: inline !important;
                }
                .es-button-border {
                    display: inline-block !important;
                }
                a.es-button,
                button.es-button {
                    font-size: 18px !important;
                    display: inline-block !important;
                }
                .es-adaptive table,
                .es-left,
                .es-right {
                    width: 100% !important;
                }
                .es-content table,
                .es-header table,
                .es-footer table,
                .es-content,
                .es-footer,
                .es-header {
                    width: 100% !important;
                    max-width: 600px !important;
                }
                .es-adapt-td {
                    display: block !important;
                    width: 100% !important;
                }
                .adapt-img {
                    width: 100% !important;
                    height: auto !important;
                }
                .es-m-p0 {
                    padding: 0 !important;
                }
                .es-m-p0r {
                    padding-right: 0 !important;
                }
                .es-m-p0l {
                    padding-left: 0 !important;
                }
                .es-m-p0t {
                    padding-top: 0 !important;
                }
                .es-m-p0b {
                    padding-bottom: 0 !important;
                }
                .es-m-p20b {
                    padding-bottom: 20px !important;
                }
                .es-mobile-hidden,
                .es-hidden {
                    display: none !important;
                }
                tr.es-desk-hidden,
                td.es-desk-hidden,
                table.es-desk-hidden {
                    width: auto !important;
                    overflow: visible !important;
                    float: none !important;
                    max-height: inherit !important;
                    line-height: inherit !important;
                }
                tr.es-desk-hidden {
                    display: table-row !important;
                }
                table.es-desk-hidden {
                    display: table !important;
                }
                td.es-desk-menu-hidden {
                    display: table-cell !important;
                }
                .es-menu td {
                    width: 1% !important;
                }
                table.es-table-not-adapt,
                .esd-block-html table {
                    width: auto !important;
                }
                table.es-social {
                    display: inline-block !important;
                }
                table.es-social td {
                    display: inline-block !important;
                }
                .es-m-p5 {
                    padding: 5px !important;
                }
                .es-m-p5t {
                    padding-top: 5px !important;
                }
                .es-m-p5b {
                    padding-bottom: 5px !important;
                }
                .es-m-p5r {
                    padding-right: 5px !important;
                }
                .es-m-p5l {
                    padding-left: 5px !important;
                }
                .es-m-p10 {
                    padding: 10px !important;
                }
                .es-m-p10t {
                    padding-top: 10px !important;
                }
                .es-m-p10b {
                    padding-bottom: 10px !important;
                }
                .es-m-p10r {
                    padding-right: 10px !important;
                }
                .es-m-p10l {
                    padding-left: 10px !important;
                }
                .es-m-p15 {
                    padding: 15px !important;
                }
                .es-m-p15t {
                    padding-top: 15px !important;
                }
                .es-m-p15b {
                    padding-bottom: 15px !important;
                }
                .es-m-p15r {
                    padding-right: 15px !important;
                }
                .es-m-p15l {
                    padding-left: 15px !important;
                }
                .es-m-p20 {
                    padding: 20px !important;
                }
                .es-m-p20t {
                    padding-top: 20px !important;
                }
                .es-m-p20r {
                    padding-right: 20px !important;
                }
                .es-m-p20l {
                    padding-left: 20px !important;
                }
                .es-m-p25 {
                    padding: 25px !important;
                }
                .es-m-p25t {
                    padding-top: 25px !important;
                }
                .es-m-p25b {
                    padding-bottom: 25px !important;
                }
                .es-m-p25r {
                    padding-right: 25px !important;
                }
                .es-m-p25l {
                    padding-left: 25px !important;
                }
                .es-m-p30 {
                    padding: 30px !important;
                }
                .es-m-p30t {
                    padding-top: 30px !important;
                }
                .es-m-p30b {
                    padding-bottom: 30px !important;
                }
                .es-m-p30r {
                    padding-right: 30px !important;
                }
                .es-m-p30l {
                    padding-left: 30px !important;
                }
                .es-m-p35 {
                    padding: 35px !important;
                }
                .es-m-p35t {
                    padding-top: 35px !important;
                }
                .es-m-p35b {
                    padding-bottom: 35px !important;
                }
                .es-m-p35r {
                    padding-right: 35px !important;
                }
                .es-m-p35l {
                    padding-left: 35px !important;
                }
                .es-m-p40 {
                    padding: 40px !important;
                }
                .es-m-p40t {
                    padding-top: 40px !important;
                }
                .es-m-p40b {
                    padding-bottom: 40px !important;
                }
                .es-m-p40r {
                    padding-right: 40px !important;
                }
                .es-m-p40l {
                    padding-left: 40px !important;
                }
            }
        </style>
    </head>
    <body
        style="
            width: 100%;
            font-family: arial, 'helvetica neue', helvetica, sans-serif;
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
            padding: 0;
            margin: 0;
        "
    >
        <div class="es-wrapper-color" style="background-color: #f6f6f6">
            <!--[if gte mso 9]>
                <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
                    <v:fill type="tile" color="#f6f6f6"></v:fill>
                </v:background>
            <![endif]-->
            <table
                class="es-wrapper"
                width="100%"
                cellspacing="0"
                cellpadding="0"
                style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    border-collapse: collapse;
                    border-spacing: 0px;
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    height: 100%;
                    background-repeat: repeat;
                    background-position: center top;
                "
            >
                <tr>
                    <td valign="top" style="padding: 0; margin: 0">
                        <table
                            class="es-header"
                            cellspacing="0"
                            cellpadding="0"
                            align="center"
                            style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                                table-layout: fixed !important;
                                width: 100%;
                                background-color: transparent;
                                background-repeat: repeat;
                                background-position: center top;
                            "
                        >
                            <tr>
                                <td class="es-adaptive" align="center" style="padding: 0; margin: 0">
                                    <table
                                        class="es-header-body"
                                        style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                            background-color: #000000;
                                            border-top: 5px solid transparent;
                                            width: 600px;
                                            border-bottom: 5px solid transparent;
                                        "
                                        cellspacing="0"
                                        cellpadding="0"
                                        bgcolor="#000000"
                                        align="center"
                                    >
                                        <tr>
                                            <td align="left" style="padding: 0; margin: 0; padding-left: 20px; padding-right: 20px">
                                                <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:270px" valign="top"><![endif]-->
                                                <table
                                                    class="es-left"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                    align="left"
                                                    style="
                                                        mso-table-lspace: 0pt;
                                                        mso-table-rspace: 0pt;
                                                        border-collapse: collapse;
                                                        border-spacing: 0px;
                                                        float: left;
                                                    "
                                                >
                                                    <tr>
                                                        <td
                                                            class="es-m-p20b"
                                                            align="left"
                                                            style="padding: 0; margin: 0; width: 270px; padding-top: 14px"
                                                        >
                                                            <table
                                                                width="100%"
                                                                cellspacing="0"
                                                                cellpadding="0"
                                                                role="presentation"
                                                                style="
                                                                    mso-table-lspace: 0pt;
                                                                    mso-table-rspace: 0pt;
                                                                    border-collapse: collapse;
                                                                    border-spacing: 0px;
                                                                "
                                                            >
                                                                <tr>
                                                                    <td class="es-m-txt-c" style="padding: 0; margin: 0; font-size: 0px" align="left">
                                                                        <a
                                                                            target="_blank"
                                                                            href="https://app.synergictrial.com/"
                                                                            style="
                                                                                -webkit-text-size-adjust: none;
                                                                                -ms-text-size-adjust: none;
                                                                                mso-line-height-rule: exactly;
                                                                                text-decoration: underline;
                                                                                color: #1376c8;
                                                                                font-size: 14px;
                                                                            "
                                                                            ><img
                                                                                src="https://firebasestorage.googleapis.com/v0/b/synergic-2.appspot.com/o/emails%2Fsynergic_logo.png?alt=media&token=e1bb94a7-6237-4de6-938a-5cb61034355f"
                                                                                alt
                                                                                style="
                                                                                    display: block;
                                                                                    border: 0;
                                                                                    outline: none;
                                                                                    text-decoration: none;
                                                                                    -ms-interpolation-mode: bicubic;
                                                                                "
                                                                                width="180"
                                                                        /></a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!--[if mso]></td><td style="width:20px"></td><td style="width:270px" valign="top"><![endif]-->
                                                <table
                                                    class="es-right"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                    align="right"
                                                    style="
                                                        mso-table-lspace: 0pt;
                                                        mso-table-rspace: 0pt;
                                                        border-collapse: collapse;
                                                        border-spacing: 0px;
                                                        float: right;
                                                    "
                                                >
                                                    <tr>
                                                        <td align="left" style="padding: 0; margin: 0; width: 270px">
                                                            <table
                                                                width="100%"
                                                                cellspacing="0"
                                                                cellpadding="0"
                                                                role="presentation"
                                                                style="
                                                                    mso-table-lspace: 0pt;
                                                                    mso-table-rspace: 0pt;
                                                                    border-collapse: collapse;
                                                                    border-spacing: 0px;
                                                                "
                                                            >
                                                                <tr>
                                                                    <td
                                                                        class="es-m-txt-c es-m-p0t es-m-p10b"
                                                                        align="right"
                                                                        style="padding: 0; margin: 0; padding-top: 5px"
                                                                    >
                                                                        <span
                                                                            class="es-button-border"
                                                                            style="
                                                                                border-style: solid;
                                                                                border-color: #2cb543;
                                                                                background: #4ecbd3;
                                                                                border-width: 0px;
                                                                                display: inline-block;
                                                                                border-radius: 15px;
                                                                                width: auto;
                                                                            "
                                                                            ><a
                                                                                href="https://app.synergictrial.com/profile"
                                                                                class="es-button es-button-1"
                                                                                target="_blank"
                                                                                style="
                                                                                    mso-style-priority: 100 !important;
                                                                                    text-decoration: none;
                                                                                    -webkit-text-size-adjust: none;
                                                                                    -ms-text-size-adjust: none;
                                                                                    mso-line-height-rule: exactly;
                                                                                    color: #000000;
                                                                                    font-size: 16px;
                                                                                    border-style: solid;
                                                                                    border-color: #4ecbd3;
                                                                                    border-width: 10px 20px 10px 20px;
                                                                                    display: inline-block;
                                                                                    background: #4ecbd3;
                                                                                    border-radius: 15px;
                                                                                    font-family: lato, 'helvetica neue', helvetica, arial, sans-serif;
                                                                                    font-weight: normal;
                                                                                    font-style: normal;
                                                                                    line-height: 19px;
                                                                                    width: auto;
                                                                                    text-align: center;
                                                                                    border-top-width: 10px;
                                                                                    border-bottom-width: 10px;
                                                                                "
                                                                                >My Account</a
                                                                            ></span
                                                                        >
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!--[if mso]></td></tr></table><![endif]-->
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <table
                            class="es-content"
                            cellspacing="0"
                            cellpadding="0"
                            align="center"
                            style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                                table-layout: fixed !important;
                                width: 100%;
                            "
                        >
                            <tr>
                                <td align="center" style="padding: 0; margin: 0">
                                    <table
                                        class="es-content-body"
                                        cellspacing="0"
                                        cellpadding="0"
                                        bgcolor="#ffffff"
                                        align="center"
                                        style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                            background-color: #ffffff;
                                            width: 600px;
                                        "
                                    >
                                        <tr>
                                            <td align="left" style="padding: 0; margin: 0">
                                                <table
                                                    width="100%"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                    style="
                                                        mso-table-lspace: 0pt;
                                                        mso-table-rspace: 0pt;
                                                        border-collapse: collapse;
                                                        border-spacing: 0px;
                                                    "
                                                >
                                                    <tr>
                                                        <td valign="top" align="center" style="padding: 0; margin: 0; width: 600px">
                                                            <table
                                                                width="100%"
                                                                cellspacing="0"
                                                                cellpadding="0"
                                                                role="presentation"
                                                                style="
                                                                    mso-table-lspace: 0pt;
                                                                    mso-table-rspace: 0pt;
                                                                    border-collapse: collapse;
                                                                    border-spacing: 0px;
                                                                "
                                                            >
                                                                <tr>
                                                                    <td style="padding: 0; margin: 0; font-size: 0px" align="center">
                                                                        <img
                                                                            class="adapt-img"
                                                                            src="https://firebasestorage.googleapis.com/v0/b/synergic-2.appspot.com/o/emails%2Fsynergic_banner.png?alt=media&token=4df4f64c-b89d-46ea-aa9c-c3497bf2281c"
                                                                            alt
                                                                            style="
                                                                                display: block;
                                                                                border: 0;
                                                                                outline: none;
                                                                                text-decoration: none;
                                                                                -ms-interpolation-mode: bicubic;
                                                                            "
                                                                            width="600"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <table
                            class="es-content"
                            cellspacing="0"
                            cellpadding="0"
                            align="center"
                            style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                                table-layout: fixed !important;
                                width: 100%;
                            "
                        >
                            <tr>
                                <td align="center" style="padding: 0; margin: 0">
                                    <table
                                        class="es-content-body"
                                        cellspacing="0"
                                        cellpadding="0"
                                        bgcolor="#ffffff"
                                        align="center"
                                        style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                            background-color: #ffffff;
                                            width: 600px;
                                        "
                                    >
                                        <tr>
                                            <td align="left" style="padding: 20px; margin: 0">
                                                <table
                                                    width="100%"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                    style="
                                                        mso-table-lspace: 0pt;
                                                        mso-table-rspace: 0pt;
                                                        border-collapse: collapse;
                                                        border-spacing: 0px;
                                                    "
                                                >
                                                    <tr>
                                                        <td valign="top" align="center" style="padding: 0; margin: 0; width: 560px">
                                                            <table
                                                                width="100%"
                                                                cellspacing="0"
                                                                cellpadding="0"
                                                                role="presentation"
                                                                style="
                                                                    mso-table-lspace: 0pt;
                                                                    mso-table-rspace: 0pt;
                                                                    border-collapse: collapse;
                                                                    border-spacing: 0px;
                                                                "
                                                            >
                                                                <tr>
                                                                    <td align="center" style="padding: 0; margin: 0">
                                                                        <p
                                                                            style="
                                                                                margin: 0;
                                                                                -webkit-text-size-adjust: none;
                                                                                -ms-text-size-adjust: none;
                                                                                mso-line-height-rule: exactly;
                                                                                font-family: arial, 'helvetica neue', helvetica, sans-serif;
                                                                                line-height: 21px;
                                                                                color: #333333;
                                                                                font-size: 14px;
                                                                            "
                                                                        >
                                                                            You have a new questionnaire available
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="es-m-txt-c" align="center" style="padding: 0; margin: 0">
                                                                        <h2
                                                                            style="
                                                                                margin: 0;
                                                                                line-height: 29px;
                                                                                mso-line-height-rule: exactly;
                                                                                font-family: arial, 'helvetica neue', helvetica, sans-serif;
                                                                                font-size: 24px;
                                                                                font-style: normal;
                                                                                font-weight: normal;
                                                                                color: #333333;
                                                                            "
                                                                        >
                                                                            Hello, ${firstName}
                                                                        </h2>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style="
                                                                            padding: 0;
                                                                            margin: 0;
                                                                            padding-top: 10px;
                                                                            padding-left: 20px;
                                                                            padding-right: 20px;
                                                                            font-size: 0;
                                                                        "
                                                                        align="center"
                                                                    >
                                                                        <table
                                                                            width="10%"
                                                                            height="100%"
                                                                            cellspacing="0"
                                                                            cellpadding="0"
                                                                            border="0"
                                                                            role="presentation"
                                                                            style="
                                                                                mso-table-lspace: 0pt;
                                                                                mso-table-rspace: 0pt;
                                                                                border-collapse: collapse;
                                                                                border-spacing: 0px;
                                                                            "
                                                                        >
                                                                            <tr>
                                                                                <td
                                                                                    style="
                                                                                        padding: 0;
                                                                                        margin: 0;
                                                                                        border-bottom: 3px solid #4ecbd3;
                                                                                        background: none;
                                                                                        height: 1px;
                                                                                        width: 100%;
                                                                                        margin: 0px;
                                                                                    "
                                                                                ></td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <table
                            class="es-content"
                            cellspacing="0"
                            cellpadding="0"
                            align="center"
                            style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                                table-layout: fixed !important;
                                width: 100%;
                            "
                        >
                            <tr>
                                <td align="center" style="padding: 0; margin: 0">
                                    <table
                                        class="es-content-body"
                                        cellspacing="0"
                                        cellpadding="0"
                                        bgcolor="#ffffff"
                                        align="center"
                                        style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                            background-color: #ffffff;
                                            width: 600px;
                                        "
                                    >
                                        <tr>
                                            <td
                                                align="left"
                                                style="padding: 0; margin: 0; padding-top: 20px; padding-left: 20px; padding-right: 20px"
                                            >
                                                <table
                                                    width="100%"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                    style="
                                                        mso-table-lspace: 0pt;
                                                        mso-table-rspace: 0pt;
                                                        border-collapse: collapse;
                                                        border-spacing: 0px;
                                                    "
                                                >
                                                    <tr>
                                                        <td valign="top" align="center" style="padding: 0; margin: 0; width: 560px">
                                                            <table
                                                                width="100%"
                                                                cellspacing="0"
                                                                cellpadding="0"
                                                                role="presentation"
                                                                style="
                                                                    mso-table-lspace: 0pt;
                                                                    mso-table-rspace: 0pt;
                                                                    border-collapse: collapse;
                                                                    border-spacing: 0px;
                                                                "
                                                            >
                                                                <tr>
                                                                    <td
                                                                        align="left"
                                                                        style="padding: 0; margin: 0; padding-top: 10px; padding-bottom: 10px"
                                                                    >
                                                                        <p
                                                                            style="
                                                                                margin: 0;
                                                                                -webkit-text-size-adjust: none;
                                                                                -ms-text-size-adjust: none;
                                                                                mso-line-height-rule: exactly;
                                                                                font-family: arial, 'helvetica neue', helvetica, sans-serif;
                                                                                line-height: 21px;
                                                                                color: #333333;
                                                                                font-size: 14px;
                                                                            "
                                                                        >
                                                                            You've been assigned a new questionnaire for you to fill. Please click on
                                                                            the button below.
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="center" style="padding: 0; margin: 0">
                                                                        <span
                                                                            class="es-button-border"
                                                                            style="
                                                                                border-style: solid;
                                                                                border-color: #4ecbd3;
                                                                                background: #4ecbd3;
                                                                                border-width: 10px 20px;
                                                                                display: block;
                                                                                border-radius: 15px;
                                                                                width: auto;
                                                                            "
                                                                            ><a
                                                                                href="https://app.synergictrial.com/home"
                                                                                class="es-button"
                                                                                target="_blank"
                                                                                style="
                                                                                    mso-style-priority: 100 !important;
                                                                                    text-decoration: none;
                                                                                    -webkit-text-size-adjust: none;
                                                                                    -ms-text-size-adjust: none;
                                                                                    mso-line-height-rule: exactly;
                                                                                    color: #000000;
                                                                                    font-size: 16px;
                                                                                    border-style: solid;
                                                                                    border-color: #4ecbd3;
                                                                                    border-width: 10px 20px 10px 20px;
                                                                                    display: block;
                                                                                    background: #4ecbd3;
                                                                                    border-radius: 15px;
                                                                                    font-family: lato, 'helvetica neue', helvetica, arial, sans-serif;
                                                                                    font-weight: normal;
                                                                                    font-style: normal;
                                                                                    line-height: 19px;
                                                                                    width: auto;
                                                                                    text-align: center;
                                                                                    border-left-width: 5px;
                                                                                    border-right-width: 5px;
                                                                                "
                                                                                >View questionnaire</a
                                                                            ></span
                                                                        >
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="padding: 20px; margin: 0; font-size: 0" align="center">
                                                                        <table
                                                                            width="100%"
                                                                            height="100%"
                                                                            cellspacing="0"
                                                                            cellpadding="0"
                                                                            border="0"
                                                                            role="presentation"
                                                                            style="
                                                                                mso-table-lspace: 0pt;
                                                                                mso-table-rspace: 0pt;
                                                                                border-collapse: collapse;
                                                                                border-spacing: 0px;
                                                                            "
                                                                        >
                                                                            <tr>
                                                                                <td
                                                                                    style="
                                                                                        padding: 0;
                                                                                        margin: 0;
                                                                                        border-bottom: 0px solid #cccccc;
                                                                                        background: none;
                                                                                        height: 1px;
                                                                                        width: 100%;
                                                                                        margin: 0px;
                                                                                    "
                                                                                ></td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <table
                            class="es-header"
                            cellspacing="0"
                            cellpadding="0"
                            align="center"
                            style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse;
                                border-spacing: 0px;
                                table-layout: fixed !important;
                                width: 100%;
                                background-color: transparent;
                                background-repeat: repeat;
                                background-position: center top;
                            "
                        >
                            <tr>
                                <td class="es-adaptive" align="center" style="padding: 0; margin: 0">
                                    <table
                                        class="es-header-body"
                                        style="
                                            mso-table-lspace: 0pt;
                                            mso-table-rspace: 0pt;
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                            background-color: #000000;
                                            border-top: 5px solid transparent;
                                            width: 600px;
                                            border-bottom: 5px solid transparent;
                                        "
                                        cellspacing="0"
                                        cellpadding="0"
                                        bgcolor="#000000"
                                        align="center"
                                    >
                                        <tr>
                                            <td align="left" style="padding: 0; margin: 0; padding-left: 20px; padding-right: 20px">
                                                <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:270px" valign="top"><![endif]-->
                                                <table
                                                    class="es-left"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                    align="left"
                                                    style="
                                                        mso-table-lspace: 0pt;
                                                        mso-table-rspace: 0pt;
                                                        border-collapse: collapse;
                                                        border-spacing: 0px;
                                                        float: left;
                                                    "
                                                >
                                                    <tr>
                                                        <td class="es-m-p20b" align="left" style="padding: 0; margin: 0; width: 270px">
                                                            <table
                                                                width="100%"
                                                                cellspacing="0"
                                                                cellpadding="0"
                                                                role="presentation"
                                                                style="
                                                                    mso-table-lspace: 0pt;
                                                                    mso-table-rspace: 0pt;
                                                                    border-collapse: collapse;
                                                                    border-spacing: 0px;
                                                                "
                                                            >
                                                                <tr>
                                                                    <td class="es-m-txt-c" style="padding: 0; margin: 0; font-size: 0px" align="left">
                                                                        <a
                                                                            target="_blank"
                                                                            href="https://app.synergictrial.com/"
                                                                            style="
                                                                                -webkit-text-size-adjust: none;
                                                                                -ms-text-size-adjust: none;
                                                                                mso-line-height-rule: exactly;
                                                                                text-decoration: underline;
                                                                                color: #1376c8;
                                                                                font-size: 14px;
                                                                            "
                                                                            ><img
                                                                                src="https://firebasestorage.googleapis.com/v0/b/synergic-2.appspot.com/o/emails%2Fsynergic_logo.png?alt=media&token=e1bb94a7-6237-4de6-938a-5cb61034355f"
                                                                                alt
                                                                                style="
                                                                                    display: block;
                                                                                    border: 0;
                                                                                    outline: none;
                                                                                    text-decoration: none;
                                                                                    -ms-interpolation-mode: bicubic;
                                                                                    padding-top: 10px;
                                                                                "
                                                                                width="188"
                                                                        /></a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!--[if mso]></td><td style="width:20px"></td><td style="width:270px" valign="top"><![endif]-->
                                                <table
                                                    class="es-right"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                    align="right"
                                                    style="
                                                        mso-table-lspace: 0pt;
                                                        mso-table-rspace: 0pt;
                                                        border-collapse: collapse;
                                                        border-spacing: 0px;
                                                        float: right;
                                                    "
                                                >
                                                    <tr>
                                                        <td align="left" style="padding: 0; margin: 0; width: 270px">
                                                            <table
                                                                width="100%"
                                                                cellspacing="0"
                                                                cellpadding="0"
                                                                role="presentation"
                                                                style="
                                                                    mso-table-lspace: 0pt;
                                                                    mso-table-rspace: 0pt;
                                                                    border-collapse: collapse;
                                                                    border-spacing: 0px;
                                                                "
                                                            >
                                                                <tr>
                                                                    <td
                                                                        class="es-m-txt-c es-m-p0t es-m-p10b"
                                                                        align="right"
                                                                        style="padding: 0; margin: 0"
                                                                    >
                                                                        <span
                                                                            class="es-button-border"
                                                                            style="
                                                                                border-style: solid;
                                                                                border-color: #2cb543;
                                                                                background: #4ecbd3;
                                                                                border-width: 0px;
                                                                                display: inline-block;
                                                                                border-radius: 15px;
                                                                                width: auto;
                                                                            "
                                                                            ><a
                                                                                href="https://app.synergictrial.com/accountHelp"
                                                                                class="es-button es-button-1"
                                                                                target="_blank"
                                                                                style="
                                                                                    mso-style-priority: 100 !important;
                                                                                    text-decoration: none;
                                                                                    -webkit-text-size-adjust: none;
                                                                                    -ms-text-size-adjust: none;
                                                                                    mso-line-height-rule: exactly;
                                                                                    color: #000000;
                                                                                    font-size: 16px;
                                                                                    border-style: solid;
                                                                                    border-color: #4ecbd3;
                                                                                    border-width: 10px 20px;
                                                                                    display: inline-block;
                                                                                    background: #4ecbd3;
                                                                                    border-radius: 15px;
                                                                                    font-family: lato, 'helvetica neue', helvetica, arial, sans-serif;
                                                                                    font-weight: normal;
                                                                                    font-style: normal;
                                                                                    line-height: 19px;
                                                                                    width: auto;
                                                                                    text-align: center;
                                                                                "
                                                                                >Need Help?</a
                                                                            ></span
                                                                        >
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!--[if mso]></td></tr></table><![endif]-->
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </body>
</html>
`;
