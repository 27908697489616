import { IoIosColorFill } from "react-icons/io";
import { ColorBox } from "./interface";

const colorBoxConfig: ColorBox = {
    title: "Encadré",
    Icon: IoIosColorFill,
};

export type { ColorBox };
export default colorBoxConfig;
