import { useEffect, useState, useCallback, useRef, useImperativeHandle, forwardRef } from "react";
import { Editor, EditorState, convertFromRaw } from "draft-js";

import { Inline } from "../controls/";

// Draft utils
import { handleKeyCommand, keyBindingFn, handleReturn } from "../utils/keyCommands";
import { saveAssets, convertToRawJSON, convertToRawString } from "../utils/data";

// highlighter
// import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
// import html from "react-syntax-highlighter/dist/esm/languages/hljs/htmlbars";
// import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
// import vsc from "react-syntax-highlighter/dist/esm/styles/hljs/vs";

// default config
import config, { blockStyleFn } from "../config/defaultToolbar";

// custom block renderer
import renderer from "../renderer";

// decorators
import decorators from "../decorators";

import { DraftParser } from "../utils/draft/draftParser";

type UltraEditorProps = {
    // eslint-disable-next-line
    onSave?: (raw: string) => Promise<void>;
    rawContent?: string;
    readOnly?: boolean;
};

const initEditorState = (rawContent?: string) => {
    try {
        return rawContent ? EditorState.createWithContent(convertFromRaw(JSON.parse(rawContent)), decorators) : EditorState.createEmpty();
    } catch (e: any) {
        console.error("Rich Text Editor: Invalid raw content. Creating empty editor", e);
        return EditorState.createEmpty();
    }
};

const UltraEditor = ({ onSave, rawContent, readOnly = false }: UltraEditorProps, ref: any) => {
    const [editorState, setEditorState] = useState<EditorState>(initEditorState(rawContent));

    const [saving, setSaving] = useState<boolean>(false);

    const editorRef = useRef<any>(null);

    // eslint-disable-next-line
    const [blockStyle, setBlockStyle] = useState("");

    const onEditorChange = useCallback(
        (newState: EditorState) => {
            const currentSelection = newState.getSelection();
            const blockKey = currentSelection.getStartKey();
            const stylingOfBlock = newState.getCurrentContent().getBlockForKey(blockKey).getType();
            setBlockStyle(stylingOfBlock);
            if (readOnly) return;
            setEditorState(newState);
        },
        [setEditorState, readOnly]
    );

    // This function is called when the user hits CTRL+S (save command)
    const handleOnSave = useCallback(async () => {
        if (!onSave) return;
        setSaving(true);

        const rawContent = convertToRawString(await saveAssets(editorState));
        await onSave(rawContent);

        setSaving(false);
    }, [onSave, editorState]);

    // This can be called on the parent component to get the rawContent of the editor. We can get it
    // as a raw string or in JSON form
    useImperativeHandle(ref, () => ({
        async getRawContent(format: "string" | "json" = "string") {
            let content;
            setSaving(true);

            if (format === "string") content = convertToRawString(await saveAssets(editorState));
            else if (format === "json") content = convertToRawJSON(await saveAssets(editorState));

            setSaving(false);
            return content;
        },
        async getHtml() {
            return new DraftParser(await saveAssets(editorState)).parse();
        },
    }));

    const handleFocus = () => {
        if (null !== editorRef.current) editorRef.current.focus();
    };

    // used to initialize the editor state in case rawContent changes after Editor has been rendered
    useEffect(() => {
        setEditorState(initEditorState(rawContent));
    }, [rawContent]);

    return (
        <>
            <div className="draftjs__controls">
                {/* <BlockType config={config.block} editorState={editorState} onChange={onEditorChange} blockStyle={blockStyle} />*/}
                <Inline config={config.inline} editorState={editorState} onChange={onEditorChange} />
                {/*<List config={config.list} editorState={editorState} onChange={onEditorChange} />*/}
                {/* <TextAlign config={config.textAlign} editorState={editorState} onChange={onEditorChange} /> */}
                {saving && "Veuillez patienter..."}
            </div>
            <div className="draftjs__container" onClick={handleFocus}>
                <Editor
                    readOnly={saving || readOnly}
                    ref={editorRef}
                    editorState={editorState}
                    onChange={onEditorChange}
                    handleKeyCommand={handleKeyCommand(onEditorChange, handleOnSave)}
                    keyBindingFn={keyBindingFn}
                    blockStyleFn={blockStyleFn}
                    blockRendererFn={renderer(editorState)}
                    handleReturn={handleReturn(editorState, onEditorChange)}
                />
            </div>
        </>
    );
};

export default forwardRef(UltraEditor);
