import React, { useRef } from "react";
import { useSnackbar } from "notistack";

// Custom components
import Title from "../../../../../general/Title";
import Editor from "../../../../../general/ultraEditor";

// Interfaces
import { Participant } from "../../../../../../interfaces/Participant";

// Mui
import { Button } from "@mui/material";

// Firebase
import { firestore } from "../../../../../../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";

interface Props {
    participant: Participant | null;
    primaryIndex: string;
    secondaryIndex: string;
}

const Goals: React.FC<Props> = ({ participant = null }) => {
    const wellnessVisionEditorRef = useRef<any>();
    const physicalEditorRef = useRef<any>();
    const cognitiveEditorRef = useRef<any>();
    const healthEditorRef = useRef<any>();
    const sleepEditorRef = useRef<any>();
    const dietEditorRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = async () => {
        if (participant && participant.id) {
            const wellnessVision = await wellnessVisionEditorRef.current.getRawContent();

            await updateDoc(doc(firestore, "Participants", participant.id), {
                ...participant,
                wellnessVision: wellnessVision,
                physical: {
                    ...participant.physical,
                    goals: await physicalEditorRef.current.getRawContent(),
                },
                cognitive: {
                    ...participant.cognitive,
                    goals: await cognitiveEditorRef.current.getRawContent(),
                },
                health: {
                    ...participant.health,
                    goals: await healthEditorRef.current.getRawContent(),
                },
                sleep: {
                    ...participant.sleep,
                    goals: await sleepEditorRef.current.getRawContent(),
                },
                diet: {
                    ...participant.diet,
                    goals: await dietEditorRef.current.getRawContent(),
                },
            });

            enqueueSnackbar("Modified Goals", { variant: "success" });
        }
    };

    return (
        <div>
            <Title>
                <div style={{ fontSize: 35 }}>Wellness Vision</div>
            </Title>
            {participant && <Editor ref={wellnessVisionEditorRef} rawContent={participant.wellnessVision} />}

            <Title>
                <div style={{ fontSize: 35 }}>Goals</div>
            </Title>

            {participant && (
                <>
                    <Title>Physical</Title>
                    <Editor ref={physicalEditorRef} rawContent={participant.physical.goals} />

                    <Title>Cognitive</Title>
                    <Editor ref={cognitiveEditorRef} rawContent={participant.cognitive.goals} />

                    <Title>Health</Title>
                    <Editor ref={healthEditorRef} rawContent={participant.health.goals} />

                    <Title>Sleep</Title>
                    <Editor ref={sleepEditorRef} rawContent={participant.sleep.goals} />

                    <Title>Diet</Title>
                    <Editor ref={dietEditorRef} rawContent={participant.diet.goals} />
                </>
            )}

            <div style={{ display: "flex" }}>
                <Button variant="contained" color="primary" style={{ marginLeft: "auto", marginTop: 20 }} onClick={handleSave}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default Goals;
