import React, { useEffect, useState } from "react";
import moment from "moment";

// Mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Grid } from "@mui/material";

// Firebase
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../../../../firebase/firebase";

// Interfaces
import { Participant } from "../../../../../interfaces/Participant";

// Utils
import { exportAOADocument } from "../../../../../utils/xlsx";
import { getTimeBetween2Dates } from "../../../../../utils/date";

// Hooks
import { genDoc } from "../../../../../utils/firebase";

const Logs: React.FC<any> = ({ participant }) => {
    // States
    const [sessions, setSessions] = useState<any[]>([]);
    const [allSessions, setAllSessions] = useState<any[]>([]);
    const [logs, setLogs] = useState<any[]>([]);
    const [allLogs, setAllLogs] = useState<any[]>([]);

    const fetchData = async () => {
        if (participant && participant.id) {
            const docRef = doc(firestore, "Participants", participant.id);

            return onSnapshot(docRef, snapshot => {
                const part = genDoc<Participant>()(snapshot);

                setLogs(
                    part.logs
                        .map((log: any) => ({ date: new Date(log.date.seconds * 1000), action: log.action }))
                        .sort((a: any, b: any) => (a.date > b.date ? -1 : 1))
                        .slice(0, 50)
                );
                setAllLogs(
                    part.logs
                        .map((log: any) => ({ date: new Date(log.date.seconds * 1000), action: log.action }))
                        .sort((a: any, b: any) => (a.date > b.date ? -1 : 1))
                );

                setSessions(
                    part.uses
                        .map((session: any) => ({
                            start: new Date(session.start.seconds * 1000),
                            end: session.end ? new Date(session.end.seconds * 1000) : undefined,
                        }))
                        .sort((a: any, b: any) => (a.start > b.start ? -1 : 1))
                        .slice(0, 50)
                );

                setAllSessions(
                    part.uses
                        .map((session: any) => ({
                            start: new Date(session.start.seconds * 1000),
                            end: session.end ? new Date(session.end.seconds * 1000) : undefined,
                        }))
                        .sort((a: any, b: any) => (a.start > b.start ? -1 : 1))
                );
            });
        }
    };

    const formatLogs = async () => {
        const dataArray: any[][] = [];

        const headers = ["Date", "Action"];
        dataArray.push(headers);

        allLogs.forEach(log => {
            dataArray.push([moment(log.date).format("YYYY-MM-DD HH:mm:ss"), log.action]);
        });

        return dataArray;
    };

    const exportLogsToXLSX = async () => {
        const fileName = `logs_${moment(new Date()).format("DD-MM-YYYY")}`;
        const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
        exportAOADocument(await formatLogs(), fileName, sheetName);
    };

    const formatSessions = async () => {
        const dataArray: any[][] = [];

        const headers = ["Start", "End", "Duration"];
        dataArray.push(headers);

        allSessions.forEach(sessions => {
            dataArray.push([
                moment(sessions.start).format("YYYY-MM-DD HH:mm:ss"),
                sessions.end ? moment(sessions.end).format("YYYY-MM-DD HH:mm:ss") : "-",
                sessions.end ? getTimeBetween2Dates(sessions.start, sessions.end) : "n/a",
            ]);
        });

        return dataArray;
    };

    const exportSessionsToXLSX = async () => {
        const fileName = `sessions_${moment(new Date()).format("DD-MM-YYYY")}`;
        const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
        exportAOADocument(await formatSessions(), fileName, sheetName);
    };

    useEffect(() => {
        let unsub: any = undefined;
        if (participant && participant.id) {
            unsub = fetchData();
        }

        return () => typeof unsub === "function" && unsub();
    }, [participant]);

    if (logs)
        return (
            <>
                <Grid item container>
                    <Grid item xs={10}>
                        Logs
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={() => exportLogsToXLSX()} style={{ float: "right", marginTop: 20 }}>
                            Export
                        </Button>
                    </Grid>
                </Grid>

                <div style={{ maxHeight: 400, overflowY: "auto" }}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Date</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {logs.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell align="center">{moment(row.date).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                        <TableCell align="center">{row.action}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div style={{ maxHeight: 400, overflowY: "auto" }}>
                    <Grid item container>
                        <Grid item xs={10}>
                            Sessions
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" onClick={() => exportSessionsToXLSX()} style={{ float: "right", marginTop: 20 }}>
                                Export
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Start</TableCell>
                                <TableCell align="center">End</TableCell>
                                <TableCell align="center">Duration</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sessions.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell align="center">{moment(row.start).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                    <TableCell align="center">{row.end ? moment(row.end).format("YYYY-MM-DD HH:mm:ss") : "-"}</TableCell>
                                    <TableCell align="center">{row.end ? getTimeBetween2Dates(row.start, row.end) : "n/a"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    else return <></>;
};

export default Logs;
