import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Mui core
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Custom components
import TabPanel from "../../../../general/TabPanel";
import Chat from "./bioTabs/Chat";
import Info from "./bioTabs/Info";
import Progresses from "./bioTabs/Progresses";
// Icons
import { MdInfoOutline, MdChatBubbleOutline, MdTrendingUp, MdOutlineOutlinedFlag } from "react-icons/md";
import Goals from "./bioTabs/Goals";

const Bio: React.FC<any> = ({ participant = null, index, tabName }) => {
    const hist = useHistory();
    // States
    const [tab, setTab] = useState(0);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const mobileScreenSize = 500;
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

    const tabs: any = [
        {
            label: "Info",
            icon: <MdInfoOutline />,
            component: Info,
        },
        {
            label: "Chat",
            icon: <MdChatBubbleOutline />,
            component: Chat,
        },
        {
            label: "Progresses",
            icon: <MdTrendingUp />,
            component: Progresses,
        },
        {
            label: "Goals",
            icon: <MdOutlineOutlinedFlag />,
            component: Goals,
        },
    ];

    useEffect(() => {
        setTab(index);
    }, [index]);

    return (
        <div>
            <div>
                <div className="tab__background__color">
                    <Tabs
                        variant="fullWidth"
                        value={tab}
                        onChange={(e, v) => participant && participant.id && hist.push(`/dashboard/participant/${participant.id}/${tabName}/${v}`)}
                    >
                        {tabs.map((t: any) => (
                            <Tab key={t.label} icon={t.icon} label={screenWidth >= mobileScreenSize ? t.label : ""} />
                        ))}
                    </Tabs>
                </div>
                {tabs.map((t: any, i: number) => (
                    <TabPanel key={i} value={tab} index={i}>
                        <t.component participant={participant} />
                    </TabPanel>
                ))}
            </div>
        </div>
    );
};

export default Bio;
