import { EditorBlock, ContentState, ContentBlock } from "draft-js";

interface RendererProps {
    contentState: ContentState;
    block: ContentBlock;
    [index: string]: any;
}

const style = { padding: 10, outline: "1px solid black", borderRadius: "2px" };

const ColorBoxRenderer = (props: RendererProps) => {
    const color = props.block.getData().get("color");
    return (
        <div style={{ ...style, backgroundColor: color }}>
            <EditorBlock {...props} />
        </div>
    );
};

export default ColorBoxRenderer;
