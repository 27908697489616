export const preCognitiveExpectancy = {
    title: "Pre-Cognitive Expectancy",
    about: "",
    instructions: "",
    questions: [
        {
            questionNumber: "1",
            question:
                "How successful would you expect the lifestyle interventions and recommendations to be at improving your thinking ability and brain health, in general?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "2",
            question:
                "How successful would you expect the lifestyle interventions and recommendations to be at improving your memory, in general or in cognitive tests, such as the ones you did in a previous session?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "3",
            question:
                "How successful would you expect the lifestyle interventions to be at improving your memory in everyday tasks (e.g., remembering important dates, appointments, things to do, names, or any information you have been told, or have read or seen on TV)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "4",
            question:
                "How successful would you expect the lifestyle interventions to be at improving your attention, in general or in cognitive tests, such as the ones you did in a previous session?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "5",
            question:
                "How successful would you expect the lifestyle interventions to be at improving your attention in everyday tasks (e.g., driving, managing finances, cooking a meal for several people, following a conversation in a noisy environment)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "6",
            question:
                "How successful would you expect the lifestyle interventions to be at improving your distractibility (e.g., avoiding distractions when trying to focus on your reading or on a complex card game, or on a complex pattern when knitting, etc.)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "7",
            question:
                "How successful would you expect the lifestyle interventions to be at improving your reasoning abilities (e.g., problem solving, planning a day of shopping, planning a trip, etc.)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "8",
            question:
                "How successful would you expect the lifestyle interventions to be at improving your reaction time / processing speed (e.g., the rapidity with which you react to unexpected events, like something falling or an animal on the road when you drive, or the rapidity with which you treat information, like when you follow a fast-pace detective film)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "9",
            question:
                "How successful would you expect the lifestyle interventions to be at improving your multi-tasking ability (e.g., managing multiple tasks at the same time, such as cooking or driving while having a conversation, knitting while watching the TV, managing finances while listening to the news on the radio, thinking to the ‘broader picture’ while watching a documentary on a specific theme, etc.)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "10",
            question: "How much did you know about lifestyle interventions before taking part in this survey?",
            answers: [
                "I have participated in lifestyle interventions (e.g., as part of a study or for personal use).",
                "I have learned about lifestyle interventions through courses, articles, books, and/or documentaries.",
                "I have heard about lifestyle interventions through word of mouth, but do not know anything specific about it.",
                "I have absolutely no knowledge of lifestyle interventions; I first heard of it through this survey.",
            ],
            type: "radio",
        },
        {
            questionNumber: "11",
            question:
                "Are you familiar with media reports that certain activities and training can improve cognitive function? If you remember, please specify which reports or advertisements you have seen and/or what the general claims were.",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "12a",
            question: "Do you expect these lifestyle interventions to be…",
            answers: [
                "Very frustrating",
                "Fairly frustrating",
                "Somewhat frustrating",
                "I don’t know",
                "Somewhat enjoyable",
                "Fairly enjoyable",
                "Completely enjoyable",
            ],
            type: "radio",
        },
        {
            questionNumber: "12b",
            question: "Do you expect these lifestyle interventions to be…",
            answers: [
                "Very easy",
                "Fairly easy",
                "Somewhat easy",
                "I don’t know",
                "Somewhat challenging",
                "Fairly challenging",
                "Completely challenging",
            ],

            type: "radio",
        },

        {
            questionNumber: "12c",
            question: "Do you expect these lifestyle interventions to be…",
            answers: [
                "Completely discouraging",
                "Fairly discouraging",
                "Somewhat discouraging",
                "I don’t know",
                "Somewhat motivating",
                "Fairly motivating",
                "Completely motivating",
            ],
            type: "radio",
        },
    ],
};
export const postCognitiveExpectancy = {
    title: "Post-Cognitive Expectancy",
    about: "",
    instructions: "",
    questions: [
        {
            questionNumber: "1",
            question:
                "How successful do you think these lifestyle interventions were at improving your thinking ability and brain health, in general?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "2",
            question:
                "How successful do you think these lifestyle interventions were at improving your memory, in general or in cognitive tests, such as the ones you did in a previous session?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "3",
            question:
                "How successful do you think these lifestyle interventions were at improving your memory in everyday tasks (e.g., remembering important dates, appointments, things to do, names, or any information you have been told, or have read or seen on TV)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "4",
            question:
                "How successful do you think these lifestyle interventions were at improving your attention, in general or in cognitive tests, such as the ones you did in a previous session?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "5",
            question:
                "How successful do you think these lifestyle interventions were at improving your attention in everyday tasks (e.g., driving, managing finances, cooking a meal for several people, following a conversation in a noisy environment)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "6",
            question:
                "How successful do you think these lifestyle interventions were at improving your distractibility (e.g., avoiding distractions when trying to focus on your reading or on a complex card game, or on a complex pattern when knitting, etc.)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "7",
            question:
                "How successful do you think these lifestyle interventions were at improving your reasoning abilities (e.g., problem solving, planning a day of shopping, planning a trip, etc.)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "8",
            question:
                "How successful do you think these lifestyle interventions were at improving your reaction time / processing speed (e.g., the rapidity with which you react to unexpected events, like something falling or an animal on the road when you drive, or the rapidity with which you treat information, like when you follow a fast-pace detective film)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "9",
            question:
                "How successful do you think these lifestyle interventions were at improving your multi-tasking ability (e.g., managing multiple tasks at the same time, such as cooking or driving while having a conversation, knitting while watching the TV, managing finances while listening to the news on the radio, thinking to the ‘broader picture’ while watching a documentary on a specific theme, etc.)?",
            answers: [
                "Completely unsuccessful",
                "Fairly unsuccessful",
                "Somewhat unsuccessful",
                "I don’t know",
                "Somewhat successful",
                "Fairly successful",
                "Completely successful",
            ],
            type: "radio",
        },
        {
            questionNumber: "10a",
            question: "Did you find these lifestyle interventions to be…",
            answers: [
                "Very frustrating",
                "Fairly frustrating",
                "Somewhat frustrating",
                "I don’t know",
                "Somewhat enjoyable",
                "Fairly enjoyable",
                "Completely enjoyable",
            ],
            type: "radio",
        },
        {
            questionNumber: "10b",
            question: "Did you find these lifestyle interventions to be…",
            answers: [
                "Very easy",
                "Fairly easy",
                "Somewhat easy",
                "I don’t know",
                "Somewhat challenging",
                "Fairly challenging",
                "Completely challenging",
            ],

            type: "radio",
        },

        {
            questionNumber: "10c",
            question: "Did you find these lifestyle interventions to be…",
            answers: [
                "Completely discouraging",
                "Fairly discouraging",
                "Somewhat discouraging",
                "I don’t know",
                "Somewhat motivating",
                "Fairly motivating",
                "Completely motivating",
            ],
            type: "radio",
        },
        {
            questionNumber: "11",
            question: "Do you have anything to add?",
            answers: [],
            type: "textfield",
        },
    ],
};
export const generalizedAnxietyDisorder = {
    title: "Generalized Anxiety Disorder ",
    about: "",
    instructions: "Over the last 2 weeks, how often have you been bothered by the following problems?",
    questions: [
        {
            questionNumber: "1",
            question: "Feeling nervous, anxious or on edge",
            answers: ["Not at all", "Several days", "More than half the days", "Nearly every day"],
            type: "radio",
        },
        {
            questionNumber: "2",
            question: "Not being able to stop or control worrying",
            answers: ["Not at all", "Several days", "More than half the days", "Nearly every day"],
            type: "radio",
        },
        {
            questionNumber: "3",
            question: "Worrying too much about different things",
            answers: ["Not at all", "Several days", "More than half the days", "Nearly every day"],
            type: "radio",
        },
        {
            questionNumber: "4",
            question: "Trouble relaxing",
            answers: ["Not at all", "Several days", "More than half the days", "Nearly every day"],
            type: "radio",
        },
        {
            questionNumber: "5",
            question: "Being so restless that it is hard to sit still",
            answers: ["Not at all", "Several days", "More than half the days", "Nearly every day"],
            type: "radio",
        },
        {
            questionNumber: "6",
            question: "Becoming easily annoyed or irritable",
            answers: ["Not at all", "Several days", "More than half the days", "Nearly every day"],
            type: "radio",
        },
        {
            questionNumber: "7",
            question: "Feeling afraid as if something awful might happen",
            answers: ["Not at all", "Several days", "More than half the days", "Nearly every day"],
            type: "radio",
        },
        {
            questionNumber: "8",
            question:
                "If you checked off any problems, how difficult have these made it for you to do your work, take care of things at home, or get along with other people?",
            answers: ["Not difficult at all", "Somewhat difficult", "Very difficult", "Extremely difficult"],
            type: "radio",
        },
    ],
};
export const geriatricDepressionScale = {
    title: "Geriatric Depression Scale",
    about: "",
    instructions: "Please choose the best answer for how you felt over the past week.",
    questions: [
        {
            questionNumber: "1",
            question: "Are you basically satisfied with your life?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "2",
            question: "Have you dropped many of your activities and interests?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "3",
            question: "Do you feel that your life is empty?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "4",
            question: "Do you often get bored?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "5",
            question: "Are you hopeful about the future?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "6",
            question: "Are you bothered by thoughts you can't get out of your head?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "7",
            question: "Are you in good spirits most of the time?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "8",
            question: "Are you afraid that something bad is going to happen to you?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "9",
            question: "Do you feel happy most of the time?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "10",
            question: "Do you often feel helpless?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "11",
            question: "Do you often get restless?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "12",
            question: "Do you prefer to stay at home, rather than going out and doing new things?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "13",
            question: "Do you frequently worry about the future?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "14",
            question: "Do you feel you have more problems with memory than most?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "15",
            question: "Do you think it is wonderful to be alive now?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "16",
            question: "Do you often feel downhearted and blue?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "17",
            question: "Do you feel pretty worthless the way you are now?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "18",
            question: "Do you worry a lot about the past?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "19",
            question: "Do you find life exciting?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "20",
            question: "Is it hard for you to get started on new projects?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "21",
            question: "Do you feel full of energy?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "22",
            question: "Do you feel that your situation is hopeless?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "23",
            question: "Do you think that most people are better off than you are?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "24",
            question: "Do you frequently get upset over little things?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "25",
            question: "Do you frequently feel like crying?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "26",
            question: "Do you have trouble concentrating?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "27",
            question: "Do you enjoy getting up in the morning?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "28",
            question: "Do you prefer to avoid social gatherings?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "29",
            question: "Is it easy for you to make decisions?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "30",
            question: "Is your mind as clear as it used to be?",
            answers: ["Yes", "No"],
            type: "radio",
        },
    ],
};
export const revisedHearingHandicapInventory = {
    title: "Revised Hearing Handicap Inventory",
    about: "",
    instructions:
        "Please check YES, SOMETIMES, or NO to each of the following items. Do not skip a question if you avoid a situation because of a hearing problem. If you use a hearing aid, please answer the way you hear without the aid.",
    questions: [
        {
            questionNumber: "1",
            question: "Does a hearing problem cause you to feel embarrassed when you meet new people?",
            answers: ["Yes", "Sometimes", "No"],
            type: "radio",
        },
        {
            questionNumber: "2",
            question: "Does a hearing problem cause you to feel frustrated when talking to members of your family?",
            answers: ["Yes", "Sometimes", "No"],
            type: "radio",
        },
        {
            questionNumber: "3",
            question: "Do you have difficulty hearing when someone speaks in a whisper?",
            answers: ["Yes", "Sometimes", "No"],
            type: "radio",
        },
        {
            questionNumber: "4",
            question: "Do you feel handicapped by a hearing problem?",
            answers: ["Yes", "Sometimes", "No"],
            type: "radio",
        },
        {
            questionNumber: "5",
            question: "Does a hearing problem cause you difficulty when visiting friends, relatives, or neighbors?",
            answers: ["Yes", "Sometimes", "No"],
            type: "radio",
        },
        {
            questionNumber: "6",
            question: "Does a hearing problem cause you to attend religious services less often than you would like?",
            answers: ["Yes", "Sometimes", "No"],
            type: "radio",
        },
        {
            questionNumber: "7",
            question: "Does a hearing problem cause you to have arguments with family members?",
            answers: ["Yes", "Sometimes", "No"],
            type: "radio",
        },
        {
            questionNumber: "8",
            question: "Does a hearing problem cause you difficulty when listening to TV or radio?",
            answers: ["Yes", "Sometimes", "No"],
            type: "radio",
        },
        {
            questionNumber: "9",
            question: "Do you feel that any difficulty with your hearing limits or hampers your personal or social life?",
            answers: ["Yes", "Sometimes", "No"],
            type: "radio",
        },
        {
            questionNumber: "10",
            question: "Does a hearing problem cause you difficulty when in a restaurant with relatives or friends?",
            answers: ["Yes", "Sometimes", "No"],
            type: "radio",
        },
    ],
};
export const visionHearing = {
    title: "Vision and Hearing",
    about: "",
    instructions: "",
    questions: [
        {
            questionNumber: "1",
            question: "How would you characterize your eyesight (using glasses or corrective lens if you use them)?",
            answers: ["Excellent", "Very good", "Good", "Fair", "Poor or non-existent (non-existent = blind)"],
            type: "radio",
        },
        {
            questionNumber: "2",
            question:
                "Besides glasses or contact lenses, do you use any aids or specialized equipment for persons who are blind or visually impaired, for example, magnifiers or Braille reading materials?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "4",
        },
        {
            questionNumber: "3",
            question: "Which of the following aids or equipment do you use? (Please check all that apply)",
            answers: [
                "Magnifiers",
                "Braille reading materials",
                "Larger print reading materials",
                "Talking books",
                "Closed circuit devices (e.g., CCTVs)",
                "A computer with Braille, large print, or speech access",
                "A white cane",
                "A guide dog",
                "Another aid (please specify) ",
            ],
            type: "checkbox",
            skipTrigger: [
                "Magnifiers",
                "Braille reading materials",
                "Larger print reading materials",
                "Talking books",
                "Closed circuit devices (e.g., CCTVs)",
                "A computer with Braille, large print, or speech access",
                "A white cane",
                "A guide dog",
            ],
            nonSkipTrigger: "Another aid (please specify) ",
            skipTo: "4",
        },
        {
            questionNumber: "3a",
            question: "Please specify",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "4",
            question: "How would you characterize your hearing (with a hearing aid if you use one)?",
            answers: ["Excellent", "Very good", "Good", "Fair", "Poor"],
            type: "radio",
        },
        {
            questionNumber: "5",
            question:
                "Do you find it difficult to follow a conversation if there is background noise, such as TV, radio or children playing, even if using a hearing aid as usual?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "6",
            question:
                "Do you use any aids, specialized equipment, or services for persons who are deaf or hard of hearing, for example, a volume control telephone or TV decoder?",
            answers: ["Yes", "No"],
            type: "radio",
            endTrigger: "No",
        },
        {
            questionNumber: "7",
            question: "Which of the following aids or equipment do you use? (Please check all that apply)",
            answers: [
                "Hearing aid",
                "Closed caption TV or decoder",
                "Computer to communicate (e.g., email or chat services)",
                "Amplifiers (e.g., FM, acoustic, infrared)",
                "Volume control telephone",
                "Visual or vibrating alarm",
                "TTY or TTD",
                "Cochlear implant",
                "Message relay service",
                "Another aid (please specify)",
                "Other phone-related devices (e.g., flashers)",
            ],
            type: "checkbox",
            endTrigger: [
                "Hearing aid",
                "Closed caption TV or decoder",
                "Computer to communicate (e.g., email or chat services)",
                "Amplifiers (e.g., FM, acoustic, infrared)",
                "Volume control telephone",
                "Visual or vibrating alarm",
                "TTY or TTD",
                "Cochlear implant",
                "Message relay service",
                "Other phone-related devices (e.g., flashers)",
            ],
            nonSkipTrigger: "Another aid (please specify)",
        },
        {
            questionNumber: "7a",
            question: "Please specify",
            answers: [],
            type: "textfield",
        },
    ],
};
export const bhproQuestionnaires = {
    title: "BHPro Questionnaires",
    about: "",
    instructions: "",
    questions: [
        {
            questionNumber: "1a",
            question: "I read (e.g. newspapers, books, magazines)",
            answers: ["Less than once per week", "1-4 times per week", "More than 5 times per week (almost every day)"],
            type: "radio",
        },
        {
            questionNumber: "1b",
            question: "I play games (e.g. puzzle, crosswords, bridge, cards)",
            answers: ["Less than once per week", "1-4 times per week", "More than 5 times per week (almost every day)"],
            type: "radio",
        },
        {
            questionNumber: "1c",
            question: "I play music (e.g choir, instrument)",
            answers: ["Less than once per week", "1-4 times per week", "More than 5 times per week (almost every day)"],
            type: "radio",
        },
        {
            questionNumber: "1d",
            question: "I do crafts activities (e.g. ceramic, woodworking)",
            answers: ["Less than once per week", "1-4 times per week", "More than 5 times per week (almost every day)"],
            type: "radio",
        },
        {
            questionNumber: "1e",
            question: "I engage in group/social activities (e.g. book club, volunteering)",
            answers: ["Less than once per week", "1-4 times per week", "More than 5 times per week (almost every day)"],
            type: "radio",
        },
        {
            questionNumber: "1f",
            question: "I engage in computer activities (e.g. internet, web search, computer games)",
            answers: ["Less than once per week", "1-4 times per week", "More than 5 times per week (almost every day)"],
            type: "radio",
        },
        {
            questionNumber: "2a",
            question: "Do you have high blood pressure",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2b",
        },
        {
            questionNumber: "2a1",
            question: "Have you been prescribed a medication or diet to help lower your blood pressure?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2b",
        },
        {
            questionNumber: "2a2",
            question: "During the past 7 days, have you followed the dose or diet prescribed?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "2b",
            question: "Do you have high cholesterol?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2c",
        },
        {
            questionNumber: "2b1",
            question: "Have you been prescribed medication or diet to help lower your cholesterol?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2c",
        },
        {
            questionNumber: "2b2",
            question: "During the past 7 days, have you followed the dose or diet prescribed?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "2c",
            question: "Do you have diabetes?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2d",
        },
        {
            questionNumber: "2c1",
            question: "Have you been prescribed medication or diet to help to help control your diabetes?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2d",
        },
        {
            questionNumber: "2c2",
            question: "During the past 7 days, have you followed the dose or diet prescribed?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2d",
        },
        {
            questionNumber: "2d",
            question: "Do you smoke?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "3a",
        },
        {
            questionNumber: "2d1",
            question: "How many cigarettes, pipes, or cigars on average per week?",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "2d2",
            question: "During the past 7 days, have you taken steps to reduce the amount you smoke or to quit smoking?",
            answers: ["Yes", "No"],
            type: "radio",
        },
        {
            questionNumber: "3a",
            question: "In the last two weeks, how lonely did you feel?",
            answers: ["Never", "(1)", "(2)", "(3)", "(4)", "Every day"],
            type: "slider",
        },
        {
            questionNumber: "3b",
            question: "How often, in the last two weeks has anyone shown prejudice against you or treated you unfairly because of your age?",
            answers: ["Never", "(1)", "(2)", "(3)", "(4)", "Every day"],
            type: "slider",
        },
        {
            questionNumber: "3c",
            question:
                "Many people feel older or younger than they actually are. What age have you felt most of the time in the last two weeks? Please input a specific age below.",
            answers: ["Never", "(1)", "(2)", "(3)", "(4)", "Every day"],
            type: "textfield",
        },
        {
            questionNumber: "3d",
            question:
                "Please tell us how you feel about this statement over the last two weeks. No matter at what point in life, you can always influence your own aging.",
            answers: ["Absolutely agree", "(1)", "(2)", "(3)", "(4)", "Do not agree"],
            type: "slider",
        },
        {
            questionNumber: "3e",
            question: "Over the last two weeks, how much have you been bothered by feeling sad, down or uninterested in life?",
            answers: ["Not at all", "A little", "Moderately", "Severely"],
            type: "slider",
        },
        {
            questionNumber: "3f",
            question: "Over the last two weeks, how much have you been bothered by feeling anxious or nervous?",
            answers: ["Not at all", "A little", "Moderately", "Severely"],
            type: "slider",
        },
        {
            questionNumber: "3g",
            question: "Over the last two weeks, how much have you been bothered by feeling stressed?",
            answers: ["Not at all", "A little", "Moderately", "Severely"],
            type: "slider",
        },
        {
            questionNumber: "3h",
            question: "Over the last two weeks, how much have you been bothered by not having the social support you feel you need?",
            answers: ["Not at all", "A little", "Moderately", "Severely"],
            type: "slider",
        },
    ],
};
