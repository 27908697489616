import { PDFDocument, PDFPage, PageSizes, StandardFonts } from "pdf-lib";
import FileSaver from "file-saver";
import moment from "moment";

// Interfaces
import { Stat } from "../interfaces/Stat";
import { Participant } from "../interfaces/Participant";

// Data
import {
    geriatricDepressionScale,
    generalizedAnxietyDisorder,
    postCognitiveExpectancy,
    preCognitiveExpectancy,
    bhproQuestionnaires,
    revisedHearingHandicapInventory,
    visionHearing,
} from "../components/pages/portal/questionnaires/data/CognitiveData";
import { dietHistory, eatingPattern, mediterraneanDietAdherence } from "../components/pages/portal/questionnaires/data/DietData";
import {
    exerciseFallsCalendar,
    fallsAndBalance,
    fallsCalendar,
    lawtonBrodyIADL,
    womenHealth,
} from "../components/pages/portal/questionnaires/data/PhysicalExerciseActivityData";
import { insomniaSeverityIndex } from "../components/pages/portal/questionnaires/data/SleepData";
import { healthBehaviorsQuestionnaire, healthUtilityIndex, qualityOfLife } from "../components/pages/portal/questionnaires/data/VascularHealth";

// Firebase
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { genDoc } from "./firebase";
import { extractQuestionnaireName } from "./survey";

export const fetchQuestion = (questionnaireName: string, questionNumber: string) => {
    // Cognitive
    if (questionnaireName === "geriatricDepressionScale") {
        return geriatricDepressionScale.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "generalizedAnxietyDisorder") {
        return generalizedAnxietyDisorder.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "postCognitiveExpectancy") {
        return postCognitiveExpectancy.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "preCognitiveExpectancy") {
        return preCognitiveExpectancy.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "revisedHearingHandicapInventory") {
        return revisedHearingHandicapInventory.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "bhproQuestionnaires") {
        return bhproQuestionnaires.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "visionHearing") {
        return visionHearing.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    // Diet
    if (questionnaireName === "dietHistory") {
        return dietHistory.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "eatingPattern") {
        return eatingPattern.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "mediterraneanDietAdherence") {
        return mediterraneanDietAdherence.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    // Physical
    if (questionnaireName === "fallsAndBalance") {
        return fallsAndBalance.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "fallsCalendar") {
        return fallsCalendar.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "exerciseFallsCalendar") {
        return exerciseFallsCalendar.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "lawtonBrodyIADL") {
        return lawtonBrodyIADL.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "womenHealth") {
        return womenHealth.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    // Sleep
    if (questionnaireName === "insomniaSeverityIndex") {
        return insomniaSeverityIndex.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    // Vascular
    if (questionnaireName === "qualityOfLife") {
        return qualityOfLife.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "healthResourceUtilization") {
        switch (questionNumber) {
            case "1a1":
                return "1a1- Health Professional";
            case "1b1":
                return "1b1- Date of visit";
            case "1c1":
                return "1c1- Reason for visit";
            case "1d1":
                return "1d1- Fall related";
            case "1e1":
                return "1e1- Behavior/cognitive problems related";
            case "1f1":
                return "1f1- No therapy";
            case "1g1":
                return "1g1- No procedure";
            case "1h1":
                return "1h1- Type of hospital department";
            case "1i1":
                return "1i1- Most important tests/procedures performed";
            case "2a1":
                return "2a1- Type of hospital department";
            case "2b1":
                return "2b1- Date of visit";
            case "2c1":
                return "2c1- Reason for visit";
            case "2d1":
                return "2d1- Fall related";
            case "2e1":
                return "2e1- Behavior/cognitive problems related";
            case "2f1":
                return "2f1- No Tests/Procedures";
            case "2g1":
                return "2g1- Length of Stay";
            case "2h1":
                return "2h1- Most important tests/procedures performed";
            case "3a1":
                return "3a1- Type of test/investigation";
            case "3b1":
                return "3b1- Date of test/investigation";
            case "3c1":
                return "3c1- Site of test/investigation";
            case "3d1":
                return "3d1- Fall related";
            case "3e1":
                return "3e1- Behavior/cognitive problems related";
            case "3f1":
                return "3f1- # of times";
            case "3g1":
                return "3g1- Visit duration";
            case "1a2":
                return "1a2- Health Professional";
            case "1b2":
                return "1b2- Date of visit";
            case "1c2":
                return "1c2- Reason for visit";
            case "1d2":
                return "1d2- Fall related";
            case "1e2":
                return "1e2- Behavior/cognitive problems related";
            case "1f2":
                return "1f2- No therapy";
            case "1g2":
                return "1g2- No procedure";
            case "1h2":
                return "1h2- Type of hospital department";
            case "1i2":
                return "1i2- Most important tests/procedures performed";
            case "2a2":
                return "2a2- Type of hospital department";
            case "2b2":
                return "2b2- Date of visit";
            case "2c2":
                return "2c2- Reason for visit";
            case "2d2":
                return "2d2- Fall related";
            case "2e2":
                return "2e2- Behavior/cognitive problems related";
            case "2f2":
                return "2f2- No Tests/Procedures";
            case "2g2":
                return "2g2- Length of Stay";
            case "2h2":
                return "2h2- Most important tests/procedures performed";
            case "3a2":
                return "3a2- Type of test/investigation";
            case "3b2":
                return "3b2- Date of test/investigation";
            case "3c2":
                return "3c2- Site of test/investigation";
            case "3d2":
                return "3d2- Fall related";
            case "3e2":
                return "3e2- Behavior/cognitive problems related";
            case "3f2":
                return "3f2- # of times";
            case "3g2":
                return "3g2- Visit duration";
            case "1a3":
                return "1a3- Health Professional";
            case "1b3":
                return "1b3- Date of visit";
            case "1c3":
                return "1c3- Reason for visit";
            case "1d3":
                return "1d3- Fall related";
            case "1e3":
                return "1e3- Behavior/cognitive problems related";
            case "1f3":
                return "1f3- No therapy";
            case "1g3":
                return "1g3- No procedure";
            case "1h3":
                return "1h3- Type of hospital department";
            case "1i3":
                return "1i3- Most important tests/procedures performed";
            case "2a3":
                return "2a3- Type of hospital department";
            case "2b3":
                return "2b3- Date of visit";
            case "2c3":
                return "2c3- Reason for visit";
            case "2d3":
                return "2d3- Fall related";
            case "2e3":
                return "2e3- Behavior/cognitive problems related";
            case "2f3":
                return "2f3- No Tests/Procedures";
            case "2g3":
                return "2g3- Length of Stay";
            case "2h3":
                return "2h3- Most important tests/procedures performed";
            case "3a3":
                return "3a3- Type of test/investigation";
            case "3b3":
                return "3b3- Date of test/investigation";
            case "3c3":
                return "3c3- Site of test/investigation";
            case "3d3":
                return "3d3- Fall related";
            case "3e3":
                return "3e3- Behavior/cognitive problems related";
            case "3f3":
                return "3f3- # of times";
            case "3g3":
                return "3g3- Visit duration";
            case "1a4":
                return "1a4- Health Professional";
            case "1b4":
                return "1b4- Date of visit";
            case "1c4":
                return "1c4- Reason for visit";
            case "1d4":
                return "1d4- Fall related";
            case "1e4":
                return "1e4- Behavior/cognitive problems related";
            case "1f4":
                return "1f4- No therapy";
            case "1g4":
                return "1g4- No procedure";
            case "1h4":
                return "1h4- Type of hospital department";
            case "1i4":
                return "1i4- Most important tests/procedures performed";
            case "2a4":
                return "2a4- Type of hospital department";
            case "2b4":
                return "2b4- Date of visit";
            case "2c4":
                return "2c4- Reason for visit";
            case "2d4":
                return "2d4- Fall related";
            case "2e4":
                return "2e4- Behavior/cognitive problems related";
            case "2f4":
                return "2f4- No Tests/Procedures";
            case "2g4":
                return "2g4- Length of Stay";
            case "2h4":
                return "2h4- Most important tests/procedures performed";
            case "3a4":
                return "3a4- Type of test/investigation";
            case "3b4":
                return "3b4- Date of test/investigation";
            case "3c4":
                return "3c4- Site of test/investigation";
            case "3d4":
                return "Fall related";
            case "3e4":
                return "3e4- Behavior/cognitive problems related";
            case "3f4":
                return "3f4- # of times";
            case "3g4":
                return "3g4- Visit duration";
            case "1a5":
                return "1a5- Health Professional";
            case "1b5":
                return "1b5- Date of visit";
            case "1c5":
                return "1c5- Reason for visit";
            case "1d5":
                return "1d5- Fall related";
            case "1e5":
                return "1e5- Behavior/cognitive problems related";
            case "1f5":
                return "1f5- No therapy";
            case "1g5":
                return "1g5- No procedure";
            case "1h5":
                return "1h5- Type of hospital department";
            case "1i5":
                return "1i5- Most important tests/procedures performed";
            case "2a5":
                return "2a5- Type of hospital department";
            case "2b5":
                return "2b5- Date of visit";
            case "2c5":
                return "2c5- Reason for visit";
            case "2d5":
                return "2d5- Fall related";
            case "2e5":
                return "2e5- Behavior/cognitive problems related";
            case "2f5":
                return "2f5- No Tests/Procedures";
            case "2g5":
                return "2g5- Length of Stay";
            case "2h5":
                return "2h5- Most important tests/procedures performed";
            case "3a5":
                return "3a5- Type of test/investigation";
            case "3b5":
                return "3b5- Date of test/investigation";
            case "3c5":
                return "3c5- Site of test/investigation";
            case "3d5":
                return "3d5- Fall related";
            case "3e5":
                return "3e5- Behavior/cognitive problems related";
            case "3f5":
                return "3f5- # of times";
            case "3g5":
                return "3g5- Visit duration";
            case "1a6":
                return "1a6- Health Professional";
            case "1b6":
                return "1b6- Date of visit";
            case "1c6":
                return "1c6- Reason for visit";
            case "1d6":
                return "1d6- Fall related";
            case "1e6":
                return "1e6- Behavior/cognitive problems related";
            case "1f6":
                return "1f6- No therapy";
            case "1g6":
                return "1g6- No procedure";
            case "1h6":
                return "1h6- Type of hospital department";
            case "1i6":
                return "1i6- Most important tests/procedures performed";
            case "2a6":
                return "2a6- Type of hospital department";
            case "2b6":
                return "2b6- Date of visit";
            case "2c6":
                return "2c6- Reason for visit";
            case "2d6":
                return "2d6- Fall related";
            case "2e6":
                return "2e6- Behavior/cognitive problems related";
            case "2f6":
                return "2f6- No Tests/Procedures";
            case "2g6":
                return "2g6- Length of Stay";
            case "2h6":
                return "2h6- Most important tests/procedures performed";
            case "3a6":
                return "3a6- Type of test/investigation";
            case "3b6":
                return "3b6- Date of test/investigation";
            case "3c6":
                return "3c6- Site of test/investigation";
            case "3d6":
                return "3d6- Fall related";
            case "3e6":
                return "3e6- Behavior/cognitive problems related";
            case "3f6":
                return "3f6- # of times";
            case "3g6":
                return "3g6- Visit duration";
            case "1a7":
                return "1a7- Health Professional";
            case "1b7":
                return "1b7- Date of visit";
            case "1c7":
                return "1c7- Reason for visit";
            case "1d7":
                return "1d7- Fall related";
            case "1e7":
                return "1e7- Behavior/cognitive problems related";
            case "1f7":
                return "1f7- No therapy";
            case "1g7":
                return "1g7- No procedure";
            case "1h7":
                return "1h7- Type of hospital department";
            case "1i7":
                return "1i7- Most important tests/procedures performed";
            case "2a7":
                return "2a7- Type of hospital department";
            case "2b7":
                return "2b7- Date of visit";
            case "2c7":
                return "2c7- Reason for visit";
            case "2d7":
                return "2d7- Fall related";
            case "2e7":
                return "2e7- Behavior/cognitive problems related";
            case "2f7":
                return "2f7- No Tests/Procedures";
            case "2g7":
                return "2g7- Length of Stay";
            case "2h7":
                return "2h7- Most important tests/procedures performed";
            case "3a7":
                return "3a7- Type of test/investigation";
            case "3b7":
                return "3b7- Date of test/investigation";
            case "3c7":
                return "3c7- Site of test/investigation";
            case "3d7":
                return "3d7- Fall related";
            case "3e7":
                return "3e7- Behavior/cognitive problems related";
            case "3f7":
                return "3f7- # of times";
            case "3g7":
                return "3g7- Visit duration";
            case "4a":
                return "4a- How many hours a week did helper(s) spend with you (assisting with things such as getting the phone or the door, getting around, or fetching things)?";
            case "4b":
                return "4b- How many hours a week did helper(s) spend with you on basic tasks such as eating, dressing or personal care (bathing, using toilet, brushing hair, etc.)?";
            case "4c":
                return "4c- How many hours a week did helper(s) spend helping you with activities such as shopping, chores, personal business, transportation, or social activities?";
            case "5a1":
                return "5a1- NEEDED HELP";
            case "5a2":
                return "5a2- AVERAGE OF HOURS/WEEK";
            case "5a3":
                return "5a3- HELPER";
            case "5b1":
                return "5b1- NEEDED HELP";
            case "5b2":
                return "5b2- AVERAGE OF HOURS/WEEK";
            case "5b3":
                return "5b3- HELPER";
            case "5c1":
                return "5c1- NEEDED HELP";
            case "5c2":
                return "5c2- AVERAGE OF HOURS/WEEK";
            case "5c3":
                return "5c3- HELPER";
            case "5d1":
                return "5d1- NEEDED HELP";
            case "5d2":
                return "5d2- AVERAGE OF HOURS/WEEK";
            case "5d3":
                return "5d3- HELPER";
            case "5e1":
                return "5e1- NEEDED HELP";
            case "5e2":
                return "5e2- AVERAGE OF HOURS/WEEK";
            case "5e3":
                return "5e3- HELPER";
            case "5f1":
                return "5f1- NEEDED HELP";
            case "5f2":
                return "5f2- AVERAGE OF HOURS/WEEK";
            case "5f3":
                return "5f3- HELPER";
            case "5g1":
                return "5g1- NEEDED HELP";
            case "5g2":
                return "5g2- AVERAGE OF HOURS/WEEK";
            case "5g3":
                return "5g3- HELPER";
            case "5h1":
                return "5h1- NEEDED HELP";
            case "5h2":
                return "5h2- AVERAGE OF HOURS/WEEK";
            case "5h3":
                return "5h3- HELPER";
            default:
                return "";
        }
    }
    if (questionnaireName === "healthBehaviorsQuestionnaire") {
        return healthBehaviorsQuestionnaire.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "healthUtilityIndex") {
        return healthUtilityIndex.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
    if (questionnaireName === "qualityOfLife") {
        return qualityOfLife.questions.find(q => q.questionNumber === questionNumber)?.question;
    }
};

export const createPdf = async (s: Stat) => {
    const pdfDoc = await PDFDocument.create();

    const payload = await getDoc(doc(firestore, "Participants", s.participantId));
    const participant = genDoc<Participant>()(payload);

    pdfDoc.setTitle(`${extractQuestionnaireName(s.questionnaireName)} - ${participant.participantId}`);

    const DEFAULT_HEIGHT = 760;
    const MAX_CHAR_PER_LINE = 87;
    const TEXT_HEIGHT = 15;
    let currentHeight = DEFAULT_HEIGHT;
    let page: PDFPage | undefined = undefined;
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
    });

    Object.entries(s.results)
        .sort((a: any, b: any) => collator.compare(a, b))
        .map((e: any) => {
            if (currentHeight <= 60) currentHeight = DEFAULT_HEIGHT;

            if (currentHeight === DEFAULT_HEIGHT) {
                page = pdfDoc.addPage(PageSizes.Letter);

                page.drawText(`${extractQuestionnaireName(s.questionnaireName)}'s answers - ${participant.participantId}`, {
                    x: 10,
                    y: currentHeight,
                    size: 20,
                });

                currentHeight -= 20;

                page.drawText(`(${participant.id})`, {
                    x: 10,
                    y: currentHeight,
                    size: TEXT_HEIGHT,
                });

                currentHeight -= 20;

                page.drawText(`Completed on ${moment(s.createdAt).format("DD/MM/YYYY")}`, {
                    x: 10,
                    y: currentHeight,
                    size: TEXT_HEIGHT,
                });

                currentHeight -= 45;
            }

            if (page && currentHeight !== DEFAULT_HEIGHT && currentHeight > 0) {
                const questionText = `${e[0].replace("question", "").toUpperCase()}: ${fetchQuestion(
                    s.questionnaireName,
                    e[0].replace("question", "")
                )}`;

                // Question
                page.drawText(questionText, {
                    x: 10,
                    y: currentHeight,
                    size: TEXT_HEIGHT,
                    maxWidth: page.getWidth() - 10,
                });

                currentHeight -= 20;

                // Question padding
                if (questionText.length / MAX_CHAR_PER_LINE >= 1) {
                    currentHeight -= TEXT_HEIGHT * Math.ceil(questionText.length / MAX_CHAR_PER_LINE);
                }

                const formatAnswer = (rawAnswer: any) => {
                    if (Array.isArray(rawAnswer)) {
                        return rawAnswer.join(", ");
                    } else if (typeof rawAnswer === "number") {
                        if (rawAnswer === 0) {
                            return "No";
                        }
                        if (rawAnswer === 1) {
                            return "Yes";
                        }
                        return "";
                    } else if (typeof rawAnswer === "boolean") {
                        if (rawAnswer === false) {
                            return "No";
                        }
                        if (rawAnswer === true) {
                            return "Yes";
                        }
                        return "";
                    } else if (typeof rawAnswer === "string") {
                        if (rawAnswer === "false") {
                            return "No";
                        }
                        if (rawAnswer === "true") {
                            return "Yes";
                        }

                        rawAnswer = rawAnswer.replace("≥", ">=");

                        const fontCharSet = boldFont.getCharacterSet();

                        let cleanAnswer = "";

                        // Special character removal
                        for (let i = 0; i < rawAnswer.length; i++) {
                            if (typeof rawAnswer[i] === "string") {
                                const charCode = rawAnswer.charCodeAt(i);
                                if (!fontCharSet.includes(charCode)) {
                                    cleanAnswer += "?";
                                } else {
                                    cleanAnswer += rawAnswer[i];
                                }
                            }
                        }

                        return cleanAnswer;
                    } else {
                        if (rawAnswer.seconds) {
                            return moment(new Date(rawAnswer.seconds * 1000)).format("DD/MM/YY hh:mm:ss");
                        }
                        return "";
                    }
                };

                const answerText = formatAnswer(e[1]);

                // Answer
                page.drawText(answerText, {
                    x: 20,
                    y: currentHeight,
                    size: TEXT_HEIGHT,
                    maxWidth: page.getWidth() - 20,
                    font: boldFont,
                });

                // Answer padding
                if (answerText.length / MAX_CHAR_PER_LINE >= 1) {
                    console.log("answer padding", TEXT_HEIGHT * Math.ceil(answerText.length / MAX_CHAR_PER_LINE));
                    currentHeight -= TEXT_HEIGHT * Math.ceil(answerText.length / MAX_CHAR_PER_LINE);
                }

                currentHeight -= 45;
            }
        });

    // Save file
    const data = new Blob([await pdfDoc.save()], { type: "application/pdf;charset=utf-8" });
    FileSaver.saveAs(data, `${s.questionnaireName}_${moment(s.createdAt).format("DD/MM/YYYY")}.pdf`);
};
