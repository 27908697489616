import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, ListItem, ListItemIcon, ListItemText } from "@mui/material";

// Pages
import Bio from "./tabs/Bio";
import Sleep from "./tabs/Sleep";
import Diet from "./tabs/Diet";
import CognitiveTraining from "./tabs/CognitiveTraining";
import Health from "./tabs/Health";
import Logbook from "./tabs/Logbook";
import Forms from "./tabs/Questionnaires";
import PhysicalExercise from "./tabs/PhysicalExercise";
import Stats from "./tabs/stats/Stats";
import Logs from "./tabs/Logs";

// Icons
import {
    MdFitnessCenter,
    MdMenuBook,
    MdOutlineBedtime,
    MdLightbulbOutline,
    MdRestaurant,
    MdOutlineLocalHospital,
    MdViewList,
    MdOutlineDescription,
    MdAutoGraph,
    MdQueryStats,
} from "react-icons/md";

// Firebase
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../../../firebase/firebase";
import { genDoc } from "../../../../utils/firebase";

// Interfaces
import { Participant as ParticipantInterface } from "../../../../interfaces/Participant";

interface Props {
    name: string;
    Icon: React.FC;
    primaryText: string;
}

const Participant: React.FC = () => {
    const hist = useHistory();
    const { id, index, tabName } = useParams<any>();

    // States
    const [selectedPage, setSelectedPage] = useState(tabName);
    const [participant, setParticipant] = useState<ParticipantInterface | undefined>(undefined);

    const NavItem: React.FC<Props> = ({ name, Icon, primaryText }) => {
        return (
            <div
                className={`participant__nav ${selectedPage === name && "active"}`}
                onClick={() => {
                    setSelectedPage(name);
                    hist.push(`/dashboard/participant/${id}/${name}/${index}`);
                }}
            >
                <ListItem button>
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                    <ListItemText primary={primaryText} color="primary" />
                </ListItem>
            </div>
        );
    };

    const fetchParticipant = async () => {
        try {
            if (id) {
                onSnapshot(doc(firestore, "Participants", id), data => {
                    setParticipant(genDoc<ParticipantInterface>()(data));
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const generateContent = (pageName: string) => {
        if (participant && index) {
            switch (pageName) {
                default:
                case "bio":
                    return <Bio participant={participant} index={parseInt(index)} tabName={tabName} />;
                case "sleep":
                    return <Sleep participant={participant} index={parseInt(index)} tabName={tabName} />;
                case "diet":
                    return <Diet participant={participant} index={parseInt(index)} tabName={tabName} />;
                case "cognitive":
                    return <CognitiveTraining />;
                case "health":
                    return <Health />;
                case "logbook":
                    return <Logbook participant={participant} />;
                case "forms":
                    return <Forms participant={participant} />;
                case "physical":
                    return <PhysicalExercise participant={participant} index={parseInt(index)} tabName={tabName} />;
                case "stats":
                    return <Stats participant={participant} />;
                case "logs":
                    return <Logs participant={participant} />;
            }
        }
    };

    useEffect(() => {
        if (id) {
            fetchParticipant();
        }
    }, [id]);

    if (participant && index)
        return (
            <div className="participant__container">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3} lg={2.5} xl={2}>
                        <div>
                            <NavItem name="bio" Icon={MdMenuBook} primaryText="Bio" />
                            <NavItem name="physical" Icon={MdFitnessCenter} primaryText="Physical" />
                            <NavItem name="cognitive" Icon={MdLightbulbOutline} primaryText="Cognitive" />
                            <NavItem name="diet" Icon={MdRestaurant} primaryText="Diet" />
                            <NavItem name="health" Icon={MdOutlineLocalHospital} primaryText="Cardiometabolic " />
                            <NavItem name="sleep" Icon={MdOutlineBedtime} primaryText="Sleep" />
                            <NavItem name="logbook" Icon={MdViewList} primaryText="Logbook" />
                            <NavItem name="forms" Icon={MdOutlineDescription} primaryText="Forms" />
                            <NavItem name="stats" Icon={MdAutoGraph} primaryText="Stats" />
                            <NavItem name="logs" Icon={MdQueryStats} primaryText="Logs" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9.5} xl={10}>
                        <div style={{ minHeight: "100vh" }}>{generateContent(selectedPage)}</div>
                    </Grid>
                </Grid>
            </div>
        );
    else return <></>;
};

export default Participant;
