import React, { useEffect, useState } from "react";

// Mui
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Firebase
import { doc, getDoc } from "@firebase/firestore";
import { firestore } from "../../../../firebase/firebase";
import { genDoc } from "../../../../utils/firebase";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";

interface Props {
    participantId: string;
    // eslint-disable-next-line
    onComplete: (data: any) => void;
}

const NeuropsychologicalTestScoresTable: React.FC<Props> = ({ participantId, onComplete }) => {
    // States
    const [participant, setParticipant] = useState<Participant | undefined>(undefined);

    const getDefaultValues = () => {
        const defaultValues: any = {};

        defaultValues.mocaTotalT0 = "";
        defaultValues.mocaTotalT6 = "";
        defaultValues.mocaTotalT12 = "";
        defaultValues.mocaMisT0 = "";
        defaultValues.mocaMisT6 = "";
        defaultValues.mocaMisT12 = "";
        defaultValues.mocaEisT0 = "";
        defaultValues.mocaEisT6 = "";
        defaultValues.mocaEisT12 = "";
        defaultValues.mocaVisT0 = "";
        defaultValues.mocaVisT6 = "";
        defaultValues.mocaVisT12 = "";
        defaultValues.mocaLisT0 = "";
        defaultValues.mocaLisT6 = "";
        defaultValues.mocaLisT12 = "";
        defaultValues.mocaAisT0 = "";
        defaultValues.mocaAisT6 = "";
        defaultValues.mocaAisT12 = "";
        defaultValues.mocaOisT0 = "";
        defaultValues.mocaOisT6 = "";
        defaultValues.mocaOisT12 = "";

        defaultValues.ravltImmediateT0 = "";
        defaultValues.ravltImmediateT6 = "";
        defaultValues.ravltImmediateT12 = "";
        defaultValues.ravltShortT0 = "";
        defaultValues.ravltShortT6 = "";
        defaultValues.ravltShortT12 = "";
        defaultValues.ravltLongRecallT0 = "";
        defaultValues.ravltLongRecallT6 = "";
        defaultValues.ravltLongRecallT12 = "";
        defaultValues.ravltRecognitionT0 = "";
        defaultValues.ravltRecognitionT6 = "";
        defaultValues.ravltRecognitionT12 = "";

        defaultValues.adas1T0 = "";
        defaultValues.adas1T6 = "";
        defaultValues.adas1T12 = "";

        defaultValues.adas2T0 = "";
        defaultValues.adas2T6 = "";
        defaultValues.adas2T12 = "";

        defaultValues.adas3T0 = "";
        defaultValues.adas3T6 = "";
        defaultValues.adas3T12 = "";

        defaultValues.adas4T0 = "";
        defaultValues.adas4T6 = "";
        defaultValues.adas4T12 = "";

        defaultValues.adas5T0 = "";
        defaultValues.adas5T6 = "";
        defaultValues.adas5T12 = "";

        defaultValues.adas6T0 = "";
        defaultValues.adas6T6 = "";
        defaultValues.adas6T12 = "";

        defaultValues.adas7T0 = "";
        defaultValues.adas7T6 = "";
        defaultValues.adas7T12 = "";

        defaultValues.adas8T0 = "";
        defaultValues.adas8T6 = "";
        defaultValues.adas8T12 = "";

        defaultValues.adas9T0 = "";
        defaultValues.adas9T6 = "";
        defaultValues.adas9T12 = "";

        defaultValues.adas10T0 = "";
        defaultValues.adas10T6 = "";
        defaultValues.adas10T12 = "";

        defaultValues.adas11T0 = "";
        defaultValues.adas11T6 = "";
        defaultValues.adas11T12 = "";

        defaultValues.adas12T0 = "";
        defaultValues.adas12T6 = "";
        defaultValues.adas12T12 = "";

        defaultValues.adas13T0 = "";
        defaultValues.adas13T6 = "";
        defaultValues.adas13T12 = "";

        return defaultValues;
    };

    const getSchema = () => {
        const schema: any = {};

        schema.mocaTotalT0 = yup.string();
        schema.mocaTotalT6 = yup.string();
        schema.mocaTotalT12 = yup.string();
        schema.mocaMisT0 = yup.string();
        schema.mocaMisT6 = yup.string();
        schema.mocaMisT12 = yup.string();
        schema.mocaEisT0 = yup.string();
        schema.mocaEisT6 = yup.string();
        schema.mocaEisT12 = yup.string();
        schema.mocaVisT0 = yup.string();
        schema.mocaVisT6 = yup.string();
        schema.mocaVisT12 = yup.string();
        schema.mocaLisT0 = yup.string();
        schema.mocaLisT6 = yup.string();
        schema.mocaLisT12 = yup.string();
        schema.mocaAisT0 = yup.string();
        schema.mocaAisT6 = yup.string();
        schema.mocaAisT12 = yup.string();
        schema.mocaOisT0 = yup.string();
        schema.mocaOisT6 = yup.string();
        schema.mocaOisT12 = yup.string();

        schema.ravltImmediateT0 = yup.string();
        schema.ravltImmediateT6 = yup.string();
        schema.ravltImmediateT12 = yup.string();
        schema.ravltShortT0 = yup.string();
        schema.ravltShortT6 = yup.string();
        schema.ravltShortT12 = yup.string();
        schema.ravltLongRecallT0 = yup.string();
        schema.ravltLongRecallT6 = yup.string();
        schema.ravltLongRecallT12 = yup.string();
        schema.ravltRecognitionT0 = yup.string();
        schema.ravltRecognitionT6 = yup.string();
        schema.ravltRecognitionT12 = yup.string();

        schema.adas1T0 = yup.string();
        schema.adas1T6 = yup.string();
        schema.adas1T12 = yup.string();

        schema.adas2T0 = yup.string();
        schema.adas2T6 = yup.string();
        schema.adas2T12 = yup.string();

        schema.adas3T0 = yup.string();
        schema.adas3T6 = yup.string();
        schema.adas3T12 = yup.string();

        schema.adas4T0 = yup.string();
        schema.adas4T6 = yup.string();
        schema.adas4T12 = yup.string();

        schema.adas5T0 = yup.string();
        schema.adas5T6 = yup.string();
        schema.adas5T12 = yup.string();

        schema.adas6T0 = yup.string();
        schema.adas6T6 = yup.string();
        schema.adas6T12 = yup.string();

        schema.adas7T0 = yup.string();
        schema.adas7T6 = yup.string();
        schema.adas7T12 = yup.string();

        schema.adas8T0 = yup.string();
        schema.adas8T6 = yup.string();
        schema.adas8T12 = yup.string();

        schema.adas9T0 = yup.string();
        schema.adas9T6 = yup.string();
        schema.adas9T12 = yup.string();

        schema.adas10T0 = yup.string();
        schema.adas10T6 = yup.string();
        schema.adas10T12 = yup.string();

        schema.adas11T0 = yup.string();
        schema.adas11T6 = yup.string();
        schema.adas11T12 = yup.string();

        schema.adas12T0 = yup.string();
        schema.adas12T6 = yup.string();
        schema.adas12T12 = yup.string();

        schema.adas13T0 = yup.string();
        schema.adas13T6 = yup.string();
        schema.adas13T12 = yup.string();

        return yup.object(schema);
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({ resolver: yupResolver(getSchema()), defaultValues: getDefaultValues() });

    const onSubmit = async (data: any) => {
        onComplete(data);
    };

    const RenderTextField: React.FC<{ name: string }> = ({ name }) => {
        return (
            <>
                <Controller
                    render={({ field }) => (
                        <TextField variant="standard" type="text" helperText={errors[name]?.message} error={!!errors[name]?.message} {...field} />
                    )}
                    name={name}
                    control={control}
                />
            </>
        );
    };

    const fetchParticipant = async () => {
        const payload = await getDoc(doc(firestore, "Participants", participantId));
        setParticipant(genDoc<Participant>()(payload));
    };

    useEffect(() => {
        if (participant && participant.forms && participant.forms.neuropsychologicalTestScoresTable) {
            setValue("mocaTotalT0", participant.forms.neuropsychologicalTestScoresTable.mocaTotalT0);
            setValue("mocaTotalT6", participant.forms.neuropsychologicalTestScoresTable.mocaTotalT6);
            setValue("mocaTotalT12", participant.forms.neuropsychologicalTestScoresTable.mocaTotalT12);
            setValue("mocaMisT0", participant.forms.neuropsychologicalTestScoresTable.mocaMisT0);
            setValue("mocaMisT6", participant.forms.neuropsychologicalTestScoresTable.mocaMisT6);
            setValue("mocaMisT12", participant.forms.neuropsychologicalTestScoresTable.mocaMisT12);
            setValue("mocaEisT0", participant.forms.neuropsychologicalTestScoresTable.mocaEisT0);
            setValue("mocaEisT6", participant.forms.neuropsychologicalTestScoresTable.mocaEisT6);
            setValue("mocaEisT12", participant.forms.neuropsychologicalTestScoresTable.mocaEisT12);
            setValue("mocaVisT0", participant.forms.neuropsychologicalTestScoresTable.mocaVisT0);
            setValue("mocaVisT6", participant.forms.neuropsychologicalTestScoresTable.mocaVisT6);
            setValue("mocaVisT12", participant.forms.neuropsychologicalTestScoresTable.mocaVisT12);
            setValue("mocaLisT0", participant.forms.neuropsychologicalTestScoresTable.mocaLisT0);
            setValue("mocaLisT6", participant.forms.neuropsychologicalTestScoresTable.mocaLisT6);
            setValue("mocaLisT12", participant.forms.neuropsychologicalTestScoresTable.mocaLisT12);
            setValue("mocaAisT0", participant.forms.neuropsychologicalTestScoresTable.mocaAisT0);
            setValue("mocaAisT6", participant.forms.neuropsychologicalTestScoresTable.mocaAisT6);
            setValue("mocaAisT12", participant.forms.neuropsychologicalTestScoresTable.mocaAisT12);
            setValue("mocaOisT0", participant.forms.neuropsychologicalTestScoresTable.mocaOisT0);
            setValue("mocaOisT6", participant.forms.neuropsychologicalTestScoresTable.mocaOisT6);
            setValue("mocaOisT12", participant.forms.neuropsychologicalTestScoresTable.mocaOisT12);

            setValue("ravltImmediateT0", participant.forms.neuropsychologicalTestScoresTable.ravltImmediateT0);
            setValue("ravltImmediateT6", participant.forms.neuropsychologicalTestScoresTable.ravltImmediateT6);
            setValue("ravltImmediateT12", participant.forms.neuropsychologicalTestScoresTable.ravltImmediateT12);
            setValue("ravltShortT0", participant.forms.neuropsychologicalTestScoresTable.ravltShortT0);
            setValue("ravltShortT6", participant.forms.neuropsychologicalTestScoresTable.ravltShortT6);
            setValue("ravltShortT12", participant.forms.neuropsychologicalTestScoresTable.ravltShortT12);
            setValue("ravltLongRecallT0", participant.forms.neuropsychologicalTestScoresTable.ravltLongRecallT0);
            setValue("ravltLongRecallT6", participant.forms.neuropsychologicalTestScoresTable.ravltLongRecallT6);
            setValue("ravltLongRecallT12", participant.forms.neuropsychologicalTestScoresTable.ravltLongRecallT12);
            setValue("ravltRecognitionT0", participant.forms.neuropsychologicalTestScoresTable.ravltRecognitionT0);
            setValue("ravltRecognitionT6", participant.forms.neuropsychologicalTestScoresTable.ravltRecognitionT6);
            setValue("ravltRecognitionT12", participant.forms.neuropsychologicalTestScoresTable.ravltRecognitionT12);

            setValue("adas1T0", participant.forms.neuropsychologicalTestScoresTable.adas1T0);
            setValue("adas1T6", participant.forms.neuropsychologicalTestScoresTable.adas1T6);
            setValue("adas1T12", participant.forms.neuropsychologicalTestScoresTable.adas1T12);

            setValue("adas2T0", participant.forms.neuropsychologicalTestScoresTable.adas2T0);
            setValue("adas2T6", participant.forms.neuropsychologicalTestScoresTable.adas2T6);
            setValue("adas2T12", participant.forms.neuropsychologicalTestScoresTable.adas2T12);

            setValue("adas3T0", participant.forms.neuropsychologicalTestScoresTable.adas3T0);
            setValue("adas3T6", participant.forms.neuropsychologicalTestScoresTable.adas3T6);
            setValue("adas3T12", participant.forms.neuropsychologicalTestScoresTable.adas3T12);

            setValue("adas4T0", participant.forms.neuropsychologicalTestScoresTable.adas4T0);
            setValue("adas4T6", participant.forms.neuropsychologicalTestScoresTable.adas4T6);
            setValue("adas4T12", participant.forms.neuropsychologicalTestScoresTable.adas4T12);

            setValue("adas5T0", participant.forms.neuropsychologicalTestScoresTable.adas5T0);
            setValue("adas5T6", participant.forms.neuropsychologicalTestScoresTable.adas5T6);
            setValue("adas5T12", participant.forms.neuropsychologicalTestScoresTable.adas5T12);

            setValue("adas6T0", participant.forms.neuropsychologicalTestScoresTable.adas6T0);
            setValue("adas6T6", participant.forms.neuropsychologicalTestScoresTable.adas6T6);
            setValue("adas6T12", participant.forms.neuropsychologicalTestScoresTable.adas6T12);

            setValue("adas7T0", participant.forms.neuropsychologicalTestScoresTable.adas7T0);
            setValue("adas7T6", participant.forms.neuropsychologicalTestScoresTable.adas7T6);
            setValue("adas7T12", participant.forms.neuropsychologicalTestScoresTable.adas7T12);

            setValue("adas8T0", participant.forms.neuropsychologicalTestScoresTable.adas8T0);
            setValue("adas8T6", participant.forms.neuropsychologicalTestScoresTable.adas8T6);
            setValue("adas8T12", participant.forms.neuropsychologicalTestScoresTable.adas8T12);

            setValue("adas9T0", participant.forms.neuropsychologicalTestScoresTable.adas9T0);
            setValue("adas9T6", participant.forms.neuropsychologicalTestScoresTable.adas9T6);
            setValue("adas9T12", participant.forms.neuropsychologicalTestScoresTable.adas9T12);

            setValue("adas10T0", participant.forms.neuropsychologicalTestScoresTable.adas10T0);
            setValue("adas10T6", participant.forms.neuropsychologicalTestScoresTable.adas10T6);
            setValue("adas10T12", participant.forms.neuropsychologicalTestScoresTable.adas10T12);

            setValue("adas11T0", participant.forms.neuropsychologicalTestScoresTable.adas11T0);
            setValue("adas11T6", participant.forms.neuropsychologicalTestScoresTable.adas11T6);
            setValue("adas11T12", participant.forms.neuropsychologicalTestScoresTable.adas11T12);

            setValue("adas12T0", participant.forms.neuropsychologicalTestScoresTable.adas12T0);
            setValue("adas12T6", participant.forms.neuropsychologicalTestScoresTable.adas12T6);
            setValue("adas12T12", participant.forms.neuropsychologicalTestScoresTable.adas12T12);

            setValue("adas13T0", participant.forms.neuropsychologicalTestScoresTable.adas13T0);
            setValue("adas13T6", participant.forms.neuropsychologicalTestScoresTable.adas13T6);
            setValue("adas13T12", participant.forms.neuropsychologicalTestScoresTable.adas13T12);
        }
    }, [participant]);

    useEffect(() => {
        fetchParticipant();
    }, [participantId]);

    return (
        <div style={{ margin: 20 }} className="admin__participant__tables">
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div style={{ paddingTop: 20, marginBottom: 20 }} className="centered__parent">
                    <h1>Neuropsychological Test Scores Table</h1>
                </div>

                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">TEST</TableCell>
                                <TableCell align="center">Screening / T0</TableCell>
                                <TableCell align="center">T6</TableCell>
                                <TableCell align="center">T12</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">MoCA TOTAL</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaTotalT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaTotalT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaTotalT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">MoCA-MIS (Memory Index Score)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaMisT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaMisT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaMisT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">MoCA-EIS (Executive Index Score)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaEisT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaEisT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaEisT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">MoCA-VIS (Visuospatial Index Score)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaVisT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaVisT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaVisT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">MoCA-LIS (Language Index Score)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaLisT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaLisT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaLisT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">MoCA-AIS (Attention Index Score)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaAisT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaAisT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaAisT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">MoCA-OIS (Orientation Index Score)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaOisT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaOisT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaOisT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">RAVLT Immediate (Series)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltImmediateT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltImmediateT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltImmediateT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">RAVLT short</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltShortT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltShortT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltShortT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">RAVLT long recall</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltLongRecallT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltLongRecallT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltLongRecallT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">RAVLT recognition</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltRecognitionT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltRecognitionT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltRecognitionT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS I. Word recall</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas1T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas1T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas1T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS II. Following commands</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas2T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas2T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas2T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS III. Constructional praxis</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas3T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas3T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas3T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS IV. Delayed recall</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas4T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas4T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas4T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS V. Naming objects & fingers</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas5T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas5T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas5T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS VI. Ideational praxis</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas6T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas6T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas6T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS VII. Orientation</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas7T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas7T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas7T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS VIII. Word recognition</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas8T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas8T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas8T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS IX. Remembering intructions</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas9T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas9T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas9T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS X. Comprehension</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas10T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas10T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas10T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS XI. Word finding</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas11T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas11T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas11T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS XII. Spoken language</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas12T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas12T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas12T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS XIII. Number cancellation</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas13T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas13T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adas13T12" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ display: "flex" }}>
                    <Button variant="contained" type="submit" color="primary" className="cta__submit" style={{ marginLeft: "auto", marginTop: 20 }}>
                        Save Neuropsychological Test Scores Table
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default NeuropsychologicalTestScoresTable;
