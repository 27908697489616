import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

// Mui core
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import { emailRegex } from "../../../../utils/regex";
import settings from "../../../../settings.json";

// Db
import { auth, functions } from "../../../../firebase/firebase";

// Firebase
import { sendPasswordResetEmail } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { firestore } from "../../../../firebase/firebase";
import { collection, addDoc, updateDoc, doc, getDocs, where, query } from "firebase/firestore";
import { genDoc } from "../../../../utils/firebase";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";
import { Autocomplete, Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { AdminUser } from "../../../../interfaces/AdminUser";

// yup validation
const requiredMessage = "Required field";

const userSchema = yup.object({
    firstName: yup.string().required(requiredMessage),
    participantId: yup.string().required(requiredMessage),
    email: yup.string().matches(emailRegex, "Invalid email").required(requiredMessage),
    sex: yup.string().oneOf(settings.app.sexes).required(requiredMessage),
    age: yup.number().required(requiredMessage),
    coordinatorId: yup.string().required(requiredMessage),
    sendPasswordEmail: yup.boolean().required(),
});

interface Props {
    open: boolean;
    setOpen: any;
}

const ParticipantDialog: React.FC<Props> = ({ open, setOpen }) => {
    const { currentAdmin, loading, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [inputValue, setInputValue] = useState("");
    const [coordinators, setCoordinators] = useState<AdminUser[]>([]);
    const [sendPasswordReset, setSendPasswordReset] = useState(true);
    const [participants, setParticipants] = useState<Participant[]>([]);

    const defaultValues = {
        firstName: "",
        participantId: "",
        email: "",
        sex: "",
        age: "",
        coordinatorId: "",
        sendPasswordEmail: true,
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch,
    } = useForm({ resolver: yupResolver(userSchema), defaultValues });

    const age = watch("age");

    const fetchCoordinators = async () => {
        const payload = await getDocs(query(collection(firestore, "Users"), where("roles", "array-contains", "coordinator")));
        setCoordinators(payload.docs.map(genDoc<AdminUser>()));
    };

    const fetchParticipants = async () => {
        const payload = await getDocs(collection(firestore, "Participants"));
        setParticipants(payload.docs.map(genDoc<Participant>()));
    };

    const onSubmit = async (data: any) => {
        try {
            if (participants.some(x => x.email === data.email)) throw new Error("Email already in use");

            const participant: Participant = { ...data };
            setLoading(true);
            const createParticipant = httpsCallable(functions, "createParticipant");

            const coordinator = coordinators.find(x => x.email === data.coordinatorId);

            const payload = await createParticipant({ ...participant, coordinatorId: coordinator?.id });

            // Check if cloud function succeeded
            if (payload.data) {
                if (sendPasswordReset) await sendPasswordResetEmail(auth, participant.email);

                const logPayload = await addDoc(collection(firestore, "Logs"), {
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    isDeleted: false,
                    adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
                    reason: `Created participant ${data && (data as any).email ? (data as any).email : (data as any).id}`,
                });

                await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });

                enqueueSnackbar("Created participant", { variant: "success" });
                setOpen(false);
            } else throw new Error("Something went wrong");
        } catch (e: any) {
            enqueueSnackbar(e.message, { variant: "error" });
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCoordinators();
        fetchParticipants();
    }, []);

    return (
        <Dialog
            open={open}
            onClose={() => !loading && setOpen(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className="participant__dialog"
        >
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="scroll-dialog-title">Participant creation</DialogTitle>
                <DialogContent>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.firstName?.message}
                                error={!!errors.firstName?.message}
                                label="Firstname"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="firstName"
                        control={control}
                    />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.participantId?.message}
                                error={!!errors.participantId?.message}
                                label="Participant id"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="participantId"
                        control={control}
                    />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.email?.message}
                                error={!!errors.email?.message}
                                label="Email"
                                fullWidth
                                required
                                {...field}
                            />
                        )}
                        name="email"
                        control={control}
                    />
                    <Controller
                        name="sex"
                        control={control}
                        render={({ field }) => (
                            <FormControl required fullWidth variant="standard">
                                <InputLabel id="roles-id">Sex</InputLabel>
                                <Select labelId="roles-id" error={!!errors.sex?.message} {...field}>
                                    {settings.app.sexes.map((s, i) => (
                                        <MenuItem key={i} value={s}>
                                            {s}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!!errors.sex?.message && <FormHelperText>{errors.sex?.message}</FormHelperText>}
                            </FormControl>
                        )}
                    />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.age?.message}
                                error={!!errors.age?.message}
                                label="Age"
                                type="number"
                                fullWidth
                                required
                                {...field}
                                value={age}
                            />
                        )}
                        name="age"
                        control={control}
                    />

                    <Controller
                        name="coordinatorId"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                options={coordinators.map(c => c.email)}
                                getOptionLabel={option => {
                                    const coordinator = coordinators.find(x => x.id === option);
                                    if (coordinator) return coordinator.email;
                                    else return option;
                                }}
                                renderInput={params => {
                                    params.fullWidth = true;
                                    return (
                                        <TextField
                                            error={errors && errors.coordinatorId && !!errors.coordinatorId?.message}
                                            helperText={errors && errors.coordinatorId && errors.coordinatorId?.message}
                                            {...params}
                                            variant="standard"
                                            label="Coordinator"
                                        />
                                    );
                                }}
                                {...field}
                                onChange={(event: any, newValue: string | null) => {
                                    if (newValue) {
                                        setValue("coordinatorId", newValue);
                                    }
                                }}
                            />
                        )}
                    />

                    {/* <FormControlLabel
                        style={{ marginLeft: 0 }}
                        control={<Checkbox checked={sendPasswordEmail} />}
                        label="Send password reset email"
                        labelPlacement="start"
                        {...field}
                    /> */}

                    <FormControlLabel
                        style={{ marginLeft: 0 }}
                        label="Send password reset email"
                        labelPlacement="start"
                        control={<Checkbox checked={sendPasswordReset} onChange={e => setSendPasswordReset(e.target.checked)} />}
                    />

                    <DialogActions>
                        <Button variant="contained" onClick={() => setOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit" color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default ParticipantDialog;
