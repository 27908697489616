import { postCognitiveExpectancy, preCognitiveExpectancy } from "../components/pages/portal/questionnaires/data/CognitiveData";
import { lawtonBrodyIADL } from "../components/pages/portal/questionnaires/data/PhysicalExerciseActivityData";
import { insomniaSeverityIndex } from "../components/pages/portal/questionnaires/data/SleepData";
import { qualityOfLife } from "../components/pages/portal/questionnaires/data/VascularHealth";

export const formatData = (data: any) => {
    const formattedData: any = {};

    data.forEach((d: any) => {
        Object.entries(d).map(e => {
            formattedData[e[0]] = e[1];
        });
    });

    return formattedData;
};

export const getScore = (data: any, answers: number[], questionnaireName: string) => {
    let score: any = 0;

    // Physical
    if (questionnaireName === "lawtonBrodyIADL") {
        Object.entries(formatData(data)).map(e => {
            const questionLength = lawtonBrodyIADL.questions[parseInt((e[0] as string).replace("question", "")) - 1].answers.length;
            const answerIndex = answers[parseInt((e[0] as string).replace("question", "")) - 1];

            score += questionLength - answerIndex;
        });
        return score;
    }
    if (questionnaireName === "fallsCalendar") {
        Object.entries(formatData(data)).map(e => {
            const answerIndex = answers[parseInt((e[0] as string).replace("question", "")) + 1];

            if (e[0] === "question6") score += answerIndex - 1;
            return score;
        });
    }
    if (questionnaireName === "fallsAndBalance") {
        score = {};
        Object.entries(formatData(data)).map(e => {
            const answerIndex = answers[parseInt((e[0] as string).replace("question", "")) + 1];

            let question1Score = 0;
            let question2Score = 0;
            let question3Score = 0;
            let question5Score = 0;

            if (e[0] === "question1a") if (answerIndex === 1) question1Score += 1;
            if (e[0] === "question1b") if (parseInt(e[1] as any)) question1Score += parseInt(e[1] as any);
            if (e[0] === "question1c") if (answerIndex === 1) question1Score += 1;
            score.question1 = question1Score;

            if (e[0] === "question2") question2Score += answerIndex - 1;
            score.question2 = question2Score;

            if (e[0] === "question3") question3Score += answerIndex - 1;
            score.question3 = question3Score;

            // Question4 no score

            if (e[0].includes("question5")) question5Score += parseInt(e[1] as any);

            score.question5 = question5Score / 16;
        });
        return score;
    }
    if (questionnaireName === "exerciseFallsCalendar") {
        // No score
        return 0;
    }

    // Health
    if (questionnaireName === "healthResourceUtilization") {
        // No score
        return 0;
    }
    if (questionnaireName === "healthBehaviorsQuestionnaire") {
        // No score
        return 0;
    }
    if (questionnaireName === "qualityOfLife") {
        let physicalFunctioning = 0;
        let roleLimitationsDueToPhysicalHealth = 0;
        let roleLimitationsDueToEmotionalProblems = 0;
        let vitalityEnergyAndFatigue = 0;
        let generalMentalHealth = 0;
        let socialFunctioning = 0;
        let bodilyPain = 0;
        let generalHealthPerceptions = 0;
        let healthChange = 0;
        let total = 0;

        let question1Score = 0;
        let question2Score = 0;
        let question3Score = 0;
        let question4Score = 0;
        let question5Score = 0;
        let question6Score = 0;
        let question7Score = 0;
        let question8Score = 0;
        let question9Score = 0;
        let question10Score = 0;
        let question11Score = 0;
        let question12Score = 0;
        let question13Score = 0;
        let question14Score = 0;
        let question15Score = 0;
        let question16Score = 0;
        let question17Score = 0;
        let question18Score = 0;
        let question19Score = 0;
        let question20Score = 0;
        let question21Score = 0;
        let question22Score = 0;
        let question23Score = 0;
        let question24Score = 0;
        let question25Score = 0;
        let question26Score = 0;
        let question27Score = 0;
        let question28Score = 0;
        let question29Score = 0;
        let question30Score = 0;
        let question31Score = 0;
        let question32Score = 0;
        let question33Score = 0;
        let question34Score = 0;
        let question35Score = 0;
        let question36Score = 0;

        Object.entries(formatData(data)).map((e: any) => {
            let answerIndex = 0;
            const question = qualityOfLife.questions.find(x => x.questionNumber === (e[0] as string).replace("question", ""));

            if (question && answers?.length) {
                answerIndex = question?.answers?.findIndex(x => x === e[1]) + 1;
            }

            if (e[0] === "question1") {
                if (answerIndex === 1) question1Score = 100;
                if (answerIndex === 2) question1Score = 75;
                if (answerIndex === 3) question1Score = 50;
                if (answerIndex === 4) question1Score = 25;
                if (answerIndex === 5) question1Score = 0;
            }

            if (e[0] === "question2") {
                if (answerIndex === 1) question2Score = 100;
                if (answerIndex === 2) question2Score = 75;
                if (answerIndex === 3) question2Score = 50;
                if (answerIndex === 4) question2Score = 25;
                if (answerIndex === 5) question2Score = 0;
            }
            if (e[0] === "question20") {
                if (answerIndex === 1) question20Score = 100;
                if (answerIndex === 2) question20Score = 75;
                if (answerIndex === 3) question20Score = 50;
                if (answerIndex === 4) question20Score = 25;
                if (answerIndex === 5) question20Score = 0;
            }
            if (e[0] === "question22") {
                if (answerIndex === 1) question22Score = 100;
                if (answerIndex === 2) question22Score = 75;
                if (answerIndex === 3) question22Score = 50;
                if (answerIndex === 4) question22Score = 25;
                if (answerIndex === 5) question22Score = 0;
            }
            if (e[0] === "question34") {
                if (answerIndex === 1) question34Score = 100;
                if (answerIndex === 2) question34Score = 75;
                if (answerIndex === 3) question34Score = 50;
                if (answerIndex === 4) question34Score = 25;
                if (answerIndex === 5) question34Score = 0;
            }
            if (e[0] === "question36") {
                if (answerIndex === 1) question36Score = 100;
                if (answerIndex === 2) question36Score = 75;
                if (answerIndex === 3) question36Score = 50;
                if (answerIndex === 4) question36Score = 25;
                if (answerIndex === 5) question36Score = 0;
            }

            // --------------------------------------

            if (e[0] === "question3") {
                if (answerIndex === 1) question3Score = 0;
                if (answerIndex === 2) question3Score = 50;
                if (answerIndex === 3) question3Score = 100;
            }

            if (e[0] === "question4") {
                if (answerIndex === 1) question4Score = 0;
                if (answerIndex === 2) question4Score = 50;
                if (answerIndex === 3) question4Score = 100;
            }

            if (e[0] === "question5") {
                if (answerIndex === 1) question5Score = 0;
                if (answerIndex === 2) question5Score = 50;
                if (answerIndex === 3) question5Score = 100;
            }
            if (e[0] === "question6") {
                if (answerIndex === 1) question6Score = 0;
                if (answerIndex === 2) question6Score = 50;
                if (answerIndex === 3) question6Score = 100;
            }
            if (e[0] === "question7") {
                if (answerIndex === 1) question7Score = 0;
                if (answerIndex === 2) question7Score = 50;
                if (answerIndex === 3) question7Score = 100;
            }
            if (e[0] === "question8") {
                if (answerIndex === 1) question8Score = 0;
                if (answerIndex === 2) question8Score = 50;
                if (answerIndex === 3) question8Score = 100;
            }
            if (e[0] === "question9") {
                if (answerIndex === 1) question9Score = 0;
                if (answerIndex === 2) question9Score = 50;
                if (answerIndex === 3) question9Score = 100;
            }
            if (e[0] === "question10") {
                if (answerIndex === 1) question10Score = 0;
                if (answerIndex === 2) question10Score = 50;
                if (answerIndex === 3) question10Score = 100;
            }
            if (e[0] === "question11") {
                if (answerIndex === 1) question11Score = 0;
                if (answerIndex === 2) question11Score = 50;
                if (answerIndex === 3) question11Score = 100;
            }
            if (e[0] === "question12") {
                if (answerIndex === 1) question12Score = 0;
                if (answerIndex === 2) question12Score = 50;
                if (answerIndex === 3) question12Score = 100;
            }

            // ----------------------------------------

            if (e[0] === "question13") {
                if (answerIndex === 1) question13Score = 0;
                if (answerIndex === 2) question13Score = 100;
            }
            if (e[0] === "question14") {
                if (answerIndex === 1) question14Score = 0;
                if (answerIndex === 2) question14Score = 100;
            }
            if (e[0] === "question15") {
                if (answerIndex === 1) question15Score = 0;
                if (answerIndex === 2) question15Score = 100;
            }
            if (e[0] === "question16") {
                if (answerIndex === 1) question16Score = 0;
                if (answerIndex === 2) question16Score = 100;
            }
            if (e[0] === "question17") {
                if (answerIndex === 1) question17Score = 0;
                if (answerIndex === 2) question17Score = 100;
            }
            if (e[0] === "question18") {
                if (answerIndex === 1) question18Score = 0;
                if (answerIndex === 2) question18Score = 100;
            }
            if (e[0] === "question19") {
                if (answerIndex === 1) question19Score = 0;
                if (answerIndex === 2) question19Score = 100;
            }

            // -------------------------------

            if (e[0] === "question21") {
                if (answerIndex === 1) question21Score = 100;
                if (answerIndex === 2) question21Score = 80;
                if (answerIndex === 3) question21Score = 60;
                if (answerIndex === 4) question21Score = 40;
                if (answerIndex === 5) question21Score = 20;
                if (answerIndex === 6) question21Score = 0;
            }
            if (e[0] === "question23") {
                if (answerIndex === 1) question23Score = 100;
                if (answerIndex === 2) question23Score = 80;
                if (answerIndex === 3) question23Score = 60;
                if (answerIndex === 4) question23Score = 40;
                if (answerIndex === 5) question23Score = 20;
                if (answerIndex === 6) question23Score = 0;
            }
            if (e[0] === "question26") {
                if (answerIndex === 1) question26Score = 100;
                if (answerIndex === 2) question26Score = 80;
                if (answerIndex === 3) question26Score = 60;
                if (answerIndex === 4) question26Score = 40;
                if (answerIndex === 5) question26Score = 20;
                if (answerIndex === 6) question26Score = 0;
            }
            if (e[0] === "question27") {
                if (answerIndex === 1) question27Score = 100;
                if (answerIndex === 2) question27Score = 80;
                if (answerIndex === 3) question27Score = 60;
                if (answerIndex === 4) question27Score = 40;
                if (answerIndex === 5) question27Score = 20;
                if (answerIndex === 6) question27Score = 0;
            }
            if (e[0] === "question30") {
                if (answerIndex === 1) question30Score = 100;
                if (answerIndex === 2) question30Score = 80;
                if (answerIndex === 3) question30Score = 60;
                if (answerIndex === 4) question30Score = 40;
                if (answerIndex === 5) question30Score = 20;
                if (answerIndex === 6) question30Score = 0;
            }

            // -------------------------------

            if (e[0] === "question24") {
                if (answerIndex === 1) question24Score = 0;
                if (answerIndex === 2) question24Score = 20;
                if (answerIndex === 3) question24Score = 40;
                if (answerIndex === 4) question24Score = 60;
                if (answerIndex === 5) question24Score = 80;
                if (answerIndex === 6) question24Score = 100;
            }
            if (e[0] === "question25") {
                if (answerIndex === 1) question25Score = 0;
                if (answerIndex === 2) question25Score = 20;
                if (answerIndex === 3) question25Score = 40;
                if (answerIndex === 4) question25Score = 60;
                if (answerIndex === 5) question25Score = 80;
                if (answerIndex === 6) question25Score = 100;
            }
            if (e[0] === "question28") {
                if (answerIndex === 1) question28Score = 0;
                if (answerIndex === 2) question28Score = 20;
                if (answerIndex === 3) question28Score = 40;
                if (answerIndex === 4) question28Score = 60;
                if (answerIndex === 5) question28Score = 80;
                if (answerIndex === 6) question28Score = 100;
            }
            if (e[0] === "question29") {
                if (answerIndex === 1) question29Score = 0;
                if (answerIndex === 2) question29Score = 20;
                if (answerIndex === 3) question29Score = 40;
                if (answerIndex === 4) question29Score = 60;
                if (answerIndex === 5) question29Score = 80;
                if (answerIndex === 6) question29Score = 100;
            }
            if (e[0] === "question31") {
                if (answerIndex === 1) question31Score = 0;
                if (answerIndex === 2) question31Score = 20;
                if (answerIndex === 3) question31Score = 40;
                if (answerIndex === 4) question31Score = 60;
                if (answerIndex === 5) question31Score = 80;
                if (answerIndex === 6) question31Score = 100;
            }

            // -------------------------------

            if (e[0] === "question32") {
                if (answerIndex === 1) question32Score = 0;
                if (answerIndex === 2) question32Score = 25;
                if (answerIndex === 3) question32Score = 50;
                if (answerIndex === 4) question32Score = 75;
                if (answerIndex === 5) question32Score = 100;
            }
            if (e[0] === "question33") {
                if (answerIndex === 1) question33Score = 0;
                if (answerIndex === 2) question33Score = 25;
                if (answerIndex === 3) question33Score = 50;
                if (answerIndex === 4) question33Score = 75;
                if (answerIndex === 5) question33Score = 100;
            }
            if (e[0] === "question35") {
                if (answerIndex === 1) question35Score = 0;
                if (answerIndex === 2) question35Score = 25;
                if (answerIndex === 3) question35Score = 50;
                if (answerIndex === 4) question35Score = 75;
                if (answerIndex === 5) question35Score = 100;
            }
        });

        healthChange = question2Score;

        physicalFunctioning =
            (question3Score +
                question4Score +
                question5Score +
                question6Score +
                question7Score +
                question8Score +
                question9Score +
                question10Score +
                question11Score +
                question12Score) /
            10;

        roleLimitationsDueToPhysicalHealth = (question13Score + question14Score + question15Score + question16Score) / 4;
        roleLimitationsDueToEmotionalProblems = (question17Score + question18Score + question19Score) / 3;
        vitalityEnergyAndFatigue = (question23Score + question27Score + question29Score + question31Score) / 4;
        generalMentalHealth = (question24Score + question25Score + question26Score + question28Score + question30Score) / 5;
        socialFunctioning = (question20Score + question32Score) / 2;
        bodilyPain = (question21Score + question22Score) / 2;
        generalHealthPerceptions = (question1Score + question33Score + question34Score + question35Score + question36Score) / 5;

        total =
            (question1Score +
                question2Score +
                question3Score +
                question4Score +
                question5Score +
                question6Score +
                question7Score +
                question8Score +
                question9Score +
                question10Score +
                question11Score +
                question12Score +
                question13Score +
                question14Score +
                question15Score +
                question16Score +
                question17Score +
                question18Score +
                question19Score +
                question20Score +
                question21Score +
                question22Score +
                question23Score +
                question24Score +
                question25Score +
                question26Score +
                question27Score +
                question28Score +
                question29Score +
                question30Score +
                question31Score +
                question32Score +
                question33Score +
                question34Score +
                question35Score +
                question36Score) /
            36;

        return {
            physicalFunctioning: physicalFunctioning,
            roleLimitationsDueToPhysicalHealth: roleLimitationsDueToPhysicalHealth,
            roleLimitationsDueToEmotionalProblems: roleLimitationsDueToEmotionalProblems,
            vitalityEnergyAndFatigue: vitalityEnergyAndFatigue,
            generalMentalHealth: generalMentalHealth,
            socialFunctioning: socialFunctioning,
            bodilyPain: bodilyPain,
            generalHealthPerceptions: generalHealthPerceptions,
            healthChange: healthChange,
            total: total,
        };
    }
    if(questionnaireName === "healthStatus"){
        // No score
        return 0
    }
    // Diet
    if (questionnaireName === "dietHistory") {
        // No score
        return 0;
    }
    if (questionnaireName === "eatingPattern") {
        let brainHealthyEatingIndexScore = 0;
        let rawScore = 0;

        Object.entries(formatData(data)).map((e, i) => {
            if (e[0] === "question1") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 7) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
            }

            if (e[0] === "question1a") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
            }

            if (e[0] === "question1b") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
            }
            if (e[0] === "question2") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 7) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
            }
            if (e[0] === "question2a") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
            }
            if (e[0] === "question3") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
            }
            if (e[0] === "question3a") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
            }
            if (e[0] === "question4") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
            }
            if (e[0] === "question4a") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 7) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
            }
            if (e[0] === "question5") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += answers[i] - 1;
                }
            }
            if (e[0] === "question6") {
                if (answers[i] === 1) {
                    rawScore += 2;
                }
                if (answers[i] === 2) {
                    rawScore += 0;
                }
                if (answers[i] === 3) {
                    rawScore += 1;
                }
            }
            if (e[0] === "question7") {
                if (answers[i] === 1) {
                    rawScore += 2;
                }
                if (answers[i] === 2) {
                    rawScore += 0;
                }
                if (answers[i] === 3) {
                    rawScore += 1;
                }
            }
            if (e[0] === "question8") {
                if (answers[i] === 1) {
                    rawScore += 1;
                }
                if (answers[i] === 2) {
                    rawScore += 0;
                }
            }
            if (e[0] === "question8a") {
                if (answers[i] === 1) {
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 2) {
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 3) {
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 4) {
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 5) {
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 6) {
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 7) {
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 8) {
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 9) {
                    rawScore += answers[i] - 1;
                }
                if (answers[i] === 10) {
                    rawScore += answers[i] - 1;
                }
            }
            if (e[0] === "question9") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 5;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 4;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 3;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 2;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 0;
                }
            }
            if (e[0] === "question9a") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 5;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += 4;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 3;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 2;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 0;
                }
            }
            if (e[0] === "question10") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 5;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += 4;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 3;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 2;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 0;
                }
            }
            if (e[0] === "question11") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 5;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 4;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 3;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 2;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 0;
                }
            }
            if (e[0] === "question12") {
                if ((e[1] as string[]).includes("None of the above")) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 5;
                }
            }
            if (e[0] === "question12a") {
                if (answers[i] === 1) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 5;
                }
                if (answers[i] === 2) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 4;
                }
                if (answers[i] === 3) {
                    brainHealthyEatingIndexScore += 1;
                    rawScore += 3;
                }
                if (answers[i] === 4) {
                    brainHealthyEatingIndexScore += 0.5;
                    rawScore += 2;
                }
                if (answers[i] === 5) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 1;
                }
                if (answers[i] === 6) {
                    brainHealthyEatingIndexScore += 0;
                    rawScore += 0;
                }
            }
        });
        return { brainHealthyEatingIndexScore, rawScore };
    }
    if (questionnaireName === "mediterraneanDietAdherence") {
        Object.entries(formatData(data)).map(e => {
            const answerIndex = answers[parseInt((e[0] as string).replace("question", "")) - 1];

            if (e[0] === "question1") if (answerIndex === 1) score += 1;
            if (e[0] === "question2") if (answerIndex === 5) score += 1;
            if (e[0] === "question3") if (answerIndex === 3) score += 1;
            if (e[0] === "question4") if (answerIndex === 4) score += 1;
            if (e[0] === "question5") if (answerIndex === 1) score += 1;
            if (e[0] === "question6") if (answerIndex === 0 || answerIndex === 1) score += 1;
            if (e[0] === "question7") if (answerIndex === 1 || answerIndex === 2) score += 1;
            if (e[0] === "question8") if (answerIndex === 8) score += 1;
            if (e[0] === "question9") if (answerIndex === 4) score += 1;
            if (e[0] === "question10") if (answerIndex === 4) score += 1;
            if (e[0] === "question11") if (answerIndex === 1 || answerIndex === 2 || answerIndex === 3) score += 1;
            if (e[0] === "question12") if (answerIndex === 4) score += 1;
            if (e[0] === "question13") if (answerIndex === 1) score += 1;
            if (e[0] === "question14") if (answerIndex === 3) score += 1;
        });
        return score;
    }
    // Sleep
    if (questionnaireName === "insomniaSeverityIndex") {
        Object.entries(formatData(data)).map(e => {
            const answerIndex = insomniaSeverityIndex.questions[parseInt((e[0] as string).replace("question", "")) - 1].answers.findIndex(
                x => x === e[1]
            );

            if (e[0] === "question1") {
                score += answerIndex;
            }
            if (e[0] === "question2") {
                score += answerIndex;
            }
            if (e[0] === "question3") {
                score += answerIndex;
            }
            if (e[0] === "question4") {
                score += answerIndex;
            }
            if (e[0] === "question5") {
                score += answerIndex;
            }
            if (e[0] === "question6") {
                score += answerIndex;
            }
            if (e[0] === "question7") {
                score += answerIndex;
            }
        });
        return score;
    }
    // Cognitive
    if (questionnaireName === "preCognitiveExpectancy") {
        Object.entries(formatData(data)).map(e => {
            const question = preCognitiveExpectancy.questions.find(x => x.questionNumber === (e[0] as string).replace("question", ""));
            const answerIndex = question?.answers.findIndex(x => x === e[1]) ? question?.answers.findIndex(x => x === e[1]) + 1 : 0;

            if (e[0] === "question1") {
                score += answerIndex;
            }
            if (e[0] === "question2") {
                score += answerIndex;
            }
            if (e[0] === "question3") {
                score += answerIndex;
            }
            if (e[0] === "question4") {
                score += answerIndex;
            }
            if (e[0] === "question5") {
                score += answerIndex;
            }
            if (e[0] === "question6") {
                score += answerIndex;
            }
            if (e[0] === "question7") {
                score += answerIndex;
            }
            if (e[0] === "question8") {
                score += answerIndex;
            }
            if (e[0] === "question9") {
                score += answerIndex;
            }
            if (e[0] === "question10") {
                score += answerIndex;
            }
            if (e[0] === "question11") {
                score += 0;
            }
            if (e[0] === "question12a") {
                score += answerIndex;
            }
            if (e[0] === "question12b") {
                score += answerIndex;
            }
            if (e[0] === "question12c") {
                score += answerIndex;
            }
        });

        return score;
    }
    if (questionnaireName === "postCognitiveExpectancy") {
        Object.entries(formatData(data)).map(e => {
            const question = postCognitiveExpectancy.questions.find(x => x.questionNumber === (e[0] as string).replace("question", ""));
            const answerIndex = question?.answers.findIndex(x => x === e[1]) ? question?.answers.findIndex(x => x === e[1]) + 1 : 0;

            if (e[0] === "question1") score += answerIndex;
            if (e[0] === "question2") score += answerIndex;
            if (e[0] === "question3") score += answerIndex;
            if (e[0] === "question4") score += answerIndex;
            if (e[0] === "question5") score += answerIndex;
            if (e[0] === "question6") score += answerIndex;
            if (e[0] === "question7") score += answerIndex;
            if (e[0] === "question8") score += answerIndex;
            if (e[0] === "question9") score += answerIndex;
            if (e[0] === "question10a") score += answerIndex;
            if (e[0] === "question10b") score += answerIndex;
            if (e[0] === "question10c") score += answerIndex;
        });
        return score;
    }
    if (questionnaireName === "generalizedAnxietyDisorder") {
        Object.entries(formatData(data)).map(e => {
            const answerIndex = answers[parseInt((e[0] as string).replace("question", "")) - 1];

            if (e[0] === "question1") score += answerIndex - 1;
            if (e[0] === "question2") score += answerIndex - 1;
            if (e[0] === "question3") score += answerIndex - 1;
            if (e[0] === "question4") score += answerIndex - 1;
            if (e[0] === "question5") score += answerIndex - 1;
            if (e[0] === "question6") score += answerIndex - 1;
            if (e[0] === "question7") score += answerIndex - 1;
            // if (e[0] === "question8") score += 0;
        });
        return score;
    }
    if (questionnaireName === "geriatricDepressionScale") {
        Object.entries(formatData(data)).map(e => {
            const answerIndex = answers[parseInt((e[0] as string).replace("question", "")) - 1];

            if (e[0] === "question1") if (answerIndex === 2) score += 1;
            if (e[0] === "question2") if (answerIndex === 1) score += 1;
            if (e[0] === "question3") if (answerIndex === 1) score += 1;
            if (e[0] === "question4") if (answerIndex === 1) score += 1;
            if (e[0] === "question5") if (answerIndex === 2) score += 1;
            if (e[0] === "question6") if (answerIndex === 1) score += 1;
            if (e[0] === "question7") if (answerIndex === 2) score += 1;
            if (e[0] === "question8") if (answerIndex === 1) score += 1;
            if (e[0] === "question9") if (answerIndex === 2) score += 1;
            if (e[0] === "question10") if (answerIndex === 1) score += 1;
            if (e[0] === "question11") if (answerIndex === 1) score += 1;
            if (e[0] === "question12") if (answerIndex === 1) score += 1;
            if (e[0] === "question13") if (answerIndex === 1) score += 1;
            if (e[0] === "question14") if (answerIndex === 1) score += 1;
            if (e[0] === "question15") if (answerIndex === 2) score += 1;
            if (e[0] === "question16") if (answerIndex === 1) score += 1;
            if (e[0] === "question17") if (answerIndex === 1) score += 1;
            if (e[0] === "question18") if (answerIndex === 1) score += 1;
            if (e[0] === "question19") if (answerIndex === 2) score += 1;
            if (e[0] === "question20") if (answerIndex === 1) score += 1;
            if (e[0] === "question21") if (answerIndex === 2) score += 1;
            if (e[0] === "question22") if (answerIndex === 1) score += 1;
            if (e[0] === "question23") if (answerIndex === 1) score += 1;
            if (e[0] === "question24") if (answerIndex === 1) score += 1;
            if (e[0] === "question25") if (answerIndex === 1) score += 1;
            if (e[0] === "question26") if (answerIndex === 1) score += 1;
            if (e[0] === "question27") if (answerIndex === 2) score += 1;
            if (e[0] === "question28") if (answerIndex === 1) score += 1;
            if (e[0] === "question29") if (answerIndex === 2) score += 1;
            if (e[0] === "question30") if (answerIndex === 2) score += 1;
        });
        return score;
    }
    if (questionnaireName === "revisedHearingHandicapInventory") {
        Object.entries(formatData(data)).map(e => {
            const answerIndex = answers[parseInt((e[0] as string).replace("question", "")) - 1];

            if (e[0] === "question1") {
                if (answerIndex === 1) score += 4;
                if (answerIndex === 2) score += 2;
                if (answerIndex === 3) score += 0;
            }
            if (e[0] === "question2") {
                if (answerIndex === 1) score += 4;
                if (answerIndex === 2) score += 2;
                if (answerIndex === 3) score += 0;
            }
            if (e[0] === "question3") {
                if (answerIndex === 1) score += 4;
                if (answerIndex === 2) score += 2;
                if (answerIndex === 3) score += 0;
            }
            if (e[0] === "question4") {
                if (answerIndex === 1) score += 4;
                if (answerIndex === 2) score += 2;
                if (answerIndex === 3) score += 0;
            }
            if (e[0] === "question5") {
                if (answerIndex === 1) score += 4;
                if (answerIndex === 2) score += 2;
                if (answerIndex === 3) score += 0;
            }
            if (e[0] === "question6") {
                if (answerIndex === 1) score += 4;
                if (answerIndex === 2) score += 2;
                if (answerIndex === 3) score += 0;
            }
            if (e[0] === "question7") {
                if (answerIndex === 1) score += 4;
                if (answerIndex === 2) score += 2;
                if (answerIndex === 3) score += 0;
            }
            if (e[0] === "question8") {
                if (answerIndex === 1) score += 4;
                if (answerIndex === 2) score += 2;
                if (answerIndex === 3) score += 0;
            }
            if (e[0] === "question9") {
                if (answerIndex === 1) score += 4;
                if (answerIndex === 2) score += 2;
                if (answerIndex === 3) score += 0;
            }
            if (e[0] === "question10") {
                if (answerIndex === 1) score += 4;
                if (answerIndex === 2) score += 2;
                if (answerIndex === 3) score += 0;
            }
        });
        return score;
    }
    if (questionnaireName === "visionHearing") {
        // No scoring
        return score;
    }
    if (questionnaireName === "healthUtilityIndex") {
        // No scoring
        return score;
    }
    if (questionnaireName === "bhproQuestionnaires") {
        // No scoring
        return score;
    }
    if (questionnaireName === "comorbidityIssuesAndCompaints") {
        // No scoring
        return score;
    }
    if (questionnaireName === "womenHealth") {
        // No scoring
        return score;
    }

    return 0;
};

export const extractQuestionnaireName = (questionnaireName: string) => {
    let questionName = "";
    switch (questionnaireName) {
        default:
        case "fallsCalendar":
            questionName = "Falls Calendar";
            break;
        case "lawtonBrodyIADL":
            questionName = "Lawton Brody IADL";
            break;
        case "fallsAndBalance":
            questionName = "Falls and Balance";
            break;
        case "insomniaSeverityIndex":
            questionName = "Insomnia Severity Index";
            break;
        case "mediterraneanDietAdherence":
            questionName = "Mediterranean Diet Adherence";
            break;
        case "dietHistory":
            questionName = "Diet History";
            break;
        case "eatingPattern":
            questionName = "Eating Pattern";
            break;
        case "healthResourceUtilization":
            questionName = "Health Resource Utilization";
            break;
        case "qualityOfLife":
            questionName = "Quality of Life";
            break;
        case "preCognitiveExpectancy":
            questionName = "Pre-Cognitive Expectancy";
            break;
        case "postCognitiveExpectancy":
            questionName = "Post-Cognitive Expectancy";
            break;
        case "generalizedAnxietyDisorder":
            questionName = "Generalized Anxiety Disorder";
            break;
        case "geriatricDepressionScale":
            questionName = "Geriatric Depression Scale";
            break;
        case "womenHealth":
            questionName = "Women's Health";
            break;
        case "healthBehaviorsQuestionnaire":
            questionName = "Health Behaviors Questionnaire";
            break;
        case "revisedHearingHandicapInventory":
            questionName = "Revised Hearing Handicap Inventory";
            break;
        case "bhproQuestionnaires":
            questionName = "BHP Pro Questionnaires";
            break;
        case "visionHearing":
            questionName = "Vision and Hearing";
            break;
        case "healthUtilityIndex":
            questionName = "Health Utility Index";
    }

    return questionName;
};
