import { useState } from "react";
import { useSnackbar } from "notistack";

// Mui
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

// Icons
import { MdMail } from "react-icons/md";

// Firebase
import { auth } from "../../firebase/firebase";
import { sendPasswordResetEmail } from "firebase/auth";

interface Props {
    open: boolean;
    onClose: any;
}

export const ForgotPassword = (props: Props) => {
    const [email, setEmail] = useState<string>("");
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (e: any) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                props.onClose();
                enqueueSnackbar("Email sent", { variant: "success" });
            })
            .catch(err => {
                console.error(err);
                if (err.code === "auth/invalid-email") enqueueSnackbar("Email doesn't exist", { variant: "error" });
                else if (err.code === "auth/user-not-found") enqueueSnackbar("No user with this email", { variant: "error" });
            });
    };

    return (
        <Dialog className="forgotPassword" open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="dialog-title">Forgot Password?</DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <DialogContentText className="forgotPassword__contextText">
                        If you have forgotten your password, please enter your email address. If this email is valid, you will receive a message with
                        the steps to follow in order to reset your password.
                    </DialogContentText>
                    <TextField fullWidth variant="outlined" label="Email" value={email} onChange={e => setEmail(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button className="cta__submit" color="primary" variant="contained" type="submit">
                        <MdMail className="addIcon" />
                        Send
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
