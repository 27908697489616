import React from "react";

// Material UI Core
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";

// Interfaces
import { Participant } from "../../../../../interfaces/Participant";

// Firebase
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../../firebase/firebase";

// Utils
import { camelcaseToWords } from "../../../../../utils/general";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

interface TopicTitleProps {
    topic: string;
}

const TopicTitle: React.FC<TopicTitleProps> = ({ topic }) => {
    return <h4 style={{ color: "black", textDecoration: "underline", paddingBottom: 20, paddingTop: 20 }}>{topic}</h4>;
};

interface QuestionnaireSwitchProps {
    questionnaire: string;
    participant: Participant;
    hasSwitch?: boolean;
    redirects?: boolean;
}

const QuestionnaireSwitch: React.FC<QuestionnaireSwitchProps> = ({ questionnaire, participant, hasSwitch = true, redirects = false }) => {
    const hist = useHistory();

    const handleChange = async (questionnaire: string) => {
        if (participant && participant.id) {
            if (!participant.questionnaires) {
                await updateDoc(doc(firestore, "Participants", participant.id), {
                    questionnaires: [{ name: questionnaire, date: new Date() }],
                });
            } else {
                if (participant.questionnaires.some(q => q.name === questionnaire)) {
                    await updateDoc(doc(firestore, "Participants", participant.id), {
                        questionnaires: participant.questionnaires.filter(q => q.name !== questionnaire),
                    });
                } else {
                    await updateDoc(doc(firestore, "Participants", participant.id), {
                        questionnaires: [...participant.questionnaires, { name: questionnaire, date: new Date() }],
                    });
                }
            }
        }
    };

    return (
        <div style={{ display: "flex" }}>
            <Grid container item style={{ padding: 2 }}>
                <Grid item xs={4}>
                    <div style={{ color: "black", fontSize: 15, paddingTop: 7 }} className="whereami">
                        {camelcaseToWords(questionnaire)}
                    </div>
                </Grid>
                <Grid item xs={3}>
                    {hasSwitch && (
                        <Switch
                            checked={participant.questionnaires?.some(q => q.name === questionnaire)}
                            onChange={() => handleChange(questionnaire)}
                            color="primary"
                            name="checkedA"
                            inputProps={{
                                "aria-label": "secondary checkbox",
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (participant) {
                                if (!redirects) {
                                    hist.push(`/dashboard/admin-survey/${participant.id}/${questionnaire}`);
                                } else {
                                    hist.push(`/dashboard/adminFallsCalendar/${participant.id}`);
                                }
                            }
                        }}
                    >
                        Fill
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

interface Props {
    participant: Participant;
}

const Questionnaires: React.FC<Props> = ({ participant }) => {
    const resetQuestionnaires = async () => {
        if (participant && participant.id) {
            try {
                await updateDoc(doc(firestore, "Participants", participant.id), {
                    resetDate: serverTimestamp(),
                });
            } catch (e) {
                console.error(e);
            }
        }
    };

    if (participant)
        return (
            <>
                <Button variant="contained" color="primary" style={{ marginLeft: "auto", marginTop: 20 }} onClick={resetQuestionnaires}>
                    Reset Questionnaires
                </Button>

                <TopicTitle topic="Physical" />
                <QuestionnaireSwitch questionnaire="fallsCalendar" participant={participant} hasSwitch={false} redirects={true} />
                <QuestionnaireSwitch questionnaire="lawtonBrodyIADL" participant={participant} />
                <QuestionnaireSwitch questionnaire="fallsAndBalance" participant={participant} />
                <QuestionnaireSwitch questionnaire="womenHealth" participant={participant} />
                <QuestionnaireSwitch questionnaire="healthStatus" participant={participant} />

                <TopicTitle topic="Heart" />
                <QuestionnaireSwitch questionnaire="healthResourceUtilization" participant={participant} />
                <QuestionnaireSwitch questionnaire="qualityOfLife" participant={participant} />
                <QuestionnaireSwitch questionnaire="healthUtilityIndex" participant={participant} />
                <QuestionnaireSwitch questionnaire="healthBehaviorsQuestionnaire" participant={participant} />

                <TopicTitle topic="Diet" />
                <QuestionnaireSwitch questionnaire="dietHistory" participant={participant} />
                <QuestionnaireSwitch questionnaire="eatingPattern" participant={participant} />
                <QuestionnaireSwitch questionnaire="mediterraneanDietAdherence" participant={participant} />

                <TopicTitle topic="Sleep" />
                <QuestionnaireSwitch questionnaire="insomniaSeverityIndex" participant={participant} />
                <QuestionnaireSwitch questionnaire="sleepDiary" participant={participant} />

                <TopicTitle topic="Cognitive" />
                <QuestionnaireSwitch questionnaire="preCognitiveExpectancy" participant={participant} />
                <QuestionnaireSwitch questionnaire="postCognitiveExpectancy" participant={participant} />
                <QuestionnaireSwitch questionnaire="generalizedAnxietyDisorder" participant={participant} />
                <QuestionnaireSwitch questionnaire="geriatricDepressionScale" participant={participant} />
                <QuestionnaireSwitch questionnaire="revisedHearingHandicapInventory" participant={participant} />
                <QuestionnaireSwitch questionnaire="visionHearing" participant={participant} />
                <QuestionnaireSwitch questionnaire="bhproQuestionnaires" participant={participant} />
            </>
        );
    else return <></>;
};

export default Questionnaires;
