import React, { useMemo } from "react";

// Mui
import Grid from "@mui/material/Grid";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Utils
import { parseGoalsContent } from "../../utils/goals";

interface Props {
    domain?: "physical" | "cognitive" | "health" | "sleep" | "diet";
}

const MyGoals: React.FC<Props> = ({ domain }) => {
    const { currentParticipant } = useFetchUser();

    const physicalTitle = useMemo(() => {
        if (currentParticipant) {
            switch (currentParticipant.state) {
                case "assessments":
                default:
                    return "Physical Exercise / Sedentarism";
                case "intervention":
                case "control":
                    return "Physical Exercise";
            }
        }
        return "Physical Exercise";
    }, [currentParticipant]);

    const healthTitle = useMemo(() => {
        if (currentParticipant) {
            switch (currentParticipant.state) {
                case "assessments":
                case "intervention":
                default:
                    return "Cardiometabolic Health";
                case "control":
                    return "";
            }
        }
        return "Health";
    }, [currentParticipant]);

    if (domain && !["physical", "cognitive", "health", "sleep", "diet"].some(x => x === domain)) {
        console.error();

        return <>ERROR</>;
    }
    if (currentParticipant) {
        if (domain) {
            return (
                <Grid container item xs={12} spacing={8}>
                    <Grid item md={12}>
                        <h4 className="home__title">My Goals</h4>
                        <div className="ct__neuropeak__content" style={{ marginLeft: 0 }}>
                            <ul style={{ paddingBottom: 20 }}>
                                {parseGoalsContent((currentParticipant as any)[domain].goals).map((paragraph: any, index: number) => {
                                    if (paragraph.props.children?.length === 0) {
                                        return (
                                            <React.Fragment key={index}>
                                                <br />
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <li key={index} style={{ color: "white", fontFamily: "Roboto" }}>
                                            <p>{paragraph}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            );
        } else
            return (
                <Grid container item xs={12} spacing={8}>
                    <Grid item md={12}>
                        <h4 className="home__title">My Goals</h4>
                        <div className="ct__neuropeak__content" style={{ marginLeft: 0 }}>
                            <h6 style={{ paddingBottom: 20 }}>{physicalTitle}</h6>
                            <ul style={{ paddingBottom: 20 }}>
                                {!currentParticipant.physical.goals && (
                                    <li style={{ color: "white", fontFamily: "Roboto" }}>
                                        <p>None</p>
                                    </li>
                                )}
                                {parseGoalsContent(currentParticipant.physical.goals).map((paragraph: any, index: number) => {
                                    if (paragraph.props.children?.length === 0) {
                                        return (
                                            <React.Fragment key={index}>
                                                <br />
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <li key={index} style={{ color: "white", fontFamily: "Roboto" }}>
                                            <p>{paragraph}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                            <h6 style={{ paddingBottom: 20 }}>Cognitive</h6>
                            <ul style={{ paddingBottom: 20 }}>
                                {!currentParticipant.cognitive.goals && (
                                    <li style={{ color: "white", fontFamily: "Roboto" }}>
                                        <p>None</p>
                                    </li>
                                )}
                                {parseGoalsContent(currentParticipant.cognitive.goals).map((paragraph: any, index: number) => {
                                    if (paragraph.props.children?.length === 0) {
                                        return (
                                            <React.Fragment key={index}>
                                                <br />
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <li key={index} style={{ color: "white", fontFamily: "Roboto" }}>
                                            <p>{paragraph}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                            <h6 style={{ paddingBottom: 20 }}>{healthTitle}</h6>
                            <ul style={{ paddingBottom: 20 }}>
                                {!currentParticipant.health.goals && (
                                    <li style={{ color: "white", fontFamily: "Roboto" }}>
                                        <p>None</p>
                                    </li>
                                )}
                                {parseGoalsContent(currentParticipant.health.goals).map((paragraph: any, index: number) => {
                                    if (paragraph.props.children?.length === 0) {
                                        return (
                                            <React.Fragment key={index}>
                                                <br />
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <li key={index} style={{ color: "white", fontFamily: "Roboto" }}>
                                            <p>{paragraph}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                            <h6 style={{ paddingBottom: 20 }}>Sleep</h6>
                            <ul style={{ paddingBottom: 20 }}>
                                {!currentParticipant.sleep.goals && (
                                    <li style={{ color: "white", fontFamily: "Roboto" }}>
                                        <p>None</p>
                                    </li>
                                )}
                                {parseGoalsContent(currentParticipant.sleep.goals).map((paragraph: any, index: number) => {
                                    if (paragraph.props.children?.length === 0) {
                                        return (
                                            <React.Fragment key={index}>
                                                <br />
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <li key={index} style={{ color: "white", fontFamily: "Roboto" }}>
                                            <p>{paragraph}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                            <h6 style={{ paddingBottom: 20 }}>Diet</h6>
                            <ul style={{ paddingBottom: 20 }}>
                                {!currentParticipant.diet.goals && (
                                    <li style={{ color: "white", fontFamily: "Roboto" }}>
                                        <p>None</p>
                                    </li>
                                )}
                                {parseGoalsContent(currentParticipant.diet.goals).map((paragraph: any, index: number) => {
                                    if (paragraph.props.children?.length === 0) {
                                        return (
                                            <React.Fragment key={index}>
                                                <br />
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <li key={index} style={{ color: "white", fontFamily: "Roboto" }}>
                                            <p>{paragraph}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            );
    } else return <></>;
};

export default MyGoals;
