import React, { useEffect, useState } from "react";

// Mui
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { Grid, IconButton } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/";

// Icons
import { MdOutlineNoteAdd, MdDeleteOutline } from "react-icons/md";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Firebase
import { doc, getDoc } from "@firebase/firestore";
import { firestore } from "../../../../firebase/firebase";
import { genDoc } from "../../../../utils/firebase";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// yup validation
const requiredMessage = "Required field";

interface Props {
    participantId: string;
    // eslint-disable-next-line
    onComplete: (data: any) => void;
}

const logbookForOngoingMedications: React.FC<Props> = ({ participantId, onComplete }) => {
    // States
    const [participant, setParticipant] = useState<Participant | undefined>(undefined);
    const [count, setCount] = useState(1);

    const getDefaultValues = () => {
        const defaultValues: any = {};

        [...Array(100)].forEach((x, i) => {
            defaultValues[`medication${i + 1}`] = "";
            defaultValues[`dose${i + 1}`] = "";
            defaultValues[`frequency${i + 1}`] = "";
            defaultValues[`startDate${i + 1}`] = new Date();
        });

        defaultValues.medicationCount = "";
        defaultValues.vitaminCount = "";

        defaultValues.neuroleptics = "";
        defaultValues.benzodiazepines = "";
        defaultValues.diuretics = "";
        defaultValues.thyroids = "";
        defaultValues.betablocker = "";
        defaultValues.alphablocker = "";
        defaultValues.vasodilators = "";
        defaultValues.statins = "";
        defaultValues.nsaids = "";
        defaultValues.vitamind = "";
        defaultValues.ssri = "";
        defaultValues.multivitamins = "";
        defaultValues.narcoleptics = "";
        defaultValues.calcium = "";
        defaultValues.aspirin = "";
        defaultValues.other = "";
        defaultValues.otherText = "";

        return defaultValues;
    };

    const getSchema = () => {
        const schema: any = {};

        [...Array(100)].forEach((x, i) => {
            schema[`medication${i + 1}`] = yup.string();
            schema[`dose${i + 1}`] = yup.string();
            schema[`frequency${i + 1}`] = yup.string();
            schema[`startDate${i + 1}`] = yup.date();
        });

        schema.medicationCount = yup.string().required(requiredMessage);
        schema.vitaminCount = yup.string().required(requiredMessage);

        schema.neuroleptics = yup.string().required(requiredMessage);
        schema.benzodiazepines = yup.string().required(requiredMessage);
        schema.diuretics = yup.string().required(requiredMessage);
        schema.thyroids = yup.string().required(requiredMessage);
        schema.betablocker = yup.string().required(requiredMessage);
        schema.alphablocker = yup.string().required(requiredMessage);
        schema.vasodilators = yup.string().required(requiredMessage);
        schema.statins = yup.string().required(requiredMessage);
        schema.nsaids = yup.string().required(requiredMessage);
        schema.vitamind = yup.string().required(requiredMessage);
        schema.ssri = yup.string().required(requiredMessage);
        schema.multivitamins = yup.string().required(requiredMessage);
        schema.narcoleptics = yup.string().required(requiredMessage);
        schema.calcium = yup.string().required(requiredMessage);
        schema.aspirin = yup.string().required(requiredMessage);
        schema.other = yup.string().required(requiredMessage);
        schema.otherText = yup.string();

        return yup.object(schema);
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({ resolver: yupResolver(getSchema()), defaultValues: getDefaultValues() });

    const onSubmit = async (data: any) => {
        Object.entries(data).map(entry => {
            if (
                !entry[1] ||
                parseInt(entry[0].replace("dose", "").replace("startDate", "").replace("frequency", "").replace("medication", "")) > count
            ) {
                delete data[entry[0]];
            }
        });

        onComplete({ ...data, count });
    };

    const RenderRadioButtons: React.FC<{ name: string }> = ({ name }) => {
        return (
            <>
                <Controller
                    control={control}
                    name={name}
                    render={({ field }) => (
                        <FormControl error={!!errors[name]?.message} required>
                            <RadioGroup row {...field}>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </RadioGroup>
                            <FormHelperText>{errors[name]?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </>
        );
    };

    const RenderTextField: React.FC<{ name: string }> = ({ name }) => {
        return (
            <>
                <Controller
                    render={({ field }) => (
                        <TextField variant="standard" type="text" helperText={errors[name]?.message} error={!!errors[name]?.message} {...field} />
                    )}
                    name={name}
                    control={control}
                />
            </>
        );
    };

    const RenderDatePicker: React.FC<{ name: string }> = ({ name }) => {
        return (
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            openTo="year"
                            disableFuture
                            views={["year", "month", "day"]}
                            label="Start date"
                            inputFormat="MM/YYYY"
                            {...field}
                            renderInput={(params: any) => (
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    required
                                    error={errors && errors[name] && !!errors[name]?.message}
                                    helperText={errors && errors[name] && errors[name]?.message}
                                    style={{ marginBottom: 10 }}
                                    {...params}
                                />
                            )}
                        />
                    </LocalizationProvider>
                )}
            />
        );
    };

    const fetchParticipant = async () => {
        const payload = await getDoc(doc(firestore, "Participants", participantId));
        setParticipant(genDoc<Participant>()(payload));
    };

    useEffect(() => {
        if (participant && participant.forms && participant.forms.logbookForOngoingMedications) {
            setCount(participant.forms.logbookForOngoingMedications.count ?? 1);

            [...Array(participant.forms.logbookForOngoingMedications.count)].forEach((x, i) => {
                setValue(`medication${i + 1}`, participant.forms.logbookForOngoingMedications[`medication${i + 1}`]);
                setValue(`dose${i + 1}`, participant.forms.logbookForOngoingMedications[`dose${i + 1}`]);
                setValue(`frequency${i + 1}`, participant.forms.logbookForOngoingMedications[`frequency${i + 1}`]);
                if (participant.forms.logbookForOngoingMedications[`startDate${i + 1}`]) {
                    setValue(`startDate${i + 1}`, new Date(participant.forms.logbookForOngoingMedications[`startDate${i + 1}`].seconds * 1000));
                }
            });

            setValue("medicationCount", participant.forms.logbookForOngoingMedications.medicationCount);
            setValue("vitaminCount", participant.forms.logbookForOngoingMedications.vitaminCount);

            setValue("neuroleptics", participant.forms.logbookForOngoingMedications.neuroleptics);
            setValue("benzodiazepines", participant.forms.logbookForOngoingMedications.benzodiazepines);
            setValue("diuretics", participant.forms.logbookForOngoingMedications.diuretics);
            setValue("thyroids", participant.forms.logbookForOngoingMedications.thyroids);
            setValue("betablocker", participant.forms.logbookForOngoingMedications.betablocker);
            setValue("alphablocker", participant.forms.logbookForOngoingMedications.alphablocker);
            setValue("vasodilators", participant.forms.logbookForOngoingMedications.vasodilators);
            setValue("statins", participant.forms.logbookForOngoingMedications.statins);
            setValue("nsaids", participant.forms.logbookForOngoingMedications.nsaids);
            setValue("vitamind", participant.forms.logbookForOngoingMedications.vitamind);
            setValue("ssri", participant.forms.logbookForOngoingMedications.ssri);
            setValue("multivitamins", participant.forms.logbookForOngoingMedications.multivitamins);
            setValue("narcoleptics", participant.forms.logbookForOngoingMedications.narcoleptics);
            setValue("calcium", participant.forms.logbookForOngoingMedications.calcium);
            setValue("aspirin", participant.forms.logbookForOngoingMedications.aspirin);
            setValue("other", participant.forms.logbookForOngoingMedications.other);
            setValue("otherText", participant.forms.logbookForOngoingMedications.otherText);
        }
    }, [participant]);

    useEffect(() => {
        fetchParticipant();
    }, [participantId]);

    return (
        <div className="admin__participant__tables">
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div style={{ paddingTop: 20, marginBottom: 20 }} className="centered__parent">
                    <h1>List of Ongoing Medications</h1>
                </div>

                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Medication</TableCell>
                                <TableCell align="center">Dose</TableCell>
                                <TableCell align="center">Frequency</TableCell>
                                <TableCell align="center">Start date (MM/YYYY)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...Array(count)].map((x, i) => {
                                return (
                                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={i}>
                                        <TableCell align="center">
                                            <RenderTextField name={`medication${i + 1}`} />
                                        </TableCell>
                                        <TableCell align="center">
                                            <RenderTextField name={`dose${i + 1}`} />
                                        </TableCell>
                                        <TableCell align="center">
                                            <RenderTextField name={`frequency${i + 1}`} />
                                        </TableCell>
                                        <TableCell align="center">
                                            <RenderDatePicker name={`startDate${i + 1}`} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">
                                    <IconButton aria-label="delete" onClick={() => count > 0 && setCount(prev => prev - 1)}>
                                        <MdDeleteOutline />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => count < 10 && setCount(prev => prev + 1)}>
                                        <MdOutlineNoteAdd />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <Grid container>
                        <Grid item xs={6}>
                            <div className="centered__parent">
                                <div>
                                    <p>Total Number of Medications Currently Taking: </p>
                                    <RenderTextField name="medicationCount" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="centered__parent">
                                <div>
                                    <p>Others (vitamins and supplements): </p>
                                    <RenderTextField name="vitaminCount" />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">YES / NO</TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">YES / NO</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Neuroleptics</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="neuroleptics" />
                                </TableCell>
                                <TableCell align="center">Benzodiazepines</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="benzodiazepines" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Diuretics</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="diuretics" />
                                </TableCell>
                                <TableCell align="center">Thyroids (ATD)</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="thyroids" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Beta-Blocker</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="betablocker" />
                                </TableCell>
                                <TableCell align="center">Alpha-Blocker</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="alphablocker" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Vasodilators</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="vasodilators" />
                                </TableCell>
                                <TableCell align="center">Statins</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="statins" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">NSAIDs</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="nsaids" />
                                </TableCell>
                                <TableCell align="center">Vitamin D</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="vitamind" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">SSRI</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="ssri" />
                                </TableCell>
                                <TableCell align="center">Multi-vitamins</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="multivitamins" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Narcoleptics</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="narcoleptics" />
                                </TableCell>
                                <TableCell align="center">Calcium</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="calcium" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Aspirin</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="aspirin" />
                                </TableCell>
                                <TableCell align="center">
                                    <div>Other:</div>
                                    <RenderTextField name="otherText" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="other" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ display: "flex" }}>
                    <Button variant="contained" type="submit" color="primary" className="cta__submit" style={{ marginLeft: "auto", marginTop: 20 }}>
                        Save List of Ongoing Medications
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default logbookForOngoingMedications;
