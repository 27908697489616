import { ReactNode, MouseEvent } from "react";

import Tooltip from "@mui/material/Tooltip";

const makeStyle = (active: boolean) => {
    return { color: active ? "grey" : "black" };
};

type OptionProps = {
    // eslint-disable-next-line
    onClick: (value: string) => void;
    children: ReactNode;
    title: string;
    active: boolean;
    value: string;
};

const Option = ({ children, onClick, title, active, value }: OptionProps) => {
    const handleClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        onClick(value);
    };

    return (
        <Tooltip title={title} className="draftjs__controls__icon">
            <span onMouseDown={handleClick} style={makeStyle(active)}>
                {children}
            </span>
        </Tooltip>
    );
};

export default Option;
