import React, { useEffect, useState } from "react";

// Mui
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Firebase
import { doc, getDoc } from "@firebase/firestore";
import { firestore } from "../../../../firebase/firebase";
import { genDoc } from "../../../../utils/firebase";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";

interface Props {
    participantId: string;
    // eslint-disable-next-line
    onComplete: (data: any) => void;
}

const SummaryReportTable: React.FC<Props> = ({ participantId, onComplete }) => {
    // States
    const [participant, setParticipant] = useState<Participant | undefined>(undefined);

    const getDefaultValues = () => {
        const defaultValues: any = {};

        defaultValues.fallsT0 = "";
        defaultValues.fallsT6 = "";
        defaultValues.fallsT12 = "";
        defaultValues.changesInMedicationT0 = "";
        defaultValues.changesInMedicationT6 = "";
        defaultValues.changesInMedicationT12 = "";
        defaultValues.changesInHealthT0 = "";
        defaultValues.changesInHealthT6 = "";
        defaultValues.changesInHealthT12 = "";
        defaultValues.mocaT0 = "";
        defaultValues.mocaT6 = "";
        defaultValues.mocaT12 = "";
        defaultValues.cdrT0 = "";
        defaultValues.cdrT6 = "";
        defaultValues.cdrT12 = "";
        defaultValues.ravltT0 = "";
        defaultValues.ravltT6 = "";
        defaultValues.ravltT12 = "";
        defaultValues.miniMentalT0 = "";
        defaultValues.miniMentalT6 = "";
        defaultValues.miniMentalT12 = "";
        defaultValues.adasCog13T0 = "";
        defaultValues.adasCog13T6 = "";
        defaultValues.adasCog13T12 = "";
        defaultValues.otmtaT0 = "";
        defaultValues.otmtaT6 = "";
        defaultValues.otmtaT12 = "";
        defaultValues.otmtbT0 = "";
        defaultValues.otmtbT6 = "";
        defaultValues.otmtbT12 = "";
        defaultValues.dsmto30T0 = "";
        defaultValues.dsmto30T6 = "";
        defaultValues.dsmto30T12 = "";
        defaultValues.gds30T0 = "";
        defaultValues.gds30T6 = "";
        defaultValues.gds30T12 = "";
        defaultValues.gad7T0 = "";
        defaultValues.gad7T6 = "";
        defaultValues.gad7T12 = "";
        defaultValues.iadlT0 = "";
        defaultValues.iadlT6 = "";
        defaultValues.iadlT12 = "";
        defaultValues.ipaqeT0 = "";
        defaultValues.ipaqeT6 = "";
        defaultValues.ipaqeT12 = "";
        defaultValues.getActiveQuestionnaireT0 = "";
        defaultValues.getActiveQuestionnaireT6 = "";
        defaultValues.getActiveQuestionnaireT12 = "";
        defaultValues.gaitT0 = "";
        defaultValues.gaitT6 = "";
        defaultValues.gaitT12 = "";
        defaultValues.chairStandingTestTimesT0 = "";
        defaultValues.chairStandingTestTimesT6 = "";
        defaultValues.chairStandingTestTimesT12 = "";
        defaultValues.sppbT0 = "";
        defaultValues.sppbT6 = "";
        defaultValues.sppbT12 = "";
        defaultValues.sf36T0 = "";
        defaultValues.sf36T6 = "";
        defaultValues.sf36T12 = "";
        defaultValues.hui15qT0 = "";
        defaultValues.hui15qT6 = "";
        defaultValues.hui15qT12 = "";
        defaultValues.epsaBrainT0 = "";
        defaultValues.epsaBrainT6 = "";
        defaultValues.epsaBrainT12 = "";
        defaultValues.epsaRawT0 = "";
        defaultValues.epsaRawT6 = "";
        defaultValues.epsaRawT12 = "";
        defaultValues.mda14T0 = "";
        defaultValues.mda14T6 = "";
        defaultValues.mda14T12 = "";
        defaultValues.isiT0 = "";
        defaultValues.isiT6 = "";
        defaultValues.isiT12 = "";
        defaultValues.caideT0 = "";
        defaultValues.caideT6 = "";
        defaultValues.caideT12 = "";
        defaultValues.frailtyScaleT0 = "";
        defaultValues.frailtyScaleT6 = "";
        defaultValues.frailtyScaleT12 = "";
        defaultValues.asbcsT0 = "";
        defaultValues.asbcsT6 = "";
        defaultValues.asbcsT12 = "";
        defaultValues.behaviorT0 = "";
        defaultValues.behaviorT6 = "";
        defaultValues.behaviorT12 = "";
        defaultValues.cognitiveExpectancyT0 = "";
        defaultValues.cognitiveExpectancyT6 = "";
        defaultValues.cognitiveExpectancyT12 = "";
        defaultValues.healthUtilityIndexT0 = "";
        defaultValues.healthUtilityIndexT6 = "";
        defaultValues.healthUtilityIndexT12 = "";

        return defaultValues;
    };

    const getSchema = () => {
        const schema: any = {};

        schema.fallsT0 = yup.string();
        schema.fallsT6 = yup.string();
        schema.fallsT12 = yup.string();
        schema.changesInMedicationT0 = yup.string();
        schema.changesInMedicationT6 = yup.string();
        schema.changesInMedicationT12 = yup.string();
        schema.changesInHealthT0 = yup.string();
        schema.changesInHealthT6 = yup.string();
        schema.changesInHealthT12 = yup.string();
        schema.mocaT0 = yup.string();
        schema.mocaT6 = yup.string();
        schema.mocaT12 = yup.string();
        schema.cdrT0 = yup.string();
        schema.cdrT6 = yup.string();
        schema.cdrT12 = yup.string();
        schema.ravltT0 = yup.string();
        schema.ravltT6 = yup.string();
        schema.ravltT12 = yup.string();
        schema.miniMentalT0 = yup.string();
        schema.miniMentalT6 = yup.string();
        schema.miniMentalT12 = yup.string();
        schema.adasCog13T0 = yup.string();
        schema.adasCog13T6 = yup.string();
        schema.adasCog13T12 = yup.string();
        schema.otmtaT0 = yup.string();
        schema.otmtaT6 = yup.string();
        schema.otmtaT12 = yup.string();
        schema.otmtbT0 = yup.string();
        schema.otmtbT6 = yup.string();
        schema.otmtbT12 = yup.string();
        schema.dsmto30T0 = yup.string();
        schema.dsmto30T6 = yup.string();
        schema.dsmto30T12 = yup.string();
        schema.gds30T0 = yup.string();
        schema.gds30T6 = yup.string();
        schema.gds30T12 = yup.string();
        schema.gad7T0 = yup.string();
        schema.gad7T6 = yup.string();
        schema.gad7T12 = yup.string();
        schema.iadlT0 = yup.string();
        schema.iadlT6 = yup.string();
        schema.iadlT12 = yup.string();
        schema.ipaqeT0 = yup.string();
        schema.ipaqeT6 = yup.string();
        schema.ipaqeT12 = yup.string();
        schema.getActiveQuestionnaireT0 = yup.string();
        schema.getActiveQuestionnaireT6 = yup.string();
        schema.getActiveQuestionnaireT12 = yup.string();
        schema.gaitT0 = yup.string();
        schema.gaitT6 = yup.string();
        schema.gaitT12 = yup.string();
        schema.chairStandingTestTimesT0 = yup.string();
        schema.chairStandingTestTimesT6 = yup.string();
        schema.chairStandingTestTimesT12 = yup.string();
        schema.sppbT0 = yup.string();
        schema.sppbT6 = yup.string();
        schema.sppbT12 = yup.string();
        schema.sf36T0 = yup.string();
        schema.sf36T6 = yup.string();
        schema.sf36T12 = yup.string();
        schema.hui15qT0 = yup.string();
        schema.hui15qT6 = yup.string();
        schema.hui15qT12 = yup.string();
        schema.epsaBrainT0 = yup.string();
        schema.epsaBrainT6 = yup.string();
        schema.epsaBrainT12 = yup.string();
        schema.epsaRawT0 = yup.string();
        schema.epsaRawT6 = yup.string();
        schema.epsaRawT12 = yup.string();
        schema.mda14T0 = yup.string();
        schema.mda14T6 = yup.string();
        schema.mda14T12 = yup.string();
        schema.isiT0 = yup.string();
        schema.isiT6 = yup.string();
        schema.isiT12 = yup.string();
        schema.caideT0 = yup.string();
        schema.caideT6 = yup.string();
        schema.caideT12 = yup.string();
        schema.frailtyScaleT0 = yup.string();
        schema.frailtyScaleT6 = yup.string();
        schema.frailtyScaleT12 = yup.string();
        schema.asbcsT0 = yup.string();
        schema.asbcsT6 = yup.string();
        schema.asbcsT12 = yup.string();
        schema.behaviorT0 = yup.string();
        schema.behaviorT6 = yup.string();
        schema.behaviorT12 = yup.string();
        schema.cognitiveExpectancyT0 = yup.string();
        schema.cognitiveExpectancyT6 = yup.string();
        schema.cognitiveExpectancyT12 = yup.string();
        schema.healthUtilityIndexT0 = yup.string();
        schema.healthUtilityIndexT6 = yup.string();
        schema.healthUtilityIndexT12 = yup.string();

        return yup.object(schema);
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({ resolver: yupResolver(getSchema()), defaultValues: getDefaultValues() });

    const onSubmit = async (data: any) => {
        onComplete(data);
    };

    const RenderTextField: React.FC<{ name: string }> = ({ name }) => {
        return (
            <>
                <Controller
                    render={({ field }) => (
                        <TextField variant="standard" type="text" helperText={errors[name]?.message} error={!!errors[name]?.message} {...field} />
                    )}
                    name={name}
                    control={control}
                />
            </>
        );
    };

    const fetchParticipant = async () => {
        const payload = await getDoc(doc(firestore, "Participants", participantId));
        setParticipant(genDoc<Participant>()(payload));
    };

    useEffect(() => {
        if (participant && participant.forms && participant.forms.summaryReportTable) {
            setValue("fallsT0", participant.forms.summaryReportTable.fallsT0);
            setValue("fallsT6", participant.forms.summaryReportTable.fallsT6);
            setValue("fallsT12", participant.forms.summaryReportTable.fallsT12);
            setValue("changesInMedicationT0", participant.forms.summaryReportTable.changesInMedicationT0);
            setValue("changesInMedicationT6", participant.forms.summaryReportTable.changesInMedicationT6);
            setValue("changesInMedicationT12", participant.forms.summaryReportTable.changesInMedicationT12);
            setValue("changesInHealthT0", participant.forms.summaryReportTable.changesInHealthT0);
            setValue("changesInHealthT6", participant.forms.summaryReportTable.changesInHealthT6);
            setValue("changesInHealthT12", participant.forms.summaryReportTable.changesInHealthT12);
            setValue("mocaT0", participant.forms.summaryReportTable.mocaT0);
            setValue("mocaT6", participant.forms.summaryReportTable.mocaT6);
            setValue("mocaT12", participant.forms.summaryReportTable.mocaT12);
            setValue("cdrT0", participant.forms.summaryReportTable.cdrT0);
            setValue("cdrT6", participant.forms.summaryReportTable.cdrT6);
            setValue("cdrT12", participant.forms.summaryReportTable.cdrT12);
            setValue("ravltT0", participant.forms.summaryReportTable.ravltT0);
            setValue("ravltT6", participant.forms.summaryReportTable.ravltT6);
            setValue("ravltT12", participant.forms.summaryReportTable.ravltT12);
            setValue("miniMentalT0", participant.forms.summaryReportTable.miniMentalT0);
            setValue("miniMentalT6", participant.forms.summaryReportTable.miniMentalT6);
            setValue("miniMentalT12", participant.forms.summaryReportTable.miniMentalT12);
            setValue("adasCog13T0", participant.forms.summaryReportTable.adasCog13T0);
            setValue("adasCog13T6", participant.forms.summaryReportTable.adasCog13T6);
            setValue("adasCog13T12", participant.forms.summaryReportTable.adasCog13T12);
            setValue("otmtaT0", participant.forms.summaryReportTable.otmtaT0);
            setValue("otmtaT6", participant.forms.summaryReportTable.otmtaT6);
            setValue("otmtaT12", participant.forms.summaryReportTable.otmtaT12);
            setValue("otmtbT0", participant.forms.summaryReportTable.otmtbT0);
            setValue("otmtbT6", participant.forms.summaryReportTable.otmtbT6);
            setValue("otmtbT12", participant.forms.summaryReportTable.otmtbT12);
            setValue("dsmto30T0", participant.forms.summaryReportTable.dsmto30T0);
            setValue("dsmto30T6", participant.forms.summaryReportTable.dsmto30T6);
            setValue("dsmto30T12", participant.forms.summaryReportTable.dsmto30T12);
            setValue("gds30T0", participant.forms.summaryReportTable.gds30T0);
            setValue("gds30T6", participant.forms.summaryReportTable.gds30T6);
            setValue("gds30T12", participant.forms.summaryReportTable.gds30T12);
            setValue("gad7T0", participant.forms.summaryReportTable.gad7T0);
            setValue("gad7T6", participant.forms.summaryReportTable.gad7T6);
            setValue("gad7T12", participant.forms.summaryReportTable.gad7T12);
            setValue("iadlT0", participant.forms.summaryReportTable.iadlT0);
            setValue("iadlT6", participant.forms.summaryReportTable.iadlT6);
            setValue("iadlT12", participant.forms.summaryReportTable.iadlT12);
            setValue("ipaqeT0", participant.forms.summaryReportTable.ipaqeT0);
            setValue("ipaqeT6", participant.forms.summaryReportTable.ipaqeT6);
            setValue("ipaqeT12", participant.forms.summaryReportTable.ipaqeT12);
            setValue("getActiveQuestionnaireT0", participant.forms.summaryReportTable.getActiveQuestionnaireT0);
            setValue("getActiveQuestionnaireT6", participant.forms.summaryReportTable.getActiveQuestionnaireT6);
            setValue("getActiveQuestionnaireT12", participant.forms.summaryReportTable.getActiveQuestionnaireT12);
            setValue("gaitT0", participant.forms.summaryReportTable.gaitT0);
            setValue("gaitT6", participant.forms.summaryReportTable.gaitT6);
            setValue("gaitT12", participant.forms.summaryReportTable.gaitT12);
            setValue("chairStandingTestTimesT0", participant.forms.summaryReportTable.chairStandingTestTimesT0);
            setValue("chairStandingTestTimesT6", participant.forms.summaryReportTable.chairStandingTestTimesT6);
            setValue("chairStandingTestTimesT12", participant.forms.summaryReportTable.chairStandingTestTimesT12);
            setValue("sppbT0", participant.forms.summaryReportTable.sppbT0);
            setValue("sppbT6", participant.forms.summaryReportTable.sppbT6);
            setValue("sppbT12", participant.forms.summaryReportTable.sppbT12);
            setValue("sf36T0", participant.forms.summaryReportTable.sf36T0);
            setValue("sf36T6", participant.forms.summaryReportTable.sf36T6);
            setValue("sf36T12", participant.forms.summaryReportTable.sf36T12);
            setValue("hui15qT0", participant.forms.summaryReportTable.hui15qT0);
            setValue("hui15qT6", participant.forms.summaryReportTable.hui15qT6);
            setValue("hui15qT12", participant.forms.summaryReportTable.hui15qT12);
            setValue("epsaBrainT0", participant.forms.summaryReportTable.epsaBrainT0);
            setValue("epsaBrainT6", participant.forms.summaryReportTable.epsaBrainT6);
            setValue("epsaBrainT12", participant.forms.summaryReportTable.epsaBrainT12);
            setValue("epsaRawT0", participant.forms.summaryReportTable.epsaRawT0);
            setValue("epsaRawT6", participant.forms.summaryReportTable.epsaRawT6);
            setValue("epsaRawT12", participant.forms.summaryReportTable.epsaRawT12);
            setValue("mda14T0", participant.forms.summaryReportTable.mda14T0);
            setValue("mda14T6", participant.forms.summaryReportTable.mda14T6);
            setValue("mda14T12", participant.forms.summaryReportTable.mda14T12);
            setValue("isiT0", participant.forms.summaryReportTable.isiT0);
            setValue("isiT6", participant.forms.summaryReportTable.isiT6);
            setValue("isiT12", participant.forms.summaryReportTable.isiT12);
            setValue("caideT0", participant.forms.summaryReportTable.caideT0);
            setValue("caideT6", participant.forms.summaryReportTable.caideT6);
            setValue("caideT12", participant.forms.summaryReportTable.caideT12);
            setValue("frailtyScaleT0", participant.forms.summaryReportTable.frailtyScaleT0);
            setValue("frailtyScaleT6", participant.forms.summaryReportTable.frailtyScaleT6);
            setValue("frailtyScaleT12", participant.forms.summaryReportTable.frailtyScaleT12);
            setValue("asbcsT0", participant.forms.summaryReportTable.asbcsT0);
            setValue("asbcsT6", participant.forms.summaryReportTable.asbcsT6);
            setValue("asbcsT12", participant.forms.summaryReportTable.asbcsT12);
            setValue("behaviorT0", participant.forms.summaryReportTable.behaviorT0);
            setValue("behaviorT6", participant.forms.summaryReportTable.behaviorT6);
            setValue("behaviorT12", participant.forms.summaryReportTable.behaviorT6);
            setValue("cognitiveExpectancyT0", participant.forms.summaryReportTable.cognitiveExpectancyT0);
            setValue("cognitiveExpectancyT6", participant.forms.summaryReportTable.cognitiveExpectancyT6);
            setValue("cognitiveExpectancyT12", participant.forms.summaryReportTable.cognitiveExpectancyT12);
            setValue("healthUtilityIndexT0", participant.forms.summaryReportTable.healthUtilityIndexT0);
            setValue("healthUtilityIndexT6", participant.forms.summaryReportTable.healthUtilityIndexT6);
            setValue("healthUtilityIndexT12", participant.forms.summaryReportTable.healthUtilityIndexT12);
        }
    }, [participant]);

    useEffect(() => {
        fetchParticipant();
    }, [participantId]);

    return (
        <div style={{ margin: 20 }} className="admin__participant__tables">
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div style={{ paddingTop: 20, marginBottom: 20 }} className="centered__parent">
                    <h1>Summary Report Table</h1>
                </div>

                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center" rowSpan={2}>
                                    Assessments
                                </TableCell>
                                <TableCell align="center" colSpan={3}>
                                    Time points/score
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Screening/T0</TableCell>
                                <TableCell align="center">T6</TableCell>
                                <TableCell align="center">T12</TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Falls</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="fallsT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="fallsT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="fallsT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Changes in Medication</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="changesInMedicationT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="changesInMedicationT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="changesInMedicationT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Changes in Health</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="changesInHealthT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="changesInHealthT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="changesInHealthT12" />
                                </TableCell>
                            </TableRow>

                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">MoCA (16 & 25 inclusive)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mocaT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">CDR (0.5)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="cdrT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="cdrT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="cdrT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">RAVLT Delayed Recall (≤ 6)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ravltT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Mini Mental</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="miniMentalT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="miniMentalT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="miniMentalT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ADAS-Cog-13</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adasCog13T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adasCog13T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="adasCog13T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">oTMT A</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="otmtaT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="otmtaT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="otmtaT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">oTMT B</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="otmtbT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="otmtbT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="otmtbT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">DSMT-O</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="dsmto30T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="dsmto30T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="dsmto30T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">{"GDS-30 (>19)"}</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="gds30T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="gds30T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="gds30T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">GAD-7</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="gad7T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="gad7T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="gad7T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">{"IADL (>14)"}</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="iadlT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="iadlT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="iadlT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">IPAQ-E</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ipaqeT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ipaqeT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="ipaqeT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Get Active Questionnaire m/w</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="getActiveQuestionnaireT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="getActiveQuestionnaireT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="getActiveQuestionnaireT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Gait (Usual gait average of 3)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="gaitT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="gaitT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="gaitT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">60s Chair Standing Test Times in 30sec/60sec</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="chairStandingTestTimesT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="chairStandingTestTimesT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="chairStandingTestTimesT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">SPPB</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="sppbT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="sppbT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="sppbT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">SF-36</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="sf36T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="sf36T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="sf36T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">HUI-15Q</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="hui15qT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="hui15qT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="hui15qT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">EPSA (Brain Healthy Eating Score)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="epsaBrainT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="epsaBrainT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="epsaBrainT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">EPSA (Raw Score)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="epsaRawT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="epsaRawT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="epsaRawT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">MDA-14 (≤7)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mda14T0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mda14T6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="mda14T12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">ISI (≥15)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="isiT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="isiT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="isiT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">CAIDE (≥6)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="caideT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="caideT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="caideT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Frailty scale</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="frailtyScaleT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="frailtyScaleT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="frailtyScaleT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Activity-specific Balance Confidence Scale (ABC)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="asbcsT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="asbcsT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="asbcsT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Behavior Change Questionnaire</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="behaviorT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="behaviorT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="behaviorT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Cognitive Expectancy Questionnaire</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="cognitiveExpectancyT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="cognitiveExpectancyT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="cognitiveExpectancyT12" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Health Utility Index 2/3. (Dead = 0.00 to Perfect health = 1.00)</TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="healthUtilityIndexT0" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="healthUtilityIndexT6" />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderTextField name="healthUtilityIndexT12" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ display: "flex" }}>
                    <Button variant="contained" type="submit" color="primary" className="cta__submit" style={{ marginLeft: "auto", marginTop: 20 }}>
                        Save Summary Report Table
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default SummaryReportTable;
