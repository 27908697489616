import { AiOutlineUnorderedList, AiOutlineOrderedList } from "react-icons/ai";
import { ListControl, ListOption } from "./interfaces";

const listConfig: ListControl = {
    inDropdown: false,
    title: "List",
    options: [
        { title: "Liste non-ordonnée", style: "unordered-list-item", Icon: AiOutlineUnorderedList },
        { title: "Liste ordonnée", style: "ordered-list-item", Icon: AiOutlineOrderedList },
    ],
};

export type { ListControl, ListOption };
export default listConfig;
