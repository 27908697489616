import { convertFromRaw, EditorState } from "draft-js";
import { DraftParser } from "../components/general/ultraEditor/utils/draft/draftParser";
import ReactHtmlParser from "react-html-parser";

export const parseGoalsContent = (draftjsObject: string) => {
    if (draftjsObject) {
        const parser = new DraftParser(EditorState.createWithContent(convertFromRaw(JSON.parse(draftjsObject))));

        const jsx = ReactHtmlParser(parser.parse(), {});

        return jsx;
    }

    return [];
};
