// Route props
import { CustomRouteProps } from "../interfaces/CustomRouteProps";

// Settings
import settings from "../settings.json";

// Components
import BaseLayout from "../components/layout/base/BaseLayout";
import DashboardLayout from "../components/layout/dashboardLayout/DashboardLayout";
import Logs from "../components/pages/dashboard/logs/Logs";
import User from "../components/pages/dashboard/user/User";
import Users from "../components/pages/dashboard/users/Users";
import AdminLogin from "../components/pages/dashboard/AdminLogin";
import Participants from "../components/pages/dashboard/participants/Participants";
import Content from "../components/pages/dashboard/content/Content";
import Appointments from "../components/pages/dashboard/Appointments";
import GlobalChat from "../components/pages/dashboard/GlobalChat";
import Stats from "../components/pages/dashboard/stats/Stats";
import Stat from "../components/pages/dashboard/stats/Stat";
import Home from "../components/pages/dashboard/Home";
import Contents from "../components/pages/dashboard/content/Contents";
import Participant from "../components/pages/dashboard/participant/Participant";
import Survey from "../components/pages/portal/Survey";
import Form from "../components/pages/dashboard/Form";
import AdminFallsCalendar from "../components/general/AdminFallsCalendar";

const Routes: Array<CustomRouteProps> = [
    {
        Component: Home,
        path: "/dashboard/",
        exact: true,
        title: "Admin Home",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: AdminLogin,
        path: "/dashboard/login",
        exact: true,
        title: "Admin Login",
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: false,
    },
    {
        Component: Users,
        path: "/dashboard/admins",
        exact: true,
        title: "Admins",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: User,
        path: "/dashboard/admin/:id/:tabIndex",
        exact: false,
        title: "Admin",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Participants,
        path: "/dashboard/participants",
        exact: true,
        title: "Participants",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Participant,
        path: "/dashboard/participant/:id/:tabName/:index",
        exact: false,
        title: "Participant",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Stats,
        path: "/dashboard/stats",
        exact: true,
        title: "Stats",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Stat,
        path: "/dashboard/stat/:id",
        exact: false,
        title: "Stat",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Logs,
        path: "/dashboard/logs",
        exact: true,
        title: "Logs",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Contents,
        path: "/dashboard/contents/",
        exact: true,
        title: "Global Content",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Content,
        path: "/dashboard/content/:topicName/:contentType/:participantId/:id",
        exact: false,
        title: "Global Content",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Content,
        path: "/dashboard/content",
        exact: true,
        title: "Global Content",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Content,
        path: "/dashboard/content/:topicName/:contentType/:participantId/",
        exact: false,
        title: "Private Content",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Appointments,
        path: "/dashboard/appointments",
        exact: true,
        title: "Appointments",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: GlobalChat,
        path: "/dashboard/globalChat",
        exact: true,
        title: "Global Chat",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: AdminFallsCalendar,
        path: "/dashboard/adminFallsCalendar/:participantId",
        exact: true,
        title: "Admin Falls Calendar",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Survey,
        path: "/dashboard/admin-survey/:participantId/:questionnaireName",
        exact: false,
        title: "Complete Survey",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
    {
        Component: Form,
        path: "/dashboard/form/:participantId/:formName",
        exact: false,
        title: "Form",
        LayoutComponent: DashboardLayout,
        rolesAllowed: [settings.app.highestRole],
        isPrivate: true,
    },
];

export default Routes;
