import { ContentBlock, ContentState, CompositeDecorator } from "draft-js";

// eslint-disable-next-line
export const findLinkEntities = (contentBlock: ContentBlock, callback: (state: number, end: number) => void, contentState: ContentState) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
    }, callback);
};

export const LinkDecorator = ({ entityKey, contentState, children }: any) => {
    const { url } = contentState.getEntity(entityKey).getData();
    return (
        <a style={{ color: "blue", fontStyle: "italic" }} href={url} target="_blank" rel="noreferrer">
            {children}
        </a>
    );
};

export const createLinkDecorator = () => new CompositeDecorator([{ strategy: findLinkEntities, component: LinkDecorator }]);

export default { strategy: findLinkEntities, component: LinkDecorator };
