import React from "react";

const Title: React.FC<any> = ({ children, ...rest }: any) => {
    return (
        <div {...rest} style={{ fontSize: "1.63rem", paddingBottom: 10, color: "#8a8a8a" }}>
            {children}
        </div>
    );
};

export default Title;
