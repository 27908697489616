import { LinkControl } from "./interface";
import { BsLink } from "react-icons/bs";

const linkConfig: LinkControl = {
    title: "Lien",
    Icon: BsLink,
};

export type { LinkControl };
export default linkConfig;
