import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";

// FullCalendar
import FullCalendar, { CalendarApi, EventInput } from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";

// Mui
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Custom components
import Title from "../../general/Title";
import EventDialog from "../../general/EventDialog";

// Interfaces
import { AdminUser } from "../../../interfaces/AdminUser";
import { Appointment } from "../../../interfaces/Appointment";

// Utils
import { useFetchUser } from "../../../hooks/useFetchUser";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { getDocs, collection, where, query } from "firebase/firestore";
import { genDoc } from "../../../utils/firebase";

const Appointments: React.FC = () => {
    const { currentAdmin } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();
    const calendarRef = useRef<FullCalendar>(null);

    // States
    const [open, setOpen] = useState(false);
    const [coordinators, setCoordinators] = useState<AdminUser[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(undefined);
    const [selectedEventId, setSelectedEventId] = useState("");
    const [selectedCoordinatorId, setSelectedCoordinatorId] = useState("");
    const [calendarApi, setCalendarApi] = useState<CalendarApi | null>(null);
    const [appointments, setAppointments] = useState<Appointment[]>([]);

    const isToday = (date: Date) => {
        const today = new Date();
        return date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear();
    };

    const fetchCoordinators = async () => {
        const payload = await getDocs(query(collection(firestore, "Users"), where("roles", "array-contains", "coordinator")));

        setCoordinators(payload.docs.map(genDoc<AdminUser>()));
    };

    const fetchAppointments = async (userId?: string) => {
        if (currentAdmin && currentAdmin.id) {
            const payload = await getDocs(query(collection(firestore, "Appointments"), where("coordinatorId", "==", userId ?? currentAdmin.id)));

            setAppointments(payload.docs.map(genDoc<Appointment>()));
        }
    };

    const initializeCalendarApi = async () => {
        if (calendarRef) {
            const calendarRefInstance = calendarRef.current;
            if (calendarRefInstance) {
                const calendarApi = calendarRefInstance.getApi();
                if (calendarApi) {
                    setCalendarApi(calendarApi);
                }
            }
        }
    };

    // On coordinatorId changed
    useEffect(() => {
        if (selectedCoordinatorId) {
            fetchAppointments(selectedCoordinatorId);
        }
    }, [selectedCoordinatorId]);

    // Initialize calendar API
    useEffect(() => {
        if (calendarRef) initializeCalendarApi();
    }, [calendarRef]);

    useEffect(() => {
        if (selectedDate) setOpen(true);
    }, [selectedDate]);

    useEffect(() => {
        fetchCoordinators();
        fetchAppointments();

        if (currentAdmin && currentAdmin.id) {
            setSelectedCoordinatorId(currentAdmin.id);
        }
    }, [currentAdmin]);

    return (
        <>
            <Title>Appointments</Title>

            <div style={{ marginBottom: 20 }}>
                <FormControl fullWidth>
                    <InputLabel id="typeSelectLabel">Coordinators</InputLabel>
                    <Select
                        labelId="typeSelectLabel"
                        value={selectedCoordinatorId}
                        label="Coordinators"
                        onChange={e => setSelectedCoordinatorId(e.target.value)}
                    >
                        {coordinators.map((c, i) => (
                            <MenuItem key={i} value={c.id}>
                                {c.firstName} {c.id === currentAdmin?.id && "(You)"}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <FullCalendar
                ref={calendarRef}
                height={700}
                plugins={[interactionPlugin, dayGridPlugin]}
                events={appointments.map(a => {
                    return {
                        ...a,
                        backgroundColor: "orange",
                    } as EventInput;
                })}
                droppable
                selectable={true}
                eventClick={arg => {
                    setSelectedEventId(arg.event.id);
                    setSelectedDate(arg.event._instance?.range.start);
                }}
                headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "add",
                }}
                dateClick={arg => {
                    if (!isToday(arg.date) && arg.date < new Date())
                        return enqueueSnackbar("Can't create an event in the past", { variant: "error" });
                    setSelectedEventId("");
                    setSelectedDate(arg.date);
                }}
                customButtons={{
                    add: {
                        text: "Add",
                        click: () => setOpen(true),
                    },
                }}
            />

            {open && currentAdmin && currentAdmin.id && (
                <EventDialog
                    open={open}
                    setOpen={setOpen}
                    selectedDate={selectedDate}
                    appointments={appointments}
                    selectedEventId={selectedEventId}
                    coordinatorId={selectedCoordinatorId}
                    setAppointments={setAppointments}
                    calendarApi={calendarApi}
                    setSelectedDate={setSelectedDate}
                />
            )}
        </>
    );
};

export default Appointments;
