// React
import React from "react";
import { useHistory } from "react-router-dom";

// Mui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

// Firebase
import { auth } from "../../firebase/firebase";

const Unauthorized: React.FC = () => {
    const hist = useHistory();

    return (
        <div className="unauthorized__container">
            <Container maxWidth="sm">
                <Typography component="h1" variant="h2" align="center" color="white" gutterBottom>
                    Access denied
                </Typography>
                <Typography variant="h5" align="center" color="white" paragraph>
                    Please contact your administrator to obtain the necessary access rights.
                </Typography>
                <div className="unauthorized__buttons">
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    auth.signOut();
                                    hist.push("/login");
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
};

export default Unauthorized;
