import React, { useEffect, useState } from "react";

// Mui
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Firebase
import { doc, getDoc } from "@firebase/firestore";
import { firestore } from "../../../../firebase/firebase";
import { genDoc } from "../../../../utils/firebase";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";

// yup validation
const requiredMessage = "Required field";

interface Props {
    participantId: string;
    // eslint-disable-next-line
    onComplete: (data: any) => void;
}

const ComorbidityIssuesAndCompaints: React.FC<Props> = ({ participantId, onComplete }) => {
    // States
    const [participant, setParticipant] = useState<Participant | undefined>(undefined);

    const defaultValues: any = {
        complaints: "",
        hypertension: "",
        lungDisease: "",
        chf: "",
        osteoarthritis: "",
        diabetesType1: "",
        cancer: "",
        diabetesType2: "",
        dyslipidemia: "",
        parkinsonsDisease: "",
        majorJointReplaced: "",
        anemia: "",
        hearingProblems: "",
        osteoporosis: "",
        otherText: "",
        other: "",
        historyOfDepression: "",
        smoke: "",
        stroke: "",
        tia: "",
        glasses: "",
        cataracts: "",
        macularDegeneration: "",
        cataractSurgery: "",
        glaucoma: "",
        legallyBlind: "",
        myocardialInfarct: "",
        pacemaker: "",
        atrialFibrillation: "",
        bypass: "",
        angioplasty: "",
        angina: "",
    };

    const getSchema = () => {
        const schema: any = {};

        schema.complaints = yup.string();
        schema.hypertension = yup.string().required(requiredMessage);
        schema.lungDisease = yup.string().required(requiredMessage);
        schema.chf = yup.string().required(requiredMessage);
        schema.osteoarthritis = yup.string().required(requiredMessage);
        schema.diabetesType1 = yup.string().required(requiredMessage);
        schema.cancer = yup.string().required(requiredMessage);
        schema.diabetesType2 = yup.string().required(requiredMessage);
        schema.dyslipidemia = yup.string().required(requiredMessage);
        schema.parkinsonsDisease = yup.string().required(requiredMessage);
        schema.majorJointReplaced = yup.string().required(requiredMessage);
        schema.anemia = yup.string().required(requiredMessage);
        schema.hearingProblems = yup.string().required(requiredMessage);
        schema.osteoporosis = yup.string().required(requiredMessage);
        schema.otherText = yup.string();
        schema.other = yup.string().required(requiredMessage);
        schema.historyOfDepression = yup.string().required(requiredMessage);
        schema.smoke = yup.string().required(requiredMessage);
        schema.stroke = yup.string().required(requiredMessage);
        schema.tia = yup.string().required(requiredMessage);
        schema.glasses = yup.string().required(requiredMessage);
        schema.cataracts = yup.string().required(requiredMessage);
        schema.macularDegeneration = yup.string().required(requiredMessage);
        schema.cataractSurgery = yup.string().required(requiredMessage);
        schema.glaucoma = yup.string().required(requiredMessage);
        schema.legallyBlind = yup.string().required(requiredMessage);
        schema.myocardialInfarct = yup.string().required(requiredMessage);
        schema.pacemaker = yup.string().required(requiredMessage);
        schema.atrialFibrillation = yup.string().required(requiredMessage);
        schema.bypass = yup.string().required(requiredMessage);
        schema.angioplasty = yup.string().required(requiredMessage);
        schema.angina = yup.string().required(requiredMessage);

        return yup.object(schema);
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({ resolver: yupResolver(getSchema()), defaultValues });

    const onSubmit = async (data: any) => {
        onComplete(data);
    };

    const RenderRadioButtons: React.FC<{ name: string }> = ({ name }) => {
        return (
            <>
                <Controller
                    control={control}
                    name={name}
                    render={({ field }) => (
                        <FormControl error={!!errors[name]?.message} required>
                            <RadioGroup row {...field}>
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </RadioGroup>
                            <FormHelperText>{errors[name]?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </>
        );
    };

    const fetchParticipant = async () => {
        const payload = await getDoc(doc(firestore, "Participants", participantId));
        setParticipant(genDoc<Participant>()(payload));
    };

    useEffect(() => {
        if (participant && participant.forms && participant.forms.comorbidityIssuesAndComplaints) {
            setValue("complaints", participant.forms.comorbidityIssuesAndComplaints.complaints ?? "");
            setValue("hypertension", participant.forms.comorbidityIssuesAndComplaints.hypertension);
            setValue("lungDisease", participant.forms.comorbidityIssuesAndComplaints.lungDisease);
            setValue("chf", participant.forms.comorbidityIssuesAndComplaints.chf);
            setValue("osteoarthritis", participant.forms.comorbidityIssuesAndComplaints.osteoarthritis);
            setValue("diabetesType1", participant.forms.comorbidityIssuesAndComplaints.diabetesType1);
            setValue("cancer", participant.forms.comorbidityIssuesAndComplaints.cancer);
            setValue("diabetesType2", participant.forms.comorbidityIssuesAndComplaints.diabetesType2);
            setValue("dyslipidemia", participant.forms.comorbidityIssuesAndComplaints.dyslipidemia);
            setValue("parkinsonsDisease", participant.forms.comorbidityIssuesAndComplaints.parkinsonsDisease);
            setValue("majorJointReplaced", participant.forms.comorbidityIssuesAndComplaints.majorJointReplaced);
            setValue("anemia", participant.forms.comorbidityIssuesAndComplaints.anemia);
            setValue("hearingProblems", participant.forms.comorbidityIssuesAndComplaints.hearingProblems);
            setValue("osteoporosis", participant.forms.comorbidityIssuesAndComplaints.osteoporosis);
            setValue("otherText", participant.forms.comorbidityIssuesAndComplaints.otherText ?? "");
            setValue("other", participant.forms.comorbidityIssuesAndComplaints.other);
            setValue("historyOfDepression", participant.forms.comorbidityIssuesAndComplaints.historyOfDepression);
            setValue("smoke", participant.forms.comorbidityIssuesAndComplaints.smoke);
            setValue("stroke", participant.forms.comorbidityIssuesAndComplaints.stroke);
            setValue("tia", participant.forms.comorbidityIssuesAndComplaints.tia);
            setValue("glasses", participant.forms.comorbidityIssuesAndComplaints.glasses);
            setValue("cataracts", participant.forms.comorbidityIssuesAndComplaints.cataracts);
            setValue("macularDegeneration", participant.forms.comorbidityIssuesAndComplaints.macularDegeneration);
            setValue("cataractSurgery", participant.forms.comorbidityIssuesAndComplaints.cataractSurgery);
            setValue("glaucoma", participant.forms.comorbidityIssuesAndComplaints.glaucoma);
            setValue("legallyBlind", participant.forms.comorbidityIssuesAndComplaints.legallyBlind);
            setValue("myocardialInfarct", participant.forms.comorbidityIssuesAndComplaints.myocardialInfarct);
            setValue("pacemaker", participant.forms.comorbidityIssuesAndComplaints.pacemaker);
            setValue("atrialFibrillation", participant.forms.comorbidityIssuesAndComplaints.atrialFibrillation);
            setValue("bypass", participant.forms.comorbidityIssuesAndComplaints.bypass);
            setValue("angioplasty", participant.forms.comorbidityIssuesAndComplaints.angioplasty);
            setValue("angina", participant.forms.comorbidityIssuesAndComplaints.angina);
        }
    }, [participant]);

    useEffect(() => {
        fetchParticipant();
    }, [participantId]);

    return (
        <div className="admin__participant__tables">
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div style={{ paddingTop: 20, marginBottom: 20 }} className="centered__parent">
                    <h1>Comorbidity Issues and Complaints</h1>
                </div>

                <TableContainer>
                    <Table size="small">
                        {/* <TableHead>
                            <TableRow>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">YES / NO</TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">YES / NO</TableCell>
                            </TableRow>
                        </TableHead> */}
                        <TableBody>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center" rowSpan={4}>
                                    <p>Total Number of comorbidities and Complaints:</p>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                type="text"
                                                helperText={errors.complaints?.message}
                                                error={!!errors.complaints?.message}
                                                {...field}
                                            />
                                        )}
                                        name="complaints"
                                        control={control}
                                    />
                                </TableCell>
                                <TableCell align="center" style={{ maxWidth: 100 }}>
                                    Hypertension
                                </TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="hypertension" />
                                </TableCell>
                                <TableCell align="center">Lung Disease</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="lungDisease" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">CHF</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="chf" />
                                </TableCell>
                                <TableCell align="center">Osteoarthritis</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="osteoarthritis" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Diabetes Type I</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="diabetesType1" />
                                </TableCell>
                                <TableCell align="center">Cancer</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="cancer" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Diabetes Type II</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="diabetesType2" />
                                </TableCell>
                                <TableCell align="center">Dyslipidemia</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="dyslipidemia" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center" rowSpan={3}>
                                    Others:
                                </TableCell>
                                <TableCell align="center">Parkinson's Disease</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="parkinsonsDisease" />
                                </TableCell>
                                <TableCell align="center">Major Joint Replaced</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="majorJointReplaced" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Anemia</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="anemia" />
                                </TableCell>
                                <TableCell align="center">Hearing Problems</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="hearingProblems" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Osteoporosis</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="osteoporosis" />
                                </TableCell>
                                <TableCell align="center">
                                    Other:
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                variant="standard"
                                                type="text"
                                                helperText={errors.otherText?.message}
                                                error={!!errors.otherText?.message}
                                                {...field}
                                            />
                                        )}
                                        name="otherText"
                                        control={control}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="other" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">History of Depression</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="historyOfDepression" />
                                </TableCell>
                                <TableCell align="center">Smoke</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="smoke" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">History of cerebral vascular disease</TableCell>
                                <TableCell align="center">Stroke</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="stroke" />
                                </TableCell>
                                <TableCell align="center">T.I.A</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="tia" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center" rowSpan={3}>
                                    Visual problems?
                                </TableCell>
                                <TableCell align="center">Glasses</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="glasses" />
                                </TableCell>
                                <TableCell align="center">Cataracts</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="cataracts" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Macular degeneration</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="macularDegeneration" />
                                </TableCell>
                                <TableCell align="center">Cataract surgery</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="cataractSurgery" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Glaucoma</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="glaucoma" />
                                </TableCell>
                                <TableCell align="center">Legally blind</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="legallyBlind" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center" rowSpan={3}>
                                    Cardiac problems
                                </TableCell>
                                <TableCell align="center">Myocardial infarct</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="myocardialInfarct" />
                                </TableCell>
                                <TableCell align="center">Pacemaker</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="pacemaker" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Atrial fibrillation</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="atrialFibrillation" />
                                </TableCell>
                                <TableCell align="center">Bypass</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="bypass" />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell align="center">Angioplasty</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="angioplasty" />
                                </TableCell>
                                <TableCell align="center">Angina</TableCell>
                                <TableCell align="center">
                                    <RenderRadioButtons name="angina" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: "flex" }}>
                    <Button variant="contained" type="submit" color="primary" className="cta__submit" style={{ marginLeft: "auto", marginTop: 20 }}>
                        Save Comorbidity Issues and Complaints
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ComorbidityIssuesAndCompaints;
