import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

// Routes
import Route from "./route";
import portalRoutes from './portalRoutes';
import dashboardRoutes from './dashboardRoutes';

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>
                {portalRoutes.concat(dashboardRoutes).map((r: any, i) => (
                    <Route key={i} {...r} />
                ))}
                <Redirect to="/login" />
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
