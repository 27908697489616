import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { customAlphabet } from "nanoid";

// Mui
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// Utils
import settings from "../../../../settings.json";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Custom components
import Title from "../../../general/Title";

// Interfaces
import { File } from "../../../../interfaces/File";

// Firebase
import { storage, firestore } from "../../../../firebase/firebase";
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { addDoc, collection, updateDoc, doc, getDoc } from "firebase/firestore";
import { genDoc } from "../../../../utils/firebase";

// Icons
import { MdOutlineFilePresent } from "react-icons/md";

const ERROR_MESSAGE = "Required field";

interface Params {
    topicName: string;
    contentType: string;
    participantId: string;
    id: string;
}

const Content: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { currentAdmin } = useFetchUser();
    const { topicName, contentType, participantId, id } = useParams<Params>();

    // States
    const [content, setContent] = useState<File | undefined>(undefined);
    const [type, setType] = useState(contentType ?? "");
    const [topic, setTopic] = useState(topicName ?? "");
    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("");
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [errors, setErrors] = useState<any>({});

    const resetFields = () => {
        setType("");
        setTopic("");
        setTitle("");
        setUrl("");
        setSelectedFiles(null);
        setErrors({});
    };

    const fetchContent = async () => {
        try {
            const payload = await getDoc(doc(firestore, "Files", id));
            const localFile = genDoc<File>()(payload);

            setType(localFile.type);
            setTopic(localFile.topic);
            setTitle(localFile.title);
            setUrl(localFile.url);
            setSelectedFiles(null);
            setContent(localFile);
        } catch (e) {
            console.error(e);
        }
    };

    const checkErrors = () => {
        let hasErrors = false;
        const localErrors: any = {};

        if (!type) {
            hasErrors = true;
            localErrors.type = { message: ERROR_MESSAGE };
        } else {
            delete localErrors.type;

            if (type === "File" && !id) {
                if (!selectedFiles) {
                    hasErrors = true;
                    localErrors.input = { message: ERROR_MESSAGE };
                } else delete localErrors.input;
            }

            if (type === "Content") {
                if (!url) {
                    hasErrors = true;
                    localErrors.url = { message: ERROR_MESSAGE };
                } else delete localErrors.url;
            }
        }

        if (!topic) {
            hasErrors = true;
            localErrors.topic = { message: ERROR_MESSAGE };
        } else {
            delete localErrors.topic;
        }

        if (!title) {
            hasErrors = true;
            localErrors.title = { message: ERROR_MESSAGE };
        } else delete localErrors.title;

        if (hasErrors) {
            setErrors(localErrors);
        }

        return hasErrors;
    };

    const getExtension = async (type: string) => {
        switch (type) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return "xlsx";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return "docx";
            case "application/pdf":
                return "pdf";
            default:
                return "pptx";
        }
    };

    const handleUpload = async () => {
        try {
            if (selectedFiles && selectedFiles[0]) {
                const type = selectedFiles[0].type;
                const extension = await getExtension(type);
                const nanoid = customAlphabet("abcdefghijklmnopqrstuvwxyz1234567890", 10);

                const path = `Files/${topic}/${nanoid()}.${extension}`;

                const storageRef = ref(storage, path);
                const snapshot = await uploadBytes(storageRef, selectedFiles[0]);

                return await getDownloadURL(snapshot.ref);
            }
        } catch (e) {
            console.error(e);
        }
        return "";
    };

    const handleSave = async () => {
        try {
            if (checkErrors()) return;

            let bucketUrl = "";

            if (selectedFiles && selectedFiles[0]) {
                // File
                bucketUrl = await handleUpload();
            }

            const file: File = {
                createdAt: new Date(),
                updatedAt: new Date(),
                isDeleted: false,
                topic,
                title,
                url: bucketUrl ? bucketUrl : url,
                type,
                extension: selectedFiles ? await getExtension(selectedFiles[0].type) : "",
            };

            // Global
            if (participantId === "undefined" || !participantId) {
                // Update file
                if (id && content && content.id) {
                    await updateDoc(doc(firestore, "Files", content.id), { ...file, createdAt: content.createdAt });

                    const logPayload = await addDoc(collection(firestore, "Logs"), {
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        isDeleted: false,
                        adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
                        reason: `Updated file ${file && file.title}`,
                    });
                    await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });
                }
                // Create file
                else {
                    const filePayload = await addDoc(collection(firestore, "Files"), file);
                    await updateDoc(doc(firestore, "Files", filePayload.id), { id: filePayload.id });

                    const logPayload = await addDoc(collection(firestore, "Logs"), {
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        isDeleted: false,
                        adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
                        reason: `Created file ${file && file.title}`,
                    });
                    await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });
                }
            }
            // Personal
            else {
                const filePayload = await addDoc(collection(firestore, "Participants", participantId, "Files"), file);
                await updateDoc(doc(firestore, "Participants", participantId, "Files", filePayload.id), { id: filePayload.id });

                const logPayload = await addDoc(collection(firestore, "Logs"), {
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    isDeleted: false,
                    adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
                    reason: `Created personal file ${file && file.title}`,
                });
                await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });
            }

            if (!id) {
                enqueueSnackbar(`${type} added in ${topic}`, { variant: "success" });
                resetFields();
            } else enqueueSnackbar(`${type} modified in ${topic}`, { variant: "success" });
        } catch (e) {
            console.error(e);
        }
    };

    const handleViewFile = () => {
        window.open(url, "_blank");
    };

    useEffect(() => {
        if (id) fetchContent();
    }, [id]);

    return (
        <>
            <Title>Content</Title>
            <Grid item container spacing={2}>
                <Grid item xs={3}>
                    <FormControl fullWidth error={!!errors.topic}>
                        <InputLabel id="topicSelectLabel">Topic</InputLabel>
                        <Select labelId="topicSelectLabel" value={topic} label="Topic" onChange={e => setTopic(e.target.value)}>
                            {settings.app.topics.map((t, i) => (
                                <MenuItem key={i} value={t}>
                                    {t}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl fullWidth error={!!errors.type}>
                        <InputLabel id="typeSelectLabel">Type</InputLabel>
                        <Select labelId="typeSelectLabel" value={type} label="Type" onChange={e => setType(e.target.value)}>
                            {settings.app.fileTypes.map((t, i) => (
                                <MenuItem key={i} value={t}>
                                    {t}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Title"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        error={!!errors.title}
                    />
                </Grid>

                {/* Content */}
                {type === settings.app.fileTypes[0] && (
                    <Grid item xs={3}>
                        <TextField fullWidth variant="outlined" label="Url" value={url} onChange={e => setUrl(e.target.value)} error={!!errors.url} />
                    </Grid>
                )}

                {/* File */}
                {type === settings.app.fileTypes[1] && (
                    <Grid item xs={3}>
                        <input style={{ display: "none" }} id="fileInput" type="file" onChange={e => setSelectedFiles(e.target.files)} />
                        <label htmlFor="fileInput">
                            <TextField
                                fullWidth
                                error={!!errors.input}
                                variant="outlined"
                                value={selectedFiles ? selectedFiles[0].name : "Choose File"}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    const fileInputButton = document.getElementById("fileInput");
                                                    if (fileInputButton) fileInputButton.click();
                                                }}
                                                edge="end"
                                            >
                                                <MdOutlineFilePresent />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </label>
                    </Grid>
                )}
            </Grid>

            {Object.values(errors).length > 0 && <p style={{ color: "red", fontSize: 12 }}>{ERROR_MESSAGE}</p>}

            <Button variant="contained" style={{ marginTop: 40 }} onClick={handleSave}>
                {id ? "Modify" : "Add"} {type}
            </Button>

            {type === "File" && (
                <Button variant="contained" style={{ marginTop: 40, float: "right" }} onClick={handleViewFile}>
                    View File
                </Button>
            )}
        </>
    );
};

export default Content;
