import React, { useState } from "react";

// Mui core
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid";

// Data
import { qualityOfLife, healthUtilityIndex, healthBehaviorsQuestionnaire } from "./data/VascularHealth";
import { mediterraneanDietAdherence, dietHistory, eatingPattern } from "./data/DietData";
import { fallsCalendar, lawtonBrodyIADL, fallsAndBalance, womenHealth } from "./data/PhysicalExerciseActivityData";
import { insomniaSeverityIndex } from "./data/SleepData";
import {
    preCognitiveExpectancy,
    postCognitiveExpectancy,
    generalizedAnxietyDisorder,
    geriatricDepressionScale,
    revisedHearingHandicapInventory,
    bhproQuestionnaires,
    visionHearing,
} from "./data/CognitiveData";

// Custom components
import FormGenerator from "./FormGenerator";
import HealthResourceUtilization from "./HealthResourceUtilization";

// Utils
import { getScore } from "../../../../utils/survey";
import HealthStatus from "./HealthStatus";

const FormTest: React.FC = () => {
    const [values, setValues] = useState<any>({
        mediterraneanDietAdherence: false,
        dietHistory: false,
        eatingPattern: false,
        healthResourceUtilization: false,
        qualityOfLife: false,
        healthUtilityIndex: false,
        healthBehaviorsQuestionnaire: false,
        fallsCalendar: false,
        lawtonBrodyIADL: false,
        fallsAndBalance: false,
        insomniaSeverityIndex: false,
        preCognitiveExpectancy: false,
        postCognitiveExpectancy: false,
        generalizedAnxietyDisorder: false,
        geriatricDepressionScale: false,
        revisedHearingHandicapInventory: false,
        womenHealth: false,
        healthStatus: false,
        bhproQuestionnaires: false,
        visionHearing: false,
    });

    const handleComplete = (results: any, answers: any, questionnaireName: string) => {
        console.log("submitData", results);
        console.log("score", getScore(results, answers, questionnaireName));
    };

    return (
        <div style={{ fontFamily: "roboto", backgroundColor: "grey" }}>
            {/* ---------------------------------- Controls ---------------------------------- */}
            <Grid container item style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                    <h3>Diet</h3>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset" style={{ color: "white" }}>
                        <FormLabel style={{ color: "white" }} component="legend">
                            {mediterraneanDietAdherence.title}
                        </FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    mediterraneanDietAdherence: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.mediterraneanDietAdherence}
                        >
                            <FormControlLabel value={true} control={<Radio style={{ color: "white" }} />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio style={{ color: "white" }} />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3} style={{ color: "white" }}>
                    <FormControl component="fieldset">
                        <FormLabel style={{ color: "white" }} component="legend">
                            {dietHistory.title}
                        </FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    dietHistory: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.dietHistory}
                        >
                            <FormControlLabel value={true} control={<Radio style={{ color: "white" }} />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio style={{ color: "white" }} />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{eatingPattern.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    eatingPattern: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.eatingPattern}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                    <h3>Heart Health</h3>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Health Ressource Utilization</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    healthResourceUtilization: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.healthResourceUtilization}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{qualityOfLife.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    qualityOfLife: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.qualityOfLife}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{healthUtilityIndex.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    healthUtilityIndex: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.healthUtilityIndex}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{healthBehaviorsQuestionnaire.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    healthBehaviorsQuestionnaire: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.healthBehaviorsQuestionnaire}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                    <h3>Physical Exercise & Activity</h3>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{fallsCalendar.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    fallsCalendar: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.fallsCalendar}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{lawtonBrodyIADL.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    lawtonBrodyIADL: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.lawtonBrodyIADL}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{fallsAndBalance.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    fallsAndBalance: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.fallsAndBalance}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{womenHealth.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    womenHealth: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.womenHealth}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Health Status</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    healthStatus: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.healthStatus}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                    <h3>Sleep</h3>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{insomniaSeverityIndex.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    insomniaSeverityIndex: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.insomniaSeverityIndex}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                    <h3>Cognitive</h3>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{preCognitiveExpectancy.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    preCognitiveExpectancy: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.preCognitiveExpectancy}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{postCognitiveExpectancy.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    postCognitiveExpectancy: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.postCognitiveExpectancy}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{generalizedAnxietyDisorder.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    generalizedAnxietyDisorder: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.generalizedAnxietyDisorder}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{geriatricDepressionScale.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    geriatricDepressionScale: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.geriatricDepressionScale}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{revisedHearingHandicapInventory.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    revisedHearingHandicapInventory: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.revisedHearingHandicapInventory}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{bhproQuestionnaires.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    bhproQuestionnaires: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.bhproQuestionnaires}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{visionHearing.title}</FormLabel>
                        <RadioGroup
                            row
                            onChange={e =>
                                setValues({
                                    ...values,
                                    visionHearing: e.target.value === "true" ? true : false,
                                })
                            }
                            value={values.visionHearing}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>

            {/* ---------------------------------- Forms ---------------------------------- */}
            {Object.values(values).some(v => v) && (
                <div style={{ backgroundColor: "black", borderRadius: 10, padding: 10, fontFamily: "Roboto" }} className="survey__container">
                    {/* Diet */}
                    {values && values.mediterraneanDietAdherence && (
                        <FormGenerator
                            formData={mediterraneanDietAdherence}
                            onComplete={(results, answers) => handleComplete(results, answers, "mediterraneanDietAdherence")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.dietHistory && (
                        <FormGenerator
                            formData={dietHistory}
                            onComplete={(results, answers) => handleComplete(results, answers, "dietHistory")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.eatingPattern && (
                        <FormGenerator
                            formData={eatingPattern}
                            onComplete={(results, answers) => handleComplete(results, answers, "eatingPattern")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {/* Heart Health */}
                    {values && values.healthResourceUtilization && <HealthResourceUtilization onComplete={handleComplete} />}
                    {values && values.qualityOfLife && (
                        <FormGenerator
                            formData={qualityOfLife}
                            onComplete={(results, answers) => handleComplete(results, answers, "qualityOfLife")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {/* Physical Exercise Activity */}
                    {values && values.fallsCalendar && (
                        <FormGenerator
                            formData={fallsCalendar}
                            onComplete={(results, answers) => handleComplete(results, answers, "fallsCalendar")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.lawtonBrodyIADL && (
                        <FormGenerator
                            formData={lawtonBrodyIADL}
                            onComplete={(results, answers) => handleComplete(results, answers, "lawtonBrodyIADL")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.fallsAndBalance && (
                        <FormGenerator
                            formData={fallsAndBalance}
                            onComplete={(results, answers) => handleComplete(results, answers, "fallsAndBalance")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.womenHealth && (
                        <FormGenerator
                            formData={womenHealth}
                            onComplete={(results, answers) => handleComplete(results, answers, "womenHealth")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.healthStatus && (
                        <HealthStatus onComplete={(results, answers) => handleComplete(results, answers, "healthStatus")} />
                    )}
                    {values && values.healthUtilityIndex && (
                        <FormGenerator
                            formData={healthUtilityIndex}
                            onComplete={(results, answers) => handleComplete(results, answers, "healthUtilityIndex")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.healthBehaviorsQuestionnaire && (
                        <FormGenerator
                            formData={healthBehaviorsQuestionnaire}
                            onComplete={(results, answers) => handleComplete(results, answers, "healthBehaviorsQuestionnaire")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {/* Sleep */}
                    {values && values.insomniaSeverityIndex && (
                        <FormGenerator
                            formData={insomniaSeverityIndex}
                            onComplete={(results, answers) => handleComplete(results, answers, "insomniaSeverityIndex")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {/* Cognitive */}
                    {values && values.preCognitiveExpectancy && (
                        <FormGenerator
                            formData={preCognitiveExpectancy}
                            onComplete={(results, answers) => handleComplete(results, answers, "preCognitiveExpectancy")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.postCognitiveExpectancy && (
                        <FormGenerator
                            formData={postCognitiveExpectancy}
                            onComplete={(results, answers) => handleComplete(results, answers, "postCognitiveExpectancy")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.generalizedAnxietyDisorder && (
                        <FormGenerator
                            formData={generalizedAnxietyDisorder}
                            onComplete={(results, answers) => handleComplete(results, answers, "generalizedAnxietyDisorder")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.geriatricDepressionScale && (
                        <FormGenerator
                            formData={geriatricDepressionScale}
                            onComplete={(results, answers) => handleComplete(results, answers, "geriatricDepressionScale")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.revisedHearingHandicapInventory && (
                        <FormGenerator
                            formData={revisedHearingHandicapInventory}
                            onComplete={(results, answers) => handleComplete(results, answers, "revisedHearingHandicapInventory")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.bhproQuestionnaires && (
                        <FormGenerator
                            formData={bhproQuestionnaires}
                            onComplete={(results, answers) => handleComplete(results, answers, "bhproQuestionnaires")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                    {values && values.visionHearing && (
                        <FormGenerator
                            formData={visionHearing}
                            onComplete={(results, answers) => handleComplete(results, answers, "visionHearing")}
                            // eslint-disable-next-line
                            setOpen={(data: any) => {
                                /**/
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default FormTest;
