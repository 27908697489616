import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Utils
import settings from "../../../../settings.json";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Custom components
import Title from "../../../general/Title";
import ContentTable from "../../../general/SimpleTable";
import ContentTableRows from "./ContentTableRows";

// Interfaces
import { File } from "../../../../interfaces/File";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import { collection, doc, QueryDocumentSnapshot, DocumentData, query, orderBy, limit, onSnapshot, endBefore, startAfter } from "firebase/firestore";
import { genDoc } from "../../../../utils/firebase";

const Contents: React.FC = () => {
    const { currentAdmin, setLoading, loading } = useFetchUser();
    const hist = useHistory();

    // States
    const [items, setItems] = useState<File[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState(0);
    const [firstVisible, setFirstVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);

    const fetchFirstItems = () => {
        try {
            setLoading(true);
            const localQuery = query(collection(firestore, "Files"), orderBy("createdAt", "desc"), limit(settings.page.rowsPerPage));

            return onSnapshot(localQuery, doc => {
                const data = doc.docs.map(genDoc<File>()).filter((item: File) => {
                    if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                        return true;
                    }
                    return !item.isDeleted;
                });

                setFirstVisible(doc.docs[0]);
                setLastVisible(doc.docs[doc.docs.length - 1]);
                setItems(data);
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handleNextPage = async () => {
        try {
            if (items.length !== 0) {
                setLoading(true);
                const localQuery = query(
                    collection(firestore, "Files"),
                    orderBy("createdAt", "desc"),
                    limit(settings.page.rowsPerPage),
                    startAfter(lastVisible)
                );

                return onSnapshot(localQuery, doc => {
                    const data = doc.docs.map(genDoc<File>()).filter((item: File) => {
                        if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                            return true;
                        }
                        return !item.isDeleted;
                    });

                    setFirstVisible(doc.docs[0]);
                    setLastVisible(doc.docs[doc.docs.length - 1]);
                    setItems(data);
                    setLoading(false);
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handlePreviousPage = async () => {
        try {
            setLoading(true);
            const localQuery = query(
                collection(firestore, "Files"),
                orderBy("createdAt", "desc"),
                limit(settings.page.rowsPerPage),
                endBefore(firstVisible)
            );

            return onSnapshot(localQuery, doc => {
                const data = doc.docs.map(genDoc<File>()).filter((item: File) => {
                    if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                        return true;
                    }
                    return !item.isDeleted;
                });

                setFirstVisible(doc.docs[0]);
                setLastVisible(doc.docs[doc.docs.length - 1]);
                setItems(data);
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchCount = () => {
        try {
            setLoading(true);
            return onSnapshot(doc(firestore, "Metadata/Files"), document => {
                if (!document.exists) setCount(0);

                const data = document.data() as any;

                setCount(data.count);
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (e: any, newPage: number) => {
        if (page < newPage) handleNextPage();
        else if (page > newPage) handlePreviousPage();

        setPage(newPage);
    };

    useEffect(() => {
        setLoading(true);
        const unsub1 = fetchCount();
        const unsub2 = fetchFirstItems();
        setLoading(false);

        return () => {
            typeof unsub1 === "function" && unsub1();
            typeof unsub2 === "function" && unsub2();
        };
    }, []);

    return (
        <>
            <Title>Files</Title>

            <ContentTable
                loading={loading}
                data={items}
                count={count}
                rows={ContentTableRows()}
                noMoreData={items.length <= count}
                rowsPerPage={settings.page.rowsPerPage}
                page={page}
                onChangePage={handlePageChange}
                onDoubleClick={(c: File) => hist.push(`/dashboard/content/undefined/undefined/undefined/${c.id}`)}
            />
        </>
    );
};

export default Contents;
