// React
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";

// Mui
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { MdLockOutline } from "react-icons/md";

// Components
import { ForgotPassword } from "../forgotPassword";

// Firebase
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase/firebase";

import { useFetchUser } from "../../../hooks/useFetchUser";

const ParticipantLogin: React.FC<any> = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { currentParticipant, fetchingUser } = useFetchUser();

    // States
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);

    const handleSubmit = async (e: any) => {
        try {
            e.preventDefault();
            await signInWithEmailAndPassword(auth, email, password);
        } catch (e: any) {
            console.error("onSubmit", e);
            if (e.code === "auth/user-not-found") enqueueSnackbar("User not found", { variant: "error" });
            else if (e.code === "auth/wrong-password") enqueueSnackbar("Invalid user or email", { variant: "error" });
            else if (e.code === "auth/invalid-email") enqueueSnackbar("Invalid user or email", { variant: "error" });
        }
    };

    if (fetchingUser) {
        return (
            <Backdrop open={fetchingUser} className="login__backdrop">
                <CircularProgress className="login__spinner" />
                {/* <img src={logo} alt="" /> */}
                <h1>SYNERGIC-2</h1>
            </Backdrop>
        );
    }

    if (currentParticipant) {
        return <Redirect to="/" />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className="login__container">
                <Avatar className="login__avatar">
                    <MdLockOutline />
                </Avatar>
                <Typography component="h1" variant="h2">
                    Synergic-2
                </Typography>
                <Typography component="h1" variant="h5">
                    Participant Login
                </Typography>
                <form noValidate onSubmit={handleSubmit} className="login__content">
                    <TextField
                        className="login__content--login"
                        id="email"
                        margin="normal"
                        label="Email"
                        variant="outlined"
                        type="text"
                        onChange={e => setEmail(e.currentTarget.value)}
                        required
                        fullWidth
                    />
                    <TextField
                        className="login__content--login"
                        id="password"
                        margin="normal"
                        label="Password"
                        variant="outlined"
                        type="password"
                        onChange={e => setPassword(e.currentTarget.value)}
                        required
                        fullWidth
                    />

                    <Button type="submit" fullWidth variant="contained" className="cta__submit">
                        Login
                    </Button>
                </form>
                <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                        <Button onClick={() => setOpen(true)}>Forgot password?</Button>
                    </Grid>
                </Grid>
            </div>
            <ForgotPassword open={open} onClose={() => setOpen(false)} />
        </Container>
    );
};

export default ParticipantLogin;
