import React from "react";

// Custom components
import Title from "../../../../../general/Title";
import ParticipantChat from "../../../../../general/ParticipantChat";

// Interfaces
import { Participant } from "../../../../../../interfaces/Participant";

// Hooks
import { useFetchUser } from "../../../../../../hooks/useFetchUser";

interface Props {
    participant: Participant | null;
    primaryIndex: string;
    secondaryIndex: string;
}

const Chat: React.FC<Props> = ({ participant = null }) => {
    const { currentAdmin } = useFetchUser();

    return (
        <div>
            <Title>Chat</Title>
            {participant && participant.id && currentAdmin && currentAdmin.id && (
                <ParticipantChat participantId={participant.id} adminId={currentAdmin.id} dashboardChat />
            )}
        </div>
    );
};

export default Chat;
