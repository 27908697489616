import { FaAlignLeft, FaAlignCenter, FaAlignRight } from "react-icons/fa";
import { TextAlign, TextAlignOption } from "./interface";

const textAlignConfig: TextAlign = {
    inDropdown: false,
    options: [
        { style: "align_left", title: "Gauche", Icon: FaAlignLeft },
        { style: "align_center", title: "Centre", Icon: FaAlignCenter },
        { style: "align_right", title: "Droite", Icon: FaAlignRight },
    ],
    title: "Text align",
};

export type { TextAlign, TextAlignOption };
export default textAlignConfig;
