import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

// Interfaces
import { AdminUser } from "../../../../interfaces/AdminUser";

// Custom components
import SearchBar from "../../../general/SearchBar";
import UserDialog from "./UserDialog";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Settings
import settings from "../../../../settings.json";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import {
    collection,
    query,
    orderBy,
    onSnapshot,
    startAfter,
    limit,
    QueryDocumentSnapshot,
    DocumentData,
    getDocs,
    getCountFromServer,
} from "firebase/firestore";
import { genDoc } from "../../../../utils/firebase";

// Mui
import { Button, Grid } from "@mui/material";

const COLLECTION = "Users";

const Users: React.FC = () => {
    const hist = useHistory();
    const { currentAdmin, setLoading } = useFetchUser();

    // States
    const [users, setUsers] = useState<AdminUser[]>([]);
    const [filteredItems, setFilteredItems] = useState<AdminUser[]>([]);
    const [count, setCount] = useState<number>(0);
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [open, setOpen] = useState(false);

    const fetchFirstItems = () => {
        try {
            const localQuery = query(collection(firestore, COLLECTION), orderBy("createdAt", "desc"), limit(15));
            return onSnapshot(localQuery, doc => {
                const data = doc.docs.map(genDoc<AdminUser>()).filter((item: AdminUser) => {
                    if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                        return true;
                    }
                    return !item.isDeleted;
                });
                setLastVisible(doc.docs[doc.docs.length - 1]);
                setUsers(data);
                setFilteredItems(data);
            });
        } catch (e) {
            console.error(e);
        }
    };

    const fetchCount = async () => {
        try {
            const col = collection(firestore, "Users");
            const snapshot = await getCountFromServer(col);

            setCount(snapshot.data().count);
        } catch (e) {
            console.error(e);
        }
    };

    const fetchMoreItems = async () => {
        try {
            if (users.length !== 0) {
                const localQuery = query(
                    collection(firestore, COLLECTION),
                    orderBy("createdAt", "desc"),
                    limit(settings.page.rowsPerPage),
                    startAfter(lastVisible)
                );

                const payload = await getDocs(localQuery);

                const data = payload.docs.map(genDoc<AdminUser>()).filter((item: AdminUser) => {
                    if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                        return true;
                    }
                    return !item.isDeleted;
                });
                setLastVisible(payload.docs[payload.docs.length - 1]);
                setUsers([...users, ...data]);
                setFilteredItems([...filteredItems, ...data]);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleSearch = (query: string) => {
        setSearchQuery(query);
    };

    const filterItems = async () => {
        const data = await getDocs(collection(firestore, COLLECTION));
        const localItems = data.docs.map(genDoc<AdminUser>());

        const lowercaseSearchQuery = searchQuery.toLowerCase().trim();

        const localFilteredUsages = localItems.filter(x => {
            if (!x.firstName || !x.lastName || !x.email || !x.createdAt) return false;

            return (
                x.email.toLowerCase().trim().includes(lowercaseSearchQuery) ||
                x.firstName.toLowerCase().trim().includes(lowercaseSearchQuery) ||
                x.lastName.toLowerCase().trim().includes(lowercaseSearchQuery) ||
                moment(x.createdAt).format("DD-MM-YYYY HH:mm:ss").includes(lowercaseSearchQuery)
            );
        });

        setCount(localFilteredUsages.length);
        setFilteredItems(localFilteredUsages.sort((a, b) => (a.createdAt && b.createdAt ? a.createdAt.getTime() - b.createdAt.getTime() : -1)));
    };

    useEffect(() => {
        setLoading(true);
        if (searchQuery) {
            filterItems();
        } else setFilteredItems(users);
        setLoading(false);
    }, [searchQuery]);

    useEffect(() => {
        setLoading(true);
        fetchCount();
        const unsub = fetchFirstItems();
        setLoading(false);

        return () => {
            if (typeof unsub === "function") unsub();
        };
    }, []);

    return (
        <>
            <SearchBar onSearch={handleSearch}>
                <div className="searchbar__button">
                    <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                        Add
                    </Button>
                </div>
            </SearchBar>

            <div id="scrollableDiv" className="scrollable__container">
                <InfiniteScroll
                    dataLength={filteredItems.length}
                    next={fetchMoreItems}
                    hasMore={filteredItems.length < count}
                    loader={<h4 className="infinitescroll__container__message">Fetching next items...</h4>}
                    endMessage={!filteredItems.length && <h4 className="infinitescroll__container__message">No data</h4>}
                    className="infinitescroll__container"
                    scrollableTarget="scrollableDiv"
                    height={count > 15 ? "calc(100vh - 278px)" : ""}
                >
                    {filteredItems.map(x => (
                        <div key={x.id} className="infinitescroll__container__card" onClick={() => hist.push(`/dashboard/admin/${x.id}/0`)}>
                            <Grid item container>
                                <Grid item xs={12} sm={4}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">Firstname</div>
                                            </div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__text">{x.firstName}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">Lastname</div>
                                            </div>
                                            <div className="centered__parent">
                                                <span className="infinitescroll__container__text">{x.lastName}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">Email</div>
                                            </div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__text">{x.email}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>

            {open && <UserDialog open={open} setOpen={setOpen} userId={""} />}
        </>
    );
};

export default Users;
