export const fallsCalendar = {
    title: "Falls calendar",
    about: "",
    instructions: "",
    questions: [
        {
            questionNumber: "5",
            question:
                "Falling down is not a normal part of aging, and the consequences of falls can be serious. A fall is any unintentional event where you fall to the ground or onto an object (e.g., a chair). Have you fallen today?",
            answers: ["Yes", "No"],
            type: "radio",
            endTrigger: "No",
        },
        {
            questionNumber: "6a",
            question: "Did you experience multiple falls?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "7",
        },
        {
            questionNumber: "6b",
            question: "If yes, how many?",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "7",
            question: "Where were you when you fell, and what were you doing? (e.g., I was in my bedroom at home and I was getting out of bed)",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "8",
            question: "Why did you fall? (e.g., I was dizzy)",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "9",
            question: "As a result of this fall did you suffer any injuries?",
            answers: ["Yes", "No"],
            type: "radio",
            endTrigger: "No",
        },
        {
            questionNumber: "10",
            question: "If YES, what were they? (e.g., Yes, I have back pain as a result of this fall)",
            answers: [],
            type: "textfield",
        },
    ],
};

export const exerciseFallsCalendar = {
    title: "Exercise & Falls calendar",
    about: "",
    instructions: "",
    questions: [
        {
            questionNumber: "1",
            question: "Did you do your exercise program today?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "5",
        },
        {
            questionNumber: "2",
            question: "If yes, for how long (minutes)?",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "3",
            question: "What did you do?",
            answers: ["Warmup", "Resistance training", "Cooldown", "Aerobic exercise"],
            type: "checkbox",
        },
        {
            questionNumber: "4",
            question:
                "Look at the rating scale below while you are engaging in an activity; it ranges from 0 to 10, where 0 means 'no effort at all' and 10 means 'maximal effort'. Choose the number from below that best describes your level of effort. This will give you a good idea of the intensity level of your activity, and you can use this information to speed up or slow down your movements to reach your desired range.",
            answers: [
                "Rest (0)",
                "Really Easy (1)",
                "Easy (2)",
                "Moderate (3)",
                "Sort of Hard (4)",
                "Hard (5)",
                "6",
                "Really Hard (7)",
                "8",
                "Really Really Hard (9)",
                "Maximal (10)",
            ],
            type: "slider",
        },
        {
            questionNumber: "5",
            question:
                "Falling down is not a normal part of aging, and the consequences of falls can be serious. A fall is any unintentional event where you fall to the ground or onto an object (e.g., a chair). Have you fallen today?",
            answers: ["Yes", "No"],
            type: "radio",
            endTrigger: "No",
        },
        {
            questionNumber: "6a",
            question: "Did you experience multiple falls?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "7",
        },
        {
            questionNumber: "6b",
            question: "If yes, how many?",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "7",
            question: "Where were you when you fell, and what were you doing? (e.g., I was in my bedroom at home and I was getting out of bed)",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "8",
            question: "Why did you fall? (e.g., I was dizzy)",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "9",
            question:
                "As a result of this fall did you suffer any injuries, and if so, what were they? (e.g., Yes, I have back pain as a result of this fall)",
            answers: [],
            type: "textfield",
        },
    ],
};

export const lawtonBrodyIADL = {
    title: "Lawton Brody IADL",
    about: "",
    instructions:
        "Select one statement for each category that most closely corresponds to the participant’s current functional ability for each task.",
    questions: [
        {
            questionNumber: "1",
            question: "Ability to use a telephone",
            answers: [
                "Operates telephone on own initiative looks up and dials numbers",
                "Dials a few well-known numbers",
                "Answers telephone, but does not dial",
                "Does not use telephone at all",
            ],
            type: "radio",
        },
        {
            questionNumber: "2",
            question: "Shopping",
            answers: [
                "Takes care of all shopping needs independently",
                "Shops independently for small purchases",
                "Needs to be accompanied on any shopping trip",
                "Completely unable to shop",
            ],
            type: "radio",
        },
        {
            questionNumber: "3",
            question: "Food Preparation",
            answers: [
                "Plans, prepares, and serves adequate meals independently",
                "Prepares adequate meals if supplied with ingredients",
                "Heats and serves prepared meals or prepares meals but does not maintain adequate diet",
                "Needs to have meals prepared and served",
            ],
            type: "radio",
        },
        {
            questionNumber: "4",
            question: "Housekeeping",
            answers: [
                "Maintains house alone with occasion assistance (heavy work)",
                "Performs light daily tasks such as dishwashing, bed making",
                "Performs light daily tasks, but cannot maintain acceptable level of cleanliness",
                "Needs help with all home maintenance tasks",
                "Does not participate in any housekeeping tasks",
            ],
            type: "radio",
        },
        {
            questionNumber: "5",
            question: "Laundry",
            answers: ["Does personal laundry completely", "Launders small items, rinses socks, stockings, etc", "All laundry must be done by others"],
            type: "radio",
        },
        {
            questionNumber: "6",
            question: "Mode of Transportation",
            answers: [
                "Travels independently on public transportation or drives own car",
                "Arranges own travel via taxi, but does not otherwise use public transportation",
                "Travels on public transportation when assisted or accompanied by another",
                "Travel limited to taxi or automobile with assistance of another",
                "Does not travel at all",
            ],
            type: "radio",
        },
        {
            questionNumber: "7",
            question: "Responsibility for Own Medications",
            answers: [
                "Is responsible for taking medication in correct dosages at correct time",
                "Takes responsibility if medication is prepared in advance in separate dosages",
                "Is not capable of dispensing own medication",
            ],
            type: "radio",
        },
        {
            questionNumber: "8",
            question: "Ability to Handle Finances",
            answers: [
                "Manages financial matters independently (budgets, writes checks, pays rent and bills, goes to bank); collects and keeps track of income",
                "Manages day-to-day purchases, but needs help with banking, major purchases, etc",
                "Incapable of handling money",
            ],
            type: "radio",
        },
    ],
};

export const fallsAndBalance = {
    title: "Falls and Balance",
    about: "",
    instructions: "",
    questions: [
        {
            questionNumber: "1a",
            question: "In the past 12 months, have you had any falls?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2",
        },
        {
            questionNumber: "1b",
            question: "How many times have you fallen in the past 12 months?",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "1c",
            question: "Did you sustain an injury?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2",
        },
        {
            questionNumber: "1d",
            question: "Details",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "1e",
            question: "Did you seek medical attention for the injury?",
            answers: ["Yes", "No"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2",
        },
        {
            questionNumber: "1f",
            question: "Details",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "2",
            question: "Using a scale from 0-10, where 0 is “not afraid” and 10 is “extremely afraid”, how afraid of falling are you?",
            answers: ["Not afraid", "1", "2", "3", "4", "Somewhat afraid", "6", "7", "8", "9", "Extremely afraid"],
            type: "slider",
        },
        {
            questionNumber: "3",
            question: "Using a scale from 0-10, where 0 is “very poor” and 10 is “very good”, how would you rate your overall balance?",
            answers: ["Very poor", "1", "2", "3", "4", "Pretty good", "6", "7", "8", "9", "Very good"],
            type: "slider",
        },
        {
            questionNumber: "4a",
            question: "In your room",
            answers: ["No assistive device", "Cane", "Walker", "Other (please specify)"],
            type: "radio",
            instructions: "Do you use an assistive device in any of the following situations?",
            skipTrigger: ["No assistive device", "Cane", "Walker"],
            skipTo: "4b",
        },
        {
            questionNumber: "4aa",
            question: "if other, specify",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "4b",
            question: "In halls (short distance)",
            answers: ["No assistive device", "Cane", "Walker", "Other (please specify)"],
            type: "radio",
            instructions: "Do you use an assistive device in any of the following situations?",
            skipTrigger: ["No assistive device", "Cane", "Walker"],
            skipTo: "4c",
        },
        {
            questionNumber: "4ba",
            question: "if other, specify",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "4c",
            question: "In halls (long distance)",
            answers: ["No assistive device", "Cane", "Walker", "Other (please specify)"],
            type: "radio",
            instructions: "Do you use an assistive device in any of the following situations?",
            skipTrigger: ["No assistive device", "Cane", "Walker"],
            skipTo: "4d",
        },
        {
            questionNumber: "4ca",
            question: "if other, specify",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "4d",
            question: "Outside",
            answers: ["No assistive device", "Cane", "Walker", "Other (please specify)"],
            type: "radio",
            instructions: "Do you use an assistive device in any of the following situations?",
            skipTrigger: ["No assistive device", "Cane", "Walker"],
            skipTo: "5a",
        },
        {
            questionNumber: "4da",
            question: "if other, specify",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "5a",
            question: "Walk around the house?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5b",
            question: "Walk up and down stairs inside your home?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5c",
            question: "Bend over and pick up a slipper from the front of a closet floor?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5d",
            question: "Reach for a small can off a shelf at eye level?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5e",
            question: "Stand on your tip-toes and reach for something above your head?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5f",
            question: "Stand on a chair and reach for something?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5g",
            question: "Sweep the floor?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5h",
            question: "Walk outside the house to a car parked in the driveway?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5i",
            question: "Get into or out of a car?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5j",
            question: "Walk across a parking lot to the mall?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5k",
            question: "Walk up or down a ramp?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5l",
            question: "Walk in a crowded mall where people rapidly walk towards you and pass you by?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5m",
            question: "When people bump into you as you walk through the mall?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5n",
            question: "Step on or off an escalator while holding onto a railing?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5o",
            question: "Step onto or off an escalator while holding onto parcels such that you cannot hold onto the railing?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
        {
            questionNumber: "5p",
            question: "Walk outside on icy sidewalks?",
            answers: ["No confidence", "10", "20", "30", "40", "50", "60", "70", "80", "90", "Complete confidence"],
            type: "slider",
            instructions:
                "Activities-specific Balance Confidence Scale (ABC). The following balance questionnaire assesses your confidence in completing common activities using a scale from 0% to 100%, where 0% represents “no confidence” in completing the activity and 100% represents “complete confidence”. How confident are you that you will not lose your balance or become unsteady when you…",
        },
    ],
};

export const womenHealth = {
    title: "Women’s Health",
    about: "",
    instructions:
        "The women’s health module is used to collect data related to reproductive factors, menopause and hormone replacement therapy. Researchers are interested in these topics because they are known to affect the risk of certain diseases and health outcomes. For example, reproductive factors are known to influence hormone-related cancers like those of the breast and ovary - and the chance of developing osteoporosis (loss of bone mass) increases as women reach menopause.",
    questions: [
        {
            questionNumber: "1",
            question: "Have you ever had a menstrual period?",
            answers: ["Yes", "No", "Don't know"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "2",
        },
        {
            questionNumber: "1a",
            question: "At what age did you have your first menstrual period?",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "2",
            question:
                "Have you ever used any hormonal contraceptives for any reason? Hormonal contraceptives include birth control pills, implants, patches, injections and rings or intra-uterine devices that release female hormones.",
            answers: ["Yes", "No", "Don't know"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "3",
        },
        {
            questionNumber: "2a",
            question: "How old were you when you started using hormonal contraceptives?",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "2b",
            question:
                "In total, how many years or months did you use or have you been using hormonal contraceptives? Add up all the time that used contraceptives even if you started and stopped several times. (in months and years)",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "3",
            question: "Have you gone through menopause, meaning that your menstrual periods stopped for at least one year and did not restart?",
            answers: ["Yes", "No", "Don't know"],
            type: "radio",
            skipTrigger: "No",
            skipTo: "4",
        },
        {
            questionNumber: "3a",
            question: "Was your menopause natural or as a result of a medical intervention?",
            answers: [
                "Natural",
                "Ovaries (bilateral oophorectomy without hysterectomy)",
                "All (hysterectomy and bilateral salpingo-oophrectomy/oophorectomy)",
                "Hysterectomy only (ovaries conserved)",
                "Hystovaques (hysterectomy but uncertain if ovaries removed)",
                "Surgradition (Induced menopause by radiation)",
                "Surdrug (Induced menopause by drug)",
                "Don’t know",
            ],
            type: "radio",
        },
        {
            questionNumber: "3b",
            question: "How old were you when your menstrual periods stopped for at least one year and did not re-start?",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "4",
            question: "Have you ever used any hormone replacement therapy, sometimes called HRT, for any reason?",
            answers: ["Yes", "No", "Don't know"],
            type: "radio",
            endTrigger: "No",
        },
        {
            questionNumber: "4a",
            question: "Which type of hormone replacement therapy have you used the most?",
            answers: [
                "Estrogen_progesterone (both estrogen and progesterone)",
                "Estrogen (e.g. Premarin, Estrace)",
                "Progesterone (e.g. Prometrium, Provera)",
                "Estrogen gel or cream applied to the skin (e.g. Estraderm, Estrogel)",
                "Device progesterone (Intra-uterine device with progesterone)",
                "Don’t know",
            ],
            type: "radio",
        },
        {
            questionNumber: "4b",
            question: "How old were you when you started using hormone replacement therapy?",
            answers: [],
            type: "textfield",
        },
        {
            questionNumber: "4c",
            question: "Are you still taking hormone replacement therapy?",
            answers: ["Yes", "No", "Don't know"],
            type: "radio",
        },
        {
            questionNumber: "4d",
            question: "In total, for how long did you use or have you been using hormone replacement therapy? (weeks, months, years)",
            answers: [],
            type: "textfield",
        },
    ],
};
