import { KeyBindingUtil, getDefaultKeyBinding, EditorState, RichUtils } from "draft-js";
import isSoftNewLineEvent from "draft-js/lib/isSoftNewlineEvent";

// eslint-disable-next-line
export const handleKeyCommand = (onEditorChange: (newState: EditorState) => void, onSave: () => void) => (command: string, state: EditorState) => {
    const newState = RichUtils.handleKeyCommand(state, command);

    if (command === "editor-save") {
        onSave();
    }
    if (!newState) return "not-handled";
    if (newState) onEditorChange(newState);

    return "handled";
};

export const keyBindingFn = (e: any): string | null => {
    const codeSKey = "KeyS";

    if (e.nativeEvent.code === codeSKey && KeyBindingUtil.isCtrlKeyCommand(e)) return "editor-save";
    return getDefaultKeyBinding(e);
};

// eslint-disable-next-line
export const handleReturn = (editorState: EditorState, onChange: (s: EditorState) => void) => (e: any) => {
    if (isSoftNewLineEvent(e)) {
        onChange(RichUtils.insertSoftNewline(editorState));
        return "handled";
    }

    return "not-handled";
};
