import React from "react";

// Custom components
import Title from "./Title";

const FileLegend: React.FC = () => {
    return (
        <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <Title>Legend</Title>
            </div>

            <div>
                <span
                    style={{
                        border: "1px solid black",
                        backgroundColor: "white",
                        color: "black",
                        padding: 10,
                        margin: 2,
                        textAlign: "center",
                        width: 80,
                        display: "inline-block",
                    }}
                >
                    Global
                </span>
                <span style={{ color: "black", marginLeft: 5 }}>
                    Unselected global file or interactive content (shared between every participant)
                </span>
            </div>

            <div>
                <span
                    style={{
                        border: "1px solid black",
                        backgroundColor: "black",
                        color: "white",
                        padding: 10,
                        margin: 2,
                        textAlign: "center",
                        width: 80,
                        display: "inline-block",
                    }}
                >
                    Global
                </span>
                <span style={{ color: "black", marginLeft: 5 }}>Selected global file or interactive content (shared between every participant)</span>
            </div>

            <div>
                <span
                    style={{
                        border: "1px solid black",
                        backgroundColor: "grey",
                        color: "white",
                        padding: 10,
                        margin: 2,
                        textAlign: "center",
                        width: 80,
                        display: "inline-block",
                    }}
                >
                    Personal
                </span>
                <span style={{ color: "black", marginLeft: 5 }}>Unselected personal file or interactive content (private to the participant)</span>
            </div>

            <div>
                <span
                    style={{
                        border: "1px solid black",
                        backgroundColor: "darkred",
                        color: "white",
                        padding: 10,
                        margin: 2,
                        textAlign: "center",
                        width: 80,
                        display: "inline-block",
                    }}
                >
                    Personal
                </span>
                <span style={{ color: "black", marginLeft: 5 }}>Selected personal file or interactive content (private to the participant)</span>
            </div>
        </>
    );
};

export default FileLegend;
