import React, { useEffect, useState } from "react";
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import _ from "lodash";

// Icons
import { FaGreaterThan } from "react-icons/fa";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { useHistory } from "react-router-dom";

interface Props {
    // eslint-disable-next-line
    onComplete: (results: any, answers: number[]) => void;
}

const HealthStatus: React.FC<Props> = ({ onComplete }) => {
    const hist = useHistory();

    // States
    const [loop, setLoop] = useState(1);
    const [results, setResults] = useState<any>({});
    const [question1, setQuestion1] = useState("");
    const [question2, setQuestion2] = useState("");
    const [question3, setQuestion3] = useState("");
    const [question4, setQuestion4] = useState("");
    const [question5, setQuestion5] = useState("");
    const [question5Completed, setQuestion5Completed] = useState(false);
    const [question6, setQuestion6] = useState<moment.Moment | null>(null);
    const [question7, setQuestion7] = useState("");
    const [question8, setQuestion8] = useState<moment.Moment | null>(null);
    const [question9a, setQuestion9a] = useState("");
    const [question9b, setQuestion9b] = useState("");
    const [question9bCompleted, setQuestion9bCompleted] = useState(false);
    const [question9c, setQuestion9c] = useState("");
    const [question9d, setQuestion9d] = useState("");
    const [question9e, setQuestion9e] = useState("");
    const [question9eCompleted, setQuestion9eCompleted] = useState(false);
    const [question9f, setQuestion9f] = useState("");
    const [question10a, setQuestion10a] = useState<moment.Moment | null>(null);
    const [question10b, setQuestion10b] = useState("");
    const [question10c, setQuestion10c] = useState<moment.Moment | null>(null);
    const [question11, setQuestion11] = useState("");

    const handleReset = () => {
        setLoop(1);

        setResults({});

        setQuestion1("");
        setQuestion2("");
        setQuestion3("");
        setQuestion4("");
        setQuestion5("");
        setQuestion5Completed(false);
        setQuestion6(null);
        setQuestion7("");
        setQuestion8(null);
        setQuestion9a("");
        setQuestion9b("");
        setQuestion9bCompleted(false);
        setQuestion9c("");
        setQuestion9d("");
        setQuestion9e("");
        setQuestion9eCompleted(false);
        setQuestion9f("");
        setQuestion10a(null);
        setQuestion10b("");
        setQuestion10c(null);
        setQuestion11("");
    };

    const prepareNewLoop = () => {
        setLoop(prev => prev + 1);

        // keep question1
        setQuestion2("");
        setQuestion3("");
        setQuestion4("");
        setQuestion5("");
        setQuestion5Completed(false);
        setQuestion6(null);
        setQuestion7("");
        setQuestion8(null);
        setQuestion9a("");
        setQuestion9b("");
        setQuestion9bCompleted(false);
        setQuestion9c("");
        setQuestion9d("");
        setQuestion9e("");
        setQuestion9eCompleted(false);
        setQuestion9f("");
        setQuestion10a(null);
        setQuestion10b("");
        setQuestion10c(null);
        setQuestion11("");
    };

    // Stop questionnaire
    useEffect(() => {
        if ((question1 === "No" || question11 === "No") && !_.isEmpty(results)) {
            // STOP QUESTIONNAIRE
            onComplete(results, []);
        }
    }, [question1, question11, results]);

    return (
        <div className="fg">
            <h2>Health Status</h2>

            <div style={{ paddingTop: 40 }}>
                <div className="question__container">
                    {!question1 && (
                        <>
                            <div>
                                <h3 className="question__title">Question 1</h3>
                                <FormControl className="forms__removeMargin" component="fieldset">
                                    <FormLabel component="legend" className="question__label">
                                        <p>Have you had any health problems/hospitalization/new diagnosis within the last month?</p>
                                    </FormLabel>

                                    <RadioGroup
                                        className="question__radioContainer"
                                        row
                                        value={question1}
                                        onChange={e => {
                                            setQuestion1(e.target.value);
                                            results[`${loop}-question1`] = e.target.value;
                                        }}
                                    >
                                        <p>
                                            <FormControlLabel
                                                value={"Yes"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"Yes"}
                                            />
                                        </p>
                                        <p>
                                            <FormControlLabel
                                                value={"No"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"No"}
                                            />
                                        </p>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </>
                    )}

                    {!question2 && question1 === "Yes" && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 2</h3>
                                <FormControl className="forms__removeMargin" component="fieldset">
                                    <FormLabel component="legend" className="question__label">
                                        <p>Have you previously reported this health problem?</p>
                                    </FormLabel>

                                    <RadioGroup
                                        className="question__radioContainer"
                                        row
                                        value={question2}
                                        onChange={e => {
                                            setQuestion2(e.target.value);
                                            results[`${loop}-question2`] = e.target.value;
                                        }}
                                    >
                                        <p>
                                            <FormControlLabel
                                                value={"Yes"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"Yes"}
                                            />
                                        </p>
                                        <p>
                                            <FormControlLabel
                                                value={"No"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"No"}
                                            />
                                        </p>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </>
                    )}

                    {!question3 && question2 && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 3</h3>
                                <FormControl className="forms__removeMargin" component="fieldset">
                                    <FormLabel component="legend" className="question__label">
                                        <p>Are your symptoms related to a medical condition diagnosed previously by your doctor?</p>
                                    </FormLabel>

                                    <RadioGroup
                                        className="question__radioContainer"
                                        row
                                        value={question3}
                                        onChange={e => {
                                            setQuestion3(e.target.value);
                                            results[`${loop}-question3`] = e.target.value;
                                        }}
                                    >
                                        <p>
                                            <FormControlLabel
                                                value={"Yes"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"Yes"}
                                            />
                                        </p>
                                        <p>
                                            <FormControlLabel
                                                value={"No"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"No"}
                                            />
                                        </p>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </>
                    )}

                    {!question4 && question3 === "Yes" && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 4</h3>
                                <FormControl className="forms__removeMargin" component="fieldset">
                                    <FormLabel component="legend" className="question__label">
                                        <p>Have your symptoms worsened in such a degree to affect your daily activities than before?</p>
                                    </FormLabel>

                                    <RadioGroup
                                        className="question__radioContainer"
                                        row
                                        value={question4}
                                        onChange={e => {
                                            setQuestion4(e.target.value);
                                            results[`${loop}-question4`] = e.target.value;
                                        }}
                                    >
                                        <p>
                                            <FormControlLabel
                                                value={"Yes"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"Yes"}
                                            />
                                        </p>
                                        <p>
                                            <FormControlLabel
                                                value={"No"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"No"}
                                            />
                                        </p>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </>
                    )}

                    {!question5Completed && question3 === "No" && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 5</h3>

                                <p style={{ paddingBottom: 15, fontSize: "1rem" }}>Please describe below the medical condition:</p>

                                <TextField
                                    className="forms__removeMargin"
                                    variant="outlined"
                                    label=""
                                    rows={5}
                                    multiline
                                    value={question5}
                                    onChange={e => {
                                        setQuestion5(e.target.value);
                                        results[`${loop}-question5`] = e.target.value;
                                    }}
                                    fullWidth
                                />
                            </div>

                            <div className="centered__parent" style={{ padding: 10 }}>
                                <Button className="question__buttons--next" variant="contained" onClick={() => setQuestion5Completed(true)}>
                                    <>
                                        <h6 className="question__buttons--text">
                                            Next <FaGreaterThan />
                                        </h6>
                                    </>
                                </Button>
                            </div>
                        </>
                    )}

                    {!question6 && (question4 || question5Completed) && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 6</h3>

                                <p style={{ paddingBottom: 15, fontSize: "1rem" }}>
                                    When did this health problem begin (or worsen in severity or frequency if not new)?
                                </p>

                                <div className="forms__date">
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            openTo="year"
                                            disableFuture
                                            views={["day"]}
                                            // label="Custom date"
                                            inputFormat="DD/MM/YYYY"
                                            value={question6}
                                            onChange={value => {
                                                setQuestion6(value);
                                                results[`${loop}-question6`] = value;
                                            }}
                                            renderInput={(params: any) => (
                                                <TextField fullWidth variant="outlined" style={{ marginBottom: 10 }} {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </>
                    )}

                    {!question7 && question6 && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 7</h3>
                                <FormControl className="forms__removeMargin" component="fieldset">
                                    <FormLabel component="legend" className="question__label">
                                        <p>Is this health problem ongoing?</p>
                                    </FormLabel>

                                    <RadioGroup
                                        className="question__radioContainer"
                                        row
                                        value={question7}
                                        onChange={e => {
                                            setQuestion7(e.target.value);
                                            results[`${loop}-question7`] = e.target.value;
                                        }}
                                    >
                                        <p>
                                            <FormControlLabel
                                                value={"Yes"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"Yes"}
                                            />
                                        </p>
                                        <p>
                                            <FormControlLabel
                                                value={"No"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"No"}
                                            />
                                        </p>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </>
                    )}

                    {!question8 && question7 === "No" && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 8</h3>

                                <p style={{ paddingBottom: 15, fontSize: "1rem" }}>When did this health problem resolve?</p>

                                <div className="forms__date">
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            openTo="year"
                                            disableFuture
                                            views={["day"]}
                                            // label="Custom date"
                                            inputFormat="DD/MM/YYYY"
                                            value={question8}
                                            onChange={value => {
                                                setQuestion8(value);
                                                results[`${loop}-question8`] = value;
                                            }}
                                            renderInput={(params: any) => (
                                                <TextField fullWidth variant="outlined" style={{ marginBottom: 10 }} {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </>
                    )}

                    {question7 === "Yes" && (
                        <>
                            {!question9a && (
                                <>
                                    {/* <hr color="#4ECBD3" /> */}

                                    <div>
                                        <h3 className="question__title">Question 9A</h3>
                                        <FormControl className="forms__removeMargin" component="fieldset">
                                            <FormLabel component="legend" className="question__label">
                                                <p>Are you taking or did you take any medication to treat your medical condition?</p>
                                            </FormLabel>

                                            <RadioGroup
                                                className="question__radioContainer"
                                                row
                                                value={question9a}
                                                onChange={e => {
                                                    setQuestion9a(e.target.value);
                                                    results[`${loop}-question9a`] = e.target.value;
                                                }}
                                            >
                                                <p>
                                                    <FormControlLabel
                                                        value={"Yes"}
                                                        control={
                                                            <Radio
                                                                className="question__radio"
                                                                onClick={() => {
                                                                    // setAnswer(i + 1);
                                                                }}
                                                            />
                                                        }
                                                        label={"Yes"}
                                                    />
                                                </p>
                                                <p>
                                                    <FormControlLabel
                                                        value={"No"}
                                                        control={
                                                            <Radio
                                                                className="question__radio"
                                                                onClick={() => {
                                                                    // setAnswer(i + 1);
                                                                }}
                                                            />
                                                        }
                                                        label={"No"}
                                                    />
                                                </p>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </>
                            )}

                            {!question9bCompleted && question9a === "Yes" && (
                                <>
                                    {/* <hr color="#4ECBD3" /> */}

                                    <div>
                                        <h3 className="question__title">Question 9B</h3>
                                        <p style={{ paddingBottom: 15, fontSize: "1rem" }}>Please provide the medications you are/were taking:</p>

                                        <TextField
                                            className="forms__removeMargin"
                                            variant="outlined"
                                            label=""
                                            rows={5}
                                            multiline
                                            value={question9b}
                                            onChange={e => {
                                                setQuestion9b(e.target.value);
                                                results[`${loop}-question9b`] = e.target.value;
                                            }}
                                            fullWidth
                                        />
                                    </div>

                                    <div className="centered__parent" style={{ padding: 10 }}>
                                        <Button className="question__buttons--next" variant="contained" onClick={() => setQuestion9bCompleted(true)}>
                                            <>
                                                <h6 className="question__buttons--text">
                                                    Next <FaGreaterThan />
                                                </h6>
                                            </>
                                        </Button>
                                    </div>
                                </>
                            )}

                            {!question9c && question9a === "No" && (
                                <>
                                    {/* <hr color="#4ECBD3" /> */}

                                    <div>
                                        <h3 className="question__title">Question 9C</h3>
                                        <FormControl className="forms__removeMargin" component="fieldset">
                                            <FormLabel component="legend" className="question__label">
                                                <p>Did you seek medical attention for the current medical condition?</p>
                                            </FormLabel>

                                            <RadioGroup
                                                className="question__radioContainer"
                                                row
                                                value={question9c}
                                                onChange={e => {
                                                    setQuestion9c(e.target.value);
                                                    results[`${loop}-question9c`] = e.target.value;
                                                }}
                                            >
                                                <p>
                                                    <FormControlLabel
                                                        value={"Yes"}
                                                        control={
                                                            <Radio
                                                                className="question__radio"
                                                                onClick={() => {
                                                                    // setAnswer(i + 1);
                                                                }}
                                                            />
                                                        }
                                                        label={"Yes"}
                                                    />
                                                </p>
                                                <p>
                                                    <FormControlLabel
                                                        value={"No"}
                                                        control={
                                                            <Radio
                                                                className="question__radio"
                                                                onClick={() => {
                                                                    // setAnswer(i + 1);
                                                                }}
                                                            />
                                                        }
                                                        label={"No"}
                                                    />
                                                </p>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </>
                            )}

                            {!question9d && (question9bCompleted || question9c) && (
                                <>
                                    {/* <hr color="#4ECBD3" /> */}

                                    <div>
                                        <h3 className="question__title">Question 9D</h3>
                                        <FormControl className="forms__removeMargin" component="fieldset">
                                            <FormLabel component="legend" className="question__label">
                                                <p>Did you go trough any medical procedure due to your current health problem?</p>
                                            </FormLabel>

                                            <RadioGroup
                                                className="question__radioContainer"
                                                row
                                                value={question9d}
                                                onChange={e => {
                                                    setQuestion9d(e.target.value);
                                                    results[`${loop}-question9d`] = e.target.value;
                                                }}
                                            >
                                                <p>
                                                    <FormControlLabel
                                                        value={"Yes"}
                                                        control={
                                                            <Radio
                                                                className="question__radio"
                                                                onClick={() => {
                                                                    // setAnswer(i + 1);
                                                                }}
                                                            />
                                                        }
                                                        label={"Yes"}
                                                    />
                                                </p>
                                                <p>
                                                    <FormControlLabel
                                                        value={"No"}
                                                        control={
                                                            <Radio
                                                                className="question__radio"
                                                                onClick={() => {
                                                                    // setAnswer(i + 1);
                                                                }}
                                                            />
                                                        }
                                                        label={"No"}
                                                    />
                                                </p>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </>
                            )}

                            {!question9eCompleted && question9d === "Yes" && (
                                <>
                                    {/* <hr color="#4ECBD3" /> */}
                                    <div>
                                        <h3 className="question__title">Question 9E</h3>
                                        <p style={{ paddingBottom: 15, fontSize: "1rem" }}>Please Provide details:</p>

                                        <TextField
                                            className="forms__removeMargin"
                                            variant="outlined"
                                            label=""
                                            rows={5}
                                            multiline
                                            value={question9e}
                                            onChange={e => {
                                                setQuestion9e(e.target.value);
                                                results[`${loop}-question9e`] = e.target.value;
                                            }}
                                            fullWidth
                                        />

                                        <div className="centered__parent" style={{ padding: 10 }}>
                                            <Button
                                                className="question__buttons--next"
                                                variant="contained"
                                                onClick={() => setQuestion9eCompleted(true)}
                                            >
                                                <>
                                                    <h6 className="question__buttons--text">
                                                        Next <FaGreaterThan />
                                                    </h6>
                                                </>
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}

                            {!question9f && (question9d === "No" || question9eCompleted) && (
                                <>
                                    {/* <hr color="#4ECBD3" /> */}

                                    <div>
                                        <h3 className="question__title">Question 9F</h3>
                                        <FormControl className="forms__removeMargin" component="fieldset">
                                            <FormLabel component="legend" className="question__label">
                                                <p>Are you or were you hospitalized due to your current health problem?</p>
                                            </FormLabel>

                                            <RadioGroup
                                                className="question__radioContainer"
                                                row
                                                value={question9f}
                                                onChange={e => {
                                                    setQuestion9f(e.target.value);
                                                    results[`${loop}-question9f`] = e.target.value;
                                                }}
                                            >
                                                <p>
                                                    <FormControlLabel
                                                        value={"Yes"}
                                                        control={
                                                            <Radio
                                                                className="question__radio"
                                                                onClick={() => {
                                                                    // setAnswer(i + 1);
                                                                }}
                                                            />
                                                        }
                                                        label={"Yes"}
                                                    />
                                                </p>
                                                <p>
                                                    <FormControlLabel
                                                        value={"No"}
                                                        control={
                                                            <Radio
                                                                className="question__radio"
                                                                onClick={() => {
                                                                    // setAnswer(i + 1);
                                                                }}
                                                            />
                                                        }
                                                        label={"No"}
                                                    />
                                                </p>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    {!question10a && question9f === "Yes" && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 10A</h3>
                                <p style={{ paddingBottom: 15, fontSize: "1rem" }}>When did you hospitalization start?</p>

                                <div className="forms__date">
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            openTo="year"
                                            disableFuture
                                            views={["day"]}
                                            // label="Custom date"
                                            inputFormat="DD/MM/YYYY"
                                            value={question10a}
                                            onChange={value => {
                                                setQuestion10a(value);
                                                results[`${loop}-question10a`] = value;
                                            }}
                                            renderInput={(params: any) => (
                                                <TextField fullWidth variant="outlined" style={{ marginBottom: 10 }} {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </>
                    )}

                    {!question10b && question10a && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 10B</h3>
                                <FormControl className="forms__removeMargin" component="fieldset">
                                    <FormLabel component="legend" className="question__label">
                                        <p>Are you still hospitalized?</p>
                                    </FormLabel>

                                    <RadioGroup
                                        className="question__radioContainer"
                                        row
                                        value={question10b}
                                        onChange={e => {
                                            setQuestion10b(e.target.value);
                                            results[`${loop}-question10b`] = e.target.value;
                                        }}
                                    >
                                        <p>
                                            <FormControlLabel
                                                value={"Yes"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"Yes"}
                                            />
                                        </p>
                                        <p>
                                            <FormControlLabel
                                                value={"No"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"No"}
                                            />
                                        </p>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </>
                    )}

                    {!question10c && question10b === "No" && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 10C</h3>
                                <p style={{ paddingBottom: 15, fontSize: "1rem" }}>When was you discharged from the hospital?</p>

                                <div className="forms__date">
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            openTo="year"
                                            disableFuture
                                            views={["day"]}
                                            // label="Custom date"
                                            inputFormat="DD/MM/YYYY"
                                            value={question10c}
                                            onChange={value => {
                                                setQuestion10c(value);
                                                results[`${loop}-question10c`] = value;
                                            }}
                                            renderInput={(params: any) => (
                                                <TextField fullWidth variant="outlined" style={{ marginBottom: 10 }} {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </>
                    )}

                    {!question11 && (question10b === "Yes" || question10c) && (
                        <>
                            {/* <hr color="#4ECBD3" /> */}

                            <div>
                                <h3 className="question__title">Question 11</h3>
                                <FormControl className="forms__removeMargin" component="fieldset">
                                    <FormLabel component="legend" className="question__label">
                                        <p>Have you experienced ANY OTHER health problems/hospitalization/new diagnosis within the last month?</p>
                                    </FormLabel>

                                    <RadioGroup
                                        className="question__radioContainer"
                                        row
                                        value={question11}
                                        onChange={e => {
                                            setQuestion11(e.target.value);
                                            results[`${loop}-question11`] = e.target.value;

                                            if (e.target.value === "Yes") {
                                                prepareNewLoop();
                                            }
                                        }}
                                    >
                                        <p>
                                            <FormControlLabel
                                                value={"Yes"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"Yes"}
                                            />
                                        </p>
                                        <p>
                                            <FormControlLabel
                                                value={"No"}
                                                control={
                                                    <Radio
                                                        className="question__radio"
                                                        onClick={() => {
                                                            // setAnswer(i + 1);
                                                        }}
                                                    />
                                                }
                                                label={"No"}
                                            />
                                        </p>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </>
                    )}

                    <Grid item container spacing={2} className="question__buttons__container">
                        <Grid item xs={12} className="question__buttons">
                            <Button className="cta__submit" fullWidth variant="contained" onClick={() => handleReset()}>
                                <h6>Reset</h6>
                            </Button>
                        </Grid>
                        <Grid item xs={12} className="question__buttons">
                            <Button className="cta__submit" fullWidth variant="contained" onClick={() => hist.goBack()}>
                                <h6>Close</h6>
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default HealthStatus;
