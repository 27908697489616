import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

// Firebase
import { firestore } from "../../../../../../firebase/firebase";
import { doc, updateDoc, getDoc, addDoc, collection, getDocs, where, query, orderBy } from "firebase/firestore";

// Mui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Utils
import { genDoc } from "../../../../../../utils/firebase";
import { getBackgroundColor, getFontColor } from "../../../../../../utils/general";

// Interfaces
import { Participant } from "../../../../../../interfaces/Participant";
import { File } from "../../../../../../interfaces/File";

// Hooks
import { useFetchUser } from "../../../../../../hooks/useFetchUser";

// Custom components
import Title from "../../../../../general/Title";
import FileLegend from "../../../../../general/FileLegend";

interface Params {
    id: string;
}

const Data: React.FC = () => {
    const { id } = useParams<Params>();
    const hist = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { currentAdmin } = useFetchUser();

    // States
    const [allFiles, setAllFiles] = useState<File[]>([]);
    const [participantFiles, setParticipantFiles] = useState<File[]>([]);
    const [content, setContent] = useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [participant, setParticipant] = useState<Participant | null>(null);

    const handleSave = async () => {
        if (participant) {
            await updateDoc(doc(firestore, "Participants", id), {
                ...participant,
                sleep: {
                    ...participant.sleep,
                    content,
                    files,
                    dates: [...participant.sleep.dates],
                },
            });
        }

        const logPayload = await addDoc(collection(firestore, "Logs"), {
            createdAt: new Date(),
            updatedAt: new Date(),
            isDeleted: false,
            adminId: currentAdmin && currentAdmin.id ? currentAdmin.id : "",
            reason: `Updated participant ${participant && (participant as any).email ? (participant as any).email : (participant as any).id}'s sleep`,
        });

        await updateDoc(doc(firestore, "Logs", logPayload.id), { id: logPayload.id });

        enqueueSnackbar("Participant updated", { variant: "success" });
    };

    const fetchAllFiles = async () => {
        const localQuery = query(
            collection(firestore, "Files"),
            where("topic", "==", "Sleep"),
            where("isDeleted", "==", false),
            orderBy("createdAt", "desc")
        );
        const payload = await getDocs(localQuery);
        const localFiles = payload.docs.map(genDoc<File>());

        setAllFiles(localFiles ?? []);
    };

    const fetchParticipantFiles = async () => {
        if (participant && participant.id) {
            const localQuery = query(
                collection(firestore, "Participants", participant.id, "Files"),
                where("topic", "==", "Sleep"),
                where("isDeleted", "==", false),
                orderBy("createdAt", "desc")
            );
            const payload = await getDocs(localQuery);
            const localFiles = payload.docs.map(genDoc<File>());

            setParticipantFiles(localFiles ?? []);
        }
    };

    const fetchParticipant = async () => {
        const payload = await getDoc(doc(firestore, "Participants", id));
        const localParticipant = genDoc<Participant>()(payload);
        setParticipant(localParticipant);
    };

    useEffect(() => {
        if (participant) {
            fetchParticipantFiles();

            setContent(participant.sleep.content);
            setFiles(participant.sleep.files);
        }
    }, [participant]);

    useEffect(() => {
        fetchParticipant();
    }, [id]);

    useEffect(() => {
        fetchAllFiles();
    }, []);

    return (
        <>
            <Grid item container>
                <Grid item xs={9}>
                    <Title>Interactive content</Title>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" style={{ float: "right" }} onClick={() => hist.push(`/dashboard/content/Sleep/Content/${id}`)}>
                        Add private content
                    </Button>
                </Grid>

                {!allFiles.filter(f => f.type === "Content").length && !participantFiles.filter(f => f.type === "Content").length && (
                    <div style={{ marginLeft: 10 }}>No media</div>
                )}

                {allFiles
                    .filter(f => f.type === "Content")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: getBackgroundColor(true, true, data, content, files),
                                color: getFontColor(true, true, data, content, files),
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (content.some((c: any) => c === data.id)) {
                                    // Remove
                                    setContent((prev: any) => prev.filter((c: any) => c !== data.id));
                                } else {
                                    // Add
                                    setContent((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}

                {participantFiles
                    .filter(f => f.type === "Content")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: getBackgroundColor(false, true, data, content, files),
                                color: getFontColor(false, true, data, content, files),
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (content.some((f: any) => f === data.id)) {
                                    // Remove
                                    setContent((prev: any) => prev.filter((f: any) => f !== data.id));
                                } else {
                                    // Add
                                    setContent((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}
            </Grid>
            <br />
            <Grid item container>
                <Grid item xs={10}>
                    <Title>Files</Title>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" style={{ float: "right" }} onClick={() => hist.push(`/dashboard/content/Sleep/File/${id}`)}>
                        Add private file
                    </Button>
                </Grid>
                {!allFiles.filter(f => f.type === "File") ? (
                    <div style={{ marginLeft: 10 }}>No file</div>
                ) : (
                    allFiles
                        .filter(f => f.type === "File")
                        .map((data, i) => (
                            <Grid
                                key={i}
                                item
                                xs={2}
                                style={{
                                    border: "1px solid black",
                                    cursor: "pointer",
                                    backgroundColor: getBackgroundColor(true, false, data, content, files),
                                    color: getFontColor(true, false, data, content, files),
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    margin: 5,
                                }}
                                className="centered__parent"
                                onClick={() => {
                                    if (files.some((f: any) => f === data.id)) {
                                        // Remove
                                        setFiles((prev: any) => prev.filter((f: any) => f !== data.id));
                                    } else {
                                        // Add
                                        setFiles((prev: any) => [...prev, data.id]);
                                    }
                                }}
                            >
                                <div>{data.title}</div>
                            </Grid>
                        ))
                )}
                {participantFiles
                    .filter(f => f.type === "File")
                    .map((data, i) => (
                        <Grid
                            key={i}
                            item
                            xs={2}
                            style={{
                                border: "1px solid black",
                                cursor: "pointer",
                                backgroundColor: getBackgroundColor(false, false, data, content, files),
                                color: getFontColor(false, false, data, content, files),
                                paddingBottom: 10,
                                paddingTop: 10,
                                margin: 5,
                            }}
                            className="centered__parent"
                            onClick={() => {
                                if (files.some((f: any) => f === data.id)) {
                                    // Remove
                                    setFiles((prev: any) => prev.filter((f: any) => f !== data.id));
                                } else {
                                    // Add
                                    setFiles((prev: any) => [...prev, data.id]);
                                }
                            }}
                        >
                            <div>{data.title}</div>
                        </Grid>
                    ))}
            </Grid>

            <FileLegend />

            <Button variant="contained" style={{ marginTop: 40 }} onClick={handleSave}>
                Save
            </Button>
        </>
    );
};

export default Data;
