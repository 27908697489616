import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import ResponsiveEmbed from "react-responsive-embed";

// Mui
import Button from "@mui/material/Button";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import { genDoc } from "../../../utils/firebase";

// Interfaces
import { File } from "../../../interfaces/File";

interface Params {
    id: string;
    participantId: string;
}

const VideoViewer: React.FC = () => {
    const { id, participantId } = useParams<Params>();
    const hist = useHistory();

    // States
    const [file, setFile] = useState<File | null>(null);

    const fetchFile = async () => {
        const payload = await getDoc(doc(firestore, "Files", id));
        let localFile = genDoc<File>()(payload);

        if (!localFile) {
            const payload = await getDoc(doc(firestore, "Participants", participantId, "Files", id));
            localFile = genDoc<File>()(payload);
        }

        setFile(localFile);
    };

    useEffect(() => {
        fetchFile();
    }, []);

    return (
        <>
            {file && <ResponsiveEmbed src={file.url} ratio="16:9" />}
            <Button className="cta__submit" variant="contained" fullWidth onClick={() => hist.goBack()} style={{ marginTop: 20 }}>
                Close
            </Button>
        </>
    );
};

export default VideoViewer;
