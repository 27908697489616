// Route props
import { CustomRouteProps } from "../interfaces/CustomRouteProps";

// Components
import BaseLayout from "../components/layout/base/BaseLayout";
import PortalLayout from "../components/layout/portalLayout/PortalLayout";
import ParticipantLogin from "../components/pages/portal/ParticipantLogin";
import Home from "../components/pages/portal/Home";
import Unauthorized from "../components/pages/Unauthorized";
import PhysicalExercise from "../components/pages/portal/PhysicalExercise";
import CognitiveTraining from "../components/pages/portal/CognitiveTraining";
import Diet from "../components/pages/portal/Diet";
import Health from "../components/pages/portal/Health";
import Sleep from "../components/pages/portal/Sleep";
import Help from "../components/pages/portal/Help";
import Survey from "../components/pages/portal/Survey";
import Profile from "../components/pages/portal/Profile";
import FormTest from "../components/pages/portal/questionnaires/FormTest";
import VideoViewer from "../components/pages/portal/VideoViewer";

const Routes: Array<CustomRouteProps> = [
    {
        Component: Unauthorized,
        path: "/unauthorized",
        exact: true,
        title: "Unauthorized",
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: false,
    },
    {
        Component: ParticipantLogin,
        path: "/login",
        exact: true,
        title: "Login",
        LayoutComponent: BaseLayout,
        rolesAllowed: [],
        isPrivate: false,
    },
    {
        Component: Home,
        path: "/",
        exact: true,
        title: "Home",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: PhysicalExercise,
        path: "/physicalExercise",
        exact: true,
        title: "Physical Exercise",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: PhysicalExercise,
        path: "/physicalExercise/:date",
        exact: false,
        title: "Physical Exercise",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: CognitiveTraining,
        path: "/cognitiveTraining",
        exact: true,
        title: "Cognitive Training",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Diet,
        path: "/diet",
        exact: true,
        title: "Diet",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Health,
        path: "/health",
        exact: true,
        title: "Health",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Sleep,
        path: "/sleep",
        exact: true,
        title: "Sleep",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Help,
        path: "/help",
        exact: true,
        title: "Help",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Survey,
        path: "/survey/:questionnaireName",
        exact: false,
        title: "Complete Survey",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: Profile,
        path: "/profile",
        exact: true,
        title: "Profile",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: VideoViewer,
        path: "/videoViewer/:id/:participantId",
        exact: false,
        title: "Video Viewer",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
    {
        Component: FormTest,
        path: "/formTest",
        exact: true,
        title: "Form Test",
        LayoutComponent: PortalLayout,
        rolesAllowed: [],
        isPrivate: true,
    },
];

export default Routes;
