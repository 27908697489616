import React from "react";
import { useHistory } from "react-router-dom";

// Mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

// Assets
import cognitiveLogo from "../../../../assets/images/logos/cognitiveTrainingLogo.png";
import dietLogo from "../../../../assets/images/logos/dietLogo.png";
import healthLogo from "../../../../assets/images/logos/healthLogo.png";
import physicalExerciseLogo from "../../../../assets/images/logos/physicalExerciseLogo.png";
import sleepLogo from "../../../../assets/images/logos/sleepLogo.png";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";
import { Button } from "@mui/material";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
    open: boolean;
    // eslint-disable-next-line
    setOpen(value: boolean): void;
    topic: string;
}

// eslint-disable-next-line
const SurveyDialog: React.FC<Props> = ({ open, setOpen, topic }) => {
    const { currentParticipant } = useFetchUser();
    const hist = useHistory();

    const Renderquestionnaires: React.FC = () => {
        return (
            <>
                {currentParticipant &&
                    (!currentParticipant.questionnaires || (currentParticipant.questionnaires && !currentParticipant.questionnaires.length)) && (
                        <p>None</p>
                    )}

                {/* Physical */}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "lawtonBrodyIADL") && (
                        <div className="surveyDialog__item">
                            <img src={physicalExerciseLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/lawtonBrodyIADL")}>Lawton Brody IADL</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "fallsAndBalance") && (
                        <div className="surveyDialog__item">
                            <img src={physicalExerciseLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/fallsAndBalance")}>Falls And Balance</p>
                        </div>
                    )}
                {currentParticipant && currentParticipant.questionnaires && currentParticipant.questionnaires.some(q => q.name === "womenHealth") && (
                    <div className="surveyDialog__item">
                        <img src={physicalExerciseLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                        <p onClick={() => hist.push("/survey/womenHealth")}>Women Health</p>
                    </div>
                )}
                {/* Sleep */}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "insomniaSeverityIndex") && (
                        <div className="surveyDialog__item">
                            <img src={sleepLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/insomniaSeverityIndex")}>Insomnia Severity Index</p>
                        </div>
                    )}
                {/* Diet */}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "mediterraneanDietAdherence") && (
                        <div className="surveyDialog__item">
                            <img src={dietLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/mediterraneanDietAdherence")}>Mediterranean Diet Adherence</p>
                        </div>
                    )}
                {currentParticipant && currentParticipant.questionnaires && currentParticipant.questionnaires.some(q => q.name === "dietHistory") && (
                    <div className="surveyDialog__item">
                        <img src={dietLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                        <p onClick={() => hist.push("/survey/dietHistory")}>Diet History</p>
                    </div>
                )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "eatingPattern") && (
                        <div className="surveyDialog__item">
                            <img src={dietLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/eatingPattern")}>Eating Pattern</p>
                        </div>
                    )}

                {/* Health */}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "healthResourceUtilization") && (
                        <div className="surveyDialog__item">
                            <img src={healthLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/healthResourceUtilization")}>Health Resource Utilization</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "qualityOfLife") && (
                        <div className="surveyDialog__item">
                            <img src={healthLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/qualityOfLife")}>Quality of life</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "qualityOfLife") && (
                        <div className="surveyDialog__item">
                            <img src={healthLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/healthUtilityIndex")}>Health Utility Index</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "qualityOfLife") && (
                        <div className="surveyDialog__item">
                            <img src={healthLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/healthBehaviorsQuestionnaire")}>Health Behaviors Questionnaire</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "healthStatus") && (
                        <div className="surveyDialog__item">
                            <img src={healthLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/healthStatus")}>Health Status Questionnaire</p>
                        </div>
                    )}

                {/* Cognitive */}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "preCognitiveExpectancy") && (
                        <div className="surveyDialog__item">
                            <img src={cognitiveLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/preCognitiveExpectancy")}>Pre-Cognitive Expectancy</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "postCognitiveExpectancy") && (
                        <div className="surveyDialog__item">
                            <img src={cognitiveLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/postCognitiveExpectancy")}>Post-Cognitive Expectancy</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "generalizedAnxietyDisorder") && (
                        <div className="surveyDialog__item">
                            <img src={cognitiveLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/generalizedAnxietyDisorder")}>Generalized Anxiety Disorder</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "geriatricDepressionScale") && (
                        <div className="surveyDialog__item">
                            <img src={cognitiveLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/geriatricDepressionScale")}>Geriatric Depression Scale</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "revisedHearingHandicapInventory") && (
                        <div className="surveyDialog__item">
                            <img src={cognitiveLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/revisedHearingHandicapInventory")}>Revised Hearing Handicap Inventory</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "visionHearing") && (
                        <div className="surveyDialog__item">
                            <img src={cognitiveLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/visionHearing")}>Vision and Hearing</p>
                        </div>
                    )}
                {currentParticipant &&
                    currentParticipant.questionnaires &&
                    currentParticipant.questionnaires.some(q => q.name === "bhproQuestionnaires") && (
                        <div className="surveyDialog__item">
                            <img src={cognitiveLogo} alt="icon" className="surveyDialog__logo nofilterimg" />
                            <p onClick={() => hist.push("/survey/bhproQuestionnaires")}>BHP pro questionnaires</p>
                        </div>
                    )}
            </>
        );
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
            className="surveyDialog"
        >
            <DialogTitle>Questionnaires available</DialogTitle>
            <DialogContent>
                <Renderquestionnaires />
            </DialogContent>
            <DialogActions>
                {/* <Grid container>
                <Grid item xs={12} onClick={() => setOpen(false)} className="home__options__card">
                    <h4 className="home__options__title">Close</h4>
                    <IoChevronBack className="home__options__image" />
                    <IoChevronBack className="home__options__background" />
                </Grid>
            </Grid> */}
                <Button fullWidth className="cta__submit" onClick={() => setOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SurveyDialog;
