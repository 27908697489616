import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";

// Custom component
import SleepDialog from "./SleepDialog";

// React-dates
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DayPicker, CalendarDay } from "react-dates";

// Mui
import Tooltip from "@mui/material/Tooltip";

// Interfaces
import { Participant } from "../../interfaces/Participant";

interface Props {
    participant: Participant;
}

const SleepCalendar: React.FC<Props> = ({ participant }) => {
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [currentMonth, setCurrentMonth] = useState(-1);
    const [currentYear, setCurrentYear] = useState(-1);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (selectedDate) setOpen(true);
    }, [selectedDate]);

    useEffect(() => {
        if (!open) setSelectedDate(null);
    }, [open]);

    useEffect(() => {
        setCurrentMonth(moment(new Date()).month());
        setCurrentYear(moment(new Date()).year());
    }, []);

    if (participant) {
        return (
            <>
                <DayPicker
                    numberOfMonths={1}
                    hideKeyboardShortcutsPanel
                    onDayClick={(date: any) => {
                        if (
                            participant.sleep.dates.some((e: any) => moment(e.date.seconds * 1000).format("DD/MM/YYYY") === date.format("DD/MM/YYYY"))
                        ) {
                            return enqueueSnackbar("Already completed summary", { variant: "error" });
                        } else if (moment(date).format("DD/MM/YY") === moment(new Date()).format("DD/MM/YY")) {
                            return enqueueSnackbar("Can't set today's sleep summary", { variant: "error" });
                        } else if (moment(date) > moment(new Date())) {
                            return enqueueSnackbar("Can't set future sleep summary", { variant: "error" });
                        }
                        setSelectedDate(date);
                    }}
                    onPrevMonthClick={date => {
                        setCurrentMonth(date.month());
                        setCurrentYear(date.year());
                    }}
                    onNextMonthClick={date => {
                        setCurrentMonth(date.month());
                        setCurrentYear(date.year());
                    }}
                    disableNext={moment(new Date()).month() === currentMonth && moment(new Date()).year() === currentYear}
                    renderCalendarInfo={() => {
                        return <div style={{ color: "black", padding: 5, fontSize: 15 }}></div>;
                    }}
                    renderCalendarDay={props => {
                        const { day } = props;
                        let { modifiers } = props;

                        if (!modifiers) modifiers = new Set<string>();

                        if (day?.format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY")) {
                            modifiers.add("selected-span");
                        }

                        return <CalendarDay {...props} modifiers={modifiers} />;
                    }}
                    daySize={50}
                    renderDayContents={day => {
                        const today = new Date();
                        today.setHours(0, 0, 0);
                        const isToday = moment(today).format("DD/MM/YYYY") === day.format("DD/MM/YYYY");

                        // Handle event per day
                        const isDailyTaskCompleted = participant.sleep.dates.some(event => {
                            const formattedDay = day.format("DD/MM/YYYY");
                            const formattedEvent = moment((event as any).date.seconds * 1000).format("DD/MM/YYYY");

                            if (formattedDay === formattedEvent) return true;
                            return false;
                        });

                        if (isToday) {
                            return (
                                <Tooltip title="Today">
                                    <span style={{ color: "lightGray", fontWeight: "bold", fontSize: 25 }}>{day.format("D")}</span>
                                </Tooltip>
                            );
                        } else if (isDailyTaskCompleted) {
                            return (
                                <Tooltip title="Completed sleep summary">
                                    <span style={{ color: "lawngreen", fontWeight: "bold", fontSize: 25 }}>{day.format("D")}</span>
                                </Tooltip>
                            );
                        } else {
                            if (day > moment(today))
                                return (
                                    <Tooltip title="Future date">
                                        <span style={{ color: "lightGray", fontSize: 25 }}>{day.format("D")}</span>
                                    </Tooltip>
                                );
                            else
                                return (
                                    <Tooltip title="Summary ready to complete">
                                        <span style={{ color: "red", fontWeight: "bold", fontSize: 25 }}>{day.format("D")}</span>
                                    </Tooltip>
                                );
                        }
                    }}
                />

                {open && <SleepDialog open={open} setOpen={setOpen} selectedDate={selectedDate} events={participant.sleep.dates} />}
            </>
        );
    }

    return <></>;
};

export default SleepCalendar;
