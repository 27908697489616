import { createRef, useEffect } from "react";
import { SnackbarProvider } from "notistack";

// Mui
import { ThemeProvider } from "@mui/material/styles";

// Router
import Router from "./routes/router";

// Settings
import versionObj from "./version.json";

// styles
import "./scss/App.scss";

// Theme
import theme from "./theme";

function App() {
    console.log(`%cVersion: ${versionObj && versionObj.version}`, "color: #bada55");

    const snackbarRef: any = createRef();

    // set theme if present in localstorage
    useEffect(() => {
        const divColor = (document.getElementById("color") as any).classList;
        const theme = localStorage.getItem("theme");
        if (theme) {
            if (divColor.contains("normal")) {
                divColor.remove("normal");
            } else if (divColor.contains("inverse")) {
                divColor.remove("inverse");
            }
            divColor.add(theme);
        } else {
            localStorage.setItem("theme", "normal");
        }
    }, []);

    // set font size if present in localstorage
    useEffect(() => {
        const divFont = (document.getElementById("font") as any).classList;
        const font = localStorage.getItem("font");
        if (font) {
            if (divFont.contains("font1")) {
                divFont.remove("font2");
                divFont.remove("font3");
            } else if (divFont.contains("font2")) {
                divFont.remove("font1");
                divFont.remove("font3");
            } else if (divFont.contains("font3")) {
                divFont.remove("font1");
                divFont.remove("font2");
            }
            divFont.add(font);
        } else {
            localStorage.setItem("font", "font1");
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                style={{ margin: 2 }}
                ref={snackbarRef}
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                autoHideDuration={4000}
            >
                <Router />
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
