import React from "react";

// Mui
import Grid from "@mui/material/Grid";

interface Props {
    title: string;
    icon: string;
    // eslint-disable-next-line
    onClick: (ev: any) => void;
}

const FileCard: React.FC<Props> = ({ title, icon, onClick }) => {
    return (
        <div className="fc">
            <Grid container item onClick={onClick} className="fc__container">
                <Grid item xs={11} className="fc__titleContainer">
                    <h6>{title}</h6>
                </Grid>
                <Grid item xs={1} className="fc__imageContainer">
                    <img src={icon} alt={`${title}-image`} className="fc__image nofilterimg" />
                </Grid>
            </Grid>
        </div>
    );
};

export default FileCard;
