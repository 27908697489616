import moment from "moment";
import "moment/locale/en-ca";

// Locale
moment.locale("en-ca");

export const formatDateTimeStripe = (datetime: Date) => {
    return moment(datetime).format("MM/DD/YY HH:mm");
};

export const formatDateTime = (datetime: Date) => {
    return moment(datetime).format("LLL");
};

export const formatDate = (datetime: Date) => moment(datetime).format("MM/DD/YYYY");

export const formatDateIntoElapsedTimeEnglish = (date: Date) => {
    if (typeof date == "number") {
        const timestamp: any = date;
        const timestampInMilliseconds = timestamp;
        const newDate = new Date(timestampInMilliseconds);
        date = newDate;
    }
    const MINUTE_IN_SECONDS = 60;
    const HOUR_IN_SECONDS = 360;
    const DAY_IN_SECONDS = 86400;

    const now = Date.now();
    const diff = now - date.getTime();
    const diffSeconds = diff / 1000;

    if (diffSeconds < MINUTE_IN_SECONDS) {
        return `${Math.ceil(diffSeconds)} second${Math.ceil(diffSeconds) > 1 ? "s" : ""} ago`;
    } else if (diffSeconds < HOUR_IN_SECONDS) {
        return `${Math.ceil(diffSeconds / 60)} minute${Math.ceil(diffSeconds / 60) > 1 ? "s" : ""} ago`;
    } else if (diffSeconds < DAY_IN_SECONDS) {
        return `${Math.ceil(diffSeconds / 3600)} hour${Math.ceil(diffSeconds / 3600) > 1 ? "s" : ""} ago`;
    } else {
        return formatDateTime(date);
    }
};

export const convertObjectIntoDate = (object: any) => {
    try {
        if (object) {
            if (typeof object === "string" || typeof object === "number") {
                return new Date(object);
            }

            if (moment(object).isValid()) {
                return moment(object).toDate();
            }

            if (typeof object === "object") {
                return new Date(object.seconds * 1000);
            }

            throw new Error("Can't convert object into date");
        }

        throw new Error("Invalid object");
    } catch (e) {
        console.error(e);
        return e;
    }
};

export const differenceInMinutesBetween2Dates = (date1: Date, date2: Date) => {
    const diff = date1.getTime() - date2.getTime();

    return Math.abs(Math.floor(diff / 1000 / 60));
};

export const differenceInHoursBetween2Dates = (date1: Date, date2: Date) => {
    const diff = date1.getTime() - date2.getTime();

    return Math.abs(Math.floor(diff / 1000 / 60 / 60));
};

export const differenceInDaysBetween2Dates = (date1: Date, date2: Date) => {
    const diff = date1.getTime() - date2.getTime();

    return Math.abs(Math.floor(diff / 1000 / 60 / 60 / 24));
};

export const getTimeBetween2Dates = (date1: any, date2: any) => {
    let diffInMilliSeconds = Math.abs(date2 - date1) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate seconds
    const seconds = Math.floor(diffInMilliSeconds) % 60;
    diffInMilliSeconds -= seconds * 60;

    let difference = "";
    if (days > 0) difference += days === 1 ? `${days} day, ` : `${days} days, `;
    if (hours !== 0) difference += `${hours} hour${hours > 1 ? "s" : ""}, `;
    if (minutes !== 0) difference += `${minutes} minute${minutes > 1 ? "s" : ""}, `;
    difference += `${seconds} second${seconds > 1 ? "s" : ""}`;

    return difference;
};
