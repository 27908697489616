import React from "react";

// Mui
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Slider from "@mui/material/Slider";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// yup validation
const requiredMessage = "Required field";

const userSchema = yup.object({
    question1: yup.number().required(requiredMessage),
    question2: yup.date().required(requiredMessage),
    question3: yup.string().required(requiredMessage),
    question4: yup.date().required(requiredMessage),
    question5: yup.number().required(requiredMessage),
    question6: yup.number().required(requiredMessage),
    question7: yup.date().required(requiredMessage),
    question8: yup.date().required(requiredMessage),
    question9: yup.string().required(requiredMessage),
    question10: yup.number().required(requiredMessage),
});

interface Props {
    onComplete: any;
    setOpen: any;
}

const SleepDiary: React.FC<Props> = ({ onComplete, setOpen }) => {
    const defaultValues = {
        question1: 0,
        question2: new Date(),
        question3: "No",
        question4: new Date(),
        question5: 0,
        question6: 0,
        question7: new Date(),
        question8: new Date(),
        question9: "No",
        question10: 0,
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({ resolver: yupResolver(userSchema), defaultValues });

    const getFontClass = () => {
        const fontObj = localStorage.getItem("font");
        if (fontObj) return fontObj;
        return "";
    };

    const getColorClass = () => {
        const themeObj = localStorage.getItem("theme");
        if (themeObj) return themeObj;
        return "";
    };

    const onSubmit = async (data: any) => {
        const score = 0;

        // if (data.bedTime !== undefined) {
        //     // 1
        //     score += 0;
        // }
        // if (data.sleepTryTime !== undefined) {
        //     // 2
        //     score += 0;
        // }
        // if (data.fallAsleepDuration !== undefined) {
        //     // 3
        //     if (data.fallAsleepDuration < 31) score += 0;
        //     else score += 1;
        // }
        // if (data.wakeupCount !== undefined) {
        //     // 4
        //     score += 0;
        //     if (data.wakeupCount > 0 && data.awakeningDuration !== undefined) {
        //         // 5
        //         if (data.awakeningDuration < 31) score += 0;
        //         else score += 1;
        //     }
        // }
        // if (data.finalAwakeningTime !== undefined) {
        //     // 6a
        //     score += 0;
        // }
        // if (data.finalAwakeningDuration !== undefined) {
        //     // 6b
        //     if (data.finalAwakeningDuration < 31) score += 0;
        //     else score += 1;
        // }
        // if (data.wakeupEarlier !== undefined) {
        //     // 6c
        //     score += 0;
        //     if (data.wakeupEarlier === "Yes" && data.wakeupEarlierCount !== undefined) {
        //         // 6d
        //         score += 0;
        //     }
        // }
        // if (data.getupTime !== undefined) {
        //     // 7
        //     score += 0;
        // }
        // if (data.totalSleepCount !== undefined) {
        //     // 8
        //     if (data.totalSleepCount >= 6 && data.totalSleepCount <= 9) score += 1;
        //     else score += 0;
        // }
        // if (data.sleepQuality !== undefined) {
        //     // 9
        //     if (data.sleepQuality === "Very poor") score += 0;
        //     else if (data.sleepQuality === "Poor") score += 0.25;
        //     else if (data.sleepQuality === "Fair") score += 0.5;
        //     else if (data.sleepQuality === "Good") score += 0.75;
        //     else if (data.sleepQuality === "Very good") score += 1;
        // }
        // if (data.restedIndex !== undefined) {
        //     // 10
        //     if (data.restedIndex === "Not at all rested") score += 0;
        //     else if (data.restedIndex === "Sightly rested") score += 0.25;
        //     else if (data.restedIndex === "Somewhat rested") score += 0.5;
        //     else if (data.restedIndex === "Well-rested") score += 0.75;
        //     else if (data.restedIndex === "Very well-rested") score += 1;
        // }
        // if (data.napCount !== undefined) {
        //     // 11a
        //     score += 0;
        //     if (data.napCount > 0 && data.napDuration !== undefined) {
        //         // 11b
        //         if (data.napDuration < 61) score += 0;
        //         else score += 1;
        //     }
        // }
        // if (data.drinkCount !== undefined) {
        //     // 12a
        //     score += 0;
        //     if (data.drinkCount > 0 && data.lastDrinkTime !== undefined && data.sleepTryTime !== undefined) {
        //         // 12b
        //         const hourDiff = differenceInHoursBetween2Dates(data.lastDrinkTime, data.sleepTryTime);
        //         if (hourDiff > 4) score += 1;
        //         else score += 0;
        //     }
        // }
        // if (data.sodaCount !== undefined) {
        //     // 13a
        //     if (data.sodaCount > 0 && data.lastSodaTime !== undefined) {
        //         // 13b
        //         const hourDiff = differenceInHoursBetween2Dates(data.lastSodaTime, data.sleepTryTime);
        //         if (hourDiff > 6) score += 1;
        //         else score += 0;
        //     }
        // }
        // if (data.takenMedication !== undefined) {
        //     // 14a
        //     if (data.takenMedication === "No") score += 1;
        //     else score += 0;

        //     if (data.takenMedication === "Yes" && data.medications !== undefined) {
        //         // 14b
        //         score += 0;
        //     }
        //     if (data.takenMedication === "Yes" && data.dose !== undefined) {
        //         // 14c
        //         score += 0;
        //     }
        //     if (data.takenMedication === "Yes" && data.medicationTime !== undefined) {
        //         // 14d
        //         score += 0;
        //     }
        // }
        // if (data.comments !== undefined) {
        //     // 15
        //     score += 0;
        // }

        onComplete(data, score);
    };

    return (
        <div style={{ borderRadius: 10, padding: 20, fontFamily: "Roboto" }} className={`${getFontClass()} ${getColorClass()}`}>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="sleepDiary__form">
                <div>
                    <p>
                        1. Yesterday, I took a nap for _____ minutes.
                        <p style={{ textAlign: "justify", paddingTop: 5 }}>
                            <i>
                                A nap is a time you decided to sleep during the day, whether in bed or not in bed. Count all the times you napped at
                                any time from when you first got out of bed in the morning until you got into bed again at night. Count how many
                                minutes you napped during each nap and add them up to obtain the total time you napped in minutes.
                            </i>
                        </p>
                    </p>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                type="number"
                                variant="standard"
                                helperText={errors.question1?.message}
                                error={!!errors.question1?.message}
                                label="Nap time in minutes"
                                fullWidth
                                required
                                {...field}
                                inputProps={{ step: 1, min: 0 }}
                                style={{ marginTop: 5 }}
                            />
                        )}
                        name="question1"
                        control={control}
                    />
                </div>
                <div>
                    <p>
                        2. I went to bed at _____.
                        <p style={{ textAlign: "justify", paddingTop: 5 }}>
                            <i>Write the time that you got into bed. This may not be the time you began “trying” to fall asleep.</i>
                        </p>
                    </p>
                    <Controller
                        name="question2"
                        control={control}
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    label="Bed time"
                                    inputFormat="hh:mm A"
                                    {...field}
                                    renderInput={(params: any) => (
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            required
                                            error={errors && errors.question2 && !!errors.question2?.message}
                                            helperText={errors && errors.question2 && errors.question2?.message}
                                            {...params}
                                            style={{ marginTop: 5 }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                    />
                </div>
                <div>
                    <p>3. I was able to go to bed at my preferred time (as early or late as desired)</p>
                    <p style={{ textAlign: "justify", paddingTop: 5 }}>
                        <i>Respond YES or NO if you were able or not to go to bed at your preferred time.</i>
                    </p>
                    <Controller
                        control={control}
                        name="question3"
                        render={({ field }) => (
                            <FormControl component="fieldset" error={!!errors.question3?.message} required>
                                <RadioGroup row {...field}>
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                                <FormHelperText>{errors.question3?.message}</FormHelperText>
                            </FormControl>
                        )}
                    />
                </div>
                <div>
                    <p>
                        4. I turned off the lights at _____.
                        <p style={{ textAlign: "justify", paddingTop: 5 }}>
                            <i>Write the time that you turned the lights off with the intention to sleep.</i>
                        </p>
                    </p>
                    <Controller
                        name="question4"
                        control={control}
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    label="Lights off time"
                                    inputFormat="hh:mm A"
                                    {...field}
                                    renderInput={(params: any) => (
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            required
                                            error={errors && errors.question4 && !!errors.question4?.message}
                                            helperText={errors && errors.question4 && errors.question4?.message}
                                            {...params}
                                            style={{ marginTop: 5 }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                    />
                </div>
                <div>
                    <p>
                        5. Once the lights went out, I fell asleep in about _________ minutes.
                        <p style={{ textAlign: "justify", paddingTop: 5 }}>
                            <i>Write the time in minutes that it took you to fall asleep, after the lights were turned off.</i>
                        </p>
                    </p>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                type="number"
                                variant="standard"
                                helperText={errors.question5?.message}
                                error={!!errors.question5?.message}
                                label="Time to fall asleep in minutes"
                                fullWidth
                                required
                                {...field}
                                inputProps={{ step: 1, min: 0 }}
                                style={{ marginTop: 5 }}
                            />
                        )}
                        name="question5"
                        control={control}
                    />
                </div>
                <div>
                    <p>
                        6. During the night, I woke up for about _______ minutes.
                        <p style={{ textAlign: "justify", paddingTop: 5 }}>
                            <i>
                                Count all the times you woke up at any time from the time you first fell asleep and your final awakening, not counting
                                your final awakening. Count how many minutes you were awake during each awake time and add them up to obtain the total
                                time you were awake. For example, if you woke 3 times for 30 minutes first time, 20 minutes second time, and 5 minutes
                                third time, add them all up (30+20+5= 55 min)
                            </i>
                        </p>
                    </p>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                type="number"
                                variant="standard"
                                helperText={errors.question6?.message}
                                error={!!errors.question6?.message}
                                label="Time awake at night in minutes"
                                fullWidth
                                required
                                {...field}
                                inputProps={{ step: 1, min: 0 }}
                                style={{ marginTop: 5 }}
                            />
                        )}
                        name="question6"
                        control={control}
                    />
                </div>
                <div>
                    <p>
                        7. This morning I woke up at _____.
                        <p style={{ textAlign: "justify", paddingTop: 5 }}>
                            <i>Record the last time you woke up in the morning.</i>
                        </p>
                    </p>
                    <Controller
                        name="question7"
                        control={control}
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    label="Wake up time"
                                    inputFormat="hh:mm A"
                                    {...field}
                                    renderInput={(params: any) => (
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            required
                                            error={errors && errors.question7 && !!errors.question7?.message}
                                            helperText={errors && errors.question7 && errors.question7?.message}
                                            {...params}
                                            style={{ marginTop: 5 }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                    />
                </div>
                <div>
                    <p>
                        8. I got up at ______.
                        <p style={{ textAlign: "justify", paddingTop: 5 }}>
                            <i>
                                Record the time you got out of bed for the day with no further attempt at sleeping. This may be different from your
                                final awakening time (e.g. you may have woken up at 6:35 a.m. but did not get out of bed to start your day until 7:20
                                a.m.)
                            </i>
                        </p>
                        <Controller
                            name="question8"
                            control={control}
                            render={({ field }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <TimePicker
                                        label="Get up time"
                                        inputFormat="hh:mm A"
                                        {...field}
                                        renderInput={(params: any) => (
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                required
                                                error={errors && errors.question8 && !!errors.question8?.message}
                                                helperText={errors && errors.question8 && errors.question8?.message}
                                                {...params}
                                                style={{ marginTop: 5 }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </p>
                </div>
                <div>
                    <p>
                        9. I woke up spontaneously (i.e. without an alarm clock or any external noise or light).
                        <p style={{ textAlign: "justify", paddingTop: 5 }}>
                            <i>
                                Respond YES or NO, if you were woken up by an alarm clock or any other external noise such as traffic, sirens, pets or
                                noise created by other members of your household.
                            </i>
                        </p>
                        <Controller
                            control={control}
                            name="question9"
                            render={({ field }) => (
                                <FormControl component="fieldset" error={!!errors.question9?.message} required>
                                    <RadioGroup row {...field}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    <FormHelperText>{errors.question9?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </p>
                </div>
                <div>
                    <p>
                        10. I find that my sleep has been:{" "}
                        <p style={{ textAlign: "justify", paddingTop: 5 }}>
                            <i>
                                Record the number that best describes your sleep quality: 1 = very relaxing; 2 = restful; 3 = neutral; 4 = not very
                                relaxing; 5 = not restful at all.
                            </i>
                        </p>
                        <Controller
                            control={control}
                            name="question10"
                            render={({ field }) => (
                                <FormControl fullWidth component="fieldset" error={!!errors.question10?.message} {...field}>
                                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                                        <Slider
                                            defaultValue={1}
                                            step={null}
                                            min={1}
                                            max={5}
                                            valueLabelDisplay="auto"
                                            marks={[
                                                {
                                                    value: 1,
                                                    label: "Very relaxing",
                                                },
                                                {
                                                    value: 2,
                                                    label: "Restful",
                                                },
                                                {
                                                    value: 3,
                                                    label: "Neutral",
                                                },
                                                {
                                                    value: 4,
                                                    label: "Not very relaxing",
                                                },
                                                {
                                                    value: 5,
                                                    label: "Not restful at all",
                                                },
                                            ]}
                                            color={"primary"}
                                            {...field}
                                        />
                                    </div>
                                    <FormHelperText style={{ fontSize: 20 }}>{errors.question10?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </p>
                </div>
                <div className="sleep__buttons">
                    <Button
                        className="cta__back"
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "auto", marginTop: 20 }}
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </Button>

                    <Button className="cta__submit" variant="contained" type="submit" color="primary" style={{ marginLeft: "auto", marginTop: 20 }}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default SleepDiary;
