import React from "react";

interface Props {
    title: string;
    image: string;
    // eslint-disable-next-line
    onClick: (ev: any) => void;
}

const HomeCard: React.FC<Props> = ({ title, image, onClick }) => {
    return (
        <div onClick={onClick} className="homeCard__container">
            <div className="homeCard__titleContainer">
                <h5 className="homeCard__title">{title}</h5>
            </div>
            <img className="homeCard__image nofilterimg" src={image} alt={`${title}-image`} />
        </div>
    );
};

export default HomeCard;
