import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

// Interfaces
import { Log } from "../../../../interfaces/Log";
import { AdminUser } from "../../../../interfaces/AdminUser";

// Custom components
import SearchBar from "../../../general/SearchBar";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Settings
import settings from "../../../../settings.json";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import {
    collection,
    query,
    orderBy,
    onSnapshot,
    startAfter,
    limit,
    QueryDocumentSnapshot,
    DocumentData,
    getDocs,
    getCountFromServer,
} from "firebase/firestore";
import { genDoc } from "../../../../utils/firebase";

// Mui
import { Grid } from "@mui/material";

// Utils
import { formatDateIntoElapsedTimeEnglish } from "../../../../utils/date";

const COLLECTION = "Logs";

const Logs: React.FC = () => {
    const { currentAdmin, setLoading } = useFetchUser();

    // States
    const [logs, setLogs] = useState<Log[]>([]);
    const [filteredItems, setFilteredItems] = useState<Log[]>([]);
    const [count, setCount] = useState<number>(0);
    const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [users, setUsers] = useState<AdminUser[]>([]);

    const fetchFirstItems = () => {
        try {
            const localQuery = query(collection(firestore, COLLECTION), orderBy("createdAt", "desc"), limit(settings.page.rowsPerPage));
            return onSnapshot(localQuery, doc => {
                const data = doc.docs.map(genDoc<Log>()).filter((item: Log) => {
                    if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                        return true;
                    }
                    return !item.isDeleted;
                });
                setLastVisible(doc.docs[doc.docs.length - 1]);
                setLogs(data);
                setFilteredItems(data);
            });
        } catch (e) {
            console.error(e);
        }
    };

    const fetchCount = async () => {
        try {
            const col = collection(firestore, COLLECTION);
            const snapshot = await getCountFromServer(col);

            setCount(snapshot.data().count);
        } catch (e) {
            console.error(e);
        }
    };

    const fetchMoreItems = async () => {
        try {
            if (logs.length !== 0) {
                const localQuery = query(
                    collection(firestore, COLLECTION),
                    orderBy("createdAt", "desc"),
                    limit(settings.page.rowsPerPage),
                    startAfter(lastVisible)
                );

                const payload = await getDocs(localQuery);

                const data = payload.docs.map(genDoc<Log>()).filter((item: Log) => {
                    if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) {
                        return true;
                    }
                    return !item.isDeleted;
                });
                setLastVisible(payload.docs[payload.docs.length - 1]);
                setLogs([...logs, ...data]);
                setFilteredItems([...filteredItems, ...data]);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleSearch = (query: string) => {
        setSearchQuery(query);
    };

    const filterItems = async () => {
        const data = await getDocs(collection(firestore, COLLECTION));
        const localItems = data.docs.map(genDoc<Log>());

        const lowercaseSearchQuery = searchQuery.toLowerCase().trim();

        const localFilteredUsages = localItems.filter(x => {
            if (!x.adminId || !x.reason || !x.createdAt) return false;

            return (
                x.reason.toLowerCase().trim().includes(lowercaseSearchQuery) ||
                x.adminId.toLowerCase().trim().includes(lowercaseSearchQuery) ||
                moment(x.createdAt).format("DD-MM-YYYY HH:mm:ss").includes(lowercaseSearchQuery)
            );
        });

        setCount(localFilteredUsages.length);
        setFilteredItems(localFilteredUsages.sort((a, b) => (a.createdAt && b.createdAt ? a.createdAt.getTime() - b.createdAt.getTime() : -1)));
    };

    const fetchUsers = async () => {
        const payload = await getDocs(collection(firestore, "Users"));
        setUsers(payload.docs.map(genDoc<AdminUser>()));
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        setLoading(true);
        if (searchQuery) {
            filterItems();
        } else setFilteredItems(logs);
        setLoading(false);
    }, [searchQuery]);

    useEffect(() => {
        setLoading(true);
        fetchCount();
        const unsub = fetchFirstItems();
        setLoading(false);

        return () => {
            if (typeof unsub === "function") unsub();
        };
    }, []);

    return (
        <>
            <SearchBar onSearch={handleSearch} />

            <div id="scrollableDiv" className="scrollable__container">
                <InfiniteScroll
                    dataLength={filteredItems.length}
                    next={fetchMoreItems}
                    hasMore={filteredItems.length < count}
                    loader={<h4 className="infinitescroll__container__message">Fetching next items...</h4>}
                    endMessage={!filteredItems.length && <h4 className="infinitescroll__container__message">No data</h4>}
                    className="infinitescroll__container"
                    scrollableTarget="scrollableDiv"
                >
                    {filteredItems.map(x => (
                        <div key={x.id} className="infinitescroll__container__card">
                            <Grid item container>
                                <Grid item xs={12} sm={3}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">Date</div>
                                            </div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__text">
                                                    {x.createdAt && formatDateIntoElapsedTimeEnglish(x.createdAt)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">User</div>
                                            </div>
                                            <div className="centered__parent">
                                                <span className="infinitescroll__container__text">{users.find(u => u.id === x.adminId)?.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="centered__parent">
                                        <div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__colname">Reason</div>
                                            </div>
                                            <div className="centered__parent">
                                                <div className="infinitescroll__container__text">{x.reason}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        </>
    );
};

export default Logs;
