import React, { useState } from "react";

// Mui components
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { IconButton, InputAdornment } from "@mui/material";

// Icons
import { MdSearch, MdClear } from "react-icons/md";

interface Props {
    // eslint-disable-next-line
    onSearch?: (query: string) => void;
    children?: any;
}

const SearchBar: React.FC<Props> = ({ onSearch = null, children = null }) => {
    const [searchQuery, setSearchQuery] = useState("");

    return (
        <div className="searchbar__container">
            <Grid container>
                <Grid item xs={children ? 8 : 12}>
                    {typeof setSearchQuery === "function" && (
                        <TextField
                            className="searchbar__text"
                            value={searchQuery}
                            onChange={(e: any) => setSearchQuery(e.target.value)}
                            onKeyPress={(e: any) => e.key === "Enter" && typeof onSearch === "function" && onSearch(searchQuery)}
                            onKeyDown={(e: any) => {
                                if (e.key === "Escape") {
                                    setSearchQuery("");
                                    typeof onSearch === "function" && onSearch("");
                                }
                            }}
                            fullWidth
                            label="Search"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                setSearchQuery("");
                                                typeof onSearch === "function" && onSearch("");
                                            }}
                                        >
                                            {searchQuery ? <MdClear style={{ fill: "#616161" }} /> : <MdSearch style={{ fill: "#616161" }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={children ? 4 : 0}>
                    {children}
                </Grid>
            </Grid>
        </div>
    );
};

export default SearchBar;
