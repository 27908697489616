import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

// Mui
import Paper from "@mui/material/Paper";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

// Settings
import settings from "../../../settings.json";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Components
import MobileDrawer from "./MobileDrawer";
import Loading from "../../general/Loading";
import Title from "../../general/Title";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { genDoc } from "../../../utils/firebase";
import { Participant } from "../../../interfaces/Participant";

const FallsCalendarDialog: React.FC<any> = ({ open, setOpen }) => {
    const { currentParticipant, setYesterdayFallsCalendarCheck, yesterdayFallsCalendarCheck } = useFetchUser();
    const hist = useHistory();

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // Window closing listener
    window.addEventListener("beforeunload", async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.uses) {
            const data = await getDoc(doc(firestore, "Participants", currentParticipant.id));
            const participant = genDoc<Participant>()(data);

            if (participant && participant.id) {
                const index = participant.uses.findIndex(u => !u.end);
                if (index !== -1) {
                    if (participant.uses[index]) {
                        participant.uses[index].end = new Date();

                        await updateDoc(doc(firestore, "Participants", participant.id), {
                            uses: [...participant.uses],
                        });
                    }
                }
            }
        }
    });

    const updateUses = async () => {
        if (currentParticipant && currentParticipant.id) {
            if (currentParticipant.uses) {
                const data = await getDoc(doc(firestore, "Participants", currentParticipant.id));
                const participant = genDoc<Participant>()(data);

                if (participant && participant.id) {
                    const index = participant.uses.findIndex(u => !u.end);
                    if (index === -1) {
                        await updateDoc(doc(firestore, "Participants", participant.id), {
                            uses: [...participant.uses, { start: new Date() }],
                        });
                    }
                }
            } else {
                await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                    uses: [{ start: new Date() }],
                });
            }
        }
    };

    // Check if use has completed yesterday's fall calendar
    useEffect(() => {
        if (currentParticipant && currentParticipant.physical.falls && !yesterdayFallsCalendarCheck && currentParticipant.state !== "assessments") {
            const start = new Date(yesterday);
            const end = new Date(yesterday);

            start.setHours(0, 0, 0);
            end.setHours(23, 59, 59, 999);

            if (
                !currentParticipant.physical.falls.some(date => {
                    return (
                        new Date((date as any).date.seconds * 1000).getTime() >= start.getTime() &&
                        new Date((date as any).date.seconds * 1000).getTime() <= end.getTime()
                    );
                })
            ) {
                setOpen(true);
            }
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (currentParticipant && currentParticipant.id) updateUses();
    }, [currentParticipant]);

    return (
        <Dialog open={open} keepMounted onClose={() => setOpen(false)} className="portalLayout__dialog">
            <DialogTitle>
                <Title>Falls Calendar</Title>
            </DialogTitle>
            <DialogContent style={{ fontFamily: "Roboto" }}>
                <p style={{ color: "white" }}>
                    We've noticed that you did not fill your falls calendar yesterday, it's important to fill this information daily, even if you do
                    not experience a fall.
                </p>
                <p style={{ color: "white" }}>Would you like to fill it now?</p>
                <div className="centered__parent">
                    <div>
                        <Button
                            className="cta__submit"
                            variant="contained"
                            onClick={() => {
                                setOpen(false);
                                setYesterdayFallsCalendarCheck(true);

                                if (currentParticipant) {
                                    if (currentParticipant.state === "control" || currentParticipant.state === "intervention") {
                                        hist.push(`/physicalExercise/${yesterday.getTime()}`);
                                    } else {
                                        hist.push(`/home`);
                                    }
                                }
                            }}
                            style={{ marginRight: 10 }}
                        >
                            Complete my falls calendar
                        </Button>
                        <Button
                            className="cta__back"
                            variant="contained"
                            onClick={() => {
                                setOpen(false);
                                setYesterdayFallsCalendarCheck(true);
                            }}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

const PortalLayout: React.FC<any> = ({ children }) => {
    const { loading, fetchingUser, currentAdmin } = useFetchUser();

    // States
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    if (currentAdmin) return <Redirect to="/dashboard/login" />;

    if (!loading && !fetchingUser) {
        return (
            <>
                {width < settings.page.maxScreenWidth ? (
                    <div className="portalLayout__container">
                        <MobileDrawer />
                        <main className="portalLayout__content">
                            <div className="portalLayout__appBarSpacer" />
                            <div>
                                <Paper className="portalLayout__spacing portalLayout__background">{children}</Paper>
                            </div>
                        </main>
                        <FallsCalendarDialog open={open} setOpen={setOpen} />
                    </div>
                ) : (
                    <main className="portalLayout__container portalLayout__background">
                        <Paper className="portalLayout__spacing portalLayout__background">{children}</Paper>
                        <FallsCalendarDialog open={open} setOpen={setOpen} />
                    </main>
                )}
            </>
        );
    }

    return (
        <>
            <Loading loading={loading || fetchingUser} />
        </>
    );
};

export default PortalLayout;
