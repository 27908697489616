import React, { useEffect, useState } from "react";
import moment from "moment";

// Mui
import { Button } from "@mui/material";

// Firebase
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { genDoc } from "../../utils/firebase";

// Interfaces
import { Appointment } from "../../interfaces/Appointment";
import { AdminUser } from "../../interfaces/AdminUser";

// Utils
import { getAppointmentInProgress, getNextAppointment, getNextAppointmentDate } from "../../utils/general";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

const NextMeeting: React.FC = () => {
    const { currentParticipant } = useFetchUser();

    // States
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [coordinators, setCoordinators] = useState<AdminUser[]>([]);

    const fetchAppointments = async () => {
        if (currentParticipant && currentParticipant.id) {
            const payload = await getDocs(query(collection(firestore, "Appointments"), where("participantId", "==", currentParticipant.id)));

            setAppointments(payload.docs.map(genDoc<Appointment>()));
        }
    };

    const fetchCoordinators = async () => {
        const payload = await getDocs(query(collection(firestore, "Users")));
        setCoordinators(payload.docs.map(genDoc<AdminUser>()));
    };

    useEffect(() => {
        if (currentParticipant) {
            fetchAppointments();
            fetchCoordinators();
        }
    }, [currentParticipant]);

    return (
        <>
            {appointments.length && getNextAppointmentDate(appointments) ? (
                <div className="ct__neuropeak__content" style={{ marginLeft: 0, marginRight: 0 }}>
                    <h5 style={{ marginBottom: 20 }}>
                        <div className="home__title" style={{ marginBottom: 20 }}>Next meeting</div>
                        <div style={{ fontWeight: "normal", fontSize: "1.125rem" }}>
                            <span>
                                {getNextAppointment(appointments)?.title} @ {moment(getNextAppointmentDate(appointments)).format("dddd MMM D")}th{" "}
                            </span>
                            <span>
                                at {moment(getNextAppointmentDate(appointments)).format("hh:mm A")} with{" "}
                                {coordinators.find(x => x.id === getNextAppointment(appointments)?.coordinatorId)?.firstName}{" "}
                                {coordinators.find(x => x.id === getNextAppointment(appointments)?.coordinatorId)?.lastName}
                            </span>
                        </div>
                    </h5>

                    {getAppointmentInProgress(appointments) && (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                const nextAppointment = getNextAppointment(appointments);
                                if (nextAppointment) {
                                    let url = "";
                                    if (nextAppointment.linkUrl.includes("http")) url = nextAppointment.linkUrl;
                                    else url = `https://${nextAppointment.linkUrl}`;

                                    window.open(url, "_blank");
                                }
                            }}
                        >
                            <span>
                                Go to meeting <span>→</span>
                            </span>
                        </Button>
                    )}
                </div>
            ) : (
                <>
                    <h5 className="home__title">Next meeting: </h5>
                    <h5>No upcoming meeting</h5>
                </>
            )}
        </>
    );
};

export default NextMeeting;
