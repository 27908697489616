export const insomniaSeverityIndex = {
    title: "Insomnia Severity Index",
    about: "",
    instructions:
        "For each question, please tell me the number that best describes your answer. Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).",
    questions: [
        {
            questionNumber: "1",
            question: "Difficulty falling asleep",
            answers: ["None", "Mild", "Moderate", "Severe", "Very Severe"],
            type: "slider",
            instructions: "Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).",
        },
        {
            questionNumber: "2",
            question: "Difficulty staying asleep",
            answers: ["None", "Mild", "Moderate", "Severe", "Very Severe"],
            type: "slider",
            instructions: "Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).",
        },
        {
            questionNumber: "3",
            question: "Problems waking up too early",
            answers: ["None", "Mild", "Moderate", "Severe", "Very Severe"],
            type: "slider",
            instructions: "Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).",
        },
        {
            questionNumber: "4",
            question: "How SATISFIED/DISSATISFIED are you with your CURRENT sleep pattern?",
            answers: ["Very Satisfied", "Satisfied", "Moderately Satisfied", "Dissatisfied", "Very Dissatisfied"],
            type: "slider",
            instructions: "Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).",
        },
        {
            questionNumber: "5",
            question: "How NOTICEABLE to others do you think your sleep problem is in terms of impairing the quality of your life?",
            answers: ["Not at all Noticeable", "A Little", "Somewhat", "Much", "Very Much Noticeable"],
            type: "slider",
            instructions: "Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).",
        },
        {
            questionNumber: "6",
            question: "How WORRIED/DISTRESSED are you about your current sleep problem?",
            answers: ["Not at all Worried", "A Little", "Somewhat", "Much", "Very Much Worried"],
            type: "slider",
            instructions: "Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).",
        },
        {
            questionNumber: "7",
            question:
                "To what extent do you consider your sleep problem to INTERFERE with your daily functioning (e.g. daytime fatigue, mood, ability to function at work/daily chores, concentration, memory, mood, etc.) CURRENTLY?",
            answers: ["Not at all Interfering", "A Little", "Somewhat", "Much", "Very Much Interfering"],
            type: "slider",
            instructions: "Please rate the CURRENT (i.e. LAST 2 WEEKS) SEVERITY of your insomnia problem(s).",
        },
    ],
};
