import { ContentBlock, EditorState, RichUtils } from "draft-js";

import { TextAlignOption } from "../../config/textAlign";
import { EditorConfigTextAlign } from "../../config/defaultToolbar";

import Option from "../../components/Option";
import { getSelectedBlockList } from "../../utils/blockType";

interface TextAlignProps {
    config: EditorConfigTextAlign;
    editorState: EditorState;
    // eslint-disable-next-line
    onChange: (s: EditorState) => void;
}

const TextAlign = ({ config, editorState, onChange }: TextAlignProps) => {
    const { options } = config;

    const isActive = (alignment: string) => {
        const alignments = getSelectedBlockList(editorState).map((block?: ContentBlock) => block?.get("type"));

        return alignments.includes(alignment);
    };

    const toggleTextAlign = (alignment: string) => {
        const newState = RichUtils.toggleBlockType(editorState, alignment);

        if (newState) onChange(newState);
    };

    return (
        <div>
            {options.map(({ style, title, Icon }: TextAlignOption, index: number) => (
                <Option key={index} onClick={toggleTextAlign} active={isActive(style)} value={style} title={title}>
                    {typeof Icon === "string" ? Icon : <Icon />}
                </Option>
            ))}
        </div>
    );
};

export default TextAlign;
